const FlexibleTradingSystem = {
  en: {
    header: "Flexible trading system",
    list: [
      {
        title: "Market Depth",
        text: "MetaTrader 5 provides a powerful trading system with Market Depth, allowing traders to access detailed information about bid and ask prices, as well as the volume available at each price level. This feature provides insights into market liquidity and price dynamics.",
      },
      {
        title: "Separate Accounting of Orders and Trades",
        text: "The platform offers a system of separate accounting of orders and trades, allowing traders to track and manage their positions more effectively. This feature provides transparency and clarity in monitoring trading activities.",
      },
      {
        title: "Netting and Hedging Options",
        text: "MetaTrader 5 supports both order accounting systems. Traders can choose between the traditional netting system, which aggregates positions on the same instrument, and the hedging option system, which allows opening multiple positions in the same instrument, including in opposite directions.",
      },
      {
        title: "Order Execution Modes",
        text: "The platform offers four order execution modes to cater to different trading objectives. Traders can choose from Instant execution, Request execution, Market execution, and Exchange execution, depending on their specific needs and preferences.",
      },
      {
        title: "Comprehensive Trade Order Support",
        text: "MetaTrader 5 supports all types of trade orders, including market orders for immediate execution, pending orders for future execution at predetermined conditions, stop orders to limit potential losses, and trailing stops to secure profits as the market moves in the trader's favor.",
      },
      {
        title: "Flexibility for Different Trading Strategies",
        text: "With the diverse range of order types and execution modes available, traders can employ various trading strategies to achieve success in the financial markets. MetaTrader 5 provides the flexibility and tools necessary to implement and execute different trading approaches.",
      },
    ],
  },
  ru: {
    header: "Гибкая торговая система",
    list: [
      {
        title: "Глубина рынка",
        text: "MetaTrader 5 предоставляет мощную торговую систему с Глубиной рынка, позволяющей трейдерам получить подробную информацию о ценах на покупку и продажу, а также о объеме доступном на каждом уровне цен. Эта функция предоставляет информацию о ликвидности рынка и динамике цен.",
      },
      {
        title: "Раздельное учета ордеров и сделок",
        text: "Платформа предлагает систему раздельного учета ордеров и сделок, что позволяет трейдерам более эффективно отслеживать и управлять своими позициями. Эта функция обеспечивает прозрачность и ясность в мониторинге торговых операций.",
      },
      {
        title: "Опции хеджирования и неттинга",
        text: "MetaTrader 5 поддерживает оба метода учета ордеров. Трейдеры могут выбирать между традиционной системой неттинга, которая агрегирует позиции по одному и тому же финансовому инструменту, и системой хеджирования, которая позволяет открывать несколько позиций на одном и том же финансовом инструменте, в том числе в противоположных направлениях.",
      },
      {
        title: "Режимы исполнения ордеров",
        text: "Платформа предлагает четыре режима исполнения ордеров, чтобы удовлетворить разные торговые цели. Трейдеры могут выбирать между мгновенным исполнением, исполнением по запросу, рыночным исполнением и исполнением на бирже, в зависимости от своих конкретных потребностей и предпочтений.",
      },
      {
        title: "Комплексная поддержка ордеров",
        text: "MetaTrader 5 поддерживает все типы торговых ордеров, включая рыночные ордера для мгновенного исполнения, отложенные ордера для будущего исполнения при заранее установленных условиях, стоп-ордера для ограничения потенциальных потерь и трейлинг-стопы для обеспечения прибыли при движении рынка в сторону трейдера.",
      },
      {
        title: "Гибкость для различных торговых стратегий",
        text: "С разнообразием типов ордеров и режимов исполнения, доступных в MetaTrader 5, трейдеры могут применять различные торговые стратегии для достижения успеха на финансовых рынках. MetaTrader 5 предоставляет гибкость и инструменты, необходимые для реализации и выполнения различных торговых подходов.",
      },
    ],
  },
};

const ProfessionalTechnicalAnalysis = {
  en: {
    header: "Professional technical analysis",
    list: [
      {
        title: "All-in-One Concept",
        text: "MetaTrader 5 offers a comprehensive platform that combines various functionalities and impressive analytical tools.",
      },
      {
        title: "Multiple Chart Capacity",
        text: "Traders can open up to 100 charts of currency and stock quotes simultaneously, allowing for comprehensive analysis and monitoring.",
      },
      {
        title: "Extensive Timeframes",
        text: "The platform provides 21 different timeframes, enabling detailed analysis of minor price movements and accommodating various trading strategies.",
      },
      {
        title: "Wide Range of Technical Indicators",
        text: "With over 80 technical indicators and analytical tools, including graphic objects, MetaTrader 5 offers extensive options for detailed analysis of quote dynamics.",
      },
      {
        title: "Additional Analytical Resources",
        text: "Traders have access to a vast array of additional analytical resources beyond the built-in tools. They can choose from thousands of free indicators from the Code Base, purchase or rent applications from the Market, and even order custom indicators from professional programmers via the Freelance service.",
      },
      {
        title: "MQL5 Programming Language",
        text: "The high-performance MQL5 programming language allows traders to create their own unique indicators with customized characteristics, enhancing their analytical capabilities.",
      },
      {
        title: "Detailed and Accurate Technical Analysis",
        text: "The combination of the built-in tools, additional resources, and programming capabilities empowers traders to conduct the most detailed and accurate technical analysis of quotes",
      },
    ],
  },
  ru: {
    header: "Профессиональный технический анализ",
    list: [
      {
        title: "Комплексный подход",
        text: "MetaTrader 5 предоставляет обширную платформу, объединяющую различные функциональные возможности и впечатляющие аналитические инструменты.",
      },
      {
        title: "Возможность работы с несколькими графиками",
        text: "Трейдеры могут открывать до 100 графиков котировок валют и акций одновременно, что позволяет проводить обширный анализ и мониторинг.",
      },
      {
        title: "Разнообразные временные интервалы",
        text: "Платформа предоставляет 21 различный временной интервал, позволяя детально анализировать мелкие движения цен и применять различные стратегии торговли.",
      },
      {
        title: "Широкий выбор технических индикаторов",
        text: "С более чем 80 техническими индикаторами и аналитическими инструментами, включая графические объекты, MetaTrader 5 предлагает обширные возможности для детального анализа динамики котировок.",
      },
      {
        title: "Дополнительные аналитические ресурсы",
        text: "Трейдеры имеют доступ к огромному количеству дополнительных аналитических ресурсов, превышающих встроенные инструменты. Они могут выбирать из тысяч бесплатных индикаторов из Code Base, покупать или арендовать приложения с рынка, а также заказывать индивидуальные индикаторы у профессиональных программистов через услугу Freelance.",
      },
      {
        title: "Язык программирования MQL5",
        text: "Высокопроизводительный язык программирования MQL5 позволяет трейдерам создавать собственные уникальные индикаторы с настраиваемыми характеристиками, повышая их аналитические возможности.",
      },
      {
        title: "Детальный и точный технический анализ",
        text: "Сочетание встроенных инструментов, дополнительных ресурсов и возможностей программирования позволяет трейдерам проводить наиболее детальный и точный технический анализ котировок.",
      },
    ],
  },
};

const FundamentalAnalysis = {
  en: {
    header: "Fundamental analysis",
    list: [
      {
        title: "Fundamental Analysis",
        text: "MetaTrader 5 recognizes the importance of fundamental analysis as a tool for forecasting price dynamics of financial instruments.",
      },
      {
        title: "Monitoring Economic and Industrial Indicators",
        text: "Traders can engage in constant monitoring of open-source economic and industrial indicators as part of their fundamental analysis.",
      },
      {
        title: "Original Fundamental Data",
        text: "MetaTrader 5 provides access to original fundamental data, allowing traders to access and utilize accurate and reliable information for their analysis.",
      },
      {
        title: "News Reports Integration",
        text: "International news agencies broadcast news reports directly into the MetaTrader 5 platform, ensuring traders have access to essential financial information in real-time.",
      },
      {
        title: "Economic Calendar",
        text: "The platform includes an economic calendar that features the main macroeconomic indicators from different countries. Traders can analyze the impact of these indicators on various financial instruments.",
      },
      {
        title: "Informed Trading Decisions",
        text: "By staying tuned to financial news and using the economic calendar, traders can make more informed and weighted trading decisions directly from the MetaTrader 5 platform.",
      },
    ],
  },
  ru: {
    header: "Фундаментальный анализ",
    list: [
      {
        title: "Фундаментальный анализ",
        text: "MetaTrader 5 признает важность фундаментального анализа как инструмента для прогнозирования динамики цен на финансовые инструменты.",
      },
      {
        title: "Мониторинг экономических и промышленных показателей",
        text: "Трейдеры могут постоянно мониторить общедоступные экономические и промышленные показатели в рамках своего фундаментального анализа.",
      },
      {
        title: "Оригинальные фундаментальные данные",
        text: "MetaTrader 5 предоставляет доступ к оригинальным фундаментальным данным, позволяя трейдерам получать доступ и использовать точную и надежную информацию для своего анализа.",
      },
      {
        title: "Интеграция новостных репортажей",
        text: "Международные новостные агентства транслируют новостные репортажи непосредственно в платформу MetaTrader 5, обеспечивая трейдерам доступ к существенной финансовой информации в режиме реального времени.",
      },
      {
        title: "Экономический календарь",
        text: "Платформа включает экономический календарь, в котором представлены основные макроэкономические показатели из разных стран. Трейдеры могут анализировать влияние этих показателей на различные финансовые инструменты.",
      },
      {
        title: "Информированные торговые решения",
        text: "Следя за финансовыми новостями и используя экономический календарь, трейдеры могут принимать более информированные и обоснованные торговые решения непосредственно из платформы MetaTrader 5.",
      },
    ],
  },
};

const TradingSignalsAndCopyTrading = {
  en: {
    header: "Trading signals and Copy Trading",
    list: [
      {
        title: "Automated Signal Replication",
        text: "MetaTrader 5 offers the option to subscribe to signals of successful traders, allowing automatic replication of their trades on your account.",
      },
      {
        title: "Vast Signal Selection",
        text: "Choose from a wide range of free and paid signals available for demo or live accounts, with options for preferred risk level and profitability.",
      },
      {
        title: "Easy Subscription Process",
        text: "Subscribing to a selected signal provider is quick and straightforward, requiring just a few clicks to enable automatic trading on your platform.",
      },
      {
        title: "Flexible Monitoring",
        text: "Traders have the flexibility to choose whether to actively monitor trading results or fully rely on the behavior of the subscribed signal.",
      },
      {
        title: "Copy Trading Option",
        text: "With the copy trading feature in MetaTrader 5, you no longer need to engage in manual trading or market analysis. Instead, let your platform execute trades automatically, emulating the strategies of professional traders.",
      },
      {
        title: "Trade Like a Pro",
        text: "By utilizing the copy trading option, you can trade with the expertise and success of experienced traders, even if you have limited time or knowledge in the markets. Let your platform handle trading for you, providing the potential for profitable results.",
      },
    ],
  },
  ru: {
    header: "Торговые сигналы и копирование сделок",
    list: [
      {
        title: "Автоматизированное копирование сигналов",
        text: "MetaTrader 5 предоставляет возможность подписки на сигналы успешных трейдеров, что позволяет автоматически копировать их сделки на ваш счет.",
      },
      {
        title: "Широкий выбор сигналов",
        text: "Выбирайте из широкого спектра бесплатных и платных сигналов, доступных для демонстрационных или реальных счетов, с возможностью выбора желаемого уровня риска и прибыльности.",
      },
      {
        title: "Простой процесс подписки",
        text: "Подписка на выбранного поставщика сигналов происходит быстро и просто, требуя всего нескольких кликов для активации автоматической торговли на вашей платформе.",
      },
      {
        title: "Гибкий мониторинг",
        text: "У трейдеров есть гибкость в выборе, активно мониторить результаты торговли или полностью полагаться на поведение подписанного сигнала.",
      },
      {
        title: "Опция копирования сделок",
        text: "С функцией копирования сделок в MetaTrader 5 вам больше не нужно заниматься ручной торговлей или анализом рынка. Вместо этого позвольте вашей платформе автоматически выполнять сделки, эмулируя стратегии профессиональных трейдеров.",
      },
      {
        title: "Торгуйте как профессионал",
        text: "Используя опцию копирования сделок, вы можете торговать с опытом и успехом опытных трейдеров, даже если у вас ограниченное время или знания в области рынка. Позвольте вашей платформе заниматься торговлей за вас и получить потенциал для прибыльных результатов.",
      },
    ],
  },
};

const MobileTrading = {
  en: {
    header: "Mobile Trading",
    list: [
      {
        title: "Accessible Trading",
        text: "Enjoy full-fledged financial market trading without the need for a desktop computer.",
      },
      {
        title: "Mobile Versions",
        text: "Install the MetaTrader 5 mobile application on your Android or iOS smartphone or tablet.",
      },
      {
        title: "Comprehensive Trading Functions",
        text: "The mobile applications support a complete set of trading functions, allowing you to execute trades, manage orders, and access market data.",
      },
      {
        title: "Analytical Capabilities",
        text: "Benefit from extensive analytical capabilities with the use of technical indicators and graphical objects, enabling detailed market analysis on the go.",
      },
      {
        title: "Account Monitoring",
        text: "Keep track of your account status, including balance, equity, and positions, directly from your mobile device.",
      },
      {
        title: "Trading History",
        text: "Access your trading history to review past trades and performance, assisting you in evaluating your trading strategies",
      },
      {
        title: "Convenience at Your Fingertips",
        text: "With the MetaTrader 5 mobile platform, you have easy and convenient access to the financial markets anytime and anywhere, putting the power of trading right at your fingertips.",
      },
    ],
  },
  ru: {
    header: "Мобильная торговля",
    list: [
      {
        title: "Доступная торговля",
        text: "Наслаждайтесь полноценной торговлей на финансовых рынках без необходимости использовать настольный компьютер.",
      },
      {
        title: "Мобильные версии",
        text: "Установите мобильное приложение MetaTrader 5 на ваш смартфон или планшет с операционной системой Android или iOS.",
      },
      {
        title: "Полноценные функции торговли",
        text: "Мобильные приложения поддерживают полный набор функций торговли, позволяя вам выполнять сделки, управлять ордерами и получать доступ к рыночным данным.",
      },
      {
        title: "Аналитические возможности",
        text: "Пользуйтесь обширными аналитическими возможностями с использованием технических индикаторов и графических объектов, позволяя подробный анализ рынка в пути.",
      },
      {
        title: "Мониторинг счета",
        text: "Следите за состоянием вашего счета, включая баланс, актив и позиции, непосредственно с вашего мобильного устройства.",
      },
      {
        title: "История торговли",
        text: "Получите доступ к истории ваших сделок, чтобы просматривать прошлые сделки и производительность, помогая вам оценить ваши торговые стратегии",
      },
      {
        title: "Удобство под рукой",
        text: "С мобильной платформой MetaTrader 5 у вас есть легкий и удобный доступ к финансовым рынкам в любое время и в любом месте, что помещает силу торговли прямо под вашими пальцами.",
      },
    ],
  },
};

const WebTrading = {
  en: {
    header: "Web Trading",
    list: [
      {
        title: "Web-Based Trading Solution",
        text: "The MetaTrader 5 Web Platform is an ideal solution when installing a desktop terminal or using a mobile version is not feasible.",
      },
      {
        title: "Browser Access",
        text: "Trade on the Forex and exchange markets directly from any browser, eliminating the need for specific software installations",
      },
      {
        title: "Operating System Compatibility",
        text: "The web platform is compatible with various operating systems, including Windows, Mac, and Linux, providing accessibility to a wide range of users.",
      },
      {
        title: "Comprehensive Functionality: ",
        text: "Access the rich functionality of MetaTrader 5 on the web, including market analysis tools and the ability to place orders",
      },
      {
        title: "Analytical Capabilities",
        text: "Analyse financial markets and make informed trading decisions through the web platform's analytical features",
      },
      {
        title: "Internet Connection Requirement",
        text: "The only requirement is an internet connection to access and utilize the MetaTrader 5 Web Platform, making it a convenient option for traders on the go or in situations where software installations are not possible",
      },
    ],
  },
  ru: {
    header: "Веб-торговля",
    list: [
      {
        title: "Веб-платформа для торговли",
        text: "Мета-трейдер 5 Веб-платформа - идеальное решение, когда установка настольного терминала или использование мобильной версии не является возможным.",
      },
      {
        title: "Доступ из браузера",
        text: "Торгуйте на рынке Форекс и биржи непосредственно из любого браузера, избегая необходимости устанавливать специальное программное обеспечение.",
      },
      {
        title: "Совместимость с различными операционными системами",
        text: "Веб-платформа совместима с различными операционными системами, включая Windows, Mac и Linux, обеспечивая доступность для широкого круга пользователей.",
      },
      {
        title: "Обширные функциональные возможности",
        text: "Пользуйтесь богатым функционалом MetaTrader 5 в Интернете, включая инструменты анализа рынка и возможность размещения ордеров.",
      },
      {
        title: "Аналитические возможности",
        text: "Анализируйте финансовые рынки и принимайте обоснованные решения о торговле с помощью аналитических функций веб-платформы.",
      },
      {
        title: "Требование к подключению к интернету",
        text: "Единственным требованием является наличие подключения к интернету для доступа и использования веб-платформы MetaTrader 5, что делает ее удобным вариантом для трейдеров в движении или в ситуациях, когда установка программного обеспечения невозможна.",
      },
    ],
  },
};

const AlgorithmicTrading = {
  en: {
    header: "Algorithmic Trading",
    list: [
      {
        title: "Algorithmic Trading",
        text: "MetaTrader 5 offers an impressive feature known as Algorithmic Trading, which enables automated trading using specialized applications called trading robots or Expert Advisors (EAs).",
      },
      {
        title: "Automated Trading Operations: ",
        text: "Expert Advisors can analyze quotes and execute trading operations based on predefined algorithms without the need for manual intervention by the trader.",
      },
      {
        title: "Robotize Your Strategy",
        text: "If you have a successful trading strategy, you can transform it into an Expert Advisor, freeing yourself from routine market analysis and trading tasks.",
      },
      {
        title: "Development Environment",
        text: "The MetaTrader 5 system provides a comprehensive development environment for creating and optimizing your own trading robots. You can leverage the high-performance MQL5 programming language to create EAs that employ heavy calculations for accurate technical analysis and forecasting",
      },
      {
        title: "Ready-to-Use Trading Applications",
        text: "If you're not inclined towards programming, MetaTrader 5 offers a wide range of ready-to-use trading applications. Choose from thousands of free apps available in the Code Base, purchase or rent products from the Market, or even order custom applications from professional programmers through the Freelance service.",
      },
      {
        title: "Customization and Flexibility",
        text: "With MetaTrader 5, you have the freedom to choose the approach that best suits your needs and preferences. Whether you prefer programming your own Expert Advisor or utilizing existing trading applications, the platform provides ample options for customization and flexibility in automated trading",
      },
    ],
  },
  ru: {
    header: "Алгоритмическая торговля",
    list: [
      {
        title: "Алгоритмическая Торговля",
        text: "MetaTrader 5 предоставляет впечатляющую функцию, известную как Алгоритмическая Торговля, которая позволяет автоматизировать торговлю с использованием специализированных приложений, называемых торговыми роботами или советниками (EA).",
      },
      {
        title: "Автоматизированные Торговые Операции: ",
        text: "Советники могут анализировать котировки и выполнять торговые операции на основе заранее заданных алгоритмов, без необходимости вмешательства трейдера.",
      },
      {
        title: "Автоматизация вашей стратегии",
        text: "Если у вас есть успешная торговая стратегия, вы можете превратить ее в советника, освободив себя от рутины анализа рынка и торговых задач.",
      },
      {
        title: "Среда Разработки",
        text: "Система MetaTrader 5 предоставляет обширную среду разработки для создания и оптимизации собственных торговых роботов. Вы можете использовать высокопроизводительный язык программирования MQL5 для создания EA, осуществляющих сложные вычисления для точного технического анализа и прогнозирования",
      },
      {
        title: "Готовые Торговые Приложения",
        text: "Если вы не склонны к программированию, MetaTrader 5 предлагает широкий выбор готовых к использованию торговых приложений. Вы можете выбрать из тысяч бесплатных приложений из Code Base, приобрести или арендовать продукты из Market или даже заказать собственные приложения у профессиональных программистов через службу Freelance.",
      },
      {
        title: "Настройка и Гибкость",
        text: "С MetaTrader 5 у вас есть свобода выбора подхода, который наилучшим образом соответствует вашим потребностям и предпочтениям. Независимо от того, предпочитаете ли вы программировать своего собственного советника или использовать готовые торговые приложения, платформа предоставляет множество возможностей для настройки и гибкости в автоматизированной торговле",
      },
    ],
  },
};

const getFlexibleTradingSystem = (language) => {
  return FlexibleTradingSystem[language];
};
const getProfessionalTechnicalAnalysis = (language) => {
  return ProfessionalTechnicalAnalysis[language];
};
const getFundamentalAnalysis = (language) => {
  return FundamentalAnalysis[language];
};
const getTradingSignalsAndCopyTrading = (language) => {
  return TradingSignalsAndCopyTrading[language];
};
const getMobileTrading = (language) => {
  return MobileTrading[language];
};
const getWebTrading = (language) => {
  return WebTrading[language];
};
const getAlgorithmicTrading = (language) => {
  return AlgorithmicTrading[language];
};

export {
  getFlexibleTradingSystem,
  getProfessionalTechnicalAnalysis,
  getFundamentalAnalysis,
  getTradingSignalsAndCopyTrading,
  getMobileTrading,
  getWebTrading,
  getAlgorithmicTrading,
};
