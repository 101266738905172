import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
// content
import getMT5PlatfromContent from "constant/services/MT5/MT5Platform";

const MT5Plaftrom = () => {
  const { language } = useContext(LanguageContext);
  const content = getMT5PlatfromContent(language);
  return (
    <section className="mt-secondary lg:mt-primary bg-newBlue">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="w-full flex flex-col-reverse lg:flex-row items-center gap-small lg:gap-secondary py-secondary ">
          <div className="flex-1 flex flex-col gap-small text-lg text-white">
            {content.MT5Plaform.text.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
          <div className="flex-1">
            <img
              src={content.MT5Plaform.image}
              alt=""
              className="w-full object-cover"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MT5Plaftrom;
