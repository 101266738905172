import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
// content
import getCommitmentContent from "constant/services/AssestManagement/AssetManagement";
// icons
import { CheckCircle } from "@phosphor-icons/react";

const AssetCommitment = () => {
  const { language } = useContext(LanguageContext);
  const content = getCommitmentContent(language);
  return (
    <section className="my-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-font text-center flex flex-col gap-ssmall lg:w-3/4 lg:mx-auto ">
          <h1 className="text-4xl">{content.title}</h1>
          <p className="text-xl">{content.header}</p>
        </div>

        <div className="mt-secondary grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-secondary text-center">
          {content.list.map((item, index) => (
            <div key={index} className="flex flex-col gap-ssmall items-center ">
              <span>
                <CheckCircle size={40} weight="light" color="#0b72ba" />
              </span>
              <h4 className="text-xl ">{item}</h4>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AssetCommitment;
