import BASE_API_URL from "../BaseUrl";

const performLogout = async (token) => {
  try {
    const response = await fetch(`${BASE_API_URL}/logout`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Logout");
      return responseData;
    } else {
      console.log(" Logout successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Logout.");
  }
};

export default performLogout;
