import PrimeBrokerageHero from "./Components/PrimeBrokerageHero";
import PrimeBrokerageSolution from "./Components/PrimeBrokerageSolution";

import OpenAccount from "../UI/OpenAccount";

const PrimeBrokerage = () => {
  return (
    <main>
      <PrimeBrokerageHero />
      <PrimeBrokerageSolution />
      <OpenAccount />
    </main>
  );
};

export default PrimeBrokerage;
