import React from "react";

const TwoColumnsGrid = ({ content, center }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-secondary md:gap-y-primary  text-font">
      {content.map((feature, index) => (
        <div
          key={index}
          className={`md:px-small lg:px-secondary md:odd:border-r md:odd:border-font ${
            center && "text-center"
          } `}
        >
          <h2 className="text-2xl md:text-3xl mb-ssmall md:mb-small">
            {feature.title}
          </h2>
          <p>{feature.text}</p>
        </div>
      ))}
    </div>
  );
};

export default TwoColumnsGrid;
