import { useState, useContext, useEffect } from "react";
// UI
import Container from "../../../../../components/Container/Container";

// forms Componenets
import CustomForm from "../../Custom Form/CustomForm";
// inputs data
import CIF_Corporate_Form_conten from "./Inputs Data/CIF-Corporate";
import FATCA_Corporate_Form_content from "./Inputs Data/FATCA-coporate";
import Upload_File_Form_conent from "./Inputs Data/UploadFile-corporate";
import MoveForms from "../../MoveForms";

import CustomUploadFrom from "../../Custom Form/CustomUploadFrom";

import performGetFormStatus from "../../../../../utils/account and forms/GetFormStatus";
import { UserInFoContext } from "../../../../../context/UserContext";

const CorporateRoute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState("");
  const [formStatus, setFormStatus] = useState(null);
  const { userData } = useContext(UserInFoContext);

  const token = userData?.token;

  const formSubmittedHandler = (value) => {
    setFormSubmitted(value);
  };
  // useEffect to get the formStatus and save into formStatus state, empty dependencis to render each time the component is mount
  useEffect(() => {
    const getFormStatus = async () => {
      try {
        setIsLoading(true);
        const data = await performGetFormStatus(token);
        if (data.status_code === 200) {
          setFormStatus(data.data);
        } else {
          setFormStatus(null);
        }
      } catch (error) {
        console.log(`failed ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    getFormStatus();
  }, []);

  useEffect(() => {
    if (formStatus?.cif_is_done && !formStatus?.fatca_is_done) {
      setFormSubmitted("Move-To-FATCA");
    } else if (formStatus?.cif_is_done && formStatus?.fatca_is_done) {
      setFormSubmitted("Move-To-Upload");
    } else if (formStatus?.document_is_done) {
    }
  }, [formStatus]);

  const renderFormContent = () => {
    if (formSubmitted === "CIF") {
      return (
        <MoveForms
          CIF={true}
          onClick={formSubmittedHandler}
          message="You have successfully filled out the CIF form. Now we are moving on to the FATCA form. Please click to proceed and begin filling out the form."
        />
      );
    } else if (formSubmitted === "FATCA") {
      return (
        <MoveForms
          FATCA={true}
          onClick={formSubmittedHandler}
          message="You have successfully filled out the FATCA form. Now we are moving on to the Upload documents form. Please click to proceed and begin filling out the form."
        />
      );
    } else if (formSubmitted === "Upload") {
      return (
        <MoveForms
          UPLOAD={true}
          onClick={formSubmittedHandler}
          message="
              You have successfully filled out the upload form. Now you have completed all the required forms. Thank you."
        />
      );
    } else if (
      formSubmitted === "Move-To-Upload" &&
      !formStatus?.document_is_done
    ) {
      return (
        <CustomUploadFrom
          onSubmit={formSubmittedHandler}
          formInputs={Upload_File_Form_conent}
        />
      );
    } else if (
      formSubmitted === "Move-To-FATCA" &&
      !formStatus?.fatca_is_done
    ) {
      return (
        <CustomForm
          formInputs={FATCA_Corporate_Form_content}
          FATCA={true}
          onSubmit={formSubmittedHandler}
        />
      );
    } else if (formSubmitted === "" && !formStatus?.cif_is_done) {
      return (
        <CustomForm
          onSubmit={formSubmittedHandler}
          CIF={true}
          formInputs={CIF_Corporate_Form_conten}
        />
      );
    } else if (
      formStatus.cif_is_done &&
      formStatus.fatca_is_done &&
      formStatus.document_is_done
    ) {
      return (
        <MoveForms
          DONE={true}
          onClick={formSubmittedHandler}
          message="
          Thank you for your interest in our services! You've already completed the registration process. If you need to make any changes or updates to your information, please feel free to contact our support team. Otherwise, feel free to explore the rest of our website for more information and resources.
"
        />
      );
    }
  };
  return (
    <main className="my-secondary lg:my-primary">
      <Container className="px-smallContainer lg:px-mainContainer">
        {renderFormContent()}
      </Container>
    </main>
  );
};

export default CorporateRoute;
