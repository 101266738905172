import BASE_API_URL from "../BaseUrl";
const performSetCifId = async (token, cifId) => {
  try {
    const formData = new FormData();
    formData.append("cifid", cifId);

    const response = await fetch(`${BASE_API_URL}/set-cifid`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Set Cif id");
      return responseData;
    } else {
      console.log("set Cif id successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Cif id.");
  }
};

export default performSetCifId;
