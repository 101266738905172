import { createContext, useState, useEffect } from "react";
import GetFaqs from "utils/Insigths/Faq/GetFaqs";

export const FAQContext = createContext();

export const FAQProvider = ({ children }) => {
  const [isLoading, setIsloaing] = useState(false);
  const [faqContent, setFaqContent] = useState();
  const [fetchStatus, setFetchStatus] = useState("");

  useEffect(() => {
    const getFaqHandler = async () => {
      try {
        setIsloaing(true);
        const faqData = await GetFaqs();

        if (faqData.status_code === 200) {
          setFaqContent(faqData.data);
        } else {
          setFetchStatus("failed");
        }
      } catch (error) {
        console.log(error);
        setFetchStatus("failed");
      } finally {
        setIsloaing(false);
      }
    };

    getFaqHandler();
  }, []);

  return (
    <FAQContext.Provider value={{ isLoading, faqContent, fetchStatus }}>
      {children}
    </FAQContext.Provider>
  );
};
