import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
import Button from "components/UI/Button";
const OpenAccount = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section className="relative services-bg mt-prmary py-primary lg:py-big">
      <div className="absolute top-0 bottom-0 left-0 right-0 min-w-full min-h-full bg-[#000000bf]  blur-small-background z-[1]"></div>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-center text-white relative z-[100] lg:w-3/4 lg:mx-auto">
          <h4 className="text-3xl md:text-4xl mb-small">
            {`${
              language === "en"
                ? "Ready to get started?"
                : "Готовы приступить к работе?"
            } `}
          </h4>

          <p className="text-xl mb-secondary">
            {`${
              language === "en"
                ? " Discover endless possibilities with ARK Capital: easily open an account and explore a wide range of asset classes, expanding your investment opportunities with simplicity and ease"
                : "Откройте для себя бесконечные возможности с ARK Capital: легко открывайте счет и изучайте широкий спектр классов активов, расширяя свои инвестиционные возможности с простотой и непринужденностью"
            }`}
          </p>

          <Button to={"/register"}>
            {" "}
            {`${language === "en" ? "Open Account" : "Открыть счет"} `}
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default OpenAccount;
