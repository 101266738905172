import React from "react";

const ServicesHeader = ({ children, width }) => {
  return (
    <div className={`${width} lg:mx-auto `}>
      <h1 className="text-font text-2xl sm:text-3xl lg:text-4xl text-center">
        {children}
      </h1>
    </div>
  );
};

export default ServicesHeader;
