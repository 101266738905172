const FATCA_Individual_Form_content = [
  // indetiy details
  {
    inputs: [
      {
        pageTitle: "Identity details",
      },

      {
        label: "Full name ",
        type: "text",
        name: "Full_name",
        error: false,
        require: false,
        hide: true,
      },

      {
        label: "Family name ",
        type: "text",
        name: "Family_name",
        require: true,
        error: false,
      },

      {
        label: "First or given name",
        type: "text",
        name: "First_or_given_name",
        require: true,
        error: false,
      },

      {
        label: "Middle name(s)",
        type: "text",
        name: "Middle_name_s",
        require: false,
        error: false,
      },

      {
        label: "Date of Birth",
        type: "date",
        name: "Date_of_Birth",
        error: false,
        require: false,
        hide: true,
      },

      {
        label: "Place of Birth",
        type: "select",
        name: "Place_of_Birth",
        placeholder: "select a country",
        require: false,
        hide: true,
        error: false,
        country: true,
      },

      {
        label: "Nationality(ies)",
        type: "select",
        name: "Nationality_ies",
        placeholder: "select a country",
        require: false,
        nationality: true,
        hide: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "Current residential address",
        megaName: "Current_residential_address",

        megaInputs: [
          {
            label: "House",
            type: "text",
            name: "House",
            require: false,
            error: false,
          },
          {
            label: "Apartment",
            type: "text",
            name: "Apartment",
            require: false,
            error: false,
          },
          {
            label: "Suite name",
            type: "text",
            name: "Suite_name",
            require: false,
            error: false,
          },
          {
            label: "Number",
            type: "number",
            name: "Number_z",
            require: false,
            error: false,
          },
          {
            label: "Street",
            type: "text",
            name: "Street",
            require: false,
            error: false,
          },
          {
            label: "Town",
            type: "text",
            name: "Town",
            require: false,
            error: false,
          },
          {
            label: "City",
            type: "text",
            name: "City",
            require: false,
            error: false,
          },
          {
            label: "Province",
            type: "text",
            name: "Province",
            require: false,
            error: false,
          },
          {
            label: "County",
            type: "select",
            name: "County",
            require: false,
            error: false,
            country: true,
          },
          {
            label: "Postal Code",
            type: "text",
            name: "Postal_Code",
            require: false,
            error: false,
          },
          {
            label: "ZIP Code",
            type: "number",
            name: "ZIP_Code",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Mailing address",
        megaName: "Mailing_address",

        megaInputs: [
          {
            label: "House",
            type: "text",
            name: "House",
            require: false,
            error: false,
          },
          {
            label: "Apartment",
            type: "text",
            name: "Apartment",
            require: false,
            error: false,
          },
          {
            label: "Suite name",
            type: "text",
            name: "Suite_name",
            require: false,
            error: false,
          },
          {
            label: "Number",
            type: "number",
            name: "Number_z",
            require: false,
            error: false,
          },
          {
            label: "Street",
            type: "text",
            name: "Street",
            require: false,
            error: false,
          },
          {
            label: "Town",
            type: "text",
            name: "Town",
            require: false,
            error: false,
          },
          {
            label: "City",
            type: "text",
            name: "City",
            require: false,
            error: false,
          },
          {
            label: "Province",
            type: "text",
            name: "Province",
            require: false,
            error: false,
          },
          {
            label: "County",
            type: "select",
            name: "County",
            require: false,
            error: false,
            country: true,
          },
          {
            label: "Postal Code",
            type: "text",
            name: "Postal_Code",
            require: false,
            error: false,
          },
          {
            label: "ZIP Code",
            type: "number",
            name: "ZIP_Code",
            require: false,
            error: false,
          },
        ],
      },

      {
        label: "United Status Person indicia",
        name: "United_Status_Person_indicia",
        type: "select",
        placeholder: "Select",
        require: false,
        error: false,

        options: [
          {
            label: "I’m a US citizen or resident",
            value: "I’m a US citizen or resident",
          },
          {
            label: "I’m born in the US",
            value: "I’m born in the US",
          },
          {
            label:
              "I have a US mailing or residence address, including a US PO Box",
            value:
              "I have a US mailing or residence address, including a US PO Box",
          },
          {
            label: " I have a US telephone number",
            value: " I have a US telephone number",
          },
          {
            label:
              "A person with a US address has a Power of Attorney on my account",
            value:
              "A person with a US address has a Power of Attorney on my account",
          },
        ],
      },
    ],
  },

  //   controlling person
  {
    inputs: [
      {
        pageTitle: "Controlling Person",
      },

      {
        content: [
          {
            title:
              "ONLY TO BE COMPLETED BY ANY INDIVIDUAL WHO IS A CONTROLLING PERSON OF: ",
            text: [
              "1)	A PASSIVE NON-FINANCIAL FOREIGN ENTITY OR ",
              "2)	AN INVESTMENT ENTITY LOCATED IN A NON-PARTICIPATING JURISDICTION AND MANAGED BY ANOTHER FINANCIAL INSTITUTION.",
            ],
          },

          {
            title: "",

            text: [
              "A Controlling Person is a natural person who exercises control over an Entity. ",

              "Where an Entity is treated as a Passive Non-Financial (Foreign) Entity (“NFE”) then a financial institution must determine whether such Controlling Persons are Reportable Persons. This definition corresponds to the term “beneficial owner” as described in Recommendation 10 and the Interpretative Note on Recommendation 10 of the Financial Action Task Force Recommendations. The term “Entity” means a legal person or a legal arrangement, such as a corporation, organization, partnership, trust, or foundation.",
            ],
          },
        ],
      },

      {
        mega: true,
        magaTitle:
          "ONLY TO BE COMPLETED BY ANY INDIVIDUAL WHO IS A CONTROLLING PERSON OF:",
        megaName:
          "ONLY_TO_BE_COMPLETED_BY_ANY_INDIVIDUAL_WHO_IS_A_CONTROLLING_PERSON_OF",
        megaInputs: [
          {
            label: "Entity name",
            type: "text",
            name: "Entity_name",
            require: false,
            error: false,
          },

          {
            label: "Entity type",
            type: "select",
            name: "Entity_type",
            require: false,
            placeholder: "Select",
            error: false,
            options: [
              { label: "Legal person", value: "Legal person" },
              { label: "Trust", value: "Trust" },
              { label: "Legal arrangement", value: "Legal arrangement" },
            ],
          },

          {
            label: "Further details",
            name: "Further_details",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  // FATCA Declaration – US Person
  {
    inputs: [
      {
        pageTitle: "FATCA Declaration – US Person",
      },

      {
        mega: true,
        magaTitle: "Are You A US Person",
        megaName: "Are_You_A_US_Person",
        megaInputs: [
          {
            label: "Select",
            name: "selectedValue",
            type: "select",
            placeholder: "select",

            require: true,
            error: false,
            options: [
              {
                label: "I confirm that I am a US Person",
                value: " I confirm that I am a US Person",
              },
              {
                label: "I confirm that I am NOT a US Person",
                value: " I confirm that I am NOT a US Person",
              },
            ],
          },

          {
            label: "(if yes) My US Tax Identification Number (TIN) is",
            name: "if_yes_My_US_Tax_Identification_Number_TIN_is",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle:
          "Please complete the following table indicating where you are a tax resident and your TIN for each country/jurisdiction indicated. ",
        megaName:
          "Please_complete_the_following_table_indicating_where_you_are_a_tax_resident_and_your_TIN_for_each_country_jurisdiction_indicated",

        megaInputs: [
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_1",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_2",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_3",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_3",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_3",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        specialDesign: true,
        magaTitle:
          "Kindly explain for what reason a TIN cannot be provided (for instance country does not issue TINs)",
        megaName:
          "Kindly_explain_for_what_reason_a_TIN_cannot_be_provided_for_instance_country_does_not_issue_TINs",
        megaInputs: [
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_1",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_1",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_2",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_2",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_3",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_3",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  // //Declaration and Undertaking
  {
    inputs: [
      {
        pageTitle: "Declaration and Undertaking",
      },

      {
        content: [
          {
            text: [
              "I understand that the information supplied by me is covered by the full provisions of the terms and conditions governing my relationship with Ark Capital Management (Dubai) Limited (hereafter “Ark”) setting out how Ark may use and share the information supplied by me. ",

              "I acknowledge that the information contained in this form may be provided to the tax authorities of the country/jurisdiction in which my account(s) is/are maintained and exchanged with tax authorities of another country/jurisdiction or countries/jurisdictions in which I may be tax resident pursuant to intergovernmental agreements to exchange financial account information. ",

              "I declare that all statements made in this declaration are, to the best of my knowledge and belief, correct and complete. I undertake to advise Ark within 30 days of any change in circumstances which affects my tax residency status or causes the information contained herein to become incorrect or incomplete. I understand that Ark may require me to provide an updated version of this form.",

              "I understand that intentional provision of incorrect of false information to Ark may result in an administrative penalty of USD 5,500 (or equivalent in AED) imposed by Ark’s regulatory authority.",
            ],
          },
        ],
      },

      {
        label: "Date",
        name: "Date_of",
        type: "date",
        require: true,
        error: false,
      },

      {
        label: "Capacity",
        name: "Capacity",
        type: "text",
        require: true,
        error: false,
      },

      {
        label: "Signature",
        name: "Signature",
        type: "text",
        require: true,
        error: false,
      },
    ],
  },
];

export default FATCA_Individual_Form_content;
