import { useState, useRef, useEffect } from "react";
import ConfirmRestPassword from "./ConfirmRestPassword";
// UI
import Spinner from "../../../../components/UI/Spinner";
// custom hook
import useInput from "../../../../form/Components/Hooks/user-input";
// input
import Input from "../../../../form/Components/Inputs/Input";
import PasswordInput from "../../../../form/Components/Inputs/PasswordInput";
// error message
import inputFromMessage from "../../../../form/Components/Messages/inputErrorMessage";
// utils
import performRestPassword from "../../../../utils/Registartion/RestPassword";

const RestPasswordForm = ({ onTogleForms }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // style of inputs
  const inputStyle =
    "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue ";

  const clearErrorMessage = () => {
    setFormState("");
  };
  // inputs
  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHandler,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const submitForm = async (event) => {
    event.preventDefault();
    clearErrorMessage();
    if (!emailIsValid) {
      setFormState("empty");
      return;
    }

    const formState = {
      email: emailInput,
    };

    try {
      setIsLoading(true);
      const data = await performRestPassword(formState);
      if (data.status_code === 422) {
        setFormState("invalid-email");
      } else {
        setIsSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setFormState("failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="py-secondary lg:py-primary">
      {!isSuccess && (
        <>
          <div className="flex items-center justify-center text-center lg:w-3/4 mx-auto">
            <p className="text-xl">
              Please enter your email address to initiate the password reset
              process. You will receive a verification code via email. Once
              received, enter the code below along with your new password.
            </p>
          </div>
          <div>
            <form className="lg:w-1/2 mx-auto my-secondary">
              {formState === "empty" && (
                <p className="mb-2 text-red-400 text-sm">
                  Please ensure that you have entered a valid email address
                </p>
              )}
              {formState === "invalid-email" && (
                <p className="mb-2 text-red-400 text-sm">
                  The selected email is invalid, Please use a valid email.
                </p>
              )}
              {!isSuccess && (
                <>
                  <Input
                    boxStyle={""}
                    inputStyle={inputStyle}
                    type={"email"}
                    name={"email"}
                    id={"email"}
                    placeholder={"Your Email"}
                    value={emailInput}
                    onChange={(event) => {
                      emailChangeHandler(event);
                      clearErrorMessage();
                    }}
                    onBlur={emailBlurHandler}
                    onFocus={emailFocusHandler}
                    isFocus={emailIsFocus}
                    hasError={emailHasError}
                    errorMessage={inputFromMessage.email}
                  />

                  <button
                    type="button"
                    onClick={submitForm}
                    className="w-full bg-blue text-white p-2 rounded-md mt-4 flex items-center justify-center gap-x-2"
                  >
                    {isLoading && <Spinner />}
                    Reset Passowrd
                  </button>
                </>
              )}
            </form>
          </div>

          <button
            onClick={() => onTogleForms("sign-in")}
            className="text-center underline flex items-center justify-center w-full"
          >
            Return to login
          </button>
        </>
      )}

      {isSuccess && <ConfirmRestPassword onToggle={onTogleForms} />}
    </main>
  );
};

export default RestPasswordForm;
