import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const changeLanguageHandler = (language) => {
    setLanguage(language);
  };
  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, changeLanguageHandler }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
