import AssetManagementHero from "./components/AssetManagementHero";
import AssetCommitment from "./components/AssetCommitment";
import OpenAccount from "../UI/OpenAccount";

const AssetManagement = () => {
  return (
    <main>
      <AssetManagementHero />
      <AssetCommitment />
      <OpenAccount />
    </main>
  );
};

export default AssetManagement;
