const Upload_File_Form_conent = [
  {
    inputs: [
      {
        pageTitle: "Identification",
      },

      {
        content: [
          {
            text: [
              "Important to note",

              "1- All documents must be certified by an accredited third party (notary, public official, bank, post office, chartered personnel, or lawyer",

              "2- Proof of address (Less than 3 months old) can include a utility bill, bank letters, or building society statements and need to be certified. Please note that mobile phone bills are not accepted.",

              "Document should not older than 6 months",
            ],
          },
        ],
      },

      {
        label: "Certificate of Incorporation/Trade license",
        type: "file",
        name: "Certificate_of_Incorporation_Trade_license",
        require: true,
        error: false,
      },

      {
        label: "Articles of Association/Memorandum of Association",
        type: "file",
        name: "Articles_of_Association_Memorandum_of_Association",
        require: true,
        error: false,
      },

      {
        label: "Certificate of Incumbency",
        type: "file",
        name: "Certificate_of_Incumbency",
        require: true,
        error: false,
      },

      {
        label:
          "Board Resolution authorizing the opening of the account with Ark Capital Management and designating authorized traders",
        type: "file",
        name: "Board_Resolution_authorizing_the_opening_of_the_account_with_Ark_Capital_Management_and_designating_authorized_traders",
        require: true,
        error: false,
      },

      {
        label:
          "Register of shareholders. The document provided must show total shares add up to 100%",
        type: "file",
        name: "Register_of_shareholders_The_document_provided_must_show_total_shares_add_up_to_100",
        require: true,
        error: false,
      },

      {
        label:
          "Registry of Directors and their passport copy and proof of address",
        type: "file",
        name: "Registry_of_Directors_and_their_passport_copy_and_proof_of_address",
        require: true,
        error: false,
      },

      {
        label:
          "List of Authorized Signatories and their passport copy and proof of address",
        type: "file",
        name: "List_of_Authorized_Signatories_and_their_passport_copy_and_proof_of_address",
        require: true,
        error: false,
      },

      {
        label:
          "The latest Ownership Structure provided is to be dated and properly signed",
        type: "file",
        name: "The_latest_Ownership_Structure_provided_is_to_be_dated_and_properly_signeds",
        require: true,
        error: false,
      },

      {
        label:
          "Latest 3-month Bank Statement from the account that will transfer funds  (stamped and signed)",
        name: "Latest_3_month_Bank_Statement_from_the_account_that_will_transfer_funds",
        type: "file",
        require: true,
        error: false,
      },

      {
        label: "Latest Portfolio Statement (stamped and signed)",
        name: "Latest_Portfolio_Statement",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Copy of International Passport  (certified by authorized Chief Accountant / Lawyer / Notary)",
        name: "Copy_of_International_Passport",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Copy of National ID  (certified by authorized Chief Accountant / Lawyer / Notary)",
        name: "Copy_of_National_ID",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Proof of address (certified by authorized Chief Accountant / Lawyer / Notary)",
        name: "Proof_of_address",
        type: "file",
        require: true,
        error: false,
      },

      {
        label: "Copy of Visa page and Emirates ID (UAE residents only) ",
        name: "Copy_of_Visa_page_and_Emirates_ID",
        type: "file",
        require: false,
        error: false,
      },

      {
        label:
          "Proof of Source of Wealth for UBOs and Source of Initial capital for the Company",
        name: "Proof_of_Source_of_Wealth_for_UBOs_and_Source_of_Initial_capital_for_the_Company",
        type: "file",
        require: true,
        error: false,
      },

      {
        label: "Certificate of Incorporation",
        name: "Certificate_of_Incorporation",
        type: "file",
        require: true,
        error: false,
      },

      {
        label: "Certificate of Name Change (if applicable)",
        name: "Certificate_of_Name_Change",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Certificate of Incumbency (if applicable)",
        name: "Certificate_of_ncumbency",
        type: "file",
        require: false,
        error: false,
      },

      {
        label:
          "Passport copies and proof of addresses of shareholders with more than 25% direct stake",
        name: "Passport_copies_and_proof_of_addresses_of_shareholders_with_more_than_25_direct_stake",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Register of Directors, providing copies of their passport, National ID and proof of address",
        name: "Register_of_Directors_providing_copies_of_their_passport_National_ID_and_proof_of_address",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "List of Authorized Signatories, providing copies of their passport and proof of address",
        name: "List_of_Authorized_Signatories_providing_copies_of_their_passport_and_proof_of_address",
        type: "file",
        require: true,
        error: false,
      },

      {
        label: "Latest Audited Financial Statements",
        name: "Latest_Audited_Financial_Statements",
        type: "file",
        require: true,
        error: false,
      },
    ],
  },
];

export default Upload_File_Form_conent;
