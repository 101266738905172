import BASE_API_URL from "../BaseUrl";

const performGetFormStatus = async (token) => {
  try {
    const response = await fetch(`${BASE_API_URL}/get-form-status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to get form Status");
      return responseData;
    } else {
      console.log("Get form Status successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Get form status.");
  }
};

export default performGetFormStatus;
