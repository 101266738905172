import { useContext } from "react";
import Container from "components/Container/Container";
// context
import { LanguageContext } from "context/LanguageContext.js";
// content
import { ownerImage } from "../../About-index-image";
import getOwnerContent from "constant/About/Team/Owner";

const Owner = () => {
  const { language } = useContext(LanguageContext);
  const content = getOwnerContent(language);
  return (
    <section>
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <div className="flex flex-col gap-small lg:gap-0 lg:flex-row items-center ">
          <div className="flex-1">
            <img
              src={ownerImage}
              alt=""
              className="lg:w-3/4 mx-auto rounded-md drop-shadow-md"
            />
          </div>

          <div className="flex-1">
            <h2 className="text-3xl text-font font-medium font-[600] mb-ssmall">
              {content.title}
            </h2>
            <div className="flex flex-col gap-2 pr-small">
              {content.text.map((txt, indx) => (
                <p key={indx}>{txt}</p>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Owner;
