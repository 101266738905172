import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
import {
  contactInfoContent,
  locationContent,
} from "../../../constant/ContactInfo.js";

const ContactInfo = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="flex-1 flex flex-col sm:flex-row lg:flex-col  justify-between gap-secondary">
      <div className="flex-1">
        <h2 className="text-font text-2xl mb-4 sm:mb-6 lg:mb-8 ">
          {`${language === "en" ? "Contact Info " : "Контактная информация "}`}
        </h2>
        <div className="flex flex-col gap-y-3 ">
          {contactInfoContent.map((item, index) => (
            <div
              key={index}
              className="flex text-sm items-center text-lightFont gap-x-4"
            >
              <span>{item.icon}</span>

              {!item.contact && <p>{item.text}</p>}

              {item.contact?.map((item, innderIndex) => (
                <a
                  key={innderIndex}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="transition ease-in duration-100 hover:text-font hover:font-semibold hover:underline"
                >
                  {item.text}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1">
        <h2 className="text-font text-2xl mb-4 sm:mb-6 lg:mb-8 ">
          {`${language === "en" ? "Our Location" : "Наше местоположение "}`}
        </h2>

        <div className="flex items-cener gap-x-4">
          <span className="text-lightFont">{locationContent.icon}</span>

          <a
            href={"https://maps.app.goo.gl/RJz61T9kBjQKnFDM6"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col gap-y-1 hover:underline"
          >
            {locationContent.text.map((text, index) => (
              <p key={index} className="text-sm text-lightFont">
                {text}
              </p>
            ))}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
