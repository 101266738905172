import Container from "../../components/Container/Container";

import RemoveAccountForm from "./components/RemoveAccountForm";
import RemoveAccountInfo from "./components/RemoveAccountInfo";

const RemoveAccount = () => {
  return (
    <section className="py-secondary lg:my-primary">
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <div 
          className="flex flex-col justify-between gap-secondary">
          <RemoveAccountInfo />
          <RemoveAccountForm />
        </div>
      </Container>
    </section>
  );
};

export default RemoveAccount;
