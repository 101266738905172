import BASE_API_URL from "../../BaseUrl";

const GetBlogByID = async (id) => {
  try {
    const response = await fetch(`${BASE_API_URL}/blog/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error("");
  }
};

export default GetBlogByID;
