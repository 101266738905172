import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";

// UI
import Container from "components/Container/Container";
// content
import getCustodyAssetContent from "constant/services/Custody/CustodyAssest";
// icon
import { CheckCircle } from "@phosphor-icons/react";

const CustodyAssest = () => {
  const { language } = useContext(LanguageContext);
  const content = getCustodyAssetContent(language);
  return (
    <section className="my-secondary lg:my-primary  text-font">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-font text-center flex flex-col gap-ssmall lg:w-3/4 lg:mx-auto mb-small md:mb-secondary">
          <h1 className="text-3xl lg:text-4xl">{content.header.title}</h1>
          <p className="text-lg">{content.header.subtitle}</p>
        </div>

        <div className="flex flex-col-reverse lg:flex-row items-center gap-secondary">
          <div className="flex-1">
            <p className="text-xl mb-small lg:mb-14">{content.header.text}</p>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {content.list.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center text-lg tracking-wide gap-x-2 transition-all ease-in duration-200 hover:pl-1 "
                >
                  <CheckCircle size={26} weight="light" /> {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1">
            <img src={content.image} alt="" className="rounded-md shadow" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CustodyAssest;
