import { useContext } from "react";
// user context
import { UserInFoContext } from "../../../context/UserContext";

// component
import WelcomePage from "./No Type/WelcomePage";

import IndividualRoute from "./Sales Force Registration/Individual/IndividualRoute";
import CorporateRoute from "./Sales Force Registration/Corporate/CorporateRoute";

const SalesForceForm = () => {
  const { userData } = useContext(UserInFoContext);
  const userType = userData?.type;

  return (
    <main>
      {userType === null && <WelcomePage />}

      {userType === "individual" && <IndividualRoute />}

      {userType === "corporate" && <CorporateRoute />}
    </main>
  );
};

export default SalesForceForm;
