const ArkProFeatures = {
  en: {
    features: [
      {
        title: "Global Market Access:",
        text: "Gain access to a diverse range of global markets, including stocks, bonds, commodities and derivatives. With ARK PRO, you can trade on major exchanges worldwide, allowing you to capitalize on market opportunities across different time zones and regions.",
      },
      {
        title: "Advanced Trading Tools:",
        text: "Our platform is equipped with a comprehensive set of advanced trading tools to help you make informed decisions. Benefit from real-time market data, customizable charts, technical analysis indicators, and trading signals. Use our intuitive interface to execute trades quickly and efficiently, ensuring you never miss out on potential profit opportunities.",
      },
      {
        title: "Multi-Asset Trading:",
        text: "Diversify your portfolio and take advantage of various asset classes with our multi-asset trading capabilities. Whether you prefer trading equities, options, futures, or forex, our platform offers a seamless trading experience across different markets, all in one place.",
      },
      {
        title: "Risk Management Tools:",
        text: "Protect your investments and manage your risk effectively with our range of risk management tools. Set stop-loss orders, trailing stops, and take-profit levels to automate your trading strategy and mitigate potential losses. Our platform empowers you to define and control your risk parameters with ease.",
      },
      {
        title: "Mobile Trading:",
        text: "Trade on the go with our mobile trading app, available for iOS and Android devices. Access real-time market data, execute trades, and monitor your portfolio from anywhere, at any time. Stay connected to the markets and never miss a trading opportunity.",
      },
      {
        title: "Account Management and Support:",
        text: "Enjoy a seamless account management experience with ARK PRO. Fund your account, track your trading history, and generate customized reports for analysis. Our dedicated customer support team is available to assist you with any inquiries or technical issues you may encounter along the way.",
      },
    ],
  },
  ru: {
    features: [
      {
        title: "Доступ к мировым рынкам:",
        text: "Получите доступ к разнообразным мировым рынкам, включая акции, облигации, товары и производные финансовые инструменты. С ARK PRO вы можете торговать на крупных биржах по всему миру, что позволяет вам использовать возможности рынка в различных часовых поясах и регионах.",
      },
      {
        title: "Продвинутые инструменты для торговли:",
        text: "Наша платформа оснащена комплексом продвинутых инструментов для принятия обоснованных решений. Воспользуйтесь данными о рынке в режиме реального времени, настраиваемыми графиками, индикаторами технического анализа и сигналами для торговли. Используйте нашу интуитивную платформу для быстрого и эффективного выполнения сделок, чтобы ничего не упустить из виду в потенциально прибыльных сделках.",
      },
      {
        title: "Торговля разнообразными активами:",
        text: "Диверсифицируйте свой портфель и воспользуйтесь разнообразными классами активов благодаря нашим возможностям мультиактивной торговли. Независимо от того, предпочитаете ли вы торговлю акциями, опционами, фьючерсами или валютой, наша платформа предлагает безпрепятственный опыт торговли на разных рынках, все в одном месте.",
      },
      {
        title: "Инструменты управления рисками:",
        text: "Защищайте свои инвестиции и эффективно управляйте рисками с помощью нашего набора инструментов управления рисками. Устанавливайте стоп-заказы, трейлинг-стопы и уровни прибыли, чтобы автоматизировать вашу стратегию торговли и смягчать потенциальные убытки. Наша платформа дает вам возможность определить и контролировать ваши параметры риска с легкостью.",
      },
      {
        title: "Мобильная торговля:",
        text: "Торгуйте на ходу с нашим мобильным приложением для iOS и Android. Получайте доступ к данным о рынке в реальном времени, выполняйте сделки и отслеживайте свой портфель из любой точки мира в любое время. Оставайтесь на связи с рынками и не упускайте возможности для торговли.",
      },
      {
        title: "Управление счетом и поддержка:",
        text: "Наслаждайтесь безпроблемным опытом управления счетом с ARK PRO. Пополняйте свой счет, отслеживайте историю ваших торгов и создавайте настраиваемые отчеты для анализа. Наша специализированная команда службы поддержки готова помочь вам с любыми вопросами или техническими проблемами, с которыми вы можете столкнуться на этом пути.",
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getArkProFeaturesContent = (language) => {
  return ArkProFeatures[language];
};

export default getArkProFeaturesContent;
