import { CheckCircle } from "@phosphor-icons/react";

const MT5HeroContent = {
  en: {
    header: "The best application for trading Currencies, Stocks and Futures",
    feature: [
      {
        title: "Extensive analysis & charting suite",
        icon: <CheckCircle />,
      },
      {
        title: "Unrestricted automated trading capabilities",
        icon: <CheckCircle />,
      },
      {
        title: "Complimentary plug-ins for clients",
        icon: <CheckCircle />,
      },
    ],
  },
  ru: {
    header: "Лучшее приложение для торговли валютами, акциями и фьючерсами",
    feature: [
      {
        title: "Обширный набор анализа и графиков",
        icon: <CheckCircle />,
      },
      {
        title: "Неограниченные возможности автоматизированной торговли",
        icon: <CheckCircle />,
      },
      {
        title: "Бесплатные плагины для клиентов",
        icon: <CheckCircle />,
      },
    ],
  },
};

const getArMT5HeroContent = (language) => {
  return MT5HeroContent[language];
};

export default getArMT5HeroContent;
