import { FullMT5 } from "../../Image-index";
import Container from "../../../../components/Container/Container";
const MT5FullImage = () => {
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div>
          <img src={FullMT5} alt="" />
        </div>
      </Container>
    </section>
  );
};

export default MT5FullImage;
