import React, { useEffect, useRef, useState } from "react";

import Button from "./Button";
const ImageContentColumn = ({ image, subHeader, header, text, button, to }) => {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`flex flex-col lg:flex-row items-center justify-between gap-small lg:gap-secondary lg:even:flex-row-reverse transition ease-linear duration-300  ${
        isVisible
          ? "opacity-100 translate-y-[0]"
          : "opacity-0 translate-y-[25%]"
      }`}
    >
      <div className="flex-1 h-[400px]">
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover drop-shadow-lg rounded-md"
        />
      </div>
      <div className="flex-1 text-font">
        <h2 className="text-3xl lg:text-4xl mb-ssmall lg:mb-small">{header}</h2>
        <p className="text-lg ">{text}</p>
      </div>
    </div>
  );
};

export default ImageContentColumn;
