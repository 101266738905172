import { useContext } from "react";
// UI
import Container from "../../../../components/Container/Container";
import Button from "../../../../components/UI/Button";
// content data
import howItWorkContent from "../../../../constant/Register/HowItWork";
// context
import { UserInFoContext } from "../../../../context/UserContext";

const WelcomePage = () => {
  const { accountTypeModalHanlder } = useContext(UserInFoContext);

  return (
    <section className="py-secondary lg:py-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className=" text-font flex flex-col lg:flex-row gap-secondary lg:gap-primary ">
          <div className="flex-1 pr-14 ">
            <h1 className="text-3xl mb-6">Here we are</h1>
            <p className="text-lg mb-10">{howItWorkContent.header}</p>

            <Button onClick={accountTypeModalHanlder}>Next</Button>
          </div>

          <div className="flex-1">
            <p className="text-3xl mb-6">Heres how it works</p>
            {howItWorkContent.howItWorks.map((content, index) => (
              <div key={index} className="flex  gap-x-3 mb-6 last:mb-0 ">
                <div className="text-blue">{content.icon}</div>
                <div>
                  <h2 className="text-xl font-euroSemibold mb-1">
                    {content.title}
                  </h2>
                  <p>{content.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WelcomePage;
