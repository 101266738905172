import BASE_API_URL from "../BaseUrl";

const performRestPassword = async (formData) => {
  try {
    const response = await fetch(`${BASE_API_URL}/password/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Rest Email");
      return responseData;
    } else {
      console.log("Rest Email successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Rest Email.");
  }
};

export default performRestPassword;
