import { useContext } from "react";

import { Link } from "react-router-dom";
// services sub menu data
import getServicesContent from "constant/Header/ServicesContent";
import { CaretLeft } from "@phosphor-icons/react";
import { LanguageContext } from "context/LanguageContext";

const MobileSubServices = ({ active, onTabsHandler, onMobileHanlder }) => {
  const { language } = useContext(LanguageContext);
  const servicesContent = getServicesContent(language);
  const duoHandler = () => {
    onTabsHandler();
    onMobileHanlder();
  };

  return (
    <div
      className={`flex flex-col px-6 pb-10 mt-6 bg-font absolute top-0 left-0 pt-32 w-full h-full transition ease-in-out duration-300 overflow-y-scroll lg:hidden ${
        active
          ? "translate-x-[0%] opacity-100 select-auto visible"
          : "translate-x-[100%] opacity-0 select-none invisible"
      }`}
    >
      <button
        className="text-white text-start border-b-2 pb-3 border-blue w-full flex items-center gap-2 mb-6"
        onClick={onTabsHandler}
      >
        <CaretLeft size={22} weight="bold" />
        {`${language === "en" ? "Back" : "назад"}`}
      </button>
      {servicesContent.map((item, index) => (
        <div
          key={index}
          className="text-white border-b  border-white w-full py-4"
        >
          <h6 className="text-2xl  block mb-ssmall">{item.tab}</h6>

          <div className="text-base">{item.content.text}</div>

          {item.content.list && (
            <ul className="mt-small flex flex-col gap-2 text-">
              {item.content?.list?.map((item, index) => (
                <li key={index} onClick={duoHandler} className="text-lg">
                  {item}
                </li>
              ))}
            </ul>
          )}

          {!item.content.list && (
            <Link className="mt-small block" onClick={duoHandler} to={item.to}>
              {`${language === "en" ? "Discover more" : "Узнать больше"}`}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileSubServices;
