const arkProHero = {
  en: {
    title:
      "Ignite Your Trading Potential: ARK PRO Trading Platform Unveils a World of Global Financial Opportunities",
  },
  ru: {
    title:
      "Зажгите свой потенциал в торговле: торговая платформа ARK PRO раскрывает мир мировых финансовых возможностей",
  },
};

// Function to retrieve content based on the selected language
const getArkProHeroContent = (language) => {
  return arkProHero[language];
};

export default getArkProHeroContent;
