import { useContext } from "react";
import { UserInFoContext } from "../../context/UserContext";

const GetHeaderLinks = () => {
  const { isLoggedIn, logoutHandler, userData } = useContext(UserInFoContext);

  const navigationContent = {
    en: [
      {
        id: -1,
        page: "Home",
        to: "/",
      },
      {
        id: 1,
        page: "Services",
        to: "",
        isService: true,
      },
      {
        id: 2,
        page: "About us",
        to: "",
        hasSubmenu: true,
        submenu: [
          {
            to: "/about-us/ethos",
            button: "Discover more",
            title: "Ethos",
            text: "ARK Capital's culture is guided by principles that shape our entire business",
          },
          {
            to: "/about-us/team",
            button: "Discover more",
            title: "Our team",
            text: "Our team is carefully selected and organized to align with our Ethos",
          },
          {
            to: "/about-us/supervison",
            button: "Discover more",
            title: "Supervision",
            text: "Ark Capital Management provides a secure and trustworthy experience for investors.",
          },
          {
            title: "Contact us",
            button: "Contact us",
            to: "/contact-us",
            text: "Feel free to reach out to our dedicated team for assistance. Contact us or find our location!",
          },
        ],
      },
      {
        id: 3,
        page: "Insights ",
        to: "",
        hasSubmenu: true,
        submenu: [
          {
            to: "/blog",
            button: "Discover more",
            title: "Blog",
            text: " Explore expert articles and industry insights",
          },
          {
            to: "/media",
            button: "Discover more",
            title: "Media",
            text: "Watch exclusive interviews and media coverage.",
          },
        ],
      },
      {
        id: -1,
        page: "FAQ",
        to: "/faq",
      },
      {
        id: -1,
        register: true,
        page: "registration",
        to: `${isLoggedIn ? "" : "/register"}`,
        hasSubmenu: `${isLoggedIn ? "true" : "false"}`,
        submenu: [
          {
            title: "Continue the process",
            to: "/register",
            button: "Continue the Process",
            text: " Continue your process from the point where you previously left off ",
          },
          {
            title: "Logout",
            button: "logout",
            onclick: logoutHandler,
            text: "Conclude your session by logging out to ensure the security of your account and maintain confidentiality.",
          },
        ],
      },
    ],
    ru: [
      {
        id: -1,
        page: "Главная",
        to: "/",
      },
      {
        id: 1,
        page: "Услуги",
        to: "",
        isService: true,
      },
      {
        id: 2,
        page: "О нас",
        to: "",
        hasSubmenu: true,
        submenu: [
          {
            to: "/about-us/ethos",
            button: "Узнать больше",
            title: "Этос",
            text: "Культура Ark Capital основывается на принципах, которые формируют весь наш бизнес",
          },
          {
            to: "/about-us/team",
            button: "Узнать больше",
            title: "Наша команда",
            text: " Наша команда тщательно отобрана и организована в соответствии с нашими принципами",
          },
          {
            to: "/about-us/supervison",
            button: "Узнать больше",
            title: "Надзор",
            text: "Ark Capital Management обеспечивает инвесторам безопасный и заслуживающий доверия опыт.",
          },
          {
            title: "Свяжитесь с нами",
            button: "Свяжитесь с нами",
            to: "/contact-us",
            text: "Не стесняйтесь обращаться за помощью к нашей преданной своему делу команде. Свяжитесь с нами или найдите наше местоположение!",
          },
        ],
      },
      {
        id: 3,
        page: "Инсайты",
        to: "",
        hasSubmenu: true,
        submenu: [
          {
            to: "/blog",
            button: "Узнать больше",
            title: "Блоги",
            text: "Изучайте статьи экспертов и отраслевую информацию. ",
          },
          {
            to: "/media",
            button: "Узнать больше",
            title: "Средства массовой информации ",
            text: "Смотрите эксклюзивные интервью и репортажи в средствах массовой информации.",
          },
        ],
      },
      {
        id: -1,
        page: "ЧАВО",
        to: "/faq",
      },
      {
        id: -1,
        register: true,
        page: "регистрация",
        to: `${isLoggedIn ? "" : "/register"}`,
        hasSubmenu: `${isLoggedIn ? "true" : "false"}`,
        submenu: [
          {
            title: "Продолжить процесс",
            to: "/register",
            button: "Продолжить процесс",
            text: "Продолжите свой процесс с того момента, где вы остановились ранее",
          },
          {
            title: "Выйти",
            button: "Выйти",
            onclick: logoutHandler,
            text: "Завершите сеанс, выйдя из системы, чтобы обеспечить безопасность своей учетной записи и сохранить конфиденциальность.",
          },
        ],
      },
    ],
  };

  return navigationContent;
};

export default GetHeaderLinks;
