import React from "react";

const Input = (props) => {
  const {
    boxStyle,
    inputStyle,
    type,
    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    apiErrorMessage,
    errorMessage = "error",
  } = props;

  return (
    <span className={boxStyle}>
      <input
        className={`${inputStyle} rounded-md`}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <p
        className={`text-red-500 text-xs mt-1 ${
          hasError ? "block" : " hidden"
        }`}
      >
        {errorMessage}
      </p>

      <p className={`text-red-500 text-xs my-1 `}>{apiErrorMessage}</p>
    </span>
  );
};

export default Input;
