import arkPlaformImage from "../../../assests/images/services/Ark-pro/ARK-PRO4.png";

const ARKProPlafrom = {
  en: {
    image: arkPlaformImage,
    text: [
      "Join ARK PRO Trading Platform today and access global financial markets.",
      " Whether you're a professional trader, an institution, or an individual investor, our feature-rich platform provides the tools and resources you need to navigate the world of trading with confidence.",
    ],
  },
  ru: {
    image: arkPlaformImage,
    text: [
      "Присоединяйтесь к торговой платформе ARK PRO сегодня и получите доступ к мировым финансовым рынкам.",
      " Независимо от того, являетесь ли вы профессиональным трейдером, институцией или частным инвестором, наша функционально насыщенная платформа предоставляет вам инструменты и ресурсы, необходимые для навигации в мире торговли с уверенностью.",
    ],
  },
};

// Function to retrieve content based on the selected language
const getARKProPlafromContent = (language) => {
  return ARKProPlafrom[language];
};

export default getARKProPlafromContent;
