import React, { useEffect, useRef, useState } from "react";
import Container from "../../../components/Container/Container";
import { HomeImage } from "../image-index";
const ArkImage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`transition ease-linear duration-300 mt-secondary lg:mt-primary  ${
        isVisible
          ? "opacity-100 translate-y-[0]"
          : "opacity-0 translate-y-[25%]"
      } `}
    >
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="lg:max-h-[800px] w-full">
          <img
            src={HomeImage}
            alt=""
            className="w-full lg:max-h-[800px] object-cover rounded-md drop-shadow-lg"
          />
        </div>
      </Container>
    </section>
  );
};

export default ArkImage;
