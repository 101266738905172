import BASE_API_URL from "../BaseUrl";

const performLogin = async (signinState) => {
  try {
    const response = await fetch(`${BASE_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signinState),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Login");
      return responseData;
    } else {
      console.log("Login successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Login.");
  }
};

export default performLogin;
