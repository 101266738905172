import { useContext } from "react";
import Container from "../../../../components/Container/Container";
// content
import { teamImage } from "../../About-index-image.js";
import { LanguageContext } from "context/LanguageContext.js";
import getTeamImage from "constant/About/Team/TeamImage";
const TeamImage = () => {
  const { language } = useContext(LanguageContext);
  const content = getTeamImage(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <p className="text-2xl lg:text-3xl mb-secondary">{content.text}</p>

        <div className="">
          <img src={teamImage} alt="object-cover rounded-md drop-shadow-lg" />
        </div>
      </Container>
    </section>
  );
};

export default TeamImage;
