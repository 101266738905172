import { createContext, useState } from "react";

export const CookieContext = createContext();

export const CookieContextProvider = ({ children }) => {
  const [statisticsIsDisable, setStatisticsIsDisable] = useState(false);

  return (
    <CookieContext.Provider
      value={{ statisticsIsDisable, setStatisticsIsDisable }}
    >
      {children}
    </CookieContext.Provider>
  );
};
