import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";

import Spinner from "../../../../components/UI/Spinner";

import { NewsContext } from "context/NewsContext";
import { LanguageContext } from "context/LanguageContext";
/**
 * Artical Component
 *
 * This component displays paginated blog articles. It fetches blog data using the `GetAllBlog`
 * utility function and organizes them into pages, with a specific number of blogs per page.
 * The component also provides pagination functionality, allowing users to navigate through
 * the different pages.
 */
const Artical = () => {
  // states for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 2;

  const { blogs, isLoading } = useContext(NewsContext);
  const { language } = useContext(LanguageContext);

  // Calculate the total number of pages
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Get the current blogs for the active page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  /**
   * Handle Page Change
   *
   * Function to handle page changes when the user clicks on the pagination buttons.
   * It updates the `currentPage` state to navigate to the previous or next page or
   * directly jump to a specific page.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex-2">
      {/* Render Blogs or Spinner */}
      {isLoading ? (
        // If blogs are still loading, display the spinner
        <div className="flex flex-col items-center justify-center gap-2">
          <Spinner />
          <p>
            {language === "en"
              ? "Please wait while the blog is being fetched..."
              : "Подождите, пока блог загружается..."}
          </p>
        </div>
      ) : blogs?.length !== 0 ? (
        // If blogs are fetched, render the NewsBox components
        <div className="flex flex-col gap-20">
          {currentBlogs?.map((blog, index) => (
            <div key={index} className="flex flex-col gap-8">
              <div className="h-[300px]">
                <img
                  src={blog.fullPath}
                  className="h-full object-cover w-full"
                  alt=""
                />
              </div>

              <div className="text-font">
                <h1 className="text-2xl mb-2">{`${
                  language === "en" ? blog.title : blog.title_ru
                }`}</h1>
                <p className="text-lightFont">
                  {" "}
                  {`${
                    language === "en"
                      ? blog.short_description
                      : blog.short_description_ru
                  }`}
                </p>

                {/* Link to Read More */}
                <Link
                  to={`/blog/${blog.id}`}
                  className="group/arrow flex items-center gap-x-2 mt-4 text-sm"
                >
                  {language === "en" ? "Read More" : "Читать далее"}
                  <span className="group-hover/arrow:translate-x-[5px] text-font transition ease-in duration-300">
                    <CaretRight size={14} weight="bold" />
                  </span>
                </Link>
              </div>
            </div>
          ))}

          {/* Pagination Buttons */}
          <div className="flex justify-center mt-primary">
            <button
              className="mx-2 py-2 px-4 rounded bg-blue text-white"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {language === "en" ? "Previous" : "Предыдущий"}
            </button>

            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                className={`mx-2 py-2 px-4 rounded ${
                  currentPage === index + 1
                    ? "bg-blue text-white"
                    : "bg-gray-300"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}

            <button
              className="mx-2 py-2 px-4 rounded bg-blue text-white"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {language === "en" ? "Next" : "Следующий"}
            </button>
          </div>
        </div>
      ) : (
        // If no blogs are fetched, render a message
        <div className="flex flex-col items-center justify-center gap-2">
          <p> {language === "en" ? "No Blogs found." : "Блоги не найдены."}</p>
        </div>
      )}
    </div>
  );
};

export default Artical;
