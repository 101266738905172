import Container from "../../../components/Container/Container";

import Artical from "./components/Artical";
import PopularBlog from "./components/PopularBlog";

const Blog = () => {
  return (
    <main className="my-secondary lg:my-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="flex gap-x-14">
          <Artical />
          <PopularBlog />
        </div>
      </Container>
    </main>
  );
};

export default Blog;
