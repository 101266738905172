import FaQImage from "./components/FaQImage";
import QA from "./components/QA";

const FAQ = () => {
  return (
    <main>
      <FaQImage />
      <QA />
    </main>
  );
};

export default FAQ;
