import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// import {
//   contactInfoContent,
//   locationContent,
// } from "../../../constant/ContactInfo.js";

const RemoveAccountInfo = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="flex-1 flex flex-col sm:flex-row lg:flex-col  justify-between gap-secondary">
      <div className="flex-1">
        <h2 className="text-font text-2xl mb-4 sm:mb-6 lg:mb-8 ">
          {`${language === "en" ? "Request Account Deletion" : "Запросить удаление учетной записи"}`}
        </h2>
        <div className="flex flex-col gap-y-3 ">
        <div><p>We understand that you wish to delete your account. Please note that due to regulatory requirements in the financial services industry, we cannot immediately delete your account upon request. However, we are committed to helping you through this process.</p>
        <p>To initiate the account deletion process, please fill out the data below. Once we receive your request, our team will review it and contact you with further instructions. This process ensures that we comply with all necessary regulations while also protecting your data and transaction history.</p>
        <p>Please remember that deleting your account is irreversible. Once your account is deleted, all your personal data, transaction history, and other related information will be permanently removed from our system. We recommend that you download and save all the information you might need in the future before submitting this form.</p>
        <p>If you have any questions or need further assistance, please contact our customer support team. We're here to help.</p>
        <p>Your request for deletion will be executed within 3 business days.</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveAccountInfo;
