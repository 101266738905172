import { createContext, useState, useEffect } from "react";
import GetAllBlog from "utils/Insigths/Blog/GetAllBlog";

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await GetAllBlog();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <NewsContext.Provider value={{ blogs, isLoading }}>
      {children}
    </NewsContext.Provider>
  );
};
