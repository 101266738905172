import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
import ServicesHeader from "../../UI/ServicesHeader";
// content
import getIressHeaderContent from "constant/services/Iress/IressHeader";
import { IressHeroImage } from "../../Image-index";

const IressHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getIressHeaderContent(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-3/4"}>{content.title}</ServicesHeader>

        <div className="my-secondary lg:my-primary">
          <img src={IressHeroImage} alt="" className="lg:w-3/4 lg:mx-auto" />
        </div>
      </Container>
    </section>
  );
};

export default IressHero;
