import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
// services sub menu data
import getServicesContent from "constant/Header/ServicesContent";
// icons
import { CaretDown, CaretUp, CaretRight } from "@phosphor-icons/react";
// slider data
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LinkNav = ({ children, buttonStyle }) => {
  const { language } = useContext(LanguageContext);
  const servicesContent = getServicesContent(language);
  const [open, setOpen] = useState(false);
  const [servicesSelectedBox, setServicesSelectedBox] = useState(0);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const settings = {
    infinite: true,
    prevArrow: <></>, // Hide the previous arrow
    nextArrow: <></>,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const testHandler = () => {
    handleMouseLeave();
    scrollToTop();
  };

  return children.to ? (
    <Link onClick={scrollToTop} to={children.to} className={buttonStyle}>
      {children.page}
    </Link>
  ) : (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${buttonStyle} cursor-pointer `}
    >
      {children.page}

      {children.hasSubmenu && (
        <div
          className={`absolute w-full top-[100%] left-1/2 -translate-x-1/2 z-[1000] transition-translate ease-in duration-300 text-start ${
            open
              ? "translate-y-[0%] opacity-100 select-auto visible "
              : "translate-y-[10%] opacity-0 select-none invisible "
          }`}
        >
          <div
            className={`bg-[#0b71ba86] blur-background ${
              open ? "pointer-events-auto" : "pointer-events-none"
            }`}
          >
            <Container className={"px-mainContainer"}>
              {" "}
              <div
                onMouseLeave={handleMouseLeave}
                className={"box-shadow-blur flex whitespace-normal"}
              >
                {children.submenu.map((item, index) => (
                  <div
                    key={index}
                    className="flex-1 flex flex-col items-stretch border-r border-white text-sm px-6 py-10 transition-underline ease-in duration-300 text-gray-100 last:border-r-0 hover:bg-hoverBlue"
                  >
                    <h2 className="font-medium text-xl">{item.title}</h2>
                    <p className="text-[14px] leading-6 my-small">
                      {item.text}
                    </p>

                    {/* links that don't have navigation routes */}
                    {!item.to && (
                      <button
                        onClick={item.onclick}
                        className="group/arrow  mt-auto whitespace-nowrap flex items-center gap-x-2 tracking-[1.5px]"
                      >
                        {item.button}
                        <span className="transition ease-in duration-300 -translate-x-[40%]   group-hover/arrow:translate-x-[0%]">
                          <CaretRight size={16} weight="bold" />
                        </span>
                      </button>
                    )}

                    {/* links that have navigation routs */}
                    {item.to && (
                      <Link
                        onClick={testHandler}
                        className="group/arrow  mt-auto whitespace-nowrap flex items-center gap-x-2 tracking-[1.5px]"
                        to={item.to}
                      >
                        {item.button}
                        <span className="transition ease-in duration-300 -translate-x-[40%]   group-hover/arrow:translate-x-[0%]">
                          <CaretRight size={16} weight="bold" />
                        </span>
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </Container>
          </div>
          <div
            className={`blur-background transition ease-in duration-300 w-full min-h-[60vh]  z-[1000]  ${
              open ? "block" : "hidden"
            }`}
          ></div>
        </div>
      )}

      {children.isService && (
        <div
          className={`absolute w-full  top-[100%] left-0 z-[1000] transition-translate ease-in duration-300 text-start   ${
            open
              ? "translate-y-[0%] opacity-100 select-auto visible "
              : "translate-y-[10%] opacity-0 select-none invisible "
          }`}
        >
          <div
            className={`bg-[#0b71ba86] blur-background ${
              open ? "pointer-events-auto" : "pointer-events-none"
            }`}
          >
            <Container className={"px-mainContainer"}>
              <div onMouseLeave={handleMouseLeave}>
                <div className="flex items-center py-2 space-x-2 justify-between service-box ">
                  {servicesContent.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => setServicesSelectedBox(service.id)}
                      className={`flex-1 flex items-center gap-x-3  text-center justify-center text-lg py-3  border-r border-white last:border-0 transition ease-in duration-300 hover:bg-hoverBlue hover:rounded-lg ${
                        servicesSelectedBox === service.id
                          ? "bg-blue rounded-lg"
                          : ""
                      } `}
                    >
                      {service.tab}
                      {servicesSelectedBox === service.id ? (
                        <CaretUp size={20} />
                      ) : (
                        <CaretDown size={20} />
                      )}
                    </button>
                  ))}
                </div>
                {servicesSelectedBox !== -1 && (
                  <div
                    className={`min-w-full whitespace-normal grid grid-cols-2 gap-x-20 py-8 `}
                  >
                    <div className="flex flex-col gap-4 ">
                      <h1 className="text-lg font-medium text-white">
                        {servicesContent[servicesSelectedBox]?.content.header}
                      </h1>
                      <div className="text-white">
                        {servicesContent[servicesSelectedBox]?.content.text}
                      </div>

                      {servicesContent[servicesSelectedBox]?.content.list && (
                        <ul className="flex items-center ">
                          {servicesContent[
                            servicesSelectedBox
                          ]?.content.list.map((link, index) => (
                            <li
                              key={index}
                              className="text-lg mb-1 first:px-0 first:pr-ssmall px-ssmall border-r border-white last:border-r-0"
                            >
                              {link}
                            </li>
                          ))}
                        </ul>
                      )}

                      {servicesContent[servicesSelectedBox]?.to && (
                        <Link
                          onClick={testHandler}
                          to={servicesContent[servicesSelectedBox]?.to}
                          className="group/arrow  mt-auto whitespace-nowrap flex items-center gap-x-2 tracking-[1.5px]"
                        >
                          {`${
                            language === "en"
                              ? "Discover more"
                              : "Узнать больше"
                          }`}

                          <span className="transition ease-in duration-300 -translate-x-[40%]   group-hover/arrow:translate-x-[0%]">
                            <CaretRight size={16} weight="bold" />
                          </span>
                        </Link>
                      )}
                    </div>

                    <div className="max-h-[250px] w-full">
                      <Slider {...settings} className="h-[250px]">
                        {servicesContent[servicesSelectedBox]?.image?.map(
                          (image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt=""
                              className=" h-[250px] object-cover rounded-md"
                            />
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
          <div
            className={` bg-transparent blur-background transition ease-in duration-300 w-full min-h-[60vh]  z-[1000]  ${
              open ? "block" : "hidden"
            }`}
          ></div>
        </div>
      )}
    </div>
  );
};

export default LinkNav;
