import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
import ServicesHeader from "../../UI/ServicesHeader";
// Content
import getArMT5HeroContent from "constant/services/MT5/MT5Hero";

const MT5Hero = () => {
  const { language } = useContext(LanguageContext);
  const content = getArMT5HeroContent(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-1/2"}>{content.header}</ServicesHeader>
        <div className="mt-secondary grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-ssmall md:gap-small lg:gap-secondary text-center ">
          {content.feature.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-2  items-center justify-center mb-small"
            >
              <span className="text-[2.6rem] lg:text-5xl text-blue">
                {item.icon}
              </span>
              <h4 className="text-lg lg:text-2xl ">{item.title}</h4>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default MT5Hero;
