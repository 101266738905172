import { useContext } from "react";
import { Link } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import { LanguageContext } from "context/LanguageContext";

const MobileSubMenu = ({ content, active, onTabsHandler, onMobileHanlder }) => {
  const { language } = useContext(LanguageContext);

  const duoHandler = () => {
    onTabsHandler();
    onMobileHanlder();
  };

  return (
    <div>
      <div
        className={`flex flex-col px-6 pb-10 mt-6 bg-font absolute top-0 left-0 pt-32 w-full h-full transition ease-in-out duration-300 overflow-y-scroll lg:hidden ${
          active
            ? "translate-x-[0%] opacity-100 select-auto visible"
            : "translate-x-[100%] opacity-0 select-none invisible"
        }`}
      >
        <button
          className="text-white text-start border-b-2 pb-3 border-blue w-full flex items-center gap-2 mb-6"
          onClick={onTabsHandler}
        >
          <CaretLeft size={22} weight="bold" />
          {`${language === "en" ? "Back" : "назад"}`}
        </button>
        {content.submenu.map((item, index) => (
          <div
            key={index}
            className="text-white border-b  border-white w-full py-4"
          >
            <h6 className="text-2xl  block mb-ssmall">{item.title}</h6>

            <p className="text-base">{item.text}</p>

            {!item.to && (
              <button
                onClick={() => {
                  item.onclick();
                  duoHandler();
                }}
                className="block mt-small text-md text-lightWhite"
              >
                {item.button}
              </button>
            )}
            {item.to && (
              <Link
                onClick={duoHandler}
                className="block mt-small text-md text-lightWhite"
                to={item.to}
              >
                {item.button}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileSubMenu;
