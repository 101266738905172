import BASE_API_URL from "../BaseUrl";

const performSetFormStatus = async (token, formStatus) => {
  try {
    const formData = new FormData();
    formData.append("cif_is_done", `${formStatus.cif}`);
    formData.append("fatca_is_done", `${formStatus.fatca}`);
    formData.append("document_is_done", `${formStatus.document}`);
    const response = await fetch(`${BASE_API_URL}/set-form-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Update form status");
      return responseData;
    } else {
      console.log("Update form status successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Update form status.");
  }
};

export default performSetFormStatus;
