import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
import ServicesHeader from "../../UI/ServicesHeader";

// data
import getAdvisoryContent from "constant/services/Advisory/AdvisoryHero";
const AdvisoryHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getAdvisoryContent(language);

  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-1/2"}>{content.title}</ServicesHeader>

        <div className="flex flex-col lg:flex-row items-center gap-small lg:gap-secondary  mt-secondary lg:mt-primary">
          <div className="flex-1">
            <img
              className="rounded-md drop-shadow-md"
              src={content.image}
              alt=""
            />
          </div>
          <div className="flex-1 flex flex-col gap-ssmall text-lg">
            {content.text.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AdvisoryHero;
