import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Container from "components/Container/Container";
// content
import getCustodySecurityContent from "constant/services/Custody/CustodySecurirty";
const CustodySecurity = () => {
  const { language } = useContext(LanguageContext);
  const content = getCustodySecurityContent(language);
  return (
    <section className="my-secondary lg:my-primary bg-font py-secondary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-white text-center flex flex-col gap-ssmall md:w-3/4 md:mx-auto">
          <h1 className="text-4xl">{content.header.title}</h1>
          <p className="text-lg">{content.header.text}</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-secondary gap-small ">
          {content.list.map((content, index) => (
            <div
              className=" border border-white py-6 px-3 md:py-10 md:px-5 text-center flex flex-col items-center rounded-md text-white shadow transition-all ease-in duration-300 hover:bg-white hover:text-font hover:border-font"
              key={index}
            >
              <span className="">{content.icon}</span>
              <h6 className="text-xl my-ssmall">{content.title}</h6>
              <p className="text-base">{content.text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CustodySecurity;
