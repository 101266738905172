const performSaveDocumentsData = async (token, formData) => {
  try {
    const response = await fetch(
      "https://arkcapital2022.my.salesforce.com/services/data/v43.0/composite/tree/Attachment",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
          "Accept-Encoding": "gzip, deflate, br",
          "Content-Type": "application/json",
        },

        body: formData,
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      console.log(
        "Failed to Save Documents data with Salesforce",
        responseData
      );
      return responseData;
    } else {
      console.log("Save Documents data successful", responseData);
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Save Documents data.");
  }
};

export default performSaveDocumentsData;
