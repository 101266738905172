import React from "react";

const WhySlide = ({ icon, title, content }) => {
  return (
    <div className="text-center flex-1 flex flex-col items-center  ">
      <div className="mb-6">
        <span className="flex items-center justify-center text-white mb-2">
          {icon}
        </span>
        <h4
          className={`text-3xl  xl:text-4xl text-white font-medium relative after:absolute after:left-1/2 after:-translate-x-1/2 after:-bottom-4 
           after:w-10 after:h-[2px] after:bg-white `}
        >
          {title}
        </h4>
      </div>
      <div className="mt-2">
        <p
          className={
            "text-[#dad9d9]  font-euroThin text-center  leading-7 tracking-wider"
          }
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default WhySlide;
