import { useState, useEffect, useContext } from "react";
import Container from "components/Container/Container";

import { LanguageContext } from "context/LanguageContext";

const ManageCookie = ({ active, onClick }) => {
  const [toggle, setToggle] = useState(false);

  const { language } = useContext(LanguageContext);

  const modalIsActive =
    "absolute bg-white rounded-md top-0 left-1/2 -translate-x-1/2 max-w-[90%] md:max-w-[75%] lg:max-w-[50%] mx-auto h-auto z-[1000000] border border-font";

  const buttonAnimationHandler = () => {
    setToggle((cur) => !cur);
  };

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0); // Scroll to the top of the page
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [active]);

  return (
    <>
      <div
        onClick={onClick}
        className={`fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#00000094] z-[10000]  ${
          active ? "" : "hidden"
        }`}
      ></div>
      <Container className={` ${active ? modalIsActive : "hidden"} `}>
        <div className={``}>
          <div className="text-center bg-blue py-4 text-white">
            <h1 className="text-lg">
              {" "}
              {language === "en"
                ? "Manage Cookie"
                : "Управление файлами cookie"}
            </h1>
          </div>

          <div className="p-6 flex flex-col gap-6 lg:gap-4  ">
            <div className="border-b border-lightFont pb-6 lg:pb-3">
              <h2 className="text-font tracking-wider font-semibold text-lg mb-2">
                {language === "en" ? "Necessary" : "Необходимые"}
              </h2>
              <p className="text-lightFont text-md ">
                {language === "en"
                  ? "Necessary cookies are essential for the functioning of the website. They enable basic functions such as page navigation and account login. Without these cookies, these fundamental features would not work"
                  : "Необходимые файлы cookie неотъемлемы для работы веб-сайта. Они обеспечивают основные функции, такие как навигация по страницам и вход в учетную запись. Без этих файлов cookie эти фундаментальные функции не будут работать"}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex-2">
                <span className="flex items-center justify-between mb-4 lg:mb-2">
                  <h2 className="text-font tracking-wider font-semibold text-lg">
                    {language === "en" ? "Statistics" : "Статистика"}
                  </h2>

                  <button
                    onClick={buttonAnimationHandler}
                    className={`flex items-center justify-center overflow-x-hidden rounded-[99px] lg:hidden ${
                      toggle ? "flex-row-reverse bg-blue text-white" : ""
                    }`}
                  >
                    <div
                      className={`border border-blue px-4 py-1.5 rounded-[99px] flex items-center justify-center gap-x-3 transition ease-in duration-300  `}
                    >
                      <span className="bg-[#ccc] block w-6 h-6 rounded-full"></span>
                      {language === "en" ? (
                        <span>{toggle ? "Yes" : "No"}</span>
                      ) : (
                        <span>{toggle ? "да" : "нет"}</span>
                      )}
                    </div>
                  </button>
                </span>
                <p className="text-lightFont text-md ">
                  {language === "en"
                    ? " We use statistic cookies to anonymously track user interactions with our website. This information is utilized to enhance your browsing experience."
                    : "Мы используем статистические файлы cookie для анонимного отслеживания взаимодействия пользователей с нашим веб-сайтом. Эта информация используется для улучшения вашего опыта просмотра."}
                </p>
              </div>

              <button
                onClick={buttonAnimationHandler}
                className={`hidden flex-1 lg:flex items-center justify-center  overflow-hidden `}
              >
                <div
                  className={`border border-blue px-4 py-1.5 rounded-[99px] flex items-center justify-center gap-x-3 transition ease-in duration-300 ${
                    toggle
                      ? "flex-row-reverse bg-blue text-white rounded-[99px]"
                      : ""
                  } `}
                >
                  <span className="bg-[#ccc] block w-6 h-6 rounded-full"></span>
                  {language === "en" ? (
                    <span>{toggle ? "Yes" : "No"}</span>
                  ) : (
                    <span>{toggle ? "да" : "нет"}</span>
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="border-t border-lightFont text-center text-blue py-4">
            <button
              onClick={onClick}
              className="border-2 border-blue px-10 py-2 rounded-xl"
            >
              {language === "en" ? "Save and close" : "Сохранить и закрыть"}
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ManageCookie;
