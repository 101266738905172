import Owner from "./components/Owner";
import TeamImage from "./components/TeamImage";
import TeamMembers from "./components/TeamMembers";
import TeamFeature from "./components/TeamFeature";

const Team = () => {
  return (
    <section className="my-primary">
      <Owner />
      <TeamImage />
      <TeamMembers />
      <TeamFeature />
    </section>
  );
};

export default Team;
