import { useContext } from "react";
import footerLogoImage from "../../../assests/images/logo/logo-blue-grey.png";
import { LanguageContext } from "context/LanguageContext";

const FooterLogo = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="">
      <img className="-mt-2" src={footerLogoImage} alt="" />

      <p className="mt-8 text-sm text-lightGreyFont pl-3">
        {language === "en"
          ? " IN THE HEART OF DUBAI INTERNATIONAL FINANCIAL CENTRE"
          : "В СЕРДЦЕ ДУБАЙСКОГО МЕЖДУНАРОДНОГО ФИНАНСОВОГО ЦЕНТРА"}
      </p>
    </div>
  );
};

export default FooterLogo;
