import { useContext } from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "@phosphor-icons/react";
import { LanguageContext } from "context/LanguageContext";

const NewsHeader = () => {
  const { language } = useContext(LanguageContext);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="mb-secondary flex items-center justify-center flex-col gap-y-ssmall sm:flex-row sm:flex-row sm:justify-between">
      <h1 className={"text-2xl text-white"}>
        {language === "en" ? "Latest News" : "Последние новости"}
      </h1>
      <div className="group/link flex items-center gap-x-1 ">
        <Link
          onClick={scrollToTop}
          className="text-white transition ease-in duration-300 group-hover/link:text-blue "
          to={"/blog"}
        >
          {language === "en" ? "View all news" : "Просмотреть все новости"}
        </Link>
        <span className="text-white transition ease-in duration-300 group-hover/link:text-blue ">
          <ArrowRight size={20} weight="light" />
        </span>
      </div>
    </div>
  );
};

export default NewsHeader;
