// icons
import {
  ShieldCheck,
  GlobeHemisphereWest,
  Article,
  RocketLaunch,
} from "@phosphor-icons/react";

const WhyArkContent = {
  en: [
    {
      id: 0,
      number: "01",
      icon: <ShieldCheck size={40} weight="light" />,
      title: "Trusted Custody",
      content:
        "Segregation between fund placement and management, coupled with unwavering supervision to ensure client peace of mind",
    },
    {
      id: 2,
      number: "03",
      icon: <RocketLaunch size={40} weight="light" />,
      title: "Efficient Onboarding ",
      content:
        "A straightforward approach to simplifying what is usually a daunting process.",
    },
    {
      id: 1,
      number: "02",
      icon: <GlobeHemisphereWest size={40} weight="light" />,
      title: "Global Coverage",
      content:
        "A global market offering, which includes equities, sovereign and corporate debt, and major and minor foreign exchange currencies.",
    },
    {
      id: 3,
      number: "04",
      icon: <Article size={40} weight="light" />,
      title: "Transparent Reporting",
      content: "Visibility and honest communication every step of the way",
    },
  ],
  ru: [
    {
      id: 0,
      number: "01",
      icon: <ShieldCheck size={40} weight="light" />,
      title: "Доверенное кастоди",
      content:
        "Разделение между размещением средств и управлением ими в сочетании с неукоснительным надзором обеспечивает душевное спокойствие клиентов",
    },
    {
      id: 2,
      number: "03",
      icon: <RocketLaunch size={40} weight="light" />,
      title: "Эффективная адаптация",
      content:
        "Прямолинейный подход к упрощению того, что обычно является сложным процессом.",
    },
    {
      id: 1,
      number: "02",
      icon: <GlobeHemisphereWest size={40} weight="light" />,
      title: "Глобальное покрытие",
      content:
        "Предложение на глобальном рынке, которое включает акции, суверенный и корпоративный долг, а также основные и второстепенные иностранные валюты.",
    },
    {
      id: 3,
      number: "04",
      icon: <Article size={40} weight="light" />,
      title: "Прозрачная отчетность",
      content: "Видимость и честное общение на каждом этапе",
    },
  ],
};

// Function to retrieve content based on the selected language
const getWhyArkContent = (language) => {
  return WhyArkContent[language];
};

export default getWhyArkContent;
