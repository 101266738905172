import { useContext } from "react";

import { Link } from "react-router-dom";
import platformLinksContent from "constant/Footer/FooterPlatform";
// context
import { LanguageContext } from "context/LanguageContext.js";
const FooterPlatform = () => {
  const { language } = useContext(LanguageContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="lg:mx-auto ">
      <h4 className="text-white text-[15px] uppercase font-medium mb-8">
        {language === "en" ? "Platforms" : "Платформы"}
      </h4>

      <ul className="flex flex-col gap-3">
        {platformLinksContent.map((item, index) => (
          <Link onClick={scrollToTop} key={index} to={item.to}>
            <li className=" text-lightGreyFont text-[14px] hover:text-lightWhiteFont transition ease-in duration-100">
              {item.page}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default FooterPlatform;
