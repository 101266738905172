const performAuth = async () => {
  try {
    // Create the form data object and append the required fields
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append(
      "client_id",
      "3MVG9gtDqcOkH4PK9bGfOCpKmmenfjqH3iOfMr_4Qw1JdS7jEmCy6PkRdGu3zF9wszrS9jgGvS60Sr88gG_vH"
    );
    formData.append(
      "client_secret",
      "FE491539500FA526EFCD24942191FB56077F91E7AD7F32083845281516E9082E"
    );
    formData.append("username", "akram.djaileb@arkcapital.com");
    formData.append("password", "ARKCapital2021");

    // Make the fetch request with the form data
    const response = await fetch(
      "https://email-api.ark-cap.com/api/sales-auth",
      {
        method: "POST",
        body: formData,
      }
    );

    // Parse the response as JSON
    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to authenticate with Salesforce", responseData);
      return responseData;
    } else {
      console.log("Authentication successful", responseData);
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform authentication.");
  }
};

export default performAuth;
