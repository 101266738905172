const FATCA_Corporate_Form_content = [
  // indetiy details
  {
    inputs: [
      {
        pageTitle: "Identity details",
      },

      {
        label: "Legal name of Entity/Branch	",
        type: "text",
        name: "Legal_name_of_Entity_Branch",
        require: true,
        error: false,
      },

      {
        label: "Country of incorporation or organization	",
        type: "select",
        name: "Country_of_incorporation_or_organization",
        require: true,
        placeholder: "Select a country",
        country: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "Current registered address",
        megaName: "Current_registered_address",

        megaInputs: [
          {
            label: "House",
            type: "text",
            name: "House",
            require: false,
            error: false,
          },
          {
            label: "Apartment",
            type: "text",
            name: "Apartment",
            require: false,
            error: false,
          },
          {
            label: "Suite name",
            type: "text",
            name: "Suite_name",
            require: false,
            error: false,
          },
          {
            label: "Number",
            type: "number",
            name: "Number_z",
            require: false,
            error: false,
          },
          {
            label: "Street",
            type: "text",
            name: "Street",
            require: false,
            error: false,
          },
          {
            label: "Town",
            type: "text",
            name: "Town",
            require: false,
            error: false,
          },
          {
            label: "City",
            type: "text",
            name: "City",
            require: false,
            error: false,
          },
          {
            label: "Province",
            type: "text",
            name: "Province",
            require: false,
            error: false,
          },
          {
            label: "County",
            type: "select",
            name: "County",
            require: false,
            error: false,
            country: true,
          },
          {
            label: "Postal Code",
            type: "text",
            name: "Postal_Code",
            require: false,
            error: false,
          },
          {
            label: "ZIP Code",
            type: "number",
            name: "ZIP_Code",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Mailing address",
        megaName: "Mailing_address",

        megaInputs: [
          {
            label: "House",
            type: "text",
            name: "House",
            require: false,
            error: false,
          },
          {
            label: "Apartment",
            type: "text",
            name: "Apartment",
            require: false,
            error: false,
          },
          {
            label: "Suite name",
            type: "text",
            name: "Suite_name",
            require: false,
            error: false,
          },
          {
            label: "Number",
            type: "number",
            name: "Number_z",
            require: false,
            error: false,
          },
          {
            label: "Street",
            type: "text",
            name: "Street",
            require: false,
            error: false,
          },
          {
            label: "Town",
            type: "text",
            name: "Town",
            require: false,
            error: false,
          },
          {
            label: "City",
            type: "text",
            name: "City",
            require: false,
            error: false,
          },
          {
            label: "Province",
            type: "text",
            name: "Province",
            require: false,
            error: false,
          },
          {
            label: "County",
            type: "select",
            name: "County",
            require: false,
            error: false,
            country: true,
          },
          {
            label: "Postal Code",
            type: "text",
            name: "Postal_Code",
            require: false,
            error: false,
          },
          {
            label: "ZIP Code",
            type: "number",
            name: "ZIP_Code",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Is the Entity a US Person?",
        megaName: "Is_the_Entity_a_US_Person",
        megaMessage: true,
        megaInputs: [
          {
            label: "Select",
            name: "SelectedValue",
            type: "select",
            placeholder: "select",

            require: true,
            error: false,
            options: [
              {
                label: "Yes",
                value: " yes",
              },
              {
                label: "No",
                value: "no",
              },
            ],
          },

          {
            label: "(if yes) U.S. Federal TIN:",
            name: "if_yes_U_S_Federal_TIN",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  //  FATCA Classification for Foreign Entities (1)
  {
    inputs: [
      {
        pageTitle:
          "FATCA Classification for Foreign Entities (1) Please choose one",
      },

      {
        label:
          "The Entity is a not a Financial Institution under FATCA, but is one of the categories listed ",
        name: "The_Entity_is_a_not_a_Financial_Institution_under_FATCA_but_is_one_of_the_categories_listed",
        type: "select",
        placeholder: "Select",
        options: [
          {
            label: "Active Non-Financial Foreign Entity (Active NFFE)",
            value: "Active Non-Financial Foreign Entity (Active NFFE)",
          },
          {
            label: "Passive Non-Financial Foreign Entity (Passive NFFE)",
            value: "Passive Non-Financial Foreign Entity (Passive NFFE)",
          },
          {
            label: "Expected Non-Financial Foreign Entity",
            value: "Expected Non-Financial Foreign Entity",
          },
        ],

        require: false,
        false: false,
      },

      {
        label:
          "the Entity is a Financial Institution under FATCA Within one of the categories listed",
        name: "the_Entity_is_a_Financial_Institution_under_FATCA_Within_one_of_the_categories_listed",
        type: "select",
        placeholder: "Select",
        options: [
          {
            label: "Partner jurisdiction Financial Institution",
            value: "Partner jurisdiction Financial Institution",
          },
          {
            label: "Registered Deemed Compliant Foreign Financial Institution",
            value: "Registered Deemed Compliant Foreign Financial Institution",
          },
          {
            label: "Participating Foreign Financial Institution",
            value: "Participating Foreign Financial Institution",
          },
        ],

        require: false,
        false: false,
      },
    ],
  },

  //  FATCA Classification for Foreign Entities (2)
  {
    inputs: [
      {
        pageTitle:
          "FATCA Classification for Foreign Entities (2) Please choose one",
      },
      {
        label:
          "The Entity’s Global Intermediary Identifation Number (GIIN) is:",
        name: "The_Entity_s_Global_Intermediary_Identifation_Number_GIIN_is",
        type: "text",
        require: false,
        false: false,
      },

      {
        mega: true,
        megaName: "The_Entity_is_unable_to_provide_a_GIIN",
        oneGrid: true,
        magaTitle: "The Entity is unable to provide a GIIN ",

        megaInputs: [
          {
            label: "because of ",
            type: "select",
            name: "because_of",
            placeholder: "Select",
            options: [
              {
                label: "Exempt Beneficial Owner",
                value: "Exempt Beneficial Owner",
              },
              {
                label:
                  "Certified Deemed Compliant Foreign Financial Institution",
                value:
                  "Certified Deemed Compliant Foreign Financial Institution",
              },
              {
                label: "Non-Participating Foreign Financial Institution",
                value: "Non-Participating Foreign Financial Institution",
              },
              {
                label: "Exempt Foreign Financial Institution",
                value: "Exempt Foreign Financial Institution",
              },
            ],
          },

          {
            label:
              "Or The entity has not yet Obtained A GIIN, but os sponsored by another entity whoch does hav A GINN",
            type: "text",
            name: "Or_The_entity_has_not_yet_Obtained_A_GIIN_but_os_sponsored_by_another_entity_whoch_does_hav_A_GINN",
            placeholder: "name sponsor",
          },
        ],
      },
    ],
  },

  // CRS Declaration of Tax Residency
  {
    inputs: [
      {
        pageTitle: "FATCA Declaration – US Person",
      },

      {
        content: [
          {
            text: [
              "Kindly complete the following table indicating where the Entity a tax resident and the TIN for each country/jurisdiction indicated. ",

              "If the Entity is not tax resident in any country/jurisdiction (e.g., because it is fiscally transparent), please indicate that on the below table and provide its place of effective management or jurisdiction in which its principal office is located. ",

              "If a TIN is unavailable please provide the appropriate reason A, B or C where appropriate: ",
            ],

            list: [
              "Reason A: the country/jurisdiction where the Entity is resident does not issue TINs to its residents ",

              "Reason B: the Entity is otherwise unable to obtain a TIN or equivalent number ",

              "Reason C: No TIN is required",
            ],
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Tax resident and the TIN",
        megaName: "Tax_resident_and_the_TIN",

        megaInputs: [
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_1",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_2",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_3",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_3",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_3",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            name: "Country_of_Tax_Residence_4",
            type: "select",
            country: "true",
            placeholder: "Select a country",
            require: false,
            error: false,
          },
          {
            label: "Tax Identification Number (TIN)",
            name: "Tax_Identification_Number_TIN_4",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "No TIN",
            name: "No_TIN_4",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        specialDesign: true,
        magaTitle:
          "Kindly explain for what reason a TIN cannot be provided (for instance country does not issue TINs)",
        megaName:
          "Kindly_explain_for_what_reason_a_TIN_cannot_be_provided_for_instance_country_does_not_issue_TINs",
        megaInputs: [
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_1",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_1",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_2",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_2",
            require: false,
            error: false,
          },
          {
            label: "Country of Tax Residence",
            type: "selecy",
            name: "Country_of_Tax_Residence_3",
            country: true,
            require: false,
            error: false,
          },

          {
            label: "Reason for no TIN available",
            type: "text",
            name: "Reason_for_no_TIN_available_3",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  //CRS Classification
  {
    inputs: [
      {
        pageTitle: "CRS Classification (Please choose one)",
      },

      {
        mega: true,
        oneGrid: true,
        megaName: "The_Entity_is_a_not_a_Financial_Institution_under_CRS",
        magaTitle: "The Entity is a not a Financial Institution under CRS, ",
        megaInputs: [
          {
            label: "But Is",
            name: "But_Is",
            type: "select",
            placeholder: "Select",
            options: [
              {
                label: "Active Non-Financial Entity (Active NFE)",
                value: "Active Non-Financial Entity (Active NFE)",
              },
              {
                label:
                  "a corporation whose stock is regularly traded on an established securities market a corporated which is a related entity of such a corporation",
                value:
                  "a corporation whose stock is regularly traded on an established securities market a corporated which is a related entity of such a corporation",
              },
              {
                label: "a Covernment Entity or Central Bank",
                value: "a Covernment Entity or Central Bank",
              },
              {
                label: "an International organazation",
                value: "an International organazation",
              },
            ],

            require: false,
            false: false,
          },

          {
            label: "Other",
            name: "Other",
            type: "text",
          },

          {
            label: "Passive Non-Financial Entity (Passive NFE)",
            placeholder:
              "Indicate the name of any Controlling Person of the Entity",
            type: "text",
            name: "Passive_Non_Financial_Entity_Passive_NFE",
          },
        ],
      },

      {
        label:
          "The Entity is a Finicial Institution under CRS within one of the categories",
        name: "The_Entity_is_a_Finicial_Institution_under_CRS_within_one_of_the_categories",
        type: "select",
        placeholder: "Select",
        options: [
          { label: "Financial Institution", value: "Financial Institution" },
          {
            label:
              "an investment entity located in a non-participating jurisdiction and managed by another Financial institution",
            value:
              "an investment entity located in a non-participating jurisdiction and managed by another Financial institution",
          },
        ],
      },
    ],
  },

  // last page
  {
    inputs: [
      {
        pageTitle: "Declaration and Undertaking",
      },

      {
        content: [
          {
            text: [
              "I understand that the information supplied by me is covered by the full provisions of the terms and conditions governing the Entity’s relationship with Ark Capital Management (Dubai) Limited (hereafter “Ark”) setting out how Ark may use and share the information supplied by me. ",

              "I acknowledge that the information contained in this form and information regarding the Entity and any Reportable Account(s) is/are maintained and exchanged with tax authorities of another country/jurisdiction or countries/jurisdictions in which the Entity may be tax resident pursuant to intergovernmental agreements to exchange financial account information.  ",

              "I declare that all statements made in this declaration are, to the best of my knowledge and belief, correct and complete. I undertake to advise Ark within 30 days of any change in circumstances which affects the Entity’s tax residency status or causes the information contained herein to become incorrect or incomplete. I understand that Ark may require the Entity to provide an updated version of this form.",

              "I understand that intentional provision of incorrect of false information to Ark may result in an administrative penalty of USD 5,500 (or equivalent in AED) imposed by Ark’s regulatory authority.",

              "I certify that I am authorised to sign for the Entity in respect of all the account(s) to which this form relates.",
            ],
          },
        ],
      },

      {
        label: "Full Name",
        name: "Full_Name",
        type: "text",
        require: true,
        error: false,
      },
      {
        label: "Date",
        name: "Date_of",
        type: "date",
        require: true,
        error: false,
      },
      {
        label: "Capacity",
        name: "Capacity",
        type: "text",
        require: true,
        error: false,
      },
      {
        label: "Signature",
        name: "Signature",
        type: "text",
        require: true,
        error: false,
      },
    ],
  },
];

export default FATCA_Corporate_Form_content;
