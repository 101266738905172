import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Container from "components/Container/Container";
import ServicesHeader from "../../UI/ServicesHeader";
// Content
import getPrimeBrokerageContent from "constant/services/PrimeBrokerage/PrimeBorkerageHero";

const PrimeBrokerageHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getPrimeBrokerageContent(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-3/4"}>{content.header}</ServicesHeader>

        <div className="flex flex-col gap-secondary lg:gap-primary mt-secondary lg:mt-primary">
          {content.heroContent.map((content, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row items-center gap-small lg:gap-secondary lg:last:flex-row-reverse"
            >
              <div className="flex-1">
                <img
                  className="rounded-md drop-shadow-md"
                  src={content.image}
                  alt=""
                />
              </div>

              <div className="flex-1">
                <h3 className="text-lg text-font">{content.text}</h3>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PrimeBrokerageHero;
