import { useRef, useState, useEffect, useContext } from "react";
// UI
import Container from "components/Container/Container";
import WhySlide from "./Components/WhySlide";

// Content
import getContent from "constant/Home/WhyArkContent";
import { LanguageContext } from "context/LanguageContext.js";

const WhyArk = () => {
  // content
  const { language } = useContext(LanguageContext);
  const content = getContent(language);
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.4 }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`relative  ${
        isVisible ? "active" : ""
      } w-full bg-newBlue py-secondary`}
    >
      <Container className={"px-smallContainer lg:px-mainContainer h-full"}>
        <div className="relative z-[200] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-stretch justify-center content-center  h-full gap-secondary">
          {content.map((box) => (
            <WhySlide
              key={box.id}
              icon={box.icon}
              title={box.title}
              content={box.content}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default WhyArk;
