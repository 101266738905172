const CIF_Individual_Form_content = [
  // Personal Details
  {
    inputs: [
      {
        pageTitle: "Your personal details",
      },

      {
        label: "Full Name",
        type: "text",
        name: "Full_Name",
        placeholder: "as on passport",
        require: true,
        error: false,
      },

      {
        label: "Previous name(s)",
        type: "text",
        name: "Previous_name",
        placeholder: "including any alias",
        require: false,
        error: false,
      },

      {
        label: "Other name(s)",
        type: "text",
        name: "Other_name",
        require: false,
        error: false,
      },

      {
        label: "Gender",
        type: "select",
        name: "Gender",
        placeholder: "Select a Gender",
        options: [
          { label: "Male", value: "Male" },
          { label: "Female", value: "Female" },
        ],
        require: false,
        error: false,
      },

      {
        label: "Date of Birth",
        type: "date",
        name: "Date_of_Birth",
        require: true,
        error: false,
      },

      {
        label: "Place of Birth ",
        type: "select",
        name: "Place_of_Birth",
        placeholder: "Select place of Birth",
        country: true,
        require: true,
      },

      {
        label: "Marital status",
        type: "select",
        name: "Marital_status",
        placeholder: "Select marital  Status",
        options: [
          { label: "Single", value: "Single" },
          { label: "Married", value: "Married" },
          { label: "Divorced", value: "Divorced" },
          { label: "Widow(er)", value: "Widow(er)" },
        ],
        require: true,
        error: false,
      },

      {
        label: "Spouse name",
        type: "text",
        name: "Spouse_name",
        require: false,
        error: false,
      },

      {
        label: "Nationality",
        type: "select",
        nationality: true,
        name: "Nationality",
        placeholder: "Select Your Nationality",
        require: true,
        error: false,
      },

      {
        label: "Other Nationalities or Citizenships  ",
        placeholder: "Only if different from above",
        name: "Other_Nationalities_or_Citizenships",
        type: "select",
        nationality: true,
        require: false,
        error: false,
      },

      {
        label: "Country of Residency",
        type: "select",
        name: "Country_of_Residency",
        country: true,
        placeholder: "Select Country of Residency",
        require: false,
        error: false,
      },

      // permanet address
      {
        mega: true,
        magaTitle: "Personal Info",
        megaName: "Personal_Info",
        megaInputs: [
          {
            label: "Street",
            name: "Street",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Building Name/No. ",
            name: "Building_Name_No",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Premises No: ",
            name: "Premises_No",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "City",
            name: "City",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            placeholder: "Select a Country",
            country: true,
            require: true,
            error: false,
          },
        ],
      },

      // mailing address
      {
        mega: true,
        magaTitle: "Mailing address",
        megaName: "Mailing_address",
        megaInputs: [
          {
            label: "Street",
            name: "Street",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "PO Box/Zip Code:",
            name: "PO_Box_Zip_Code",
            type: "number",
            require: false,
            error: false,
          },
          {
            label: "Building Name/No. ",
            name: "Building_Name_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Premises No: ",
            name: "Premises_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "City",
            name: "City",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            placeholder: "Select a Country",
            country: true,
            require: false,
            error: false,
          },
        ],
      },

      // contact details
      {
        mega: true,
        magaTitle: "Contact details",
        megaName: "Contact_details",
        megaInputs: [
          {
            label: "Telephone",
            name: "Telephone",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "Fax",
            name: "Fax",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "Registered Email",
            name: "Registered_Email",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Relationship Manager / name:",
            placeholder: "(if applicable)",
            name: "Relationship_Manager_name",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Relationship Manager / email:",
            placeholder: "(if applicable)",
            name: "Relationship_Manager_email",
            type: "email",
            require: false,
            error: false,
          },
          {
            label: "Websites/LinkedIn profiles",
            name: "Websites_LinkedIn_profiles",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      //  Professional details
      {
        mega: true,
        title: "Professional details",
        magaTitle: "Professional details",
        megaName: "Professional_details",
        megaInputs: [
          {
            label: "Employment Status",
            type: "select",
            name: "Employment_Status",
            placeholder: "Select status",
            options: [
              { label: "Employed", value: "Employed" },
              { label: "Self Employed", value: "Self Employed" },
              { label: "Retired", value: "Retired" },
              { label: "Not Employed", value: "Not Employed" },
            ],
            require: true,
            error: false,
          },
          {
            label: "Job title / Function ",
            name: "Job_title_Function",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Sector / Industry",
            name: "Sector_Industry",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Name of employer ",
            name: "Name_of_employer",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country of business",
            name: "Country_of_business",
            placeholder: "Select A Country",
            type: "select",
            country: true,
            require: true,
            error: false,
          },
        ],
      },

      //Politically member
      {
        label:
          "Are you /is a member of your family / a close associate a Politically Exposed Person?",
        type: "select",
        name: "Are_you_is_a_member_of_your_family_a_close_associate_a_Politically_Exposed_Person",
        placeholder: "Select",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "Politically Exposed Person",
        megaMessage: true,
        specialDesign: true,
        megaName: "Politically_Exposed_Person",
        megaInputs: [
          {
            label: "Name ",
            name: "Name_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Function ",
            name: "Function_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Name ",
            name: "Name_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Function ",
            name: "Function_2",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  // your busniess
  {
    inputs: [
      { pageTitle: "About your business intentions with us" },

      {
        label: "How did you learn about us and our services? ",
        type: "select",
        name: "How_did_you_learn_about_us_and_our_services",
        placeholder: "Select",
        options: [
          {
            label: "By one of your employees",
            value: "By one of your employees",
          },
          {
            label: "On my own initiative (marketing, website…)",
            value: "On my own initiative (marketing, website…)",
          },
          {
            label: "Referral by one of your other customers",
            value: "Referral by one of your other customers",
          },
          {
            label:
              "Referral by one of your business introducers/intermediaries",
            value:
              "Referral by one of your business introducers/intermediaries",
          },
        ],
        required: true,
        error: false,
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "What are the services or products you are interested in?",
        megaName: "What_are_the_services_or_products_you_are_interested_in",
        megaInputs: [
          {
            label: "Services",
            name: "Services",
            type: "select",
            placeholder: "Select",
            options: [
              { label: "Investment advice", value: "Investment advice" },
              {
                label: "Arranging credit facilities",
                value: "Arranging credit facilities",
              },
              {
                label:
                  "Receipt and transmission of orders in financial products",
                value:
                  "Receipt and transmission of orders in financial products",
              },
              {
                label: "Execution of orders in financial products",
                value: "Execution of orders in financial products",
              },
              {
                label: "Arranging investments",
                value: "Arranging investments",
              },
              {
                label: "Discretionary Portfolio Management",
                value: "Discretionary Portfolio Management",
              },
              {
                label: "Non-discretionary Portfolio Management",
                value: "Non-discretionary Portfolio Management",
              },
            ],

            require: false,
            error: false,
          },
          {
            label: "Other",
            name: "Other",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Which amount of assets do you want to be serviced by us?",
        megaName: "Which_amount_of_assets_do_you_want_to_be_serviced_by_us",
        megaInputs: [
          {
            label: "USD",
            name: "USD",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "% of total net wealth ",
            name: "total_net_wealth",
            type: "number",
            require: true,
            error: false,
          },
        ],
      },

      {
        label: "What kind of assets will be transferred to us?  ",
        type: "select",
        name: "What_kind_of_assets_will_be_transferred_to_us",
        placeholder: "Select",
        options: [
          { label: "Securities", value: "securities" },
          {
            label: "Cash",
            value: "Cash",
          },
          {
            label: "Cash & Securities",
            value: "Cash & Securities",
          },
        ],
        require: true,
        error: false,
      },

      {
        label: "What is the expected annual deposit of assets?  ",
        type: "select",
        name: "What_is_the_expected_annual_deposit_of_assets",
        placeholder: "Select",
        options: [
          { label: "Less than 500.000 USD", value: "Less than 500.000 USD" },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD",
          },
          {
            label: "More than 1.000.000 USD",
            value: "More than 1.000.000 USD",
          },
        ],

        require: true,
        error: false,
      },

      {
        label: "What is the expected annual withdrawal of assets?",
        type: "select",
        name: "What_is_the_expected_annual_withdrawal_of_assets",
        placeholder: "Select",
        options: [
          { label: "Less than 500.000 USD", value: "Less than 500.000 USD" },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD",
          },
          {
            label: "More than 1.000.000 USD",
            value: "More than 1.000.000 USD",
          },
        ],

        require: true,
        error: false,
      },

      {
        label: "What is the expected number of trades per month?",
        type: "select",
        name: "What_is_the_expected_number_of_trades_per_month",
        placeholder: "Select",
        options: [
          { label: "0 - 20", value: "0-20" },
          {
            label: "21 - 50",
            value: "21-50",
          },
          {
            label: "50+",
            value: "50+",
          },
        ],

        require: true,
        error: false,
      },

      {
        mega: true,
        bigDesign: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "Which Trading Platform would you like access to?",
        megaName: "Which_Trading_Platform_would_you_like_access_to",
        megaInputs: [
          {
            label: "Iress (Equities)",
            name: "Iress_Equities",
            type: "select",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "Leverage",
                value: "Leverage",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],

            require: false,
            error: false,
          },
          {
            label: "MT5 (FX - Commodities - Indices)",
            type: "select",
            name: "MT5_FX_Commodities_Indices",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "1:100",
                value: "1:100",
              },
              {
                label: "1:30",
                value: "1:30",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],
            require: false,
            error: false,
          },
          {
            label:
              "ARK Pro (FX - Commodities - Indices - Equities - Options - Futures)",
            type: "select",
            name: "ARK_Pro_FX_Commodities_Indices_Equities_Options_Futures",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "Leverage",
                value: "Leverage",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],
            require: false,
            error: false,
          },

          {
            label: "Dealing Desk/Emails/Voice/BBG Chat",
            type: "select",
            name: "Dealing_Desk_Emails_Voice_BBG_Chat",
            placeholder: "Dealing Desk/Emails/Voice/BBG Chat",
            options: [
              {
                label: "Dealing Desk/Emails/Voice/BBG Chat",
                value: "Dealing Desk/Emails/Voice/BBG Chat",
              },
            ],
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "How will the assets be transferred to/from us?",
        megaName: "How_will_the_assets_be_transferred_to_from_us",
        megaInputs: [
          {
            label: "Name financial institution",
            name: "Name_financial_institution",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Country financial institution",
            name: "Country_financial_institution",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Name accountholder ",
            name: "Name_accountholder",
            type: "text",
            require: true,
            error: false,
          },
        ],
      },
    ],
  },

  //  wealth and income
  {
    inputs: [
      {
        pageTitle: "About your wealth and income",
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "What is your estimated global net wealth?",
        megaName: "What_is_your_estimated_global_net_wealth",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Less than 500.000 USD",
                value: "Less than 500.000 USD",
              },
              {
                label: "500.000 to 1.000.000 USD",
                value: "500.000 to 1.000.000 USD",
              },
              {
                label: "1.000.000 to 5.000.000 USD",
                value: "1.000.000 to 5.000.000 USD",
              },
              {
                label: "5.000.000 to 10.000.000 USD",
                value: "5.000.000 to 10.000.000 USD",
              },
              {
                label: "More than 10.000.000 USD",
                value: "More than 10.000.000 USD",
              },
            ],
          },
          {
            name: "Other_What_is_your_estimated_global_net_wealth",
            label: "Other",
            type: "number",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "How have you built up your global net wealth?",
        megaName: "How_have_you_built_up_your_global_net_wealth",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Professional/business activities",
                value: "Professional/business activities",
              },
              {
                label: "Inheritance / Gifts",
                value: "Inheritance / Gifts",
              },
              {
                label: "Pension or Stock option payout",
                value: "Pension or Stock option payout",
              },
              {
                label: "Real estate transactions",
                value: "Real estate transactions",
              },
              {
                label: "Managing personal investments",
                value: "Managing personal investments",
              },
              {
                label: "Sale of own business",
                value: "Sale of own business",
              },
            ],
          },
          {
            name: "Other_How_have_you_built_up_your_global_net_wealth",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        label: "In which countries did you build up your wealth?",
        name: "In_which_countries_did_you_build_up_your_wealth",
        type: "select",
        placeholder: "Select a Country",
        country: true,
        require: true,
        error: false,
      },

      {
        label: "In which countries do you hold your accumulated wealth?",
        name: "In_which_countries_do_you_hold_your_accumulated_wealth",
        type: "select",
        placeholder: "(Only if different from above)",
        country: true,
        require: true,
        error: false,
      },

      {
        label: "What is your annual regular net income?",
        type: "select",
        name: "What_is_your_annual_regular_net_income",
        placeholder: "Select",
        options: [
          { label: "Up to 100.000 USD", value: "Up to 100.000 USD" },
          { label: "100.000 to 250.000 USD", value: "100.000 to 250.000 USD " },
          { label: "250.000 to 500.000 USD", value: "250.000 to 500.000 USD " },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD ",
          },
          {
            label: "More than 1.000.000 USD",
            value: "More than 1.000.000 USD ",
          },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "How do you make your regular net income? ",
        megaName: "How_do_you_make_your_regular_net_income",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Salary or pension",
                value: "Salary or pension",
              },
              {
                label: "income from investments",
                value: "income from investments",
              },
              {
                label: "Business income",
                value: "Business income",
              },
              {
                label: "rental income",
                value: "rental income",
              },
            ],
          },
          {
            name: "Other_How_do_you_make_your_regular_net_income",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        label: "In which countries do you make your regular net income?",
        name: "In_which_countries_do_you_make_your_regular_net_income",
        type: "select",
        placeholder: "Select a Country",
        country: true,
        require: true,
        error: false,
      },
    ],
  },

  //  understanding of financial markets
  {
    inputs: [
      {
        pageTitle:
          "Your experience and understanding of financial markets and instruments",
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "How is your knowledge and understanding of financial markets and products?",
        megaName:
          "How_is_your_knowledge_and_understanding_of_financial_markets_and_products",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Poor",
                value: "Poor",
              },
              {
                label: "Moderate ",
                value: "Moderate ",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Excellent",
                value: "Excellent",
              },
            ],
          },

          {
            name: "Other_How_is_your_knowledge_and_understanding_of_financial_markets_and_products",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "For how long have you been active on the financial markets / investing in financial products?",
        megaName:
          "For_how_long_have_you_been_active_on_the_financial_markets_investing_in_financial_products",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Less than 1 year",
                value: "Less than 1 year",
              },
              {
                label: "1 to 2 years ",
                value: "1 to 2 years ",
              },
              {
                label: "3 to 5 years",
                value: "3 to 5 years",
              },
              {
                label: "more than 5 years",
                value: "more than 5 years",
              },
            ],
          },
          {
            name: "Other_For_how_long_have_you_been_active_on_the_financial_markets_investing_in_financial_products",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "How have your assets been managed before?",
        megaName: "How_have_your_assets_been_managed_before",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "by myself",
                value: "by myself",
              },
              {
                label: "with advice of a financial advisor",
                value: "with advice of a financial advisor",
              },
              {
                label: "non-discretionary management",
                value: "non-discretionary management",
              },
              {
                label: "discretionary management",
                value: "discretionary management",
              },
            ],
          },
          {
            name: "Other_How_have_your_assets_been_managed_before",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "In which financial products have you already invested on a regular basis?",
        megaName:
          "In_which_financial_products_have_you_already_invested_on_a_regular_basis",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "shares (equity)",
                value: "shares (equity)",
              },
              {
                label: "bonds (debentures)",
                value: "bonds (debentures)",
              },
              {
                label: "collective investment funds",
                value: "collective investment funds",
              },
              {
                label: "sukuk",
                value: "sukuk",
              },
              {
                label: "structured products",
                value: "structured products",
              },
              {
                label: "derivatives (options, futures)",
                value: "derivatives (options, futures)",
              },
              {
                label: "insurance investments",
                value: "insurance investments",
              },
              {
                label: "commodities",
                value: "commodities",
              },
              {
                label: "currencies",
                value: "currencies",
              },
              {
                label: "hedge funds",
                value: "hedge funds",
              },
              {
                label: "CFDs",
                value: "CFDs",
              },
            ],
          },
          {
            name: "Other_In_which_financial_products_have_you_already_invested_on_a_regular_basis",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "How often do you take investments decisions (buy/sell)?",
        megaName: "How_often_do_you_take_investments_decisions_buy_sell",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "seldom",
                value: "seldom",
              },
              {
                label: "monthly",
                value: "monthly",
              },
              {
                label: "weekly",
                value: "weekly",
              },
              {
                label: "daily",
                value: "daily",
              },
            ],
          },
          {
            name: "Other_How_often_do_you_take_investments_decisions_buy_sell",
            label: "Other",
            type: "text",
            validation: {
              errorMessage: "Please choose",
            },
          },
        ],
      },

      {
        label:
          "About which financial services and/or financial products would you like to receive more information",
        type: "text",
        name: "About_which_financial_services_and_or_financial_products_would_you_like_to_receive_more_information",

        require: true,
        error: false,
      },
    ],
  },

  // final page
  {
    inputs: [
      {
        pageTitle: "Final Step",
      },
      {
        content: [
          {
            title: "Data protection statement",
            text: [
              "In order to provide you with products and services we need to collect, use, share and store personal and financial information about you. This includes the information requested on this form and information obtained from third parties. The information requested may be used to assist us in providing the service you are applying for, to advise you of other products and services, to confirm, update and enhance records and to establish your identity. The data that we collect may be shared / transferred to / or stored / or processed at, our offices in other countries outside the DIFC.",
            ],
          },
          {
            title: "Declaration",
            text: [
              "I confirm that all the assets / funds to be invested upon advice / arrangements by you have been lawfully acquired and are not derived from, either directly or indirectly, or otherwise connected with any criminal or unlawful activity. I declare to act on my own behalf. ",
              "I declare that the above-mentioned information is true and correct to the best of our knowledge and it has been made in good faith. I accept that you will rely on this information to fulfil all of its regulatory requirements.",
            ],
          },
        ],
      },

      {
        label: "Name",
        type: "text",
        name: "Capacity",
        require: true,
        error: false,
      },
      {
        label: "Date",
        type: "date",
        name: "Date_of",
        require: true,
        error: false,
      },
      {
        label: "Signature",
        type: "upload",
        name: "Signature",
        require: true,
        error: false,
      },
    ],
  },
];

export default CIF_Individual_Form_content;
