const Upload_File_Form_conent = [
  {
    inputs: [
      {
        pageTitle: "Due Diligence Documents for Individual Clients",
      },
      {
        content: [
          {
            text: [
              "Important to note",
              "1- All documents must be certified by an accredited third party (notary, public official, bank, post office, chartered personnel, or lawyer",
              "2- Proof of address (Less than 3 months old) can include a utility bill, bank letters, or building society statements and need to be certified. Please note that mobile phone bills are not accepted.",
              "Document should not older than 6 months",
            ],
          },
        ],
      },
      {
        label:
          "Copy of International Travel Passport (can be provided in picture format)",
        type: "file",
        name: "Copy_of_International_Travel_Passport",
        require: true,
        error: false,
      },

      {
        label: "Copy of National ID (can be provided in picture format)",
        type: "file",
        name: "Copy_of_National_ID",
        require: true,
        error: false,
      },

      {
        label:
          "Proof of Residential Address Utility. Bills or Bank letters, and Building society statements are accepted. Mobile phone bills are not accepted",
        name: "Proof_of_Residential_Address_Utility",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Stamped bank statements showing total funds for cash transfer from the bank stated in the Client Identification Form. Transfers and Deposits will take place only on the bank account/s declared in the Customer Identification Form",
        name: "Stamped_bank_statements_showing_total_funds_for_cash_transfer_from_the_bank_stated_in_the_Client_Identification_Form",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Documentation (could be in the form of bank statements or portfolio statements) showing proof of total net wealth. ",
        name: "Documentation_showing_proof_of_total_net_wealth",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Stamped Real estate transactions/title deeds (if applicable as part of source of wealth)",
        name: "Stamped_Real_estate_transactions_title_deeds",
        type: "file",
        require: false,
        error: false,
      },

      {
        label:
          "Stamped fixed deposits statements (if applicable as part of source of wealth)",
        name: "Stamped_fixed_deposits_statements",
        type: "file",
        require: false,
        error: false,
      },

      {
        label:
          "Latest portfolio statement to prove experience and knowledge of financial markets",
        name: "Latest_portfolio_statement_to_prove_experience_and_knowledge_of_financial_markets",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Stamped Bank statements showing salary credited consistently for 3 months",
        name: "Stamped_Bank_statements_showing_salary_credited_consistently_for_3_months",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Stamped annual income tax statements for the most recent two years",
        name: "Stamped_annual_income_tax_statements_for_the_most_recent_two_years",
        type: "file",
        require: true,
        error: false,
      },

      {
        label:
          "Certified document showing income from the company (if applicable) ",
        name: "Certified_document_showing_income_from_the_company",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Certificate of Incorporation (if applicable)",
        name: "Certificate_of_Incorporation",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Certificate of Name Change (if applicable)",
        name: "Certificate_of_Name_Change",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Certificate of Incumbency (if applicable)",
        name: "Certificate_of_Incumbency",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Trade License",
        name: "Trade_License",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Memorandum or Articles of Association",
        name: "Memorandum_or_Articles_of_Association",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Register of shareholders (shareholding adding up to 100%). **",
        name: "Register_of_shareholders",
        type: "file",
        require: false,
        error: false,
      },

      {
        label: "Latest Audited Financial Statements",
        name: "Latest_Audited_Financial_Statements",
        type: "file",
        require: false,
        error: false,
      },

      {
        label:
          "Latest Ownership Structure provided to be dated and properly signed",
        name: "Latest_Ownership_Structure_provided_to_be_dated_and_properly_signed",
        type: "file",
        require: false,
        error: false,
      },
    ],
  },
];

export default Upload_File_Form_conent;
