import IressHero from "./Components/IressHero";
import IressFeatures from "./Components/IressFeatures";
import OpenAccount from "../UI/OpenAccount";

const Iress = () => {
  return (
    <main>
      <IressHero />
      <IressFeatures />
      <OpenAccount />
    </main>
  );
};

export default Iress;
