import {
  PencilSimpleLine,
  UploadSimple,
  CheckSquare,
  SortDescending,
} from "@phosphor-icons/react";

const howItWorkContent = {
  header:
    " You are about to open an account with ARK Capital, a brokerage firm headquartered in Dubai, U.A.E and regulated by Dubai Financial Services Authority (DFSA). The application form will take you 10 miniutes. Signing up is really easy!",

  howItWorks: [
    {
      icon: <CheckSquare size={32} />,
      title: "Choose the account type (corporate /individual)",
      // text: "We will ask you what type of ARK Capital account you want to open with us",
    },
    {
      icon: <PencilSimpleLine size={32} />,
      title: "Complete your application",
      // text: "we need some information about you in order to open your account",
    },
    {
      icon: <UploadSimple size={32} />,
      title: "Upload your Documents and confirm your ID",
      // text: "We will ask you to upload your documents to verify your account",
    },
    {
      icon: <SortDescending size={32} />,
      title: "Submit your form",
      // text: "You can digitally sign contracts, and submit them",
    },
  ],
};

export default howItWorkContent;
