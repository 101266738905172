import { useContext } from "react";
// UI
import Container from "components/Container/Container";
// content
import { gettermsContent } from "constant/Disclaimer/TermOfUse/TermsOfUse";
import { gettermsUseOfWebsite } from "constant/Disclaimer/TermOfUse/TermsOfUse";
import { gettermsContentList } from "constant/Disclaimer/TermOfUse/TermsOfUse";
// component
import SmallList from "../UI/SmallList";
import BigList from "../UI/BigList";
// context
import { LanguageContext } from "context/LanguageContext";

const TermsOfUse = () => {
  const { language } = useContext(LanguageContext);
  const termsFirstChunk = gettermsContent(language);
  const termsUseOfWebsite = gettermsUseOfWebsite(language);
  const termsContentList = gettermsContentList(language);
  return (
    <main>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-center text-3xl lg:text-4xl mt-secondary lg:mt-primary ">
          <h1>
            {language === "en" ? "Terms of Use" : "Условия использования"}
          </h1>
        </div>

        <div className="mb-primary">
          <section className="mt-secondary">
            <SmallList contentList={termsFirstChunk} />
          </section>

          <section className="mt-secondary">
            <BigList contentList={termsUseOfWebsite} />
          </section>

          <section className="mt-secondary">
            <SmallList contentList={termsContentList} />
          </section>
        </div>
      </Container>
    </main>
  );
};

export default TermsOfUse;
