const termsContent = {
  en: [
    {
      title: "",
      content: [
        "Thank you for visiting the website of Ark Capital Management (Dubai) Limited (“Ark Capital”). This website (“Website”) is provided by Ark Capital and its contents are to be used for informational purposes only.",
        "The information contained in this communication is intended for Clients who qualify as Professional Clients or Market Counterparty under the Rules enacted by the DFSA, and no other Person shall act upon such information. This Website is exclusively provided for residents of the DIFC and residents of countries where it would not be prohibited or against local laws to provide information in this Website (hereafter “Permitted Jurisdictions”). The financial products and services described in this Website are only available to persons accessing the Website from within the DIFC or another Permitted Jurisdiction. Ark Capital reserves the right to modify these Terms without notice and each use of this Website constitutes your agreement to be bound by the Terms and modified Terms at the time of such use.",
        "Any information or opinions contained herein (other than information or opinions contained in reports or articles prepared by third parties) have been produced by Ark Capital based on information obtained from sources believed to be reliable but which it has not independently verified. Ark Capital makes no guarantee, representation or warranty and accepts no responsibility or liability as to the accuracy and completeness of such information or opinions.",
        "This Website may contain certain reports or articles from third parties. The information contained in these reports and articles was prepared exclusively by third parties that are independent from Ark Capital and its employees. As such, these articles and reports solely represent the opinions and work of their third party authors. Ark Capital does not provide any assurances as to the veracity, reliability or comprehensiveness of these articles. Nothing in any reprinted or linked reports or articles should be considered an offer to buy or sell any security by Ark Capital.",
        "This Website, and all materials, including but not limited to, content, information, trade names, trademarks, logos and other intellectual property (collectively “Materials”) is copyrighted and protected by worldwide copyright. You agree to comply with all copyright laws worldwide in your use of this Website and to prevent any unauthorized copying, reproduction and/or distribution of the Materials in part or in whole by any means whatsoever. All content and information shown on the Website (“Content”) are owned by, controlled by or licensed to Ark Capital.",
        "Ark Capital and its related persons do not accept any liability for errors and omissions. The Materials or information displayed on this Website does not in any way constitute investment, legal, tax or other advice and it should not be relied upon and the material or information on this Website does not in any way constitute and offer to sell or a solicitation of an offer to buy investments, financial instruments, securities or other services. Ark Capital does not guarantee any particular rate or return on the performance of any investment and all investments are subject to various risks. Past performance is not an indicator of future results. You should consult your legal adviser or your professional adviser if you require financial or any other advice specific to your situation.",
        "Any descriptions of past performance of funds or clients advised by Ark Capital do not indicate future performance results. Any reference to Ark Capital’s clients or specific investment recommendations may not represent the full universe and diversity of Ark Capital’s clients and investment recommendations.",
        "Information contained on this Website, including commentary on any markets in which Ark Capital or any of its clients invests, reflects analysis and other information available as of either time such information was posted on this Website or as otherwise specifically indicated. Such information and commentary are subject to change without notice. Readers should not rely on any material contained herein and should seek independent advice wherever necessary.",
        "By using this Website, you acknowledge that you have read, understood and agreed to these terms and conditions of use. If you do not agree to these terms and conditions of use, you may not access or use this Website. Ark Capital reserved the right, at its sole discretion, to change, modify, add or remove portions of these terms at any time. Therefore, Ark Capital suggests that you review these terms periodically for changes. By using this Website after Ark Capital has posted changes to these terms and conditions of use, you are agreeing to be bound by these terms as amended.",
      ],
    },
  ],
  ru: [
    {
      title: "",
      content: [
        "Благодарим вас за посещение веб-сайта Ark Capital Management (Dubai) Limited («Ark Capital»). Этот веб-сайт («Сайт») предоставляется Ark Capital и его содержание предназначено исключительно для информационных целей.",
        "Информация, содержащаяся в данной связи, предназначена для клиентов, которые соответствуют критериям Профессиональных клиентов или Контрагентов на рынке в соответствии с Правилами, принятыми DFSA, и никакое другое лицо не должно действовать на основе такой информации. Данный Сайт предоставляется исключительно для резидентов DIFC и резидентов стран, где это не было бы запрещено или не противоречило местным законам предоставление информации на данном Сайте (далее «Разрешенные юрисдикции»). Финансовые продукты и услуги, описанные на этом Сайте, доступны только лицам, получающим доступ к Сайту из DIFC или другой Разрешенной юрисдикции. Ark Capital оставляет за собой право вносить изменения в настоящие Условия без предварительного уведомления, и каждое использование этого Сайта означает ваше согласие быть связанным Условиями и измененными Условиями на момент такого использования.",
        "Любая информация или мнение, содержащееся здесь (за исключением информации или мнений, содержащихся в отчетах или статьях, подготовленных третьими лицами), были созданы Ark Capital на основе информации, полученной из источников, считаемых надежными, но которую он не проверял независимо. Ark Capital не гарантирует, не представляет и не дает никаких гарантий и не несет ответственности за точность и полноту такой информации или мнений.",
        "На этом Сайте может быть размещена определенная информация, отчеты или статьи от третьих лиц. Информация, содержащаяся в этих отчетах и статьях, была подготовлена исключительно третьими лицами, независимыми от Ark Capital и его сотрудников. Такие статьи и отчеты исключительно представляют собой мнение и работу их авторов-третьих лиц. Ark Capital не предоставляет никаких гарантий относительно достоверности, надежности или полноты этих статей. Ничто в любых перепечатанных или связанных отчетах или статьях не должно рассматриваться как предложение к покупке или продаже каких-либо ценных бумаг со стороны Ark Capital.",
        "Этот Сайт и все материалы, включая, но не ограничиваясь, содержание, информацию, коммерческие наименования, товарные знаки, логотипы и другую интеллектуальную собственность (далее - «Материалы»), защищены авторским правом и охраняются мировым авторским правом. Вы соглашаетесь соблюдать все законы об авторских правах в мире при использовании этого Сайта и предотвращать несанкционированное копирование, воспроизводство и/или распространение Материалов частично или полностью любыми способами. Весь контент и информация, размещенные на Сайте («Контент»), принадлежат, контролируются или лицензированы Ark Capital.",
        "Ark Capital и связанные с ним лица не несут ответственности за ошибки и упущения. Материалы или информация, размещенные на этом Сайте, никоим образом не составляют инвестиционных, юридических, налоговых или иных советов, и на них нельзя полагаться, и материал или информация на этом Сайте никаким образом не составляют предложение к покупке или призыв к предложению к покупке инвестиций, финансовых инструментов, ценных бумаг или других услуг. Ark Capital не гарантирует конкретную ставку или доходность по результатам какой-либо инвестиции, и все инвестиции подвержены различным рискам. Прошлая производительность не является индикатором будущих результатов. Если вам требуется финансовый или какой-либо другой совет, специфический для вашей ситуации, вы должны проконсультироваться с вашим юридическим советником или профессиональным советником.",
        "Любые описания прошлой производительности фондов или клиентов, которыми управляет Ark Capital, не указывают на результаты будущей производительности. Любая ссылка на клиентов Ark Capital или конкретные инвестиционные рекомендации может не представлять собой полную вселенную и разнообразие клиентов Ark Capital и инвестиционных рекомендаций.",
        "Информация, размещенная на этом Сайте, включая комментарии по любым рынкам, в которых инвестирует Ark Capital или его клиенты, отражает анализ и другую информацию, доступную на момент размещения такой информации на этом Сайте или как иначе явно указано. Такая информация и комментарии могут изменяться без уведомления. Читатели не должны полагаться на материал, содержащийся здесь, и должны искать независимый совет, где это необходимо.",
        "Используя этот Сайт, вы подтверждаете, что прочитали, поняли и согласились с этими условиями и положениями использования. Если вы не согласны с этими условиями и положениями использования, вы не можете получить доступ к этому Сайту или использовать его. Ark Capital оставляет за собой право по своему усмотрению изменять, модифицировать, добавлять или удалять части этих условий в любое время. Поэтому Ark Capital предлагает вам периодически пересматривать эти условия на предмет изменений. Используя этот Сайт после того, как Ark Capital разместила изменения в этих условиях и положениях использования, вы соглашаетесь быть связанными этими измененными условиями.",
      ],
    },
  ],
};
const gettermsContent = (language) => {
  return termsContent[language];
};
export { gettermsContent };

export const termsUseOfWebsite = {
  en: {
    contentTwo: [
      "All information, text, materials, graphics, logos, button icons, images, video and audio clips, trade marks (whether registered or not), advertisements, layout, arrangement, graphical user interface, look and feel, and control features of the Website are hereinafter collectively referred to as the 'Website Content'",
    ],
    header: "USE OF WEBSITE CONTENT",
    list: {
      title:
        "You are only permitted to access and use the Website for your personal, non-commercial purposes, and you must not:",
      list: [
        "modify, publish, transmit, transfer, sell, reproduce, create derivative works from, distribute, perform, display, or exploit any of the Website Content, in whole or in part, in any way for any public or commercial purpose, except as otherwise expressly permitted herein;",
        "use the Website in violation of any Applicable Law or regulation;",
        "use the Website in a manner that will infringe the copyright, trademark, trade secret, or other intellectual property rights of Ark Capital or any third party or violate other rights of third parties;",
        "access data not intended for you or log into a server or account which you are not authorized to access;",
        "breach security or authentication measures;",
        "use the Website Content to impersonate or otherwise misrepresent your identity;",
        "use the Website Content to send unsolicited mail or email, make unsolicited phone calls, or send unsolicited faxes regarding promotions and/or advertising of products or services;",
        "use any device, software, or routine to interfere or attempt to interfere with the proper working of the Website or any activity being conducted on the Website, including, without limitation, material that contains viruses, Trojan horses, or other computer programming routines or engines that are intended to damage, detrimentally interfere with, intercept, or expropriate any system, data, or information; or",
        "attempt to decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website.",
      ],
    },
    content: [
      "Ark Capital is not responsible for any Website Content posted on the Website and reserves the right to change or remove any content it sees fit.",
    ],
  },
  ru: {
    contentTwo: [
      "Вся информация, текст, материалы, графика, логотипы, значки кнопок, изображения, видео- и аудиоклипы, товарные знаки (зарегистрированные или нет), реклама, макет, компоновка, графический интерфейс, внешний вид и элементы управления веб-сайта, далее именуемые совместно 'Содержание веб-сайта'.",
    ],
    header: "ИСПОЛЬЗОВАНИЕ СОДЕРЖАНИЯ САЙТА",
    list: {
      title:
        "Вы можете использовать Сайт только для личных, не коммерческих целей, и вы не должны:",
      list: [
        "изменять, публиковать, передавать, продавать, воспроизводить, создавать производные произведения, распространять, выполнять, отображать или использовать какое-либо Содержание Сайта полностью или частично каким-либо образом для каких-либо общественных или коммерческих целей, за исключением случаев, когда это явно разрешено здесь;",
        "использовать Сайт с нарушением любого применимого закона или регуляции;",
        "использовать Сайт так, чтобы это нарушило авторские права, товарные знаки, коммерческую тайну или другие интеллектуальные права Ark Capital или какого-либо третьего лица или нарушило права третьих лиц;",
        "получать доступ к данным, не предназначенным для вас, или входить в сервер или аккаунт, к которому у вас нет прав доступа;",
        "нарушать меры безопасности или аутентификации;",
        "использовать Содержание Сайта для подражания или иным образом искажения вашей личности;",
        "использовать Содержание Сайта для отправки незапрошенной почты или электронной почты, совершения незапрошенных телефонных звонков или отправки незапрошенных факсов, касающихся рекламы продуктов или услуг;",
        "использовать какое-либо устройство, программное обеспечение или процедуру для вмешательства или попытки вмешательства в нормальную работу Сайта или любой деятельности, проводимой на Сайте, включая, но не ограничиваясь, материал, содержащий вирусы, троянские кони или другие программные процедуры или движки, предназначенные для повреждения, нанесения вреда, перехвата или завладения какой-либо системой, данными или информацией; или",
        "попытаться разгадать, декомпилировать, раскладывать или проводить обратную разработку какого-либо программного обеспечения, включающего или составляющего часть Сайта.",
      ],
    },
    content: [
      "Ark Capital не несет ответственности за Содержание Сайта, размещенное на Сайте, и оставляет за собой право изменять или удалять любое содержание по своему усмотрению.",
    ],
  },
};

const gettermsUseOfWebsite = (language) => {
  return termsUseOfWebsite[language];
};
export { gettermsUseOfWebsite };

const termsContentList = {
  en: [
    {
      title: "INTELLECTUAL PROPERTY",
      content: [
        "Except as otherwise expressly stated, Ark Capital owns or is a licensee to the copyright and all other intellectual property contained in the Website and the App, including but not limited to all text, images or links. All trade names, brand names, or logos, (collectively, 'Marks') are trademarks owned by or licensed to Ark Capital unless otherwise noted. The Marks on the Website and/or the App are variously protected by the laws of the UAE and other countries where applicable. No use of any of these Marks may be made without the prior written authorization of Ark Capital.",
      ],
    },
    {
      title: "MARKETING AND/OR DISTRIBUTION OF FOREIGN FUNDS",
      content: [
        "When you consult information (prospectus or private placement memorandum) on a Foreign Fund, you should keep in mind the following warning: 'This Prospectus relates to a Fund which is not subject to any form or regulation or approval by the Dubai Financial Services Authority (“DFSA”).",

        "The DFSA has no responsibility for reviewing or verifying any Prospectus or other documents in connection to this Fund. Accordingly, the DFSA has not approved this Prospectus or any other associated documents nor taken any steps to verify the information set out in this Prospectus, and has no responsibility for it. The Units to which this Prospectus relates may be illiquid and/or subject to restrictions on their resale. Prospective purchasers should consult an authorized financial advisor.'",
      ],
    },
    {
      title: "PRIVACY / DATA PROTECTION",
      content: [
        "You agree that any Personal Data you provide through this Website is expressly being provided with your consent. Ark Capital is subject to the DIFC Law no 5 of 2020 (the “Data Protection Law 2020”) and respects users’ privacy and treats information received from your use of this Website with the utmost care and confidentiality. ",

        "The personal data will be processed and, if necessary, transferred for storage on a secure dedicated server, by Ark Capital in accordance with the Data Protection Law, and you have a right of access to, and rectification, and erasure or blocking of your personal data. ",

        "Ark Capital does not sell or pass on any contact information you provide through this Website to third parties.",
      ],
    },
    {
      title: "LIMITATION OF LIABILITY AND INDEMNITY",
      content: [
        "Under no circumstances shall Ark Capital and/or its officers, directors, owners, agents, employees, information providers, and copyright owners be liable for any claims, liabilities, losses, costs, or damages, including without limitation any direct, indirect, special, punitive, incidental, or consequential damages or any damages including, but not limited to, damages arising out of or in connection with any access, use or inability to use this Website or your breach of these Terms of Use or your violation of any Applicable Law or any right of a third party or any information or materials obtained through use and access of this Website, whether based on contract, tort, negligence, strict liability, or otherwise, even if Ark Capital has been advised of the possibility of damages or losses.",

        "The Website, the App, the Website Content, and the App Content are provided for information purposes only and should not be used as a basis for making business decisions nor should it be regarded by you as a substitute for specific professional advice. ",

        "Ark Capital makes no warranties or representations to you as to the accuracy, authenticity, or completeness of the content on the Website, which is subject to change at any time without notice.",

        "The Website, the App, the Website Content, and the App Content are provided on an 'as-is' basis and, to the extent lawful, with no warranties whatsoever. Ark Capital expressly disclaims (to the fullest extent permitted by law) any and all express, implied, and statutory warranties, including, without limitation, the warranties of merchantability, fitness for a particular purpose, freedom from computer virus, title, and non-infringement of proprietary rights.",

        "Ark Capital and its personnel are not liable for any direct, indirect, punitive, incidental, special, or consequential damages or loss (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) arising out of, or in any way connected with, the use or inability to use the Website, the App, the Website Content, and the App Content, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not Ark Capital is advised of the possibility of such damages.",

        "Without limiting the above, Ark Capital does not accept any liability in respect of your involvement in any mediation, arbitration, tribunal hearing, court proceeding, or other proceedings (of whatever nature) with any third party in any way.",

        "The material appearing on our Website and/or App may include technical, typographical, or photographic errors. Ark Capital does not represent, warrant, or endorse that any of the Website Content and/or App Content is accurate, complete, reliable, or current. Ark Capital may make changes to the materials contained on the Website and/or App at any time without notice. You acknowledge that any reliance upon such Website Content and/or App Content shall be at your sole risk.",

        "Ark Capital is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any email due to technical problems or traffic congestion on the Internet or on the Website or the App or combination thereof, including any injury or damage to any users or to any person's computer related to or resulting from participation or downloading materials in connection with the Website.",

        "Reference to any specific commercial product, process, or service by trade name, trademark, manufacturer, or otherwise does not constitute or imply its endorsement, recommendation, or favoring by Ark Capital. The views and opinions of document authors do not necessarily state or reflect those of Ark Capital or any of its affiliates.",
      ],
    },
    {
      title: "CHANGES TO T&Cs",
      content: [
        "The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, you are encouraged to frequently visit these sections to be updated about the changes on the Website. ",
      ],
    },
    {
      title: "ONLINE PAYMENTS",
      content: [
        "We accept payments online using Visa and MasterCard debit cards in AED (or any other agreed currency) *",
        "*The exchange rate will be applied as per the bank conversion rate on the settlement date.",

        "Refunds will be done only through the Original Mode of Payment.",
      ],
    },
    {
      title: "TERMINATION OF SERVICES",
      content: [
        "We reserve the right, in our sole discretion, to restrict, suspend or terminate your access to all or any part of the Website and/or the App at any time for any reason without prior notice or liability. ",

        "We may change, suspend, or discontinue all or any aspect of the Website and/or the App at any time, including the availability of any feature, database, Website Content, or App Content without prior notice or liability. ",

        "We shall not be liable to you or to any third party for any modification, termination, suspension or discontinuation of the Website.",
      ],
    },
    {
      title: "GENERAL",
      content: [
        "Ark Capital will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.",

        "Each provision of these Terms is individually severable. If any provision is or becomes illegal, unenforceable, or invalid in any jurisdiction, it is to be treated as being severed from this document in the relevant jurisdiction, but the rest of this document will not be affected. The legality, validity, and enforceability of the provision in any other jurisdiction will not be affected.",
      ],
    },
    {
      title: "GOVERNING LAW",
      content: [
        "These Terms as well as your access to the Website and the App are subject to and governed by the laws of the Dubai International Financial Centre (“DIFC”), and the DIFC courts shall have exclusive jurisdiction on any dispute that may arise out of the use of this Site. You agree to submit to the exclusive jurisdiction of the DIFC courts should any litigation be raised by you against Ark Capital.",
      ],
    },
  ],
  ru: [
    {
      title: "ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ",
      content: [
        "За исключением случаев, когда это явно указано иное, Ark Capital владеет или является лицензиаром авторских прав и всех других объектов интеллектуальной собственности, содержащихся на Сайте и в приложении, включая, но не ограничиваясь, весь текст, изображения или ссылки. Все торговые наименования, бренды или логотипы (в совокупности 'Знаки') являются торговыми марками, принадлежащими Ark Capital или имеющими лицензию на использование, за исключением случаев, когда указано иное. Знаки на Сайте и/или в приложении различными способами защищены законами Объединенных Арабских Эмиратов и других стран, где это применимо. Никакое использование этих Знаков не может осуществляться без предварительного письменного разрешения Ark Capital.",
      ],
    },
    {
      title: "МАРКЕТИНГ И/ИЛИ РАСПРОСТРАНЕНИЕ ИНОСТРАННЫХ ФОНДОВ",
      content: [
        "При консультации информации (проспекта или меморандума о частном размещении) о Зарубежном Фонде, помните следующее предупреждение: 'Данный Проспект относится к Фонду, который не подвергается какой-либо форме регулирования или одобрения Дубайским Управлением финансовых услуг ('DFSA').",

        "DFSA не несет ответственности за рассмотрение или проверку какого-либо проспекта или других документов, связанных с этим Фондом. Следовательно, DFSA не одобрило данный Проспект или какие-либо другие связанные с ним документы и не предприняло никаких мер по проверке информации, представленной в данном Проспекте, и не несет ответственности за нее. Единицы, к которым относится данный Проспект, могут быть непродаваемыми и/или подвергаться ограничениям на их воспроизводство. Потенциальные покупатели должны проконсультироваться с уполномоченным финансовым консультантом.'",
      ],
    },
    {
      title: "КОНФИДЕНЦИАЛЬНОСТЬ / ЗАЩИТА ДАННЫХ",
      content: [
        "Вы соглашаетесь, что любые персональные данные, предоставленные вами через данный Сайт, предоставляются исключительно с вашего согласия. Ark Capital подчиняется Закону ДИФК No 5 от 2020 года (Закон о защите данных 2020 года) и уважает конфиденциальность пользователей и обрабатывает информацию, полученную от вашего использования данного Сайта, с максимальной осторожностью и конфиденциальностью. ",

        "Персональные данные будут обрабатываться и, при необходимости, передаваться для хранения на безопасном выделенном сервере Ark Capital в соответствии с Законом о защите данных и у вас есть право на доступ, исправление и стирание или блокирование ваших персональных данных. ",

        "Ark Capital не продает и не передает третьим лицам контактную информацию, предоставленную вами через данный Сайт.",
      ],
    },
    {
      title: "ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ И ВОЗМЕЩЕНИЕ УБЫТКОВ",
      content: [
        "По никаким обстоятельствам Ark Capital и/или его должностные лица, директора, владельцы, агенты, сотрудники, поставщики информации и владельцы авторских прав не несут ответственности за какие-либо претензии, обязательства, убытки, расходы или убытки, включая, но не ограничиваясь, любые непосредственные, косвенные, специальные, взыскательные, случайные или последствующие убытки или какие-либо убытки, включая, но не ограничиваясь, убытки, возникшие в связи с доступом, использованием или невозможностью использования данного Сайта или нарушением вами настоящих Условий использования или нарушением вами какого-либо применимого закона или права третьей стороны или получением информации или материалов через использование и доступ к данному Сайту, независимо от того, основана ли ответственность на контракте, деликте, небрежности, жесткой ответственности или иным образом, даже если Ark Capital было уведомлено о возможности убытков или убытков.",

        "Сайт, приложение, контент Сайта и контент приложения предоставляются исключительно в информационных целях и не должны использоваться в качестве основы для принятия бизнес-решений, ни рассматриваться вами как замена конкретным профессиональным советам. ",

        "Ark Capital не предоставляет вам никаких гарантий или представлений относительно точности, подлинности или полноты содержания Сайта, которое может изменяться в любое время без предварительного уведомления.",

        "Сайт, приложение, контент Сайта и контент приложения предоставляются 'как есть' и, в пределах, допустимых законом, без каких-либо гарантий. Ark Capital явно отказывается (полностью, как это предусмотрено законом) от всех явных, подразумеваемых и законодательных гарантий, включая, но не ограничиваясь, гарантийами товарной пригодности, соответствия определенной цели, отсутствия компьютерных вирусов, права на собственность и отсутствия нарушения авторских прав.",

        "Ark Capital и его персонал не несут ответственности за непосредственные, косвенные, взыскательные, случайные или последствующие убытки или потери (включая, но не ограничиваясь, случайными и последствующими убытками, упущенной прибылью или убытками, связанными с утратой данных или прерыванием бизнеса), возникшими из-за или в связи с использованием или невозможностью использования Сайта, приложения, контента Сайта и контента приложения, независимо от того, на каком основании возникла такая ответственность, будь то договор, деликт (включая небрежность) или иная правовая теория, и независимо от того, уведомлено ли Ark Capital о возможности таких убытков.",

        "Не ограничивая вышеизложенное, Ark Capital не несет ответственности за ваше участие в каких-либо средствах разрешения споров, арбитражных, третейских судебных заседаниях, судебных процедурах или других процедурах (любой природы).",

        "Материалы, размещенные на нашем Сайте и/или в приложении, могут включать технические, типографские или фотографические ошибки. Ark Capital не утверждает, не гарантирует и не поддерживает то, что весь контент Сайта и/или контент приложения точен, полон, надежен или актуален. Ark Capital вправе вносить изменения в материалы, содержащиеся на Сайте и/или в приложении, в любое время без предварительного уведомления. Вы соглашаетесь с тем, что вся зависимость от такого контента Сайта и/или контента приложения происходит на вашем собственном риске.",

        "Ark Capital не несет ответственности за какие-либо проблемы или технические сбои любой телефонной сети или линий, компьютерных систем онлайн, серверов или поставщиков, компьютерного оборудования, программного обеспечения, сбои электронной почты из-за технических проблем или перегрузки сети Интернет или Сайта или приложения или их сочетания, включая какие-либо травмы или повреждения пользователей или компьютера любого лица, связанные с участием или скачиванием материалов в связи с Сайтом.",

        "Указание на какой-либо конкретный коммерческий продукт, процесс или услугу по торговому наименованию, товарному знаку, производителю или иным образом не означает или не подразумевает его одобрение, рекомендацию или благосклонность со стороны Ark Capital. Взгляды и мнения авторов документов не всегда отражают точку зрения Ark Capital или каких-либо его аффилированных лиц.",
      ],
    },
    {
      title: "ИЗМЕНЕНИЯ В УСЛОВИЯХ",
      content: [
        "Политика Сайта и Условия использования могут изменяться или обновляться время от времени в соответствии с требованиями и стандартами. Поэтому вас призывают регулярно посещать эти разделы, чтобы быть в курсе изменений на Сайте.",
      ],
    },
    {
      title: "ОНЛАЙН-ПЛАТЕЖИ",
      content: [
        "Мы принимаем онлайн-платежи с использованием дебетовых карт Visa и MasterCard в AED (или в любой другой согласованной валюте) *",
        "* Курс обмена будет применяться согласно банковскому курсу обмена на дату расчета.",

        "Возвраты будут производиться только через Оригинальный способ оплаты.",
      ],
    },
    {
      title: "ПРЕКРАЩЕНИЕ УСЛУГ",
      content: [
        "Мы оставляем за собой право в одиночку ограничивать, приостанавливать или прекращать ваш доступ ко всему или к какой-либо части Сайта и/или приложения в любое время по любой причине без предварительного уведомления или ответственности. ",

        "Мы можем в любое время изменить, приостановить или прекратить все или какой-либо аспект Сайта и/или приложения, включая доступность какой-либо функции, базы данных, контента Сайта или контента приложения без предварительного уведомления или ответственности. ",

        "Мы не несем ответственности перед вами или перед какой-либо третьей стороной за любое изменение, приостановку или прекращение Сайта.",
      ],
    },
    {
      title: "ОБЩИЕ ПОЛОЖЕНИЯ",
      content: [
        "Ark Capital НЕ будет заниматься предоставлением услуг или продуктов какой-либо из стран, подвергшихся санкциям ОФАК (Управление по активам и контролю над иностранными активами) в соответствии с законом Объединенных Арабских Эмиратов.",

        "Каждое положение настоящих Условий является независимо разрешимым. Если какое-либо положение является или становится незаконным, невозможным к применению или недействительным в какой-либо юрисдикции, оно рассматривается как отсеченное от данного документа в соответствующей юрисдикции, но это не затрагивает остальной документ. Законность, действительность и исполнимость положения в любой другой юрисдикции не будут затронуты.",
      ],
    },
    {
      title: "ПРАВОВОЕ РЕГУЛИРОВАНИЕ",
      content: [
        "На эти Условия, а также на ваш доступ к Сайту и приложению, распространяется их подчинение законам Дубайского Международного Финансового Центра (ДМФЦ), и ДМФЦ имеет исключительную юрисдикцию в отношении любых споров, которые могут возникнуть при использовании данного Сайта. Вы соглашаетесь подчиняться исключительной юрисдикции судов ДМФЦ, если какие-либо судебные разбирательства будут заведены вами против Ark Capital.",
      ],
    },
  ],
};

const gettermsContentList = (language) => {
  return termsContentList[language];
};
export { gettermsContentList };
