import Container from "../../components/Container/Container";

import ContactForm from "./components/ContactForm";
import ContactInfo from "./components/ContactInfo";

const ContactUs = () => {
  return (
    <section className="py-secondary lg:my-primary">
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <div className="flex flex-col lg:flex-row justify-between gap-secondary">
          <ContactForm />
          <ContactInfo />
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
