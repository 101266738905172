import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Container from "components/Container/Container";
import ImageContentColumn from "components/UI/ImageContentColumn";
// Conent
import getEthosContent from "constant/About/Ethos/EthosContent";

const Ethos = () => {
  const { language } = useContext(LanguageContext);
  const content = getEthosContent(language);
  return (
    <section className="py-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="">
          <h1 className="text-xl lg:text-2xl ">{content.title}</h1>

          <div className="flex flex-col gap-secondary lg:gap-primary mt-secondary lg:mt-primary">
            {content.list.map((content, index) => (
              <ImageContentColumn
                key={index}
                image={content.image}
                header={content.header}
                text={content.text}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Ethos;
