import custodyAssetImage from "../../../assests/images/services/custody/assests.jpg";

const custodyAsset = {
  en: {
    header: {
      title: "What Types of Assets Do We Custody?",
      subtitle:
        "At Ark Capital, we understand that our clients have unique needs when it comes to custody services.",
      text: "That's why we offer a diverse range of options to cater to a broad client base, including high-net-worth individuals, financial institutions, corporates, and investment firms",
    },
    list: [
      "Multi-Currency cash",
      "Bonds",
      "Stocks",
      "Commodities",
      "Options",
      "Futures",
      "Mutual Funds",
      "Structured Products",
    ],
    image: custodyAssetImage,
  },
  ru: {
    header: {
      title: "Какие типы активов мы храним?",
      subtitle:
        "В Ark Capital мы понимаем, что у наших клиентов есть уникальные потребности, когда дело доходит до услуг по хранению.",

      text: "В Ark Capital мы понимаем, что у наших клиентов есть уникальные потребности, когда дело доходит до услуг по хранению.",
    },
    list: [
      "Мультивалютные наличные",
      "Узы",
      "Запасы",
      "Товары широкого потребления",
      "Опции",
      "Будущее",
      "Взаимные фонды",
      "Структурированные продукты",
    ],
    image: custodyAssetImage,
  },
};

// Function to retrieve content based on the selected language
const getCustodyAssetContent = (language) => {
  return custodyAsset[language];
};

export default getCustodyAssetContent;
