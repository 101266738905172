const inputStyle = {
  boxStyle:
    "flex flex-col lg:flex-row lg:items-center gap-2 p-3 border-b border-gray-300",
  inputStyle:
    "w-full border border-gray-400 rounded-md p-1.5 focus:outline-blue",
  labelStyle: "flex-1 lg:border-r lg:border-gray-400",

  //   mega

  megaBoxStyle: "flex flex-col lg:flex-row lg:items-center  gap-2 ",
  megaInputStyle:
    "w-full  border border-gray-400 rounded-md p-1.5 focus:outline-blue",
  megaLabelStyle: " flex-1 lg:border-r lg:border-gray-400  ",
};

export default inputStyle;
