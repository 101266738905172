import { useContext } from "react";
// context
import { LanguageContext } from "context/LanguageContext.js";
// component
import LinkNav from "./LinkNav";
// nav link
import GetHeaderLinks from "constant/Header/HeaderLinks";

const Navigation = ({ headerIsSticky }) => {
  const { language } = useContext(LanguageContext);
  const navigationContent = GetHeaderLinks();
  const headerLinks = navigationContent[language];
  const linkButtonStyle = `capitalize transition ease-in duration-300 font-euroRegular tracking-[1px] bg-blue text-white hover:bg-hoverBlue hover:textblue rounded-md ${
    headerIsSticky ? "px-4 py-2" : "px-6 py-3"
  }`;

  return (
    <nav
      className={`hidden lg:flex items-center ml-auto min-w-fit rounded-md  pr-smallContainer md:pr-mainContainer ${
        headerIsSticky ? "" : "pb-2"
      }`}
    >
      <ul className={`flex items-center gap-x-2`}>
        {headerLinks.map((link, index) => {
          return (
            <LinkNav
              buttonStyle={linkButtonStyle}
              headerIsSticky={headerIsSticky}
              key={index}
            >
              {link}
            </LinkNav>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
