import React from "react";
import { Link } from "react-router-dom";
// placholder
import primeBrokerage from "../../assests/images/services/Submenu/Prime-prokerage.jpeg";
import assestsManagement from "../../assests/images/services/Submenu/assest-management.jpg";
import custody from "../../assests/images/services/Submenu/custody-service.jpg";
import advisory from "../../assests/images/services/Submenu/advisory.jpg";

// trading platform images
import iresImage from "../../assests/images/services/Iress/IRESS-submenu.png";
import arkProImage from "../../assests/images/services/Ark-pro/ARK-PRO2.png";
import MT5Image from "../../assests/images/services/Ark-pro/ARK-PRO5.png";

const servicesContent = {
  en: [
    {
      id: 0,
      to: "/custody",
      tab: "Custody Services",
      content: {
        header: "Custody Services",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              At Ark Capital, we understand that our clients have unique needs
              when it comes to custody services. That's why we offer a diverse
              range of options to cater to a broad client base, including
              high-net-worth individuals, financial institutions, corporates,
              and investment firms.
            </p>
            <p className="block lg:hidden">
              At Ark Capital, we understand that our clients have unique needs
              when it comes to custody services.
            </p>
          </React.Fragment>
        ),
      },
      image: [custody],
    },
    {
      id: 1,
      tab: "Prime Brokerage",
      to: "/prime-brokerage",
      content: {
        header: "Prime Brokerage",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              Ark Capital provides a variety of prime brokerage services using
              integrated trading platforms. Our clients can access the market
              directly through multiple multi-asset trading platforms, allowing
              them to source liquidity seamlessly via direct GUI or API.
              Additionally, we offer customized reporting solutions and funding
              services in accordance with appropriate risk allocation.
            </p>
            <p className="block lg:hidden">
              Ark Capital provides a variety of prime brokerage services using
              integrated trading platforms.
            </p>
          </React.Fragment>
        ),
      },
      image: [primeBrokerage],
    },
    {
      id: 2,
      tab: " Platforms ",
      // to: "/services-platforms",
      content: {
        header: "Multi Trading Platforms",
        text: "Experience Successful Trading with ARK's Licensed Multi-Trading Platforms such as:",
        list: [
          <React.Fragment>
            <Link
              to={"/ARK-Pro"}
              className="text-white underline hover:opacity-[90%]"
            >
              ARK Pro
            </Link>{" "}
          </React.Fragment>,
          <React.Fragment>
            <Link
              to={"/Iress"}
              className="text-white underline hover:opacity-[90%]"
            >
              IRESS
            </Link>{" "}
          </React.Fragment>,
          <React.Fragment>
            <Link
              to={"/Meta-Trade-5"}
              className="text-white underline hover:opacity-[90%]"
            >
              Meta Trader 5
            </Link>{" "}
          </React.Fragment>,
        ],
      },
      image: [arkProImage, iresImage, MT5Image],
    },
    {
      id: 3,
      tab: "Asset Management",
      to: "/asset-management",
      content: {
        header: "Asset Management",
        text: "Our firm serves as a one-stop shop, providing a complete range of services, including complimentary external resources, to fulfill our clients’ needs and requirements",
      },
      image: [assestsManagement],
    },
    {
      id: 4,
      tab: "Advisory ",
      to: "/advisory",
      content: {
        header: "Advisor and Fund Manager Platform",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              Ark Capital Management offers a regulated ecosystem for managers
              and clients, allowing them to delegate investment decisions and
              client assets to an advisor or fund under our team’s supervision.
              This structure ensures that managers comply with client
              requirements and regulatory standards, which we monitor through
              trade and reporting oversight
            </p>
            <p className="block lg:hidden">
              Ark Capital Management offers a regulated ecosystem for managers
              and clients,
            </p>
          </React.Fragment>
        ),
      },
      image: [advisory],
    },
  ],
  ru: [
    {
      id: 0,
      to: "/custody",
      tab: "Услуги по хранению ",
      content: {
        header: "Услуги по хранению ",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              В Ark Capital мы понимаем, что у наших клиентов есть Уникальные
              Потребности, когда дело доходит до Услуг По Хранению. Вот Почему
              Мы Предлагаем Широкий Спектр Вариантов для Обслуживания Широкой
              Клиентской Базы, Включая Состоятельных Частных Лиц, Финансовые
              Учреждения, Корпорации И Инвестиционные Фирмы.
            </p>
            <p className="block lg:hidden">
              В Ark Capital мы понимаем, что у наших клиентов уникальные
              потребности, когда речь идет о кастодиальных услугах.
            </p>
          </React.Fragment>
        ),
      },
      image: [custody],
    },
    {
      id: 1,
      tab: "Первоклассный брокерский центр",
      to: "/prime-brokerage",
      content: {
        header: "Первоклассный брокерский центр",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              Ark Capital Предоставляет Широкий Спектр Первоклассных Брокерских
              Услуг с Использованием Интегрированных Торговых Платформ. Наши
              Клиенты Могут Получить Прямой Доступ К Рынку Через Несколько
              Торговых Платформ С Несколькими Активами, Что Позволяет Им
              Беспрепятственно Получать Ликвидность С Помощью Прямого
              Графического Интерфейса Или API. Кроме Того, Мы Предлагаем
              Индивидуальные Решения Для Отчетности И Финансовые Услуги В
              Соответствии С Соответствующим Распределением Рисков.
            </p>
            <p className="block lg:hidden">
              Ark Capital предоставляет разнообразные услуги прайм-брокереджа с
              использованием интегрированных торговых платформ.
            </p>
          </React.Fragment>
        ),
      },
      image: [primeBrokerage],
    },
    {
      id: 2,
      tab: "Платформы",
      // to: "/services-platforms",
      content: {
        header: "Несколько торговых платформ",
        text: "Испытайте успешную торговлю на лицензированных Мультитрейдинговых платформах ARK, таких как :",
        list: [
          <React.Fragment>
            <Link
              to={"/ARK-Pro"}
              className="text-white underline hover:opacity-[90%]"
            >
              ARK Pro
            </Link>{" "}
          </React.Fragment>,
          <React.Fragment>
            <Link
              to={"/Iress"}
              className="text-white underline hover:opacity-[90%]"
            >
              IRESS
            </Link>{" "}
          </React.Fragment>,
          <React.Fragment>
            <Link
              to={"/Meta-Trade-5"}
              className="text-white underline hover:opacity-[90%]"
            >
              Meta Trader 5
            </Link>{" "}
          </React.Fragment>,
        ],
      },
      image: [arkProImage, iresImage, MT5Image],
    },
    {
      id: 3,
      tab: "Управление активами",
      to: "/asset-management",
      content: {
        header: "Управление активами",
        text: "Наша фирма работает по принципу 'Одного Окна', предоставляя полный спектр услуг, включая бесплатные внешние ресурсы, для удовлетворения потребностей наших клиентов",
      },
      image: [assestsManagement],
    },
    {
      id: 4,
      tab: "Консультации ",
      to: "/advisory",
      content: {
        header: "Платформа для консультантов и Управляющих фондами",
        text: (
          <React.Fragment>
            <p className="hidden lg:block">
              Ark Capital Management предлагает регулируемую экосистему для
              менеджеров и клиентов, позволяющую им делегировать инвестиционные
              решения и активы клиентов, консультанту или фонду под руководством
              нашей команды. Такая структура гарантирует, что менеджеры
              соблюдают требования клиентов и нормативные стандарты, которые мы
              отслеживаем с помощью надзора за торговлей и отчетностью
            </p>
            <p className="block lg:hidden">
              Управление Ark Capital предлагает регулируемую среду для
              управляющих и клиентов,
            </p>
          </React.Fragment>
        ),
      },
      image: [advisory],
    },
  ],
};

const getServicesContent = (language) => {
  return servicesContent[language];
};

export default getServicesContent;
