import Container from "../../../components/Container/Container";

import QA from "./component/QA";
import FAQHeader from "./component/FAQHeader";

const FaQ = () => {
  return (
    <section className=" py-secondary lg:py-primary">
      <Container className="px-smallContainer lg:px-mainContainer">
        <div className="flex flex-col gap-y-secondary lg:gap-y-0 lg:flex-row  ">
          <FAQHeader />
          <QA />
        </div>
      </Container>
    </section>
  );
};

export default FaQ;
