import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";

// UI
import Container from "components/Container/Container";
// conent
import GetSuperVisionContent from "constant/About/Supervision/SupervissionContent";

const SuperviosonContent = () => {
  const { language } = useContext(LanguageContext);
  const content = GetSuperVisionContent(language);
  return (
    <section>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="flex flex-col lg:flex-row gap-small lg:gap-secondary">
          {content.thee.map((text, index) => (
            <p key={index} className="text-lg">
              {text}
            </p>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default SuperviosonContent;
