import { createContext, useState } from "react";

export const IsVerfiedContext = createContext();

export const IsVerfiedProvider = ({ children }) => {
  const [isUserVerfied, setIsUserVerfied] = useState(false);

  return (
    <IsVerfiedContext.Provider value={{ isUserVerfied, setIsUserVerfied }}>
      {children}
    </IsVerfiedContext.Provider>
  );
};
