import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";

// UI
import Container from "components/Container/Container";
// content
import getPrimeBrokerageSolutionContent from "constant/services/PrimeBrokerage/PrimeBrokerageSolution";
// icons
import { CheckCircle } from "@phosphor-icons/react";

const PrimeBrokerageSolution = () => {
  const { language } = useContext(LanguageContext);
  const content = getPrimeBrokerageSolutionContent(language);
  return (
    <section className="mt-secondary lg:mt-primary py-secondary bg-font">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-white text-center flex flex-col gap-ssmall lg:w-3/4 lg:mx-auto">
          <h1 className="text-4xl">{content.header.title}</h1>
          <p className="text-lg">{content.header.subTitle}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-small mt-secondary">
          {content.list.map((content, index) => (
            <div
              className="border border-white py-6 px-3 md:py-10 md:px-5 text-center flex flex-col items-center rounded-md text-white shadow transition-all ease-in duration-300 hover:bg-white hover:text-font hover:border-font"
              key={index}
            >
              <span>
                <CheckCircle size={48} weight="light" />
              </span>
              <h6 className="text-xl my-ssmall">{content}</h6>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PrimeBrokerageSolution;
