const discoverMore = {
  en: [
    {
      title: "Expert Guidance: ",
      text: "Our experienced team of advisors provides valuable insights and guidance to help managers make informed investment decisions. We understand the complexities of the financial markets and work closely with managers to develop tailored strategies that align with their clients' objectives",
    },
    {
      title: "Regulatory Compliance:",
      text: "We place great emphasis on regulatory compliance, ensuring that both managers and clients adhere to industry regulations and standards. Our rigorous oversight and monitoring processes help mitigate risks and maintain transparency in all investment activities.",
    },

    {
      title: "Delegated Investment Decisions:",
      text: "Our platform allows managers to delegate investment decisions, providing them with the flexibility to focus on strategic planning and client relationships. By leveraging our expertise and resources, managers can confidently navigate the ever-changing market conditions.",
    },
    {
      title: "Client Asset Protection:",
      text: "We prioritize the security and protection of client assets. Through our regulated ecosystem, managers can entrust us with the safekeeping and management of their clients' assets, giving them peace of mind and enhancing client trust.",
    },
    {
      title: "Trade and Reporting Oversight: ",
      text: "We provide robust trade and reporting oversight to ensure accuracy, transparency, and accountability. Managers can rely on our sophisticated systems and processes to track and analyze investment performance, generate comprehensive reports, and effectively communicate with clients.",
    },

    {
      title: "Customized Solutions:",
      text: "We understand that each manager and their clients have unique investment needs. Our platform offers customizable solutions that cater to specific investment strategies, risk tolerance levels, and financial goals. We work closely with managers to tailor our services accordingly.",
    },
    {
      title: "Collaborative Partnership:",
      text: "At Ark Capital Management, we believe in fostering a strong partnership with our advisors and fund managers. We provide ongoing support, and resources to help them stay ahead in the industry and maximize their potential for success.",
    },
  ],
  ru: [
    {
      title: "Экспертное руководство:",
      text: "Наша опытная команда консультантов предоставляет ценную информацию и рекомендации, помогающие менеджерам принимать обоснованные инвестиционные решения. Мы понимаем сложности финансовых рынков и тесно сотрудничаем с менеджерами для разработки индивидуальных стратегий, соответствующих целям их клиентов",
    },
    {
      title: "Соблюдение регуляторных норм:",
      text: "Мы уделяем большое внимание соблюдению регуляторных норм, обеспечивая соблюдение их как управляющими, так и клиентами отраслевых норм и стандартов. Наши строгие процессы надзора и мониторинга помогают снизить риски и поддерживают прозрачность во всех инвестиционных операциях.",
    },

    {
      title: "Соответствие нормативным требованиям:",
      text: "Мы уделяем большое внимание соблюдению нормативных требований, гарантируя, что как менеджеры, так и клиенты придерживаются отраслевых норм и стандартов. Наши строгие процессы надзора и мониторинга помогают снизить риски и поддерживать прозрачность во всей инвестиционной деятельности",
    },
    {
      title: "Делегированные инвестиционные решения:",
      text: "Наша платформа позволяет менеджерам делегировать инвестиционные решения, предоставляя им гибкость, позволяющую сосредоточиться на стратегическом планировании и взаимоотношениях с клиентами. Используя наш опыт и ресурсы, менеджеры могут уверенно ориентироваться в постоянно меняющихся рыночных условиях.",
    },
    {
      title: "Защита активов клиентов: ",
      text: "Мы уделяем приоритетное внимание безопасности и защите активов клиентов. Благодаря нашей регулируемой экосистеме менеджеры могут доверить нам хранение активов своих клиентов и управление ими, что дает им душевное спокойствие и повышает доверие клиентов.",
    },

    {
      title: "Индивидуальные решения:",
      text: "Мы понимаем, что у каждого менеджера и их клиентов есть уникальные инвестиционные потребности. Наша платформа предлагает настраиваемые решения, которые соответствуют конкретным инвестиционным стратегиям, уровням толерантности к риску и финансовым целям. Мы тесно сотрудничаем с менеджерами, чтобы соответствующим образом адаптировать наши услуги.",
    },
    {
      title: "Совместное партнерство",
      text: "В Ark Capital Management мы верим в укрепление прочных партнерских отношений с нашими консультантами и управляющими фондами. Мы предоставляем постоянную поддержку и ресурсы, чтобы помочь им оставаться впереди в отрасли и максимально использовать свой потенциал для достижения успеха.",
    },
  ],
};

// Function to retrieve content based on the selected language
const getDiscoverMoreContent = (language) => {
  return discoverMore[language];
};

export default getDiscoverMoreContent;
