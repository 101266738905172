import ArkProFeatures from "./Components/ArkProFeatures";
import ArkProHero from "./Components/ArkProHero";
import ArkProPlatfom from "./Components/ArkProPlatfom";
import OpenAccount from "../UI/OpenAccount";

const ArkPro = () => {
  return (
    <main>
      <ArkProHero />
      <ArkProPlatfom />
      <ArkProFeatures />
      <OpenAccount />
    </main>
  );
};

export default ArkPro;
