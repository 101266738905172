import { useContext, useEffect, useState } from "react";
// context
import { UserInFoContext } from "../../../../context/UserContext";
// API request
import performAccountType from "../../../../utils/account and forms/AccountType";
// UI and style
import Spinner from "../../../../components/UI/Spinner";
import generalStyle from "../../../../Style";

const SelectTypeModal = () => {
  const {
    acountTypeModalIsActive,
    accountTypeModalHanlder,
    userData,
    logoutHandler,
  } = useContext(UserInFoContext);

  const [isNewAccount, setIsNewAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState(false);

  // handle the  account type
  const selectAccountTypeHandler = async (type) => {
    const state = {
      type: type,
      email: userData.email,
    };
    const token = userData.token;
    // const token = userData.token;

    // Check if any property in the state object has an empty value
    const isStateEmpty = Object.values(state).some((value) => !value);

    // Check if the token is empty
    const isTokenEmpty = !token;

    if (isStateEmpty || isTokenEmpty) {
      setRequestMessage("failed-to-send");

      return;
    }

    try {
      setIsLoading(true);
      setRequestMessage("");
      const data = await performAccountType(state, token);
      if (data.status_code === 201) {
        accountTypeModalHanlder(false);
        logoutHandler();
      }
    } catch (error) {
      setRequestMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  // to indetify if the user is new of he need to change is account
  useEffect(() => {
    if (userData.type === null) {
      setIsNewAccount(true);
    } else {
      setIsNewAccount(false);
    }
  }, [userData.type]);

  // to make enable the body from scrolling and scroll to top when the modal is open
  useEffect(() => {
    if (acountTypeModalIsActive) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0); // Scroll to the top of the page
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [acountTypeModalIsActive]);

  return (
    acountTypeModalIsActive && (
      <section className="py-small">
        <div
          onClick={accountTypeModalHanlder}
          className="fixed blur-background bg-[#0000007a] top-0 left-0 w-[100vw] h-[100vh] z-[10000]"
        ></div>

        <div className="absolute bg-white rounded-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[auto] px-small py-secondary z-[10000] w-[90%] mx-auto md:w-auto">
          <div>
            <div className="text-center">
              <h2 className="text-xl text-font ">
                {`${
                  isNewAccount
                    ? "Please select your account type"
                    : "Change your account type to:"
                }`}
              </h2>
              {requestMessage === "failed" && (
                <p className="mt-2 text-red-500 text-sm">
                  Failed to select an account type. Please try again later.
                </p>
              )}
              {requestMessage === "failed-to-send" && (
                <p className="mt-2 text-red-500 text-sm">
                  Failed to send the request. Please try again later.
                </p>
              )}

              {isNewAccount && (
                <p className="mt-2 text-sm">
                  {`After selecting the account type, please note that for an effective process, you will be logged out and will need to log in again to continue`}
                </p>
              )}
            </div>

            <div className="flex items-center justify-center  gap-x-10 lg:gap-x-24 mt-secondary">
              {isNewAccount && (
                <>
                  <button
                    onClick={() => selectAccountTypeHandler("corporate")}
                    className={generalStyle.choiceModalButtonStyle}
                  >
                    {isLoading && <Spinner />}
                    Corporate
                  </button>
                  <button
                    onClick={() => selectAccountTypeHandler("individual")}
                    className={generalStyle.choiceModalButtonStyle}
                  >
                    {isLoading && <Spinner />}
                    Individual
                  </button>
                </>
              )}

              {!isNewAccount && userData.type !== "corporate" && (
                <button
                  onClick={() => selectAccountTypeHandler("corporate")}
                  className={generalStyle.choiceModalButtonStyle}
                >
                  {isLoading && <Spinner />}
                  Corporate
                </button>
              )}

              {!isNewAccount && userData.type !== "individual" && (
                <button
                  onClick={() => selectAccountTypeHandler("individual")}
                  className={generalStyle.choiceModalButtonStyle}
                >
                  {isLoading && <Spinner />}
                  Individual
                </button>
              )}
            </div>

            {!isNewAccount && (
              <div className="text-sm text-center text-red-400 mt-10">
                <p>
                  {`Changing the account type will log you out to ensure effective application. Please be aware that this action will result in data loss and process reset. Your current account type is "${userData.type}"`}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  );
};

export default SelectTypeModal;
