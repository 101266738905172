import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
import Container from "components/Container/Container";
// css
import "../Home.css";
import getContent from "constant/Home/Hero";

const Home = () => {
  const { language } = useContext(LanguageContext);
  const content = getContent(language);
  return (
    <section className="relative h-[90dvh] xxl:h-[800px] hero  ">
      <Container
        className={"min-h-full px-smallContainer lg:px-mainContainer h-full"}
      >
        <div className="pt-big relative z-[2] h-full">
          <div className="mb-14">
            <h1 className="text-blue uppercase text-[2.5rem] leading-[1.2] ss:text-5xl lg:text-[3.4rem] lg:leading-[1.2] tracking-wide mb-ssmall  font-[800] ">
              {content.title}
            </h1>
            <p className="text-white uppercase text-[1.5rem] ss:text-2xl lg:text-[1.6rem] lg:leading-[1.2] font-euroRegular tracking-wide">
              {content.subTitle}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Home;
