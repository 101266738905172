import { useState, useContext } from "react";
import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";
// icons
import { CaretDown, CaretUp } from "@phosphor-icons/react";
// content
import GetfooterDisclaimerLinks from "constant/Footer/FooterDisclamer";
// context
import { LanguageContext } from "context/LanguageContext.js";

const FooterDisclaimer = () => {
  const [toggle, setToggle] = useState(false);
  const { language } = useContext(LanguageContext);
  const disclamerContent = GetfooterDisclaimerLinks();
  const footerDisclaimerContent = disclamerContent[language];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleToggle = () => {
    setToggle((cur) => !cur);
  };

  return (
    <div className="pt-14 lg:pt-0">
      <Container className={"px-smallContainer lg:hidden"}>
        <button
          onClick={handleToggle}
          className={`w-full flex items-center justify-between border-t ${
            toggle ? "" : "border-b"
          } border-lightWhite py-3 text-lightWhite`}
        >
          <div className="text-center text-lightWhite text-xl">
            {language === "en" ? "Ark Policy" : "Политика Ark"}
          </div>

          {!toggle && <CaretDown size={28} />}
          {toggle && <CaretUp size={28} />}
        </button>
      </Container>

      {toggle && (
        <Container className={"px-smallContainer lg:px-mainContainer"}>
          <div className=" text-lightWhite flex flex-col gap-y-4 text-md py-7 border-b border-[#808080]">
            {footerDisclaimerContent.map((link, index) => (
              <Link
                onClick={scrollToTop}
                className="border-b last:border-b-0 border-lightWhite pb-2 "
                key={index}
                to={link.to}
              >
                {link.link}
              </Link>
            ))}
          </div>
        </Container>
      )}

      <Container className={" lg:px-mainContainer hidden lg:block"}>
        <div className=" text-lightWhite flex items-center justify-center flex-wrap gap-y-4 text-md py-7 border-b  border-[#808080]">
          {footerDisclaimerContent.map((link, index) => (
            <Link
              onClick={scrollToTop}
              className="border-r border-[#808080] px-6 last:border-r-0"
              key={index}
              to={link.to}
            >
              {link.link}
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FooterDisclaimer;
