import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";

import MobileSubMenu from "./MobileSubMenu";
import MobileSubServices from "./MobileSubServices";

const MobileLinkNav = ({ children, onMobileHanlder }) => {
  const [isContentVisible, setContentVisible] = useState(false);

  const handleTabsClick = () => {
    setContentVisible((cur) => !cur);
  };

  return children.to ? (
    <Link
      onClick={onMobileHanlder}
      className="text-xl text-white border-b pb-3 border-white"
      to={children.to}
    >
      {children.page}
    </Link>
  ) : (
    <div className="text-xl text-white border-b pb-2 border-white flex flex-col iems-center gap-x-3 overflow-hidden">
      <span onClick={handleTabsClick} className="flex items-center gap-x-2 ">
        {children.page} <CaretRight size={22} weight="bold" />
      </span>

      {children.hasSubmenu && (
        <MobileSubMenu
          content={children}
          active={isContentVisible}
          onTabsHandler={handleTabsClick}
          onMobileHanlder={onMobileHanlder}
        />
      )}

      {children.isService && (
        <MobileSubServices
          active={isContentVisible}
          onTabsHandler={handleTabsClick}
          onMobileHanlder={onMobileHanlder}
        />
      )}
    </div>
  );
};

export default MobileLinkNav;
