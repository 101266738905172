import { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Spinner from "../../../components/UI/Spinner";
// hooks
import useInput from "../../../form/Components/Hooks/user-input";
// number input
import "react-phone-number-input/style.css";
// inputs
import Input from "../../../form/Components/Inputs/Input";
// Message
import inputErrorMessage from "../../../form/Components/Messages/inputErrorMessage";

import emailjs, { EmailJSResponseStatus }  from '@emailjs/browser';

const Form = () => {
  const { language } = useContext(LanguageContext);
  const [formStatus, setFormStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [deleteAccountChecked, setDeleteAccountChecked] = useState(false);
  const [understandChecked, setUnderstandChecked] = useState(false);
  

  const inputStyle =
  "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue";
  
  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    isTouched: firstNameIsTouched,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHanlder,
    reset: firstNameReset,
  } = useInput((value) => value.trim("") !== "");
  
  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const clearInputsHandler = () => {
    firstNameReset();
    emailReset();
  };
  const [submitClicked, setSubmitClicked] = useState(false);
  const clearErrorHandler = () => {
    setSubmitClicked(false);
  };
  const submitFormHandler = async (e) => {
    const formStatus = {
      name: firstNameInput.toString(),
      email: emailInput.toString(),
    };

    let formIsValid =
      firstNameIsValid && emailIsValid && consentChecked && deleteAccountChecked && understandChecked;

    if (!formIsValid) {
      setFormStatus("empty");
      return;
    }

    try {
      setIsLoading(true);
      await emailjs.send(
        'service_o6e2zzq',
        'template_zw9w2hj',
        formStatus,
        {
          publicKey: '4MBzlMDHKkmlVbhHa',
        },
      );
      
      setFormStatus("success");
      clearInputsHandler();

    } catch (err) {
      if (err instanceof EmailJSResponseStatus) {
        console.log('EMAILJS FAILED...', err);
        return;
      }
      console.log(err);
      setFormStatus("failed");
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className="flex-2 md:pr-14 flex flex-col gap-4">
      <h1 className="text-font mb-10 text-xl mr-2">
        {`${
          language === "en"
            ? "Please fulfil the form below."
            : "Пожалуйста, заполните форму ниже "
        }`}
      </h1>

      {formStatus === "empty" && (
        <p className="text-sm text-red-500 mb-2">
          Kindly ensure that all form inputs are properly filled, and all the check boxes are checked.
          Provided information appears to be invalid.
        </p>
      )}
      {formStatus === "failed" && (
        <p className="text-sm text-red-500 mb-2">Failed to Submit</p>
      )}
      {formStatus === "success" && (
        <p className="text-sm text-green-500 mb-2">
          Your form submission was successful. We will be in touch with you
          soon.
        </p>
      )}
      <form className=" flex flex-col gap-4 ">
        <div className="flex flex-col  items-center gap-4">
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"text"}
            id={"firstName"}
            value={firstNameInput}
            placeholder={`${language === "en" ? "Your name " : "Имя"}`}
            onChange={(event) => {
              firstNameChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={firstNameBlurHanlder}
            hasError={
              firstNameHasError || (!firstNameIsTouched && submitClicked)
            }
            errorMessage={inputErrorMessage.text}
          />

          
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"email"}
            id={"email"}
            value={emailInput}
            placeholder={`${
              language === "en" ? "Email address" : "Адрес электронной почты"
            }`}
            onChange={(event) => {
              emailChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={emailBlurHanlder}
            hasError={emailHasError || (!emailIsTouched && submitClicked)}
            errorMessage={inputErrorMessage.email}
          />
          <div className="flex flex-col gap-4">
          <label>
            <input className="mr-2" type="checkbox" checked={consentChecked} onChange={(e)=>{setConsentChecked(e.target.checked); }}/>
            I hereby consent to the processing of my personal data
          </label>
          <label>
            <input className="mr-2" type="checkbox" checked={deleteAccountChecked} onChange={(e)=>{setDeleteAccountChecked(e.target.checked);}}/>
            I want to delete my account and data
          </label>
          <label>
            <input className="mr-2" type="checkbox" checked={understandChecked} onChange={(e)=>{setUnderstandChecked(e.target.checked);}}/>
            I understand my account can't be recovered
          </label>
          </div>
        </div>

 
        <button
          type="button"
          onClick={submitFormHandler}
          className="px-6 py-2 rounded bg-blue text-white w-max ml-auto flex items-center gap-x-2"
        >
          {isLoading && <Spinner />}
          Send Request
        </button>
      </form>
    </div>
  );
};

export default Form;
