import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
import Container from "../../../../components/Container/Container";
import getTeamFeatureContent from "constant/About/Team/Feature";

const TeamFeature = () => {
  const { language } = useContext(LanguageContext);
  const content = getTeamFeatureContent(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <p className="text-2xl lg:text-3xl mb-secondary">{content.text}</p>
      </Container>
    </section>
  );
};

export default TeamFeature;
