// import { styles } from "../../../../Style.js";

import { ArrowBendDownRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
const NewsBox = ({ image, title, text, id }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Link
      onClick={scrollToTop}
      to={`/blog/${id}`}
      className="flex flex-col gap-small"
    >
      <div className="flex-2 group/bg overflow-hidden relative cursor-pointer">
        <div className="absolute left-0 right-0 w-full h-full  bg-[#0b71ba86]  transition-scale ease-in duration-300 opacity-0 group-hover/bg:opacity-100 rounded-md"></div>
        <span className="absolute right-[10px] text-white bottom-[5px] text-sm transition ease-in duration-300 opacity-0 -translate-x-[20px] group-hover/bg:opacity-100  group-hover/bg:translate-x-[0]">
          <ArrowBendDownRight size={32} weight="fill" />
        </span>
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover rounded-md transition-scale ease-in duration-300 hover:scale-110"
        />
      </div>
      <div className="flex-1 flex flex-col gap-ssmall">
        <h4
          className={`text-xl text-white transition ease-in duration-300 hover:text-blue`}
        >
          {title}
        </h4>
        <p className="text-lightWhite">{text}</p>
      </div>
    </Link>
  );
};

export default NewsBox;
