/**
 * CustomForm Component
 *
 * This component represents a custom form with multiple pages that allows users
 * to input their information and submit it to SalesForce for further processing.
 *
 * Props:
 * - formInputs: An array of objects representing the form inputs and their configurations.
 * - onSubmit: A callback function to handle form submission.
 * - CIF: A boolean flag to determine if the form is for CIF (Customer Information Form).
 * - FATCA: A boolean flag to determine if the form is for FATCA (Foreign Account Tax Compliance Act).
 *
 * Usage Example:
 * <CustomForm formInputs={formConfig} onSubmit={handleFormSubmit} CIF={true} FATCA={false} />
 */
import { useState, useContext, useEffect } from "react";
import countryList from "./Constant/CountryList.js";
import nationalityList from "./Constant/Nationality.js";
import inputStyle from "./InputsStyle.js";

// form components
import CustomInput from "./Components/CustomInput";
import CustomSelect from "./Components/CustomSelect";
import FormButton from "./Components/FormButton.jsx";
import Content from "./Components/Content.jsx";
// UI componnent
import Spinner from "../../../../components/UI/Spinner.jsx";
// context
import { UserInFoContext } from "../../../../context/UserContext.js";
import { SalesForceContext } from "../../../../context/SalesForceContext.js";

// Utiles
import performSaveForm from "../../../../utils/Registartion/SaveForm.js";
import performAuth from "../../../../utils/salesforce/Authantication.js";
import performSaveData from "../../../../utils/salesforce/SaveData.js";
import performSetFormStatus from "../../../../utils/account and forms/SetFormStatus.js";
// icon
import { Star, CheckCircle } from "@phosphor-icons/react";

const CustomForm = ({ formInputs, onSubmit, CIF, FATCA }) => {
  // State hooks for managing form data and UI states
  // State to handle inputs values
  const [formState, setFormState] = useState({});
  // state to handle if user have already data
  const [userFormData, setUserFormData] = useState({});
  // state to take save transformation of fominputs into A json
  const [formData, setFormData] = useState([]); // is the JSON that we generate based on formInput props
  const [saveFormData, setSaveFormData] = useState(); // is the JSON we generate bases on mergin formSTate and formData
  // state to handle pagination between pages
  const [currentPage, setCurrentPage] = useState(0);
  // state to handle inputs error
  const [isError, setIsError] = useState(false);
  // state to handle mega inpuyts error
  const [isMegaError, setIsMegaError] = useState(false);
  // statet to handle SAVE form SalesForce sava data
  const [formIs, setFormIs] = useState("");
  // state to handle saveFormData when it's fetched
  const [saveDataResponse, setSaveDataResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // UI state
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitIsLoding, setFormSubmitIsLoading] = useState(false);
  // state to handle spinner loding
  const [saveHasError, setSaveHasError] = useState(false);
  // state to handle save fetching error
  const [saveIsSuccess, setSaveIsSuccess] = useState(false);

  // Context hooks for accessing user data and SalesForce context
  const { userData, accountTypeModalHanlder, setCifIdHanlder } =
    useContext(UserInFoContext);
  const token = userData?.token;
  const userType = userData?.type;
  const userHasData = userData?.hasData;
  const CIFId = userData?.cifid;

  const { accessToken, setAccessToken, salesfoceEndPoint } =
    useContext(SalesForceContext);

  const pageTitle = formInputs[currentPage]?.inputs[0]?.pageTitle;
  const pageContent = formInputs[currentPage]?.inputs?.find(
    (input) => input.content
  );

  const currentPageInputs = pageContent
    ? formInputs[currentPage]?.inputs.slice(2)
    : formInputs[currentPage]?.inputs.slice(1);

  // function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /**
   * 
   1- check if user hava data to render them
   */
  // 1:1 useEffect to check if the user has data so he need to continue his process
  useEffect(() => {
    if (userHasData === 1) {
      const parsedData = JSON.parse(userData?.formData?.data || null);
      setUserFormData(parsedData);
    }
  }, [userHasData, userData.formData]);

  // 1:2 useEffect to render user Data in their inputs
  useEffect(() => {
    if (userFormData) {
      setFormState(userFormData);
    }
  }, [userFormData]);

  /**
   * 
   2- Generate a JSON taken from formInputs to use it when we need to send the JSON for salesforce
   */

  // use Effect to transfer the formInput into a json this JSON will be use it to make merge with formState and generate final JSON to send to salesforce
  useEffect(() => {
    const mappedInputs = formInputs.flatMap((input) =>
      input.inputs.reduce((acc, innerInput) => {
        if (innerInput.mega) {
          const { megaName, megaInputs } = innerInput;
          const megaInputsNames = megaInputs.map((megaInput) => megaInput.name);
          acc.push({
            [megaName]: megaInputsNames.reduce((megaAcc, name) => {
              megaAcc[name] = "";
              return megaAcc;
            }, {}),
          });
        } else if (!innerInput.pageTitle && !innerInput.content) {
          acc.push({ [innerInput.name]: "" });
        }
        return acc;
      }, [])
    );

    // Convert mappedInputs to JSON
    setFormData(mappedInputs);
  }, [formInputs]);

  /**
   * Generates a new state object by merging data from formData and formState.
   *
   * @param {Array} formData
   * - An array of objects representing the full structure of form inputs.
   *  Each object contains a single form input with its name as the key.
   * @param {Object} formState
   * - An object representing the user-inputted form data.
   *  It contains the values of form inputs as key-value pairs.
   * @returns {Object}
   * - A new object containing the merged data from formData and formState.
   *  The returned object has the same structure as formData, with the corresponding
   *  values taken from formState. Empty inputs from formState are included, and the
   *  nested objects are fully copied from formData.
   */
  const generateNewState = (formData, formState) => {
    const mergedData = {};
    /**
     * Recursively merges nested objects.
     *
     * @param {Object} target - The target object to merge the data into.
     * @param {Object} source - The source object containing the data to be merged.
     */
    const mergeObjects = (target, source) => {
      Object.keys(source).forEach((key) => {
        if (source[key] && typeof source[key] === "object") {
          target[key] = target[key] || {};
          // Recursively merge nested objects
          mergeObjects(target[key], source[key]);
        } else if (source[key] !== "" || !target.hasOwnProperty(key)) {
          // Copy the value if it's not empty or if the target doesn't have the key
          target[key] = source[key];
        }
      });
    };

    // Merge data from formData into mergedData (copy the full structure)
    formData.forEach((item) => {
      const inputName = Object.keys(item)[0];
      if (!mergedData.hasOwnProperty(inputName)) {
        mergedData[inputName] = item[inputName];
      }
    });

    // Merge data from formState into mergedData
    mergeObjects(mergedData, formState);

    // Add empty keys to mergedData if they exist in formState but not in formData
    const formStateKeys = Object.keys(formState);
    formStateKeys.forEach((key) => {
      if (!mergedData.hasOwnProperty(key)) {
        mergedData[key] = formState[key];
      }
    });

    // const jsonData = JSON.stringify(mergedData);
    setSaveFormData(mergedData);
  };

  /**
   * 
   3- handling inputs changes and save, and pages navigation with inputs validationl
   */
  // clear handler to clear all error messgaes
  const clearErrorHandler = () => {
    setIsError(false);
    setIsMegaError(false);
    setSaveHasError(false);
    setSaveIsSuccess(false);
    setSaveDataResponse(false);
    setErrorMessage("");
    setSaveIsSuccess(false);
  };

  // handler to track input value and save them into a state
  const changeHandler = (e, mega, subInputs) => {
    const { name, value } = e.target;

    if (mega !== undefined) {
      setFormState((prevState) => ({
        ...prevState,
        [mega]: {
          ...prevState[mega],
          [name]: value,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    clearErrorHandler();
  };
  // reusable functions to validate inputs and make sure there is no require filed not valid
  const validateInputs = () => {
    let isValid = true;

    currentPageInputs.forEach((input) => {
      const isMegaInput = input.mega;

      if (isMegaInput) {
        const megaInputs = input.megaInputs;
        const megaMutipleInputs = input.multiple;

        if (megaMutipleInputs) {
          const megaRequire = input.megaRequire;

          let hasValidInput = false; // Flag to check if at least one input is valid

          input.megaInputs.forEach((innerInput) => {
            if (megaRequire && formState[input.megaName]?.[innerInput.name]) {
              // At least one valid input found
              hasValidInput = true;
            }
          });

          if (!hasValidInput) {
            input.megaError = true;
            isValid = false;
            setIsMegaError(true);
          } else {
            input.megaError = false;
          }
        }

        megaInputs.forEach((innerInput) => {
          const requireInput = innerInput.require;

          if (requireInput && !formState[input.megaName]?.[innerInput.name]) {
            innerInput.error = true;
            isValid = false;
            setIsError(true);
          } else {
            innerInput.error = false;
          }
        });

        if (!isValid) {
          return;
        }
      } else if (input.require && !formState[input.name]) {
        input.error = true;
        setIsError(true);
        isValid = false;
      } else {
        input.error = false;
      }
    });

    return isValid;
  };
  // handlder to navigate prev page
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    scrollToTop();
  };
  // handlder to navigate next page
  const handleNextPage = () => {
    const isValid = validateInputs();
    if (isValid) {
      setCurrentPage((prevPage) => prevPage + 1);
    }

    scrollToTop();
  };
  // to check if the form page is empty
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  // save handler if user need to save it process and get back later to contiue
  const handleSave = async () => {
    if (isEmptyObject(formState)) {
      setSaveHasError("Empty");
      return;
    }
    try {
      setIsLoading(true);
      setSaveHasError(false);
      const save = await performSaveForm(formState, token);
      if (save.status_code === 200) {
        setSaveIsSuccess(true);
      } else {
        setSaveHasError("failed");
      }
    } catch (error) {
      setSaveHasError("failed");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 4- handling submit forms with salesforce
   */

  // 4:1 handler submit: first it check if inputs is validate, then based on form Type it make the first API call with salesforce to create the contection using auth API
  const handleSubmit = async () => {
    const isValid = validateInputs();

    if (isValid && CIF) {
      performAuthHandler("CIF");
    } else if (isValid && FATCA) {
      performAuthHandler("FATCA");
    }
  };

  /**
   * 4:2 Perform Authentication Handler
   * This function is used to perform authentication with SalesForce API.
   *
   * @param {string} formIs - The form type to be used for authentication (CIF or FATCA).
   */
  const performAuthHandler = async (formIs) => {
    try {
      const authData = await performAuth();

      // if performAuth succefully return, we set access token, and we set formIS to specific form, and we generate the saveFormData to send it via savedata api
      if (!authData.error) {
        setAccessToken(authData.data.access_token);
        setFormIs(formIs);
        generateNewState(formData, formState);
      } else {
        setSaveDataResponse("authentication-error");
      }
    } catch (error) {
      console.error(
        "An error occurred while performing authentication:",
        error
      );

      setSaveDataResponse("authentication-faield");
    }
  };

  // 4:3 use effect to perform save handler when the autth return the access-token
  useEffect(() => {
    if (accessToken !== null && formIs === "CIF") {
      const newJSON = {
        CIF: {
          SalesforceId: "",
          ...saveFormData,
        },
      };
      setFormSubmitIsLoading(true);
      const jsonString = JSON.stringify(newJSON);
      perfromSaveHandler(
        userType === "individual"
          ? salesfoceEndPoint.CIF_individual
          : salesfoceEndPoint.CIF_corporate,
        accessToken,
        jsonString
      );
    } else if (accessToken !== null && formIs === "FATCA") {
      const newJSON = {
        Fatca: {
          CIFId: CIFId,
          FatcaId: "",
          ...saveFormData,
        },
      };
      setFormSubmitIsLoading(true);

      const jsonString = JSON.stringify(newJSON);
      perfromSaveHandler(
        userType === "individual"
          ? salesfoceEndPoint.FATCA_individual
          : salesfoceEndPoint.FATCA_corporate,
        accessToken,
        jsonString
      );
    }
  }, [formIs, accessToken, saveFormData]);

  /**
   * 4:4 Perform Save Handler
   * This function is used to save the form data to SalesForce after authentication.
   * this function is called from the useEffect 4:3 once the AUTH API return Success, this FUnction is repsonsible to call save data API with salesforce, if the data is succesfully saved we need to move to next form, and if CIF we need to call setCIF API to set CIF id to use it in other calles
   *
   * @param {string} url - The SalesForce API endpoint for saving data.
   * @param {string} token - The access token for authentication.
   * @param {object} json - The JSON data representing the form inputs.
   */
  const perfromSaveHandler = async (url, salesforcetoken, json) => {
    try {
      setFormSubmitIsLoading(true);
      const saveData = await performSaveData(url, salesforcetoken, json);

      if (saveData.Status === "Success") {
        if (formIs === "CIF") {
          setCifIdHanlder(token, saveData.CIFId);
        }
        performGeneratePDF();
        performMovingFormsHandler();
      } else {
        setErrorMessage(`Failed to Save: ${saveData.Message}`);
      }
    } catch (error) {
      setSaveDataResponse("save-data-failed");
      console.error("An error occurred while performing Save data:", error);
    } finally {
      setFormSubmitIsLoading(false);
    }
  };

  const performMovingFormsHandler = async () => {
    let formStatus;
    if (formIs === "CIF") {
      formStatus = {
        cif: 1,
        fatca: 0,
        document: 0,
      };
    } else if (formIs === "FATCA") {
      formStatus = {
        cif: 1,
        fatca: 1,
        document: 0,
      };
    }

    try {
      const data = await performSetFormStatus(token, formStatus);

      if (data.status_code === 200) {
        onSubmit(formIs);
        performSaveForm({}, token);

        console.log("set form sucess");
      } else {
        console.log("failed to perform set form");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const performGeneratePDF = async () => {
    const formData = new FormData();
    formData.append("json", JSON.stringify(formState));
    formData.append("formCategory", userType);
    formData.append("formType", formIs);

    try {
      const response = await fetch(
        `https://email-api.ark-cap.com/api/generatePDF`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Generating PDF success");
      } else {
        const errorData = await response.json();
        console.log("Failed to perform generate PDF");
      }
    } catch (error) {
      console.error("Error while generating PDF", error);
    }
  };

  return (
    <div className="">
      <h1 className="text-center text-2xl  text-font mb-2 underline">
        {` ${FATCA ? "FATCA" : "CIF"} Form`}
      </h1>
      {/* when we move to FATCA we make chanage type disaper */}
      {!FATCA && (
        <h1 className="text-center text-2xl  text-font mb-6">
          {` Your account type is ${userType}`} {""}
          <button
            onClick={accountTypeModalHanlder}
            className="text-blue underline"
          >{`change to ${
            userType === "individual" ? "corporate" : "individual "
          }`}</button>
        </h1>
      )}

      {/* form */}
      <div className="p-2 bg-blue text-white text-lg ">
        <h1 className="">{pageTitle}</h1>
      </div>
      <form>
        <div className="border border-gray-400">
          {/* content */}
          {pageContent && <Content content={pageContent.content} />}

          {/* inpus */}
          {currentPageInputs?.map((input, innerIndex) => {
            const isMegaInput = input.mega;

            if (isMegaInput) {
              const megaInputs = input.megaInputs;
              const megaSmallDesign = megaInputs.length < 3;
              const megaSpecialDesign = input.specialDesign;
              const megaBigDesign = input.bigDesign;
              const isFourColumns = input.fourColumns;
              const isOneGrid = input.oneGrid;
              const regularDesign =
                !megaBigDesign &&
                !megaSmallDesign &&
                !megaSpecialDesign &&
                !isFourColumns &&
                !isOneGrid;

              return (
                <div
                  key={innerIndex}
                  className={`flex flex-col gap-10  py-6 border-b border-gray-400`}
                >
                  {/* mega label */}
                  <span className={`px-3`}>
                    <h2
                      className={`text-lg font-semibold flex items-center gap-x-2`}
                    >
                      {input.magaTitle}

                      {input.megaRequire && (
                        <span className="text-blue flex items-center gap-x-1">
                          <Star size={14} weight="fill" />
                        </span>
                      )}
                    </h2>

                    {input.megaRequire && (
                      <p className="mt-1 text-sm text-blue">
                        At least one input is Require
                      </p>
                    )}

                    {input.megaMessage && (
                      <p className="mt-1 text-sm text-blue">
                        If you have selected "yes," please proceed with filling
                        out the other fields.
                      </p>
                    )}

                    {isMegaError && input.megaError && (
                      <p className="mt-1 text-sm text-red-400">
                        This Filed is not Valid
                      </p>
                    )}
                  </span>
                  {/* mega input */}
                  <div
                    className={`grid gap-x-3 px-3
                    ${
                      regularDesign &&
                      "grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-y-8 "
                    }
                    ${
                      megaSpecialDesign &&
                      "grid-cols-1 lg:grid-cols-2 lg:w-3/4 lg:ml-auto gap-y-4 lg:gap-y-8 "
                    }
                    ${
                      megaBigDesign &&
                      "grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-8"
                    }
                    ${megaSmallDesign && "gap-y-4 "}
                    ${isFourColumns && "grid-cols-4 gap-y-4 lg:gap-y-8"}
                    ${isOneGrid && "grid-cols-1 gap-y-4 lg:gap-y-8"}
                    `}
                  >
                    {megaInputs.map((innerInput, index) => {
                      const isSelectInputType = innerInput.type === "select";

                      if (isSelectInputType) {
                        const isCountryType = innerInput.country;
                        const isNationalityType = innerInput.nationality;
                        // const options = isCountryType ? countryList : input.options;
                        let options;
                        if (isCountryType) {
                          options = countryList;
                        } else if (isNationalityType) {
                          options = nationalityList;
                        } else {
                          options = innerInput.options;
                        }

                        return (
                          <CustomSelect
                            key={index}
                            megaBigDesign={true}
                            isFourColumns={isFourColumns}
                            label={innerInput.label}
                            require={innerInput.require}
                            name={innerInput.name}
                            value={
                              formState[input.megaName]?.[innerInput.name] || ""
                            }
                            onChange={(e) => changeHandler(e, input.megaName)}
                            isError={isError}
                            error={innerInput.error}
                            placeholder={innerInput.placeholder}
                            options={options}
                            boxStyle={inputStyle.megaBoxStyle}
                            labelStyle={inputStyle.megaLabelStyle}
                            inputStyle={`${inputStyle.megaInputStyle}`}
                          />
                        );
                      } else {
                        return (
                          <CustomInput
                            key={index}
                            isFourColumns={isFourColumns}
                            label={innerInput.label}
                            require={innerInput.require}
                            id={innerInput.name}
                            name={innerInput.name}
                            placeholder={innerInput.placeholder}
                            value={
                              formState[input.megaName]?.[innerInput.name] || ""
                            }
                            type={innerInput.type}
                            onChange={(e) => changeHandler(e, input.megaName)}
                            isError={isError}
                            error={innerInput.error}
                            boxStyle={inputStyle.megaBoxStyle}
                            labelStyle={inputStyle.megaLabelStyle}
                            inputStyle={inputStyle.megaInputStyle}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              );
              // regular inputs
            } else {
              const isSelectInputType = input.type === "select";

              if (isSelectInputType) {
                const isCountryType = input.country;
                const isNationalityType = input.nationality;
                // const options = isCountryType ? countryList : input.options;
                let options;
                if (isCountryType) {
                  options = countryList;
                } else if (isNationalityType) {
                  options = nationalityList;
                } else {
                  options = input.options;
                }

                return (
                  <CustomSelect
                    key={innerIndex}
                    isNotMega={true}
                    hide={input.hide}
                    label={input.label}
                    name={input.name}
                    value={formState[input.name] || ""}
                    onChange={changeHandler}
                    isError={isError}
                    error={input.error}
                    require={input.require}
                    placeholder={input.placeholder}
                    options={options}
                    boxStyle={inputStyle.boxStyle}
                    labelStyle={inputStyle.labelStyle}
                    inputStyle={inputStyle.inputStyle}
                  />
                );
              } else {
                return (
                  <CustomInput
                    hide={input.hide}
                    placeholder={input.placeholder}
                    key={innerIndex}
                    isNotMega={true}
                    require={input.require}
                    label={input.label}
                    id={input.name}
                    name={input.name}
                    value={formState[input.name] || ""}
                    type={input.type}
                    onChange={changeHandler}
                    isError={isError}
                    error={input.error}
                    boxStyle={inputStyle.boxStyle}
                    labelStyle={inputStyle.labelStyle}
                    inputStyle={inputStyle.inputStyle}
                  />
                );
              }
            }
          })}
        </div>

        {/* buttons */}
        <div className="mt-6 flex flex-wrap items-center gap-3 justify-end">
          <div className="flex items-center gap-x-2">
            {saveHasError === "Empty" && (
              <p className="text-sm text-red-500">The Form Is Empty</p>
            )}
            {saveHasError === "failed" && (
              <p className="text-sm text-red-500">
                Save failed. Please check your network connection and try again.
              </p>
            )}

            <button
              type="button"
              className={`px-6 py-1.5 rounded-md border border-gray-600 flex items-center gap-x-2 ${
                saveIsSuccess && "bg-green-500 text-white"
              }`}
              onClick={handleSave}
            >
              {isLoading && <Spinner />}
              {saveIsSuccess && <CheckCircle size={22} />}
              Save and Continue
            </button>
          </div>
          {currentPage > 0 && (
            <FormButton onClick={handlePreviousPage}>Previous</FormButton>
          )}
          {currentPage < formInputs?.length - 1 ? (
            <FormButton error={isError} onClick={handleNextPage}>
              Next
            </FormButton>
          ) : (
            <div className="flex items-center gap-x-2">
              {saveDataResponse === "authentication-error" && (
                <p className="text-sm text-red-500">Faield To authenticate</p>
              )}
              {saveDataResponse === "authentication-faield" && (
                <p className="text-sm text-red-500">
                  An error occurred during performing authentication either due
                  to your internet connection or from our engine. Please try
                  again. If the error persists, please contact us at{" "}
                  <a href="mailto:cs@ark-cap.com" className="underline">
                    cs@ark-cap.com
                  </a>
                </p>
              )}
              {saveDataResponse === "save-data-failed" && (
                <p className="text-sm text-red-500">
                  An error occurred during saving data either due to your
                  internet connection or from our engine. Please try again. If
                  the error persists, please contact us at{" "}
                  <a href="mailto:cs@ark-cap.com" className="underline">
                    cs@ark-cap.com
                  </a>
                </p>
              )}
              {saveDataResponse === "save-data-error" && (
                <p className="text-sm text-red-500">
                  You have encountered an input validation error. Please review
                  your input data and ensure that it is valid. Make sure to
                  provide accurate information for fields marked with "yes" or
                  "no." If you select "yes," remember to fill in the associated
                  input fields accordingly
                </p>
              )}
              {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-blue text-white px-6 py-1.5 rounded-md flex items-center gap-x-2"
              >
                {formSubmitIsLoding && <Spinner />}
                Submit
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CustomForm;
