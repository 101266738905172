const quickLinksContent = [
  {
    link: "Home",
    to: "/",
  },
  {
    link: "Contact us",
    to: "/contact-us",
  },
  {
    link: "FAQ",
    to: "/faq",
  },
  {
    link: "Media",
    to: "/media",
  },
  {
    link: "Open account",
    to: "/register",
  },
];

export default quickLinksContent;
