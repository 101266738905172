import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Container from "components/Container/Container";
// content
import getAboutHeroContent from "constant/About/AboutHero";
const AboutHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getAboutHeroContent(language);
  return (
    <section className="py-primary lg:py-big about-hero  relative z-[-1]">
      <div className="absolute top-0 left-0 w-full h-full bg-[#ffffff99] z-[1]"></div>
      <Container className={"px-smallContainer lg:px-mainContainer h-full"}>
        <div className="flex flex-col relative z-[200] justify-center h-full">
          <h1 className="text-font text-4xl font-medium  ">
            {" "}
            {`${language === "en" ? "About us" : "О нас"}`}
          </h1>

          <div className="w-[3.2rem] h-[4px] bg-blue my-small"></div>

          <div className="flex flex-col lg:flex-row  gap-small lg:gap-secondary ">
            <p className="flex-1 font-euroSemibold text-lg text-font leading-relaxed">
              {content.text}
            </p>
            <p className="flex-1 font-euroSemibold text-lg text-font leading-relaxed ">
              {content.textTwo}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutHero;
