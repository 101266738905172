import { Star } from "@phosphor-icons/react";
const CustomInput = ({
  label,
  id,
  name,
  value,
  type,
  onChange,
  isError,
  error,
  placeholder,
  isFourColumns,
  require,
  hide,
  // style
  boxStyle,
  labelStyle,
  labelBox,
  inputStyle,
  isNotMega,
  megaBigDesign,
}) => {
  const inputClasses = `${inputStyle} ${value ? "bg-[#e8f5fe]" : ""}`;
  return (
    <div className={`${boxStyle} ${hide ? "hidden" : ""} `}>
      <label
        className={`${labelStyle} flex lg:items-center gap-x-2  ${
          isFourColumns && "lg:text-center lg:justify-center"
        } ${isNotMega && "lg:text-start"} ${
          !isFourColumns && !isNotMega && "lg:text-end lg:pr-2 lg:justify-end"
        } `}
      >
        {label}
        {require ? (
          <span className="text-blue flex items-center gap-x-1">
            <Star size={14} weight="fill" />
          </span>
        ) : (
          ""
        )}
      </label>

      <span className={"flex-1"}>
        <input
          className={`${inputClasses} ${
            isError && error ? "border-red-500" : ""
          }`}
          id={name}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
        />
        {isError && error && (
          <p className="text-sm text-red-500 mt-1">This field is required</p>
        )}
      </span>
    </div>
  );
};

export default CustomInput;
