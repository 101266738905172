import Container from "../../components/Container/Container";

import FooterServices from "./Components/FooterServices";
import FooterLogo from "./Components/FooterLogo";
import FooterPlatform from "./Components/FooterPlatform";
import FooterContact from "./Components/FooterContact";
import FooterBanner from "./Components/FooterBanner";
import FooterDisclaimer from "./Components/FooterDisclaimer";

const Footer = () => {
  return (
    <footer className="bg-newBlue">
      <FooterDisclaimer />
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <div className="footer-grid py-16 ">
          <FooterLogo />
          <FooterPlatform />
          <FooterServices />
          <FooterContact />
        </div>
      </Container>
      <FooterBanner />
    </footer>
  );
};

export default Footer;
