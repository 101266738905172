import BASE_API_URL from "../../BaseUrl";

const GetFaqs = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}/faqs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const data = await response.json();
      return data;
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error("");
  }
};

export default GetFaqs;
