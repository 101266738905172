import { createContext, useState } from "react";

export const FormStateContext = createContext();

export const FormStateProvider = ({ children }) => {
  const [formStateContext, setFormStateContext] = useState();

  const [dataReturned, setDataReturned] = useState({});

  return (
    <FormStateContext.Provider
      value={{
        formStateContext,
        setFormStateContext,
        dataReturned,
        setDataReturned,
      }}
    >
      {children}
    </FormStateContext.Provider>
  );
};
