const whatAreCookies = {
  en: [
    {
      title: "What are cookies?",
      content: [
        "To provide you with a good experience we sometimes place small files known as cookies on your computer. A cookie is a text file made up of letters and numbers that allows us to distinguish your browser/device from another visitor's browser/device.",
        "Cookies set across our Website and App, whether placed by our third-party partners or us, can be in the form of session or persistent cookies, and may use different technologies, such as JavaScript or Flash.",
      ],
    },
  ],
  ru: [
    {
      title: "Что такое файлы cookie?",
      content: [
        "Для обеспечения вам хорошего опыта мы иногда помещаем на вашем компьютере небольшие файлы, известные как файлы cookie. Файл cookie представляет собой текстовый файл, состоящий из букв и цифр, который позволяет нам различать ваш браузер/устройство от браузера/устройства другого посетителя.",
        "Файлы cookie, устанавливаемые на нашем веб-сайте и в приложении, будь то размещенные нашими партнерами из числа третьих сторон или нами сами, могут быть в форме сеансовых или постоянных файлов cookie и могут использовать различные технологии, такие как JavaScript или Flash.",
      ],
    },
  ],
};
const getwhatAreCookies = (language) => {
  return whatAreCookies[language];
};
export { getwhatAreCookies };

//
const useCookieFor = {
  en: [
    {
      title: "What do we use cookies for?",
      content: [
        "Currently across our Website and App, we make use of cookies to help manage your visitor experience.",
        "This means that, in order to use our Website and App, we require your consent to our cookie use, through which we may analyze your non-personal visitor activity outlines and manage your personal preferences. Where any of your information collected through cookies qualifies as Personal Data, our Data Protection Policy will apply.",
        "If you would like to 'opt out' of the cookies set by our Website and App, you can do this on a cookie-by-cookie basis subject to browser settings. You may limit site operation or functions if you limit the cookies.",
      ],
    },
  ],
  ru: [
    {
      title: "Зачем мы используем файлы cookie?",
      content: [
        "В настоящее время на нашем веб-сайте и в приложении мы используем файлы cookie для управления вашим опытом посетителя.",
        "Это означает, что для использования нашего веб-сайта и приложения нам необходимо ваше согласие на использование файлов cookie, с помощью которых мы можем анализировать ваши неперсональные активности посетителей и управлять вашими личными предпочтениями. Если какая-либо из ваших данных, собранных с использованием файлов cookie, относится к личным данным, применяется наша политика защиты данных.",
        "Если вы хотите 'отказаться' от файлов cookie, установленных нашим веб-сайтом и приложением, вы можете сделать это на основе настроек браузера для каждого файла cookie. Ограничив файлы cookie, вы можете ограничить работу сайта или его функции.",
      ],
    },
  ],
};
const getuseCookieFor = (language) => {
  return useCookieFor[language];
};
export { getuseCookieFor };
