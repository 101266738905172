import imageOne from "../../../assests/images/services/PrimeBrokerage/image-two.jpg";
import imageTwo from "../../../assests/images/services/PrimeBrokerage/image-one.jpg";

const primeBrokerage = {
  en: {
    header:
      "Ark Capital provides a variety of prime brokerage services using integrated trading platforms",
    heroContent: [
      {
        text: "Our clients can access the market directly through multiple multi-asset trading platforms, allowing them to source liquidity seamlessly via direct GUI or API.",
        image: imageOne,
      },
      {
        text: "Our prime brokerage services offer several benefits to our clients, including margin financing for leverage trading, securities lending for short sales, and custody and clearing services. Additionally, our backoffice technology facilitates easy reporting for our clients.",
        image: imageTwo,
      },
    ],
  },
  ru: {
    header:
      "Ark Capital предоставляет широкий спектр первоклассных брокерских услуг с использованием интегрированных торговых платформ",
    heroContent: [
      {
        text: "Наши клиенты могут получить прямой доступ к рынку через несколько торговых платформ с несколькими активами, что позволяет им беспрепятственно получать ликвидность с помощью прямого графического интерфейса или API.",
        image: imageOne,
      },
      {
        text: "Наши первоклассные брокерские услуги предлагают нашим клиентам ряд преимуществ, включая маржинальное финансирование для торговли с использованием кредитного плеча, кредитование ценными бумагами для коротких продаж, а также депозитарные и клиринговые услуги. Кроме того, наша технология backoffice облегчает составление отчетов для наших клиентов.",
        image: imageTwo,
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getPrimeBrokerageContent = (language) => {
  return primeBrokerage[language];
};

export default getPrimeBrokerageContent;
