export const superVisionContent = {
  en: {
    thee: [
      "The DFSA’s regulatory mandate includes asset management, banking and credit services, dealing in investments, investment advice, collective investment funds, custody and trust services, Islamic finance, insurance, financial technology, crypto and investment tokens, money services, capital markets, crowdfunding, an international equities exchange, and an international commodities derivatives exchange. In addition to regulating financial and ancillary services (such as auditors) in the DIFC, the DFSA",

      "is responsible for supervising and enforcing anti-money laundering (AML) and counter-terrorist financing (CTF) requirements applicable in the DIFC to its regulated community. In aiming to establish and maintain an environment that supports the DIFC’s guiding principles of integrity, transparency, and efficiency, the DFSA has set uncompromisingly high standards in building a clear and flexible regulatory framework, based on the best practices and laws of one of the world’s leading financial jurisdictions.",
    ],
  },
  ru: {
    thee: [
      "Регулирующий мандат DFSA включает управление активами, банковские и кредитные услуги, инвестиционные консультации, коллективные инвестиционные фонды, депозитарные и трастовые услуги, исламские финансы, страхование, финансовые технологии, крипто- и инвестиционные токены, денежные услуги, рынки капитала, краудфандинг, международную фондовую биржу и международный биржа товарных деривативов. В дополнение к регулированию финансовых и вспомогательных услуг (таких как аудиторские услуги) в DIFC, DFSA",

      "Отвечает за надзор и обеспечение соблюдения требований по борьбе с отмыванием денег (AML) и финансированием терроризма (CTF), применимых в DIFC к своему регулируемому сообществу. Стремясь создать и поддерживать среду, поддерживающую руководящие принципы DIFC - честность, прозрачность и эффективность, DFSA установило бескомпромиссно высокие стандарты в построении четкой и гибкой нормативно-правовой базы, основанной на лучших практиках и законах одной из ведущих мировых финансовых юрисдикций.",
    ],
  },
};

const GetSuperVisionContent = (language) => {
  return superVisionContent[language];
};

export default GetSuperVisionContent;
