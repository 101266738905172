import { useEffect, useState } from "react";
import GetAllBlog from "../../../../utils/Insigths/Blog/GetAllBlog";

const PopularBlog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetAllBlog();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchData();
  }, []);

  // Sort blogs by updated_at in descending order (newest to oldest)
  const sortedBlogs = blogs.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  // Get the latest 3 blogs
  const latestBlogs = sortedBlogs.slice(0, 3);

  return (
    <div className="flex-1 hidden md:block">
      <h2 className="text-font text-xl">Latest News</h2>

      <div className="flex flex-col gap-4 mt-6 ">
        {latestBlogs.map((blog, index) => (
          <div key={index} className="flex items-center gap-6">
            <div className="flex-1">
              <img src={blog.fullPath} alt="" />
            </div>
            <div className="flex-2 text-font">
              <p>{blog.title}</p>
              <p className="text-sm">{blog.short_description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularBlog;
