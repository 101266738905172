import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
// content
import getArkProHeroContent from "constant/services/ArkPro/ArkProHero";
// images for app and hero
import { ARKProHero, appleIcon, googleIcon } from "../../Image-index";

import ServicesHeader from "../../UI/ServicesHeader";
const ArkProHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getArkProHeroContent(language);
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-3/4"}>{content.title}</ServicesHeader>

        <div className="my-secondary lg:w-[85%] mx-auto">
          <img src={ARKProHero} alt="" className="drop-shadow-2xl rounded-md" />
        </div>

        <div>
          <p className="text-center text-2xl">{`${
            language === "en"
              ? "Download the mobile app:"
              : "Скачайте мобильное приложение:"
          }`}</p>
          <div className="mt-small flex items-center gap-x-6 lg:gap-x-0 w-3/4 mx-auto md:w-1/2 ">
            <div className="flex-1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.ark.client"
              >
                <img
                  src={googleIcon}
                  className="lg:w-3/4 lg:mx-auto  object-cover"
                  alt=""
                />
              </a>
            </div>
            <div className="flex-1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/us/app/ark-pro-trader/id6444772940?itsct=apps_box_link&itscg=30200"
              >
                <img
                  src={appleIcon}
                  className="lg:w-3/4 lg:mx-auto  object-cover"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ArkProHero;
