import "./MobileNavBtn.css";

const MobileNavBtn = ({ onClick, active }) => {
  return (
    <div className="pr-smallContainer">
      <button
        onClick={onClick}
        className={`nav-btn    ${
          active ? "active" : ""
        } z-[100] relative text-white`}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default MobileNavBtn;
