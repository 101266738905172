import { contactImages } from "../../About-index-image";
import Container from "../../../../components/Container/Container";

const ImageGrid = () => {
  return (
    <section className="mb-secondary lg:mb-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="lg:hidden">
          <img src={contactImages[1]} alt="" />
        </div>
        <div className="hidden lg:grid grid-cols-3 gap-x-small justify-between h-[300px] w-full">
          {contactImages.map((image, index) => (
            <div className="even:mt-4 h-full drop-shadow-lg " key={index}>
              <img
                src={image}
                alt=""
                className="h-full object-cover rounded-md"
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ImageGrid;
