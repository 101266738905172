import { createContext, useState, useEffect } from "react";
import performLogout from "../utils/Registartion/Lougout";
import performSetCifId from "../utils/salesforce/SetCIFId";

export const UserInFoContext = createContext();

export const UserInfoProvicer = ({ children }) => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || {}
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [CIFId, setCIFId] = useState(userData?.cifid || null);

  // account type
  const [acountTypeModalIsActive, setAcountTypeModalIsActive] = useState(false);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  const userInfoHandler = (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    setUserData(userData);
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    localStorage.removeItem("userData");
    setUserData({});
    setIsLoggedIn(false);
    performLogout(userData.token);
  };

  // account type handler
  const accountTypeModalHanlder = () => {
    setAcountTypeModalIsActive((cur) => !cur);
  };

  // CIF handler
  const setCifIdHanlder = (token, id) => {
    setCIFId(id);
    performSetCifId(token, id);

    // Update the cifid property in userData
    setUserData((prevUserData) => ({ ...prevUserData, cifid: id }));
  };

  useEffect(() => {
    if (userData) {
      const cifid = userData.cifid;
      if (cifid !== null) {
        setCIFId(cifid);
      }
    }
  }, [userData]);

  return (
    <UserInFoContext.Provider
      value={{
        userInfoHandler,
        isLoggedIn,
        logoutHandler,
        userData,

        // modal Type acoount
        accountTypeModalHanlder,
        acountTypeModalIsActive,

        // CIF
        setCifIdHanlder,
        CIFId,
      }}
    >
      {children}
    </UserInFoContext.Provider>
  );
};
