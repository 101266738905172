import { useState, useEffect, useRef, useContext } from "react";
import Spinner from "../../../../components/UI/Spinner";
import { UserInFoContext } from "../../../../context/UserContext";

import {
  DisplayFormMessgae,
  DisplayFormMessageWithLink,
  DisplaySuccsefullMessage,
} from "../../../../form/Components/Messages/DisplayFormMessgae";

// utilities
import performConfirmCode from "../../../../utils/Registartion/ConfimCode";
import performResendCode from "../../../../utils/Registartion/ResendCode";

const LoginVerfication = ({ email }) => {
  // context
  const { userInfoHandler } = useContext(UserInFoContext);
  // input ref
  const codeInputs = useRef([]);
  //
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  // form states
  const [formMessage, setFormMessage] = useState("");
  const [newCodeIsSucess, setNewCodeIsSucess] = useState(false);

  // state to send with resend-code
  const resendCodeState = {
    email: email,
  };

  // handler for clear error message
  const clearHandler = () => {
    setFormMessage("");
  };

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  // use effect to track if timer is 0 and the newCode isn't send yet to make the input disable so the user can't filled them with data
  useEffect(() => {
    codeInputs.current.forEach((input) => {
      if (timer === 0 || !newCodeIsSucess) {
        input.disabled = true;
        input.style.opacity = "0.5";
      } else {
        input.disabled = false;
        input.style.opacity = "1";
      }
    });
  }, [timer, newCodeIsSucess]);

  // handler for tracking inputs in the form
  const handleVerificationCodeChange = (e, index) => {
    const value = e.target.value;

    setVerificationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;

      return newCode;
    });

    if (value !== "" && index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  // reusable handler for sending new code
  const performSendNewCodeHandler = async () => {
    clearHandler("");
    if (!email) return;
    try {
      setIsLoading(true);
      const data = await performResendCode(resendCodeState);

      if (data.status_code === 422) {
        setFormMessage("new-code-invalid-data");
      } else {
        setFormMessage("new-code-success");
        setNewCodeIsSucess(true);
      }
    } catch (error) {
      setFormMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  // re-sende-code
  const resendNewCodeHandler = async () => {
    setVerificationCode(["", "", "", ""]);
    setTimer(60);

    codeInputs.current.forEach((input) => {
      input.disabled = false;
      input.style.opacity = "1";
    });

    await performSendNewCodeHandler();
  };

  // to send for the first time
  const sendNewCodeHandler = async () => {
    await performSendNewCodeHandler();
  };

  // code confirm handler
  const confirmCodeHandler = async () => {
    if (!email) return;
    if (verificationCode.join("") === "") return;

    const confirmCodeState = {
      email: email,
      code: verificationCode.join(""),
    };

    try {
      setIsLoading(true);
      clearHandler();
      const data = await performConfirmCode(confirmCodeState);

      if (data.status_code === 422) {
        setFormMessage("cofirm-code-invalid-data");
      } else {
        userInfoHandler(data.data);
      }
    } catch (error) {
      setFormMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-1 flex flex-col  lg:p-14 ">
      <div className="mb-10">
        <h1 className="text-2xl  text-font ">Verfied Your account</h1>

        {/* different UI message to handle error */}
        {formMessage === "new-code-invalid-data" && (
          <DisplayFormMessgae
            message={
              "Failed to send new code. Kindly ensure that you are using a valid email address"
            }
          />
        )}
        {formMessage === "failed" && (
          <DisplayFormMessageWithLink
            message={
              "An error occurred either due to your internet connection or from our engine. Please try again. If the error persists, please contact us at "
            }
            link={"cs@ark-cap.com"}
          />
        )}
        {formMessage === "new-code-success" && (
          <DisplaySuccsefullMessage
            message={
              " The code has been sent successfully. Please check your email and enter the new code into the input field "
            }
          />
        )}
        {formMessage === "cofirm-code-invalid-data" && (
          <DisplayFormMessgae
            message={
              "The code you have entered is incorrect. Please double-check the code and try again"
            }
          />
        )}
      </div>

      <form className="flex flex-col justify-center">
        {/* default UI when the user login and it's not veriffied */}
        <div className="flex items-center  space-x-8">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="w-16 h-16 bg-white border border-gray-200 rounded-lg flex items-center justify-center shadow-md"
            >
              <input
                ref={(el) => (codeInputs.current[index] = el)}
                type="text"
                id={`code-${index}`}
                name={`code-${index}`}
                value={verificationCode[index] || ""}
                onChange={(e) => {
                  handleVerificationCodeChange(e, index);
                  clearHandler();
                }}
                maxLength={1}
                className="w-full h-full text-center text-xl bg-transparent outline-none"
              />
            </div>
          ))}
        </div>
        {/* button to send new code to make the user verfied it's email */}
        {!newCodeIsSucess && (
          <button
            type="button"
            className="bg-blue px-4 py-2 text-white rounded-md  tracking-wide flex items-center gap-x-2 w-max mt-10"
            onClick={sendNewCodeHandler}
          >
            {isLoading && <Spinner />}
            Send new Code
          </button>
        )}

        {/* when the new code is succseflly send this UI will be vaisible  */}
        {newCodeIsSucess && (
          <div className="flex items-center gap-x-3 mt-10">
            <span className="flex items-center   ">
              {/* the timer will start auto with a button to confirm the code */}
              {timer > 0 && (
                <button
                  type="button"
                  onClick={confirmCodeHandler}
                  className="bg-blue px-4 py-2 text-white rounded-md  tracking-wide flex items-center gap-x-2"
                >
                  {isLoading && <Spinner />}
                  Confirm
                </button>
              )}
              {/* when the timer is dine, new resend code button is visible to resend the code and renew the process */}
              {timer === 0 && (
                <button
                  type="button"
                  className="bg-blue px-4 py-2 text-white rounded-md  tracking-wide flex items-center gap-x-2 "
                  onClick={resendNewCodeHandler}
                >
                  Resend Code
                </button>
              )}
            </span>
            <div className="flex items-center gap-x-4 ">
              <p className="text-font text-sm">
                Time Remaining: {timer} seconds
              </p>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginVerfication;
