import { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Spinner from "../../../components/UI/Spinner";
// hooks
import useInput from "../../../form/Components/Hooks/user-input";
// number input
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// inputs
import Input from "../../../form/Components/Inputs/Input";
import Textarea from "../../../form/Components/Inputs/Textarea";

// country list
import countryList from "../../Register/Sales Force Forms/Custom Form/Constant/CountryList";
// Message
import inputErrorMessage from "../../../form/Components/Messages/inputErrorMessage";

//utils
import perfomSubmitContactUs from "../../../utils/Contact us/Contactus";

const Form = () => {
  const [netWorth, setNetWorth] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [countryvalue, setCountryValue] = useState("");
  const defaultCountry = "AE";
  const { language } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);

  const [formStatus, setFormStatus] = useState("");

  // styles
  const inputStyle =
    "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue";

  // states and hander
  const [submitClicked, setSubmitClicked] = useState(false);

  const clearErrorHandler = () => {
    setSubmitClicked(false);
  };

  //   hooks
  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    isTouched: firstNameIsTouched,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHanlder,
    reset: firstNameReset,
  } = useInput((value) => value.trim("") !== "");
  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    isTouched: lastNameIsTouched,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHanlder,
    reset: lastNameReset,
  } = useInput((value) => value.trim("") !== "");
  const {
    value: organizationInput,
    isValid: organizationIsValid,
    isTouched: organizationIsTouched,
    HasError: organizationHasError,
    inputChangeHandler: organizationChangeHandler,
    inputBlurHandler: organizationBlurHanlder,
    reset: organizationReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: messageInput,
    isValid: messageIsValid,
    isTouched: messageIsTouched,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHanlder,
    reset: messageReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const netWorthHanlder = (event) => {
    setNetWorth(event.target.value);
  };
  const countryHanlder = (event) => {
    setCountryValue(event.target.value);
  };

  const clearInputsHandler = () => {
    firstNameReset();
    lastNameReset();
    organizationReset();
    setCountryValue("");
    emailReset();
    setNetWorth("");
    setPhoneValue("");
    messageReset();
  };

  const submitFormHandler = async (e) => {
    const formStatus = {
      first_name: firstNameInput,
      last_name: lastNameInput,
      organization: organizationInput,
      country: countryvalue,
      phone: phoneValue,
      email: emailInput,
      financial_instituation: netWorth,
      text: messageInput,
    };

    let formIsValid =
      firstNameIsValid && lastNameIsValid && emailIsValid && messageIsValid;

    if (!formIsValid) {
      setFormStatus("empty");
      return;
    }

    try {
      setIsLoading(true);
      const formData = await perfomSubmitContactUs(formStatus);
      if (formData.status_code === 200) {
        setFormStatus("success");
        clearInputsHandler();
      } else {
        setFormStatus("failed");
      }
      console.log(formData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-2 md:pr-14">
      <h1 className="text-font text-2xl mb-10">
        {`${
          language === "en"
            ? "Please fulfil the form below."
            : "Пожалуйста, заполните форму ниже "
        }`}
      </h1>

      {formStatus === "empty" && (
        <p className="text-sm text-red-500 mb-2">
          Kindly ensure that all form inputs are properly filled, as the
          provided information appears to be invalid.
        </p>
      )}
      {formStatus === "failed" && (
        <p className="text-sm text-red-500 mb-2">Failed to Submit</p>
      )}
      {formStatus === "success" && (
        <p className="text-sm text-green-500 mb-2">
          Your form submission was successful. We will be in touch with you
          soon.
        </p>
      )}
      <form className=" flex flex-col gap-4 ">
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"text"}
            id={"firstName"}
            value={firstNameInput}
            placeholder={`${language === "en" ? "First name " : "Имя"}`}
            onChange={(event) => {
              firstNameChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={firstNameBlurHanlder}
            hasError={
              firstNameHasError || (!firstNameIsTouched && submitClicked)
            }
            errorMessage={inputErrorMessage.text}
          />

          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"text"}
            id={"lastName"}
            value={lastNameInput}
            placeholder={`${language === "en" ? "Last name " : "фамилия "}`}
            onChange={(event) => {
              lastNameChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={lastNameBlurHanlder}
            hasError={lastNameHasError || (!lastNameIsTouched && submitClicked)}
            errorMessage={inputErrorMessage.text}
          />
        </div>

        <Input
          boxStyle={"w-full"}
          inputStyle={inputStyle}
          type={"text"}
          id={"organization"}
          value={organizationInput}
          placeholder={`${
            language === "en" ? "Organization " : "Организация "
          }`}
          onChange={(event) => {
            organizationChangeHandler(event);
            clearErrorHandler();
          }}
          onBlur={organizationBlurHanlder}
          hasError={
            organizationHasError || (!organizationIsTouched && submitClicked)
          }
          errorMessage={inputErrorMessage.number}
        />

        <select
          className={`${inputStyle} rounded-md bg-transparent`}
          id="contact-us-country"
          onChange={countryHanlder}
          // value={countryvalue}
        >
          <option className="">Select a Country</option>
          {countryList.map((country, index) => (
            <option key={index} value={country.value}>
              {country.label}
            </option>
          ))}
        </select>

        <div className="flex flex-col lg:flex-row items-center gap-4">
          <span className="w-full">
            <PhoneInput
              className={`${inputStyle} rounded-md bg-transparent`}
              country="US"
              placeholder={`${
                language === "en"
                  ? "Enter phone number "
                  : "Введите номер телефона"
              }`}
              value={phoneValue}
              onChange={setPhoneValue}
              defaultCountry={defaultCountry}
            />
          </span>

          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"email"}
            id={"email"}
            value={emailInput}
            placeholder={`${
              language === "en" ? "Email address" : "Адрес электронной почты"
            }`}
            onChange={(event) => {
              emailChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={emailBlurHanlder}
            hasError={emailHasError || (!emailIsTouched && submitClicked)}
            errorMessage={inputErrorMessage.email}
          />
        </div>

        <span className="w-full">
          <select
            className={`${inputStyle} rounded-md bg-transparent`}
            id="contact-us-country-net-worth"
            value={netWorth}
            onChange={netWorthHanlder}
          >
            <option
              disabled
              className="text-grey"
              value="High-Net-Worth
  "
            >
              High Net Worth
            </option>
            <option value="Financial-Institution">
              {`${
                language === "en"
                  ? "Financial Institution"
                  : "Финансовое учреждение "
              }`}
            </option>
            <option value="Introducing-Broke">
              {`${
                language === "en"
                  ? "Introducing Broke"
                  : "Представляющий брокер"
              }`}
            </option>
            <option value="Family-Office">
              {`${language === "en" ? " Family Office" : "Семейный офис "}`}
            </option>
            <option value="Corporate">
              {`${language === "en" ? "Corporate" : "Корпоративный"}`}
            </option>
          </select>
        </span>

        <Textarea
          placeholder={`${
            language === "en" ? " You're message" : "Ты сообщение"
          }`}
          boxStyle={"w-full"}
          inputStyle={inputStyle}
          type={"text"}
          id={"message"}
          value={messageInput}
          onChange={(event) => {
            messageChangeHandler(event);
            clearErrorHandler();
          }}
          onBlur={messageBlurHanlder}
          hasError={messageHasError || (!messageIsTouched && submitClicked)}
          errorMessage={inputErrorMessage.text}
          rows={5}
          wrap="soft"
        />

        <button
          type="button"
          onClick={submitFormHandler}
          className="px-6 py-2 rounded bg-blue text-white w-max ml-auto flex items-center gap-x-2"
        >
          {isLoading && <Spinner />}
          Send
        </button>
      </form>
    </div>
  );
};

export default Form;
