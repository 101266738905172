import { Link } from "react-router-dom";
import mainLogo from "../../../assests/images/logo/new-logo.png";
const Logo = ({ headerIsSticky }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link onClick={scrollToTop} to={"/"} className="">
      <div
        className={`pl-smallContainer md:pl-mainContainer z-[1000] relative ${
          headerIsSticky
            ? "w-[16rem] ss:w-[18rem] lg:w-[20rem] "
            : "w-[16rem] ss:w-[18rem] lg:w-[26rem] "
        } `}
      >
        <img src={mainLogo} className="w-full h-full object-cover" alt="" />
      </div>
    </Link>
  );
};

export default Logo;
