const teamFeatureContent = {
  en: {
    text: "Each team member was chosen for their unique background and experience, through which they complement each other and enable us to provide exceptional value to our customers. Additionally, we pride ourselves on our multilingualism, with team members who speak Arabic, English, French, Greek, Hindi, and Russian.",
  },
  ru: {
    text: "Каждый член команды был выбран за их уникальное образование и опыт, благодаря которым они дополняют друг друга и позволяют нам предоставлять исключительную ценность нашим клиентам. Кроме того, мы гордимся нашим многоязычием: члены команды говорят на арабском, английском, французском, греческом, хинди и русском языках",
  },
};

// Function to retrieve content based on the selected language
const getTeamFeatureContent = (language) => {
  return teamFeatureContent[language];
};

export default getTeamFeatureContent;
