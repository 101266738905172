const superVisionHeaderContent = {
  en: {
    title:
      "ARK Capital Management (Dubai) Limited (the 'Firm') is regulated by the Dubai Financial Services Authority (DFSA) from within the Dubai International Financial Centre (DIFC), UAE. Incorporated on September 23, 2013, under prudential category 3C with the addition of a prudential category 3A licence on July 14, 2021, the Firm is licensed to provide the following services alongside a custody endorsement:",

    services: [
      {
        text: "Managing Assets",
      },
      {
        text: "Arranging Credit and Advising on Credit",
      },
      {
        text: "Dealing in Investments as Matched Principal",
      },
      {
        text: "Advising on Financial Products",
      },
      {
        text: "Arranging Deals in Investments",
      },
      {
        text: "Dealing in Investments as Agent",
      },
    ],
  },
  ru: {
    title:
      "Деятельность ARK Capital Management (Dubai) Limited ('Фирма') регулируется Управлением финансовых услуг Дубая (DFSA) из Дубайского международного финансового центра (DIFC), ОАЭ. Зарегистрированная 23 сентября 2013 года в соответствии с пруденциальной категорией 3C с добавлением лицензии пруденциальной категории 3а 14 июля 2021 года, фирма имеет лицензию на предоставление следующих услуг наряду с подтверждением депозитарной деятельности:",

    services: [
      {
        text: "Управление активами",
      },
      {
        text: "Организация кредитования и консультирование по кредитным вопросам",
      },
      {
        text: "Работа с инвестициями в качестве согласованного принципала ",
      },
      {
        text: "Консультирование по финансовым продуктам",
      },
      {
        text: "Организация сделок в области инвестиций",
      },
      {
        text: "Работа с инвестициями в качестве агента",
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getSuperVisionHeaderContent = (language) => {
  return superVisionHeaderContent[language];
};

export default getSuperVisionHeaderContent;
