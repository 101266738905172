import React from "react";

const Content = ({ content }) => {
  return (
    <div>
      {content.map((cnt, index) => (
        <div key={index} className="lg:w-3/4 mb-4 p-1.5">
          <h4 className="text-lg font-semibold mb-3">{cnt.title}</h4>
          <span className="flex flex-col gap-2">
            {cnt.text?.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </span>

          {cnt.list && (
            <ul className={"list-disc list-inside mt-2"}>
              {cnt.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default Content;
