import CustodyHero from "./components/CustodyHero";
import CustodySecurity from "./components/CustodySecurity";
import CustodyAssest from "./components/CustodyAssest";
import OpenAccount from "../UI/OpenAccount";
const Custody = () => {
  return (
    <main>
      <CustodyHero />
      <CustodySecurity />
      <CustodyAssest />
      <OpenAccount />
    </main>
  );
};

export default Custody;
