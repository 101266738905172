import { useContext, useEffect } from "react";
// component
import MobileLinkNav from "./MobileLinkNav";
import LanguageButtonMobile from "./LanguageButtonMobile";
// context
import { LanguageContext } from "context/LanguageContext.js";
// links content
import GetHeaderLinks from "constant/Header/HeaderLinks";

const MobileNavigation = ({ active, onClick }) => {
  const { language } = useContext(LanguageContext);
  const navigationContent = GetHeaderLinks();
  const headerLinks = navigationContent[language];

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [active]);

  return (
    <div
      className={`fixed bg-font z-[10] top-0 left-0 h-[100vh] w-[100vw] px-6  flex flex-col pt-32 gap-small transition ease-in-out duration-300 lg:hidden overflow-y-scroll overflow-x-hidden  ${
        active
          ? "translate-x-[0%] opacity-100 select-auto visible"
          : "-translate-x-[100%] opacity-0 select-none invisible"
      }`}
    >
      {headerLinks.map((link, index) => (
        <MobileLinkNav onMobileHanlder={onClick} key={index}>
          {link}
        </MobileLinkNav>
      ))}

      {/* <LanguageButtonMobile /> */}
    </div>
  );
};

export default MobileNavigation;
