import AdvisoryHero from "./components/AdvisoryHero";
import DiscoverMore from "./components/DiscoverMore";
import DiscoverMoreBlog from "./components/DiscoverMoreBlog";
import OpenAccount from "../UI/OpenAccount";
const Advisory = () => {
  return (
    <main>
      <AdvisoryHero />
      <DiscoverMoreBlog />
      <DiscoverMore />
      <OpenAccount />
    </main>
  );
};

export default Advisory;
