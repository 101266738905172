import { Routes, Route } from "react-router-dom";
// layout
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
// portals
import SelectTypeModal from "./pages/Register/Sales Force Forms/No Type/SelectTypeModal";

// tracker
import UserJourneyTracker from "./User Tracking/UserJourneyTracker";
// cookie
import CookieBanner from "./pages/Disclamair/Cookie/CookieBanner";
// Routes
import {
  Home,
  Blog,
  BlogPage,
  Media,
  ContactUs,
  FAQ,
  RegisterationRoute,
  // about
  Aboutus,
  Team,
  Ethos,
  Supervision,
  // services
  Iress,
  ArkPro,
  MT5,
  Custody,
  PrimeBrokerage,
  AssetManagement,
  Advisory,

  // disclaimer
  CookiePolicy,
  Diclaimer,
  ProtectionPolicy,
  RiskWarning,
  TermsOfUse,

  RemoveAccount,
} from "./routes/index";

// context
import ContextProvider from "./context/ContextProvider";


const App = () => {
  return (
    <ContextProvider>
      {/* cookies */}
      <CookieBanner />
      {/* <UserJourneyTracker /> */}
      <Header />
      {/* portals */}
      <SelectTypeModal />
      <Routes>
        <Route index element={<Home />} />
        {/* abouts us */}
        <Route path="" element={<Aboutus />}>
          <Route path="about-us/ethos" element={<Ethos />} />
          <Route path="about-us/team" element={<Team />} />
          <Route path="about-us/supervison" element={<Supervision />} />
        </Route>
        <Route path="contact-us" element={<ContactUs />} />

        {/* services */}
        <Route path="Iress" element={<Iress />} />
        <Route path="ARK-Pro" element={<ArkPro />} />
        <Route path="Meta-Trade-5" element={<MT5 />} />
        <Route path="custody" element={<Custody />} />
        <Route path="asset-management" element={<AssetManagement />} />
        <Route path="prime-brokerage" element={<PrimeBrokerage />} />
        <Route path="advisory" element={<Advisory />} />

        {/* insights */}
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<BlogPage />} />
        <Route path="media" element={<Media />} />

        {/* register */}
        <Route path="register" element={<RegisterationRoute />} />
        {/* other */}
        <Route path="faq" element={<FAQ />} />

        {/* disclaimer */}
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="disclaimer" element={<Diclaimer />} />
        <Route path="protection-policy" element={<ProtectionPolicy />} />
        <Route path="risk-warning" element={<RiskWarning />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />

        {/* remove account */}
        <Route path="remove-account" element={<RemoveAccount />} />

        {/* 404 */}
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </ContextProvider>
  );
};

export default App;
