import React, { useState, useEffect, useContext } from "react";
// import Faqcontent from "../../../constant/FAQ/FAQ";
import { Plus, Minus } from "@phosphor-icons/react";
// ui
import Container from "components/Container/Container";
import IsError from "components/UI/HandlingStatus/IsError";
import IsLoading from "components/UI/HandlingStatus/IsLoading";
// context
import { FAQContext } from "context/FAQContext";
import { LanguageContext } from "context/LanguageContext";

const QA = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { isLoading, faqContent, fetchStatus } = useContext(FAQContext);
  const { language } = useContext(LanguageContext);

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  function stripHtmlTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  return (
    <section className=" bg-[#f7f7f7] py-secondary lg:py-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <h1 className="text-3xl sm:text-4xl mb-secondary">
          {" "}
          {language === "en"
            ? " Frequently asked questions"
            : "Часто задаваемые вопросы"}
        </h1>
        {fetchStatus === "failed" && <IsError language={language} />}

        {isLoading && <IsLoading language={language} />}
        <div className="grid grid-cols-1 lg:grid-cols-2 items-stretch gap-x-secondary gap-y-small">
          {faqContent?.map((item, index) => (
            <div
              key={index}
              className={`${item.table ? "lg:col-span-2  " : ""}`}
            >
              <div
                className="flex items-center cursor-pointer gap-x-4 border-b border-gray-200 pb-2"
                onClick={() => handleClick(index)}
              >
                <span
                  className={`transition ease-in duration-500 text-newBlue p-1 `}
                >
                  {activeIndex === index ? (
                    <Minus size={20} />
                  ) : (
                    <Plus size={20} />
                  )}
                </span>
                <h3 className="text-lg">{`${
                  language === "en" ? item.question : item.question_ru
                } `}</h3>
              </div>
              <div
                className={` ${
                  activeIndex === index
                    ? "max-h-auto opacity-100 visible select-auto my-8  "
                    : "max-h-0 opacity-0 select-none invisible my-2"
                } transition-all ease-in duration-500 `}
              >
                <span className=" text-lightFont flex flex-col gap-2 ">
                  {stripHtmlTags(
                    `${language === "en" ? item.answer : item.answer_ru}`
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default QA;
