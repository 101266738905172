import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
import ServicesHeader from "../../UI/ServicesHeader";
// content
import getHeroContent from "constant/services/AssestManagement/AssestManagementHero";

const AssetManagementHero = () => {
  const { language } = useContext(LanguageContext);
  const content = getHeroContent(language);

  return (
    <section className="mt-secondary lg:mt-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <ServicesHeader width={"lg:w-3/4"}>{content.title}</ServicesHeader>

        <div className="flex flex-col gap-secondary lg:gap-primary mt-secondary lg:mt-primary">
          {content.heroContent.map((item, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row items-center gap-small lg:gap-secondary lg:even:flex-row-reverse"
            >
              <div className="flex-1">
                <img
                  src={item.image}
                  alt=""
                  className="rounded-md drop-shadow-md"
                />
              </div>
              <div className="flex-1 flex flex-col gap-ssmall md:gap-small">
                {item.text.map((text, index) => (
                  <p key={index} className="text-lg text-font">
                    {text}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AssetManagementHero;
