import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
// UI
import Spinner from "components/UI/Spinner";
import Container from "components/Container/Container";
// Utilitis
import GetBlogByID from "utils/Insigths/Blog/GetBlogById";
// context
import { LanguageContext } from "context/LanguageContext";

const BlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState("");
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const data = await GetBlogByID(id);
        setBlog(data.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  return (
    <main className="my-secondary lg:my-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        {!blog && (
          <div className="flex flex-col items-center justify-center gap-2">
            <Spinner />

            <p>
              {language === "en"
                ? "Please wait while the blog is being fetched..."
                : "Подождите, пока блог загружается..."}
            </p>
          </div>
        )}

        {blog && (
          <div>
            <div className="max-h-[600px] w-full">
              <img
                src={`${blog.fullPath}`}
                alt=""
                className="w-full max-h-[600px] object-cover"
              />
            </div>
            <div className="flex flex-col items-center justify-center text-center mt-secondary">
              <h1 className="text-4xl">{`${
                language === "en" ? blog.title : blog.title_ru
              }`}</h1>

              <p className="mt-1 italic">{`${
                language === "en"
                  ? blog.short_description
                  : blog.short_description_ru
              }`}</p>
            </div>

            <div className="mt-secondary">
              {language === "en" ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: blog?.description.replace(/\r\n/g, "<br>"),
                  }}
                ></p>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: blog?.description_ru?.replace(/\r\n/g, "<br>"),
                  }}
                ></p>
              )}
            </div>
          </div>
        )}
      </Container>
    </main>
  );
};

export default BlogPage;
