export const DisplayFormMessgae = ({ message }) => {
  return <p className="mb-2 text-sm text-red-400">{message}</p>;
};

export const DisplayFormMessageWithLink = ({ message, link }) => {
  return (
    <p className="mb-2 text-sm text-red-400">
      {message}{" "}
      <a
        className="underline"
        href={`mailto:${link}`}
        target="_blank"
        rel="noreferrer"
      >
        {link}
      </a>
    </p>
  );
};

export const DisplaySuccsefullMessage = ({ message }) => {
  return <p className="text-green-400 mb-2 text-sm">{message}</p>;
};
