const ownerContent = {
  en: {
    title: "Saed Abukarsh",
    text: [
      "Founder and CEO of Ark Capital, has over 20 years of experience in the MENA financial markets. As the former Chief Dealer at Al Mashreq Bank, he has a deep understanding of global markets and products.",
      " Saed's expertise and extensive network have enabled him to successfully set up and expand ARK Capital into a leading firm in the MENA region.",
    ],
  },
  ru: {
    title: "Саед Абукарш",
    text: [
      "Основатель и генеральный директор Ark Capital, имеет более чем 20-летний опыт работы на финансовых рынках Ближнего Востока и Северной Африки. Как бывший главный дилер банка 'Аль-Машрек', он обладает глубоким пониманием мировых рынков и продуктов.",
      "Опыт Саеда и обширные связи позволили ему успешно создать и расширить ARK Capital, превратив ее в ведущую фирму в регионе MENA..",
    ],
  },
};

// Function to retrieve content based on the selected language
const getOwnerContent = (language) => {
  return ownerContent[language];
};

export default getOwnerContent;
