import { useContext } from "react";
import { Link } from "react-router-dom";
// icons
import { CaretRight } from "@phosphor-icons/react";
// context
import { LanguageContext } from "context/LanguageContext";

const FAQHeader = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="flex-1 flex flex-col gap-small">
      {language === "en" ? (
        <h1 className={"text-4xl"}>
          Frequently <br className="hidden lg:block" /> asked{" "}
          <br className="hidden lg:block" /> questions
        </h1>
      ) : (
        <h1 className={"text-4xl"}>
          Часто <br className="hidden lg:block" /> задаваемые{" "}
          <br className="hidden lg:block" /> вопросы
        </h1>
      )}
      <div className="group/link flex items-center gap-x-3 ">
        <Link
          className="text-blue text-xl transition ease-in duration-300 group-hover/link:text-blue "
          to={"/faq"}
        >
          {language === "en" ? "View all Questions" : "Посмотреть все вопросы"}
        </Link>
        <span className="text-blue transition ease-in duration-300 group-hover/link:text-blue ">
          <CaretRight size={18} weight="bold" />
        </span>
      </div>
    </div>
  );
};

export default FAQHeader;
