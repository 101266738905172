import { mt5Hero } from "../../../pages/Services/Image-index";

const MT5Platfrom = {
  en: {
    MT5Plaform: {
      image: mt5Hero,
      text: [
        "MetaTrader 5 is a multi-asset platform that allows trading Forex, stocks and futures. It offers superior tools for comprehensive price analysis, use of algorithmic trading applications (trading robots, Expert Advisor) and copy trading.",
        "The MetaTrader 5 multi-asset platform supports the hedging method, which allows opening multiple positions of the same financial instrument, of opposite or same direction. This feature is widely used in Forex trading.",
      ],
    },
  },
  ru: {
    MT5Plaform: {
      image: mt5Hero,
      text: [
        "MetaTrader 5 - это многоактивная платформа, которая позволяет торговать на рынке Forex, акциями и фьючерсами. Она предлагает передовые инструменты для полного анализа цен, использования приложений для алгоритмической торговли (торговых роботов и советников) и копирования сделок.",
        "Многоактивная платформа MetaTrader 5 поддерживает метод хеджирования, который позволяет открывать несколько позиций на одном и том же финансовом инструменте, в противоположном или одном и том же направлении. Эта функция широко используется в торговле на рынке Forex.",
      ],
    },
  },
};

// Function to retrieve content based on the selected language
const getMT5PlatfromContent = (language) => {
  return MT5Platfrom[language];
};

export default getMT5PlatfromContent;
