import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
import TwoColumnsGrid from "components/UI/TwoColumnsGrid";
// content
import getArkProFeaturesContent from "constant/services/ArkPro/ArkProContent";

const ArkProFeatures = () => {
  const { language } = useContext(LanguageContext);
  const content = getArkProFeaturesContent(language);
  return (
    <section className="my-secondary lg:my-primary ">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <TwoColumnsGrid center={true} content={content.features} />
      </Container>
    </section>
  );
};

export default ArkProFeatures;
