const teamImage = {
  en: {
    text: "Our team is carefully selected and organized to align with our Ethos. The explicit segregation of responsibilities allows us to look after our clients’ best interests at every step of the way, from introduction to execution to exit.",
  },
  ru: {
    text: "Наша команда тщательно отобрана и организована в соответствии с нашими принципами. Четкое разделение обязанностей позволяет нам заботиться о наилучших интересах наших клиентов на каждом этапе - от внедрения до исполнения и выхода.",
  },
};

// Function to retrieve content based on the selected language
const getTeamImage = (language) => {
  return teamImage[language];
};

export default getTeamImage;
