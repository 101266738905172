import React from "react";

const FormButton = ({ children, submit, onClick, error }) => {
  const bg = `${submit ? "bg-blue text-white" : "border border-gray-600"} ${
    error ? "bg-red-400 text-white" : ""
  }`;
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${bg}  px-6 py-1.5 rounded-md`}
    >
      {children}
    </button>
  );
};

export default FormButton;
