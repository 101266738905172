import AboutHero from "./AboutHero/AboutHero";
import { Outlet } from "react-router-dom";

const Aboutus = () => {
  return (
    <main>
      {/* Here we make the about hero fixed in all about us pages and the outlet will be: - Team, Ethos, Supervision */}
      <AboutHero />
      <Outlet />
    </main>
  );
};

export default Aboutus;
