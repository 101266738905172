import { useContext } from "react";
import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";
// icons
import { CaretRight } from "@phosphor-icons/react";
// content
import getWhyArkContent from "constant/Home/AboutUs-Section";
import { LanguageContext } from "context/LanguageContext.js";
const About = () => {
  const { language } = useContext(LanguageContext);
  const content = getWhyArkContent(language);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="py-secondary lg:py-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-secondary justify-center">
          {content.map((content, index) => (
            <div
              key={index}
              className="text-center text-font lg:border-r border-font last:border-r-0 px-ssmall"
            >
              <h2 className="text-3xl lg:text-4xl mb-small">
                {content.header}
              </h2>
              <p className="font-euroThin  leading-7 tracking-wider mb-small lg:mb-secondary">
                {content.text}
              </p>
              <button onClick={scrollToTop} id={content.name}>
                <Link
                  className={`group/arrow text-sm tracking-wide transition ease-in duration-300 text-black hover:text-lightFont  flex items-center justify-center  gap-x-2`}
                  to={content.to}
                >
                  {`${language === "en" ? "Discover more" : "Узнать больше"} `}
                  <span className="group-hover/arrow:translate-x-[5px] text-font transition ease-in duration-300 ">
                    <CaretRight size={14} weight="bold" />
                  </span>
                </Link>
              </button>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default About;
