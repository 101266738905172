import React from "react";

const IsError = ({ isWhite, language }) => {
  const data = `${
    language === "en"
      ? " An error occurred while attempting to retrieve the data. Kindly retry at a later time."
      : "При попытке получения данных произошла ошибка. Пожалуйста, повторите попытку позже."
  }`;
  return (
    <div>
      <p>{data}</p>
    </div>
  );
};

export default IsError;
