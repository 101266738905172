import Container from "../../../components/Container/Container";
import Button from "../../../components/UI/Button";
const MoveForms = ({ onClick, message, CIF, FATCA, UPLOAD, DONE }) => {
  const movingHandler = () => {
    if (CIF) {
      onClick("Move-To-FATCA");
    } else if (FATCA) {
      onClick("Move-To-Upload");
    }
  };
  return (
    <div>
      <Container className={"px-smallContainer px-mainContainer"}>
        <div className="flex flex-col gap-secondary lg:gap-primary items-center justify-center">
          <h1 className="text-xl md:text-2xl lg:text-3xl text-center">
            {message}
          </h1>

          {!UPLOAD && !DONE && <Button onClick={movingHandler}>Move</Button>}

          {UPLOAD && <Button to={"/"}>Confirm</Button>}
        </div>
      </Container>
    </div>
  );
};

export default MoveForms;
