const commitmentContent = {
  en: {
    title: "Our Commitment",
    header:
      "Our firm serves as a one-stop shop, providing a complete range of services, including complimentary external resources, to fulfill our clients’ needs and requirements:",
    list: [
      "Asset Allocation",
      "Global Fund & Security selection",
      "Active risk oversight",
      "In-house execution",
      "Transparent passive & online reporting",
      "Compliance checks",
    ],
  },
  ru: {
    title: "Наше обязательство",
    header:
      "Наша фирма работает по принципу 'одного окна', предоставляя полный спектр услуг, включая бесплатные внешние ресурсы, для удовлетворения потребностей наших клиентов:",
    list: [
      "распределение активов",
      "Выбор глобального фонда и ценных бумаг",
      "Активный надзор над рисками",
      "Внутреннее исполнение",
      "Прозрачная пассивная и онлайн-отчетность",
      "Проверки соответствия требованиям",
    ],
  },
};

// Function to retrieve content based on the selected language
const getCommitmentContent = (language) => {
  return commitmentContent[language];
};

export default getCommitmentContent;
