import React from "react";

const SmallList = ({ contentList }) => {
  return (
    <div className="lg:w-[90%] text-font">
      {contentList?.map((item, index) => (
        <div key={index} className="mb-secondary">
          <h6 className="font-semibold underline text-xl mb-3 tracking-wider">
            {item.title}
          </h6>
          <div className="flex flex-col gap-3 text-[17px]">
            {item?.content?.map((text, innderIndex) => (
              <p key={innderIndex}>{text}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SmallList;
