import React, { useState, useContext } from "react";
import { Plus, Minus } from "@phosphor-icons/react";

// ui
import IsError from "components/UI/HandlingStatus/IsError";
import IsLoading from "components/UI/HandlingStatus/IsLoading";
// context
import { FAQContext } from "context/FAQContext";
import { LanguageContext } from "context/LanguageContext";

const FaQForm = () => {
  const { language } = useContext(LanguageContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const { isLoading, faqContent, fetchStatus } = useContext(FAQContext);

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // to randomly choose 6 FAQ and render them
  const shuffledFaqContent =
    faqContent && [...faqContent].sort(() => Math.random() - 0.5);

  // Take the first 10 elements
  const randomFaqItems = shuffledFaqContent?.slice(0, 6);

  // to transfer rhe tag
  function stripHtmlTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  return (
    <div className=" md:w-3/4 lg:flex-2">
      <div className="flex flex-col ">
        {isLoading && <IsLoading language={language} />}
        {fetchStatus === "failed" && <IsError language={language} />}

        {randomFaqItems?.map((item, index) => (
          <div key={index} className="">
            <div
              className="flex items-center cursor-pointer gap-x-4 border-b border-gray-200 pb-2"
              onClick={() => handleClick(index)}
            >
              <span
                className={`transition ease-in duration-500 text-blue p-1 `}
              >
                {activeIndex === index ? (
                  <Minus size={20} />
                ) : (
                  <Plus size={20} />
                )}
              </span>
              <h3 className="text-lg">{`${
                language === "en" ? item.question : item.question_ru
              } `}</h3>
            </div>
            <div
              className={`pl-10 ${
                activeIndex === index
                  ? "max-h-[500px] opacity-100 visible select-auto my-8  "
                  : "max-h-0 opacity-0 select-none invisible my-2"
              } transition-all ease-in duration-500 `}
            >
              <span className=" text-lightFont flex flex-col gap-2 ">
                {stripHtmlTags(
                  `${language === "en" ? item.answer : item.answer_ru}`
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaQForm;
