import { useContext } from "react";
// content
import { footerContactInfoContent } from "../../../constant/ContactInfo.js";
// context
import { LanguageContext } from "context/LanguageContext.js";
const FooterContact = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="flex flex-col lg:mx-auto">
      <h4 className="text-white  text-[15px] font-medium uppercase  mb-8">
        {language === "en" ? "Contact Info" : "КОНТАКТЫ"}
      </h4>
      <div className="flex flex-col gap-2.5">
        {footerContactInfoContent.map((item, index) => (
          <ul
            key={index}
            className="flex items-center gap-3 text-lightGreyFont"
          >
            <span>{item.icon}</span>
            {!item.link && (
              <li className="text-lightGreyFont text-[14px] ">{item.text}</li>
            )}

            {item.link && (
              <a
                className="text-lightGreyFont text-[14px]  hover:text-lightWhiteFont transition ease-in duration-100"
                target="_blank"
                rel="noreferrer"
                href={item.link}
              >
                {item.text}
              </a>
            )}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default FooterContact;
