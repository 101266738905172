import BASE_API_URL from "../BaseUrl";

const performResendCode = async (email) => {
  try {
    const response = await fetch(`${BASE_API_URL}/resend-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });

    const responseData = await response.json();
    if (!response.ok) {
      console.log("Failed to resend ");
      return responseData;
    } else {
      console.log("resend code successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform resend code.");
  }
};

export default performResendCode;
