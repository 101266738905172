import {
  Clock,
  EnvelopeSimple,
  PhoneCall,
  LinkedinLogo,
  MapPinLine,
} from "@phosphor-icons/react";

export const contactInfoContent = [
  {
    icon: <Clock size={22} />,
    text: "Mon - Fri : 09:00 - 18:00 ",
  },
  {
    icon: <EnvelopeSimple size={22} />,
    contact: [
      {
        text: "info@ark-cap.com",
        link: "mailto:info@ark-cap.com",
      },
    ],
  },
  {
    icon: <PhoneCall size={22} />,

    contact: [
      {
        text: "971 4 3184444",
        link: "tel:+97143184444",
      },
      {
        text: "971 4 3184471",
        link: "tel:+97143184471",
      },
    ],
  },
  {
    icon: <LinkedinLogo size={22} />,
    contact: [
      {
        text: "Linkedin",
        link: "https://www.linkedin.com/company/ark-capital-management-dubai/about/",
      },
    ],
  },
];
export const footerContactInfoContent = [
  {
    icon: <Clock size={18} />,
    text: "Mon - Fri : 09:00 - 18:00",
  },
  {
    icon: <EnvelopeSimple size={18} />,
    text: "info@ark-cap.com",
    link: "mailto:info@ark-cap.com",
  },
  {
    icon: <PhoneCall size={18} />,
    text: "971 4 3184444",
    link: "tel:+97143184444",
  },
  {
    icon: <PhoneCall size={18} />,

    text: "971 4 3184471",
    link: "tel:+97143184471",
  },
  {
    icon: <LinkedinLogo size={18} />,
    text: "Linkedin",
    link: "https://www.linkedin.com/company/ark-capital-management-dubai/about/",
  },
];

export const locationContent = {
  text: [
    "Unit S2402, Level 24, Emirates Financial Towers",
    "Dubai International Financial Centre (DIFC)",
    "  Dubai, UAE",
  ],
  link: "https://maps.app.goo.gl/RJz61T9kBjQKnFDM6",
  icon: <MapPinLine size={22} />,
};
