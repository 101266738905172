import React from "react";
import { Star } from "@phosphor-icons/react";

const CustomSelect = ({
  label,
  name,
  value,
  onChange,
  isError,
  error,
  hide,
  placeholder,
  options,
  require,

  // style
  boxStyle,
  labelStyle,
  labelBox,
  inputStyle,
  isFourColumns,
  isNotMega,
  megaBigDesign,
}) => {
  const inputClasses = `${inputStyle} ${value ? "bg-[#e8f5fe]" : ""}`;

  return (
    <div className={`${boxStyle} ${hide ? "hidden" : ""}`}>
      <label
        className={`${labelStyle} flex flex-co lg:flex-row lg:items-center gap-x-2  ${
          isFourColumns && "lg:text-center lg:justify-center"
        } ${isNotMega && "text-start"} ${
          !isFourColumns && !isNotMega && "lg:text-end lg:justify-end lg:pr-2"
        } `}
      >
        {label}

        {require ? (
          <span className="text-blue flex items-center gap-x-1">
            <Star size={14} weight="fill" />
          </span>
        ) : (
          ""
        )}
      </label>
      <span className={"flex-1"}>
        <select
          // multiple
          className={`capitalize ${inputClasses} ${
            isError && error ? "border-red-500" : ""
          } `}
          onChange={onChange}
          name={name}
          value={value}
        >
          <option value="">{placeholder}</option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {isError && error && (
          <p className="text-sm text-red-500 mt-1">This field is required</p>
        )}
      </span>
    </div>
  );
};

export default CustomSelect;
