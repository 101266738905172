import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
import TwoColumnsGrid from "components/UI/TwoColumnsGrid";
// content
import getIressContent from "constant/services/Iress/IressContent";

const IressFeatures = () => {
  const { language } = useContext(LanguageContext);
  const content = getIressContent(language);
  return (
    <section className="mb-primary ">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <TwoColumnsGrid center={true} content={content.features} />
      </Container>
    </section>
  );
};

export default IressFeatures;
