import Container from "../../../components/Container/Container";
import "./style.css";
const ProtectionPolicy = () => {
  return (
    <main>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
      <div className="WordSection1">

              <br/>
              <p className={"MsoNormal"}><b><span
              lang="EN-GB" className={"topHeading"}>Ark
              Capital Online Data Protection Policy</span></b></p>
              
              <p className={"MsoNormal"}><b><span
              lang="EN-GB" className={"subHeading"}>Effective
              Date:</span></b><span lang="EN-GB" className={"subHeading"}>&nbsp;
              2024-02-10</span></p>
              
              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Protecting
              Your Privacy:</span></b></p>
              
              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>At
              Ark Capital Management (Dubai) Limited (&quot;Ark Capital&quot;,
              &quot;we&quot;, &quot;us&quot;, and &quot;our&quot;), we understand the
              importance of safeguarding the privacy of all information entrusted to us by
              users of our website and mobile app (collectively referred to as the
              &quot;Services&quot;). This Online Data Protection Policy outlines how we
              collect, use, and disclose your personal data, your rights under the DIFC Law
              No. 5 of 2020 (Data Protection Law) and Data Protection Regulations (&quot;Data
              Protection Law and Regulation&quot;), and our commitment to data protection
              compliance.</span></p>
              
              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>What
              Information Do We Collect?</span></b></p>
              
              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              collect personal data in various ways when you interact with our Services,
              including:</span></p>
              
              <ul className={"MsoNormal"} >
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}> <span lang="EN-GB" className={"MsoNormal"}> Information you provide directly:
                  This includes data from registration forms, client onboarding
                  applications, email communications, and other interactions where you share
                  details such as contact information, biographical details, financial
                  information, investment knowledge, interests, and preferences.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}> <span lang="EN-GB" className={"MsoNormal"}>Information from third parties: We
                  may obtain data from intermediaries, public sources, and background checks
                  where necessary and permitted by law.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><span lang="EN-GB" className={"MsoNormal"}>Device and usage data: When you use
                  our Services, we collect information like your IP address, cookies,
                  browsing history, clickstream data, and device data that helps us improve
                  your experience and understand how you interact with our platforms.</span></li>
              </ul>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Why
              Do We Use Your Data?</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              use your personal data for legitimate purposes such as:</span></p>

              <ul className={"MsoNormal"} type="disc">
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Providing financial services:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;To
                  fulfill your requests for services like investment management, reporting,
                  and client administration.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Compliance:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;To
                  adhere to anti-money laundering, tax, and other legal obligations.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Regulatory reporting:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Sharing
                  necessary data with relevant authorities as required by law.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Marketing (with your consent):</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Sending
                  you tailored information about our services and other offerings that may
                  interest you.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Service improvement:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Analyzing
                  usage data to enhance the functionality and user experience of our
                  Services.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Security:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Detecting
                  and preventing fraud and ensuring the security of your information and our
                  platforms.</span></li>
              </ul>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Sharing
              Your Data with Third Parties:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              may share your data with third parties under certain circumstances, such as:</span></p>

              <ul className={"MsoNormal"} type="disc">
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Service providers:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Partnering
                  with data storage providers, legal advisors, and other entities who assist
                  us in delivering our services.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Regulatory authorities and law
                  enforcement:</span></b><span lang="EN-GB" style={{fontSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;
                  Sharing data when required by law or legal proceedings.</span></li>
              <li className={"MsoNormal"} style={{color:'#1F1F1F', marginBottom:'0cm',  
                  lineHeight:'normal', background: 'white'}}><b><span lang="EN-GB" className={"MsoNormal"}>Financial institutions:</span></b><span
                  lang="EN-GB" style={{fongSize:'12.0pt', fontFamily:'"Arial",sans-serif'}}>&nbsp;Collaborating
                  with auditors and other financial institutions in compliance with
                  regulations.</span></li>
              </ul>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              ensure appropriate safeguards are in place to protect your data during
              transfers, including standard Data Protection clauses approved by the DIFC Data
              Protection Commissioner.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Your
              Rights Under the Data Protection Law and Regulation:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>You
              have the right to access, rectify, and erase your personal data, restrict its
              processing, object to its processing, and request its portability. We encourage
              you to exercise these rights and understand your control over your personal
              information.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Data
              Retention:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              retain your data for a limited period based on legal requirements and business
              needs. We will erase your data when it is no longer necessary for the purposes
              it was collected.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Marketing
              Opt-Out:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>You
              can easily opt-out of marketing communications by managing your account
              settings or contacting us directly. We will respect your preferences and
              refrain from sending you unsolicited marketing messages.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Data
              Breach Notification:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>In
              the unlikely event of a data breach, we have procedures in place to promptly
              identify, report, and address the issue. We will notify you promptly in
              accordance with the Data Protection Law and Regulation.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Contact
              Us:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>If
              you have any questions or concerns about your data or this policy, please feel
              free to contact our Data Protection Officer at <a className = {"MsoNormal"} href="mailto:DPO@ark-cap.com">DPO@ark-cap.com</a>. We
              are committed to transparent communication and addressing your inquiries
              promptly.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Changes
              to this Policy:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>We
              may update this policy periodically to reflect changes in legal requirements,
              our practices, or the Services. We will notify you of any significant changes.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><b><span
              lang="EN-GB" className={"subHeading"}>Your
              Trust Matters:</span></b></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>At
              Ark Capital, we value your trust and are committed to protecting your privacy.
              We strive to be transparent about our data practices and ensure your
              information is handled responsibly and lawfully. By using our Services, you
              acknowledge and agree to the terms of this policy.</span></p>

              <p className={"MsoNormal"} style={{lineHeight:'normal', background:'white'}}><span
              lang="EN-GB" className={"subHeading"}>This
              rewritten policy provides a more comprehensive and flowing narrative while
              covering the essential information. Remember to personalize it by filling in
              specific details and consulting with legal professionals for complete
              compliance.</span></p>

              <p className={"MsoNormal"}><span lang="EN-GB">&nbsp;</span></p>


      </div>
      </Container>
    </main>
  );
};

export default ProtectionPolicy;
