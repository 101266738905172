import { useRef, useState, useEffect, useContext } from "react";
// UI
import Container from "components/Container/Container";
import Spinner from "components/UI/Spinner";
// data
// new components
import NewsBox from "./Components/NewsBox";
import NewsHeader from "./Components/NewsHeader";

import GetAllBlog from "utils/Insigths/Blog/GetAllBlog";
import { LanguageContext } from "context/LanguageContext";

/**
 * News Component
 *
 * This component displays a section of news articles or blog posts fetched from the server.
 * It fetches blog data using the `GetAllBlog` utility function and renders a list of `NewsBox`
 * components containing the article's image, title, and short description. While the data is
 * being fetched, a spinner is displayed. The component also includes an animation that triggers
 * when the section comes into view on the screen.
 */
const News = () => {
  // State for saving fetched blogs
  const [blogs, setBlogs] = useState([]);
  // State to render spinner while fetch is loading
  const [isLoading, setIsLoading] = useState(false);
  // language state
  const { language } = useContext(LanguageContext);

  // Ref and state to implement animation
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Intersection Observer Effect
   *
   * This effect sets up an Intersection Observer to track when the `ref` element (section) comes
   * into view on the screen. When it becomes visible, the `isVisible` state is set to true,
   * triggering the animation for the blog section.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  /**
   * Fetch Blogs Effect
   *
   * This effect fetches blog data from the server using the `GetAllBlog` utility function
   * when the component mounts. The fetched data is stored in the `blogs` state.
   */
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setIsLoading(true);
        const data = await GetAllBlog();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, []);

  // Sort blogs by updated_at in descending order (newest to oldest)
  const sortedBlogs = blogs.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  // Get the latest 3 blogs
  const latestBlogs = sortedBlogs.slice(0, 3);

  return (
    <section className="bg-font py-secondary lg:py-primary ">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <NewsHeader />

        {/* Blogs Grid */}
        <div
          ref={ref}
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-secondary gap-x-secondary  transiton ease-in duration-300 ${
            isVisible
              ? "translate-y-[0%] opacity-100"
              : "translate-y-[5%] opacity-0"
          } `}
        >
          {/* Render Blogs or Spinner */}
          {isLoading ? (
            // If blogs are still loading, display the spinner
            <div className="flex flex-col  gap-2 text-white">
              <Spinner />
              <p>{`${
                language === "en"
                  ? "Please wait while the blogs are being fetched..."
                  : "Подождите, пока блог загружается..."
              }`}</p>
            </div>
          ) : blogs.length !== 0 ? (
            // If blogs are fetched, render the NewsBox components
            latestBlogs?.map((content, index) => (
              <NewsBox
                key={index}
                id={content?.id}
                image={content?.fullPath}
                text={`${
                  language === "en"
                    ? content?.short_description
                    : content?.short_description_ru
                }`}
                title={`${
                  language === "en" ? content.title : content.title_ru
                }`}
              />
            ))
          ) : (
            // If no blogs are fetched, render a message
            <p className="text-white">{`${
              language === "en" ? "No Blogs found." : "Блоги не найдены."
            }`}</p>
          )}
        </div>
      </Container>
    </section>
  );
};

export default News;
