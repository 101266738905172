import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
// content
import getAdvisoryContent from "constant/services/Advisory/AdvisoryBlog";

const DiscoverMoreBlog = () => {
  const { language } = useContext(LanguageContext);
  const content = getAdvisoryContent(language);
  return (
    <section className="mt-secondary lg:mt-primary py-secondary bg-blue text-white">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="w-full flex flex-col-reverse lg:flex-row items-center gap-small lg:gap-secondary">
          <div className="flex-1 flex flex-col gap-small text-lg">
            {content.text.map((text, index) => (
              <p className="text-lg" key={index}>
                {text}
              </p>
            ))}
          </div>
          <div className="flex-1">
            <img
              className="w-full object-cover rounded-md drop-shadow-md"
              src={content.image}
              alt=""
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DiscoverMoreBlog;
