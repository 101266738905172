const AboutUsSectionContent = {
  en: [
    {
      header: "Our Mission",
      text: "ARK Capital offers unparalleled custody and advisory services with seamless access to global markets. Our mission is to grow together with our clients.",
      to: "/about-us/team",
      button: "Discover Ark",
    },
    {
      header: "Location Matters",
      text: "ARK Capital's Dubai location offers liquidity and connectivity to global markets. Our offices are in DIFC, a financial hub for banking and trading in the Middle East.",
      to: "/about-us/supervison",
      button: "Discover Our Location",
    },
    {
      header: "Corporate Ethos",
      text: "ARK Capital's culture is built on principles of humility, alignment, and integrity. We manage assets and deliver results for clients by living up to these values.",
      to: "/about-us/ethos",
      button: "Discover our Ethos",
    },
  ],
  ru: [
    {
      header: "Наша миссия",
      text: "ARK Capital предлагает беспрецедентные депозитарные и консультационные услуги с беспрепятственным доступом к мировым рынкам. Наша миссия - расти вместе с нашими клиентами.",
      to: "/about-us/team",
      button: "Узнайте больше",
    },
    {
      header: "Местоположение имеет значение",
      text: "Расположение ARK Capital в Дубае обеспечивает ликвидность и связь с мировыми рынками. Наши офисы находятся в DIFC, финансовом центре банковского дела и торговли на Ближнем Востоке",
      to: "/about-us/supervison",
      button: "Узнайте больше",
    },
    {
      header: "Корпоративный дух",
      text: "Культура ARK Capital построена на принципах смирения, уравновешенности и честности. Мы управляем активами и добиваемся результатов для клиентов, придерживаясь этих ценностей.",
      button: "Узнайте больше",
    },
  ],
};

const getWhyArkContent = (language) => {
  return AboutUsSectionContent[language];
};

export default getWhyArkContent;
