import BASE_API_URL from "../BaseUrl";

const performSaveForm = async (formData, token) => {
  try {
    const data = new FormData();
    data.append("data", JSON.stringify(formData));

    const response = await fetch(`${BASE_API_URL}/save-form`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    const responseData = await response.json();
    if (!response.ok) {
      console.log("Failed to perform Save Data with ARK");
      return responseData;
    } else {
      console.log("Save data with ARK successful");
      return responseData;
    }
  } catch (error) {
    throw new Error("Failed to perform Save data.");
  }
};

export default performSaveForm;
