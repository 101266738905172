import { useContext } from "react";

// sales force Form
import SalesForceForm from "./Sales Force Forms/SalesForceForm";
// register Form
import Register from "./Ark Registration/Register";

import { UserInFoContext } from "../../context/UserContext";

// component to toggle between registration and sales force forms
const RegisterationRoute = () => {
  // context state
  const { isLoggedIn } = useContext(UserInFoContext);

  return <div>{isLoggedIn ? <SalesForceForm /> : <Register />}</div>;
};

export default RegisterationRoute;
