import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
// UI
import Container from "components/Container/Container";
// content
import getARKProPlafromContent from "constant/services/ArkPro/ArkProPlatform";
const ArkProPlatfom = () => {
  const { language } = useContext(LanguageContext);
  const content = getARKProPlafromContent(language);
  return (
    <section className="mt-secondary lg:mt-primary py-secondary bg-newBlue text-white">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="w-full flex flex-col lg:flex-row items-center gap-small lg:gap-secondary">
          <div className="flex-1 flex flex-col gap-small text-lg text-white">
            {content.text.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
          <div className="flex-1">
            <img
              src={content.image}
              alt=""
              className="w-full object-cover rounded-md drop-shadow-md"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ArkProPlatfom;
