import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
// cokkies
import Cookies from "js-cookie";
// UI
import Container from "components/Container/Container";
// context
import { LanguageContext } from "context/LanguageContext";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(!Cookies.get("cookieConsent"));
  const location = useLocation();
  const isCookiePolicyRoute = location.pathname === "/cookie-policy";
  const shouldShowBanner = showBanner && !isCookiePolicyRoute;

  const { language } = useContext(LanguageContext);

  const handleAccept = () => {
    Cookies.set("cookieConsent", true, { expires: 365 }); // Set the cookie with an expiration date of 1 year
    setShowBanner(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {shouldShowBanner && (
        <div className="bg-font text-white fixed bottom-0 z-[1000] w-full py-4 ">
          <Container
            className={
              "px-smallContainer lg:px-mainContainer flex lg:items-center justify-between gap-x-small md:gap-x-secondary lg:gap-x-primary"
            }
          >
            <div className="text-xs md:text-sm lg:text-base">
              <p>
                {language === "en"
                  ? "  By using our website you agree to our use of cookies in accordance with our"
                  : "Используя наш веб-сайт, вы соглашаетесь с использованием нами файлов cookie в соответствии с нашей"}
                <Link
                  onClick={scrollToTop}
                  className="text-blue underline"
                  to={"/cookie-policy"}
                >
                  {" "}
                  {language === "en"
                    ? " cookie policy."
                    : "Политика файлов cookie"}
                </Link>
              </p>
            </div>

            <div>
              <button
                onClick={handleAccept}
                className="border border-white px-4 tracking-wider py-1.5 rounded-md"
              >
                {language === "en" ? "Confirm" : "Подтвердить"}
              </button>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;
