import { useRef, useState, useEffect } from "react";
// UI
import Container from "../../components/Container/Container";
import "./Header.css";

// header component
import Navigation from "./components/Desktop/Navigation";
import Logo from "./components/Logo";
import MobileNavBtn from "./components/Mobile/MobileBtn";
import MobileNavigation from "./components/Mobile/MobileNavigation";
// import LanguageButton from "./components/LanguageButton";

const Header = () => {
  const ref = useRef();
  const [mobileNavIsActive, setMobileNavIsActive] = useState(false);
  const [headerIsSticky, setHeaderIsSticky] = useState(false);

  const mobileNavHandler = () => {
    setMobileNavIsActive((cur) => !cur);
  };

  useEffect(() => {
    const handleHeader = () => {
      if (window.scrollY > 200) {
        setHeaderIsSticky(true);
      }
      if (window.scrollY === 0) {
        setHeaderIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleHeader);
    return () => {
      window.removeEventListener("scroll", handleHeader);
    };
  }, []);

  return (
    <header
      className={`header relative   ${headerIsSticky ? "stikcy  " : ""}`}
      ref={ref}
    >
      <Container>
        <div
          className={`flex items-center justify-between   ${
            headerIsSticky
              ? "flex-row items-center"
              : "lg:flex-col lg:items-start"
          }`}
        >
          <div
            className={`flex items-center justify-between  ${
              mobileNavIsActive ? "bg-font" : ""
            } lg:bg-white w-full z-[1000]  ${
              headerIsSticky ? " py-1 " : "py-3"
            }`}
          >
            <Logo headerIsSticky={headerIsSticky} />
            <MobileNavBtn
              onClick={mobileNavHandler}
              active={mobileNavIsActive}
            />
            {/* <LanguageButton headerIsSticky={headerIsSticky} /> */}
          </div>

          <Navigation headerIsSticky={headerIsSticky} />

          <MobileNavigation
            onClick={mobileNavHandler}
            active={mobileNavIsActive}
          />
        </div>
      </Container>
    </header>
  );
};

export default Header;
