import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext.js";
// UI
import Container from "components/Container/Container";
// conent
import getSuperVisionHeaderContent from "constant/About/Supervision/SupervionHeaderContent";
// arrow
import { ArrowFatRight } from "@phosphor-icons/react";

const Supervisionheader = () => {
  const { language } = useContext(LanguageContext);
  const content = getSuperVisionHeaderContent(language);
  return (
    <section className="mb-secondary lg:mb-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <h1 className="text-font text-xl mb-secondary">{content.title}</h1>
        <ul className="grid grid-cols-1  md:grid-cols-2 gap-small md:gap-secondary">
          {content.services.map((item, index) => (
            <li
              className="text-font text-lg flex items-center gap-3"
              key={index}
            >
              <ArrowFatRight size={22} color="#0b72ba" /> {item.text}
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default Supervisionheader;
