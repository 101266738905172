const riskWarningFirstChunk = {
  en: [
    {
      title: "Risk Warning",
      content: [
        "This notice cannot disclose all the risks associated with the products we make available to you. You should not invest in or deal in any financial product unless you understand its nature and the extent of your exposure to risk. You should also be satisfied that it is suitable for you in the light of your circumstances and financial position. Different investment products have varied levels of exposure to risks and to different combinations of risks.",
        "Our services relate to certain investments whose prices are dependent on fluctuations in the financial markets outside our control. Investments and the income from them may go down as well as up and you may get back less than the amount you have invested. Past performance is not a guide to future performance.",
        "CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs, FX, or any of our other products work and whether you can afford to take the high risk of losing your money.",
        "Trading in the products and services of ARK Capital may, even if made in accordance with a Recommendation, result in losses as well as profits.",
        "Trading risks are magnified by leverage – losses can exceed your deposits. Margin calls may be made quickly or frequently, especially in times of high volatility, and if you cannot meet them, your positions may be closed out and any shortfall will be borne by you. Values may fluctuate significantly in times of high volatility or market/economic uncertainty; such swings are even more significant if your positions are leveraged and may also adversely affect your position. Trade only after you have acknowledged and accepted the risks. You should carefully consider whether trading in leveraged products is appropriate for you based on your financial circumstances and seek independent financial consultation.",
        "Speculative trading is not suitable for all investors.",
        "Any mentioning, if any, in a Publication of the risks pertaining to a particular product or service may not and should neither be construed as a comprehensive disclosure nor full description of all risks pertaining to such product or service, and ARK Capital strongly encourages any recipient considering trading in its products and services to employ and continuously consult suitable financial advisors prior to the conclusion of any investment or transaction.",
      ],
    },
  ],
  ru: [
    {
      title: "Предупреждение о рисках",
      content: [
        "Это уведомление не может раскрывать все риски, связанные с продуктами, которые мы предоставляем вам. Вы не должны инвестировать или торговать каким-либо финансовым продуктом, если вы не понимаете его природу и степень вашей экспозиции к риску. Вы также должны быть уверены, что он подходит вам в свете ваших обстоятельств и финансового положения. Различные инвестиционные продукты имеют разные уровни риска и разные комбинации рисков.",
        "Наши услуги относятся к определенным инвестициям, цены на которые зависят от колебаний финансовых рынков, которые находятся вне нашего контроля. Инвестиции и доходы от них могут как расти, так и уменьшаться, и вы можете получить меньше, чем вложили. Прошлая производительность не является руководством к будущей производительности.",
        "Контракты на разницу (CFD) - это сложные инструменты, сопряженные с высоким риском быстрой потери средств из-за кредитного плеча. Вы должны обдумать, понимаете ли вы, как работают CFD, FX или любой из наших других продуктов, и можете ли позволить себе высокий риск потери ваших денег.",
        "Торговля продуктами и услугами ARK Capital может, даже если она проводится в соответствии с Рекомендацией, привести как к убыткам, так и к прибыли.",
        "Риски торговли усугубляются кредитным плечом - убытки могут превысить ваши депозиты. Звонки по марже могут быть сделаны быстро или часто, особенно в периоды высокой волатильности, и если вы не можете их покрыть, ваши позиции могут быть закрыты, и недостаток будет покрыт вами. Стоимость может сильно колебаться в периоды высокой волатильности или рыночной/экономической неопределенности; такие колебания еще более значительны, если ваши позиции кредитованы, и они также могут неблагоприятно повлиять на ваше положение. Торгуйте только после того, как вы осознали и приняли риски. Вы должны внимательно рассмотреть, подходит ли торговля кредитованными продуктами вам с учетом ваших финансовых обстоятельств и проконсультироваться у независимых финансовых консультантов.",
        "Спекулятивная торговля не подходит для всех инвесторов.",
        "Любое упоминание, если оно есть, в Публикации о рисках, относящихся к конкретному продукту или услуге, не может и не должно рассматриваться как полное раскрытие или полное описание всех рисков, относящихся к такому продукту или услуге, и ARK Capital настоятельно рекомендует каждому получателю, рассматривающему торговлю продуктами и услугами ARK Capital, использовать и непрерывно консультироваться с соответствующими финансовыми советниками перед заключением какой-либо инвестиции или сделки.",
      ],
    },
  ],
};
const getriskWarningFirstChunk = (language) => {
  return riskWarningFirstChunk[language];
};
export { getriskWarningFirstChunk };

export const RiskWarningNoRecommendation = {
  en: {
    header: "No recommendations",
    list: {
      title:
        "ARK Capital does not in any of its Publications take into account any particular recipient’s investment objectives, special investment goals, financial situation, and specific needs and demands. Therefore, all Publications of ARK Capital are, unless otherwise specifically stated, intended for informational and/or marketing purposes only and should not be construed as:",
      list: [
        "business, financial, investment, hedging, legal, regulatory, tax or accounting advice,",
        "a recommendation or trading idea, or any other type of encouragement to act, invest or divest in a particular manner (collectively “Recommendations”).",
      ],
    },
    content: [
      "ARK Capital shall not be responsible for any loss arising from any investment based on a perceived Recommendation.",
    ],
  },
  ru: {
    header: "Отсутствие рекомендаций",
    list: {
      title:
        "ARK Capital в своих Публикациях не учитывает цели инвестиций, специальные инвестиционные цели, финансовое положение и конкретные потребности и требования конкретного получателя. Поэтому все Публикации ARK Capital, если не оговорено иное, предназначены исключительно для информационных и/или маркетинговых целей и не должны рассматриваться как:",
      list: [
        "бизнес-, финансовый, инвестиционный, хеджирования, юридический, регуляторный, налоговый или бухгалтерский совет,",
        "рекомендация или торговая идея или любой другой вид поощрения к действию, инвестированию или отчуждению в определенном порядке (в совокупности “Рекомендации”).",
      ],
    },
    content: [
      "ARK Capital не несет ответственности за убытки, возникшие в результате инвестиций, основанных на воспринимаемой Рекомендации.",
    ],
  },
};
const getRiskWarningNoRecommendation = (language) => {
  return RiskWarningNoRecommendation[language];
};
export { getRiskWarningNoRecommendation };

export const RiskWarningContentList = {
  en: [
    {
      title: "Currency risk",
      content: [
        "Investments denominated in a currency other than dollars or ones that undertake transactions on foreign markets, which include the financial markets of developing countries (Emerging Markets), may expose you to greater risks caused by fluctuations in foreign exchange rates. This can adversely affect the value of your return and the value of your investment. Investments in emerging markets are exposed to additional risks, including accelerated inflation, exchange rate fluctuations, adverse repatriation laws and fiscal measures, and macroeconomic and political factors.",
      ],
    },
    {
      title: "Investment manager risks",
      content: [
        "This is the risk of loss from the poor performance of the fund managers in your portfolio as well as by us in the management of your managed or advised portfolio.",
      ],
    },
    {
      title: "Liquidity risk",
      content: [
        "There may be difficulty in selling an investment caused by a number of factors, including but not limited to insolvency of the investment, adverse stock market conditions, selling restrictions placed on funds by their managers (sometimes referred to as gating, lockups, notice periods or suspension of redemptions). In these circumstances you may not be able to sell such investments in a timely manner and the value of those investments may fall significantly.",
      ],
    },
    {
      title: "Stabilization/Initial public offerings (IPOs)/New issues",
      content: [
        "When securities are newly issued, the market price is sometimes artificially maintained by the issuer during the period when a new issue is to be sold to the public. This is known as stabilization and may affect not only the price of the new issue but also the price of other securities relating to it. Stabilization is allowed, as long as a strict set of rules is followed, in order to counter the prospect of a drop in price before buyers can be found. The overall effect of this process may be to keep the price at a higher level than it would otherwise be during the period of stabilization.",
      ],
    },
    {
      title: "Suspensions of trading",
      content: [
        "Under certain trading conditions it may be difficult or impossible to liquidate a position. This may occur, for example, at times of rapid price movement if the price rises or falls in one trading session to such an extent that under the rules of the relevant exchange trading is suspended or restricted.",
      ],
    },
    {
      title: "Clearing house protections",
      content: [
        "On many exchanges, the settlement of a transaction is ‘guaranteed’ by the exchange or clearing house. However, this guarantee may not protect you if your investment manager or another party defaults on its obligations to the exchange.",
      ],
    },
    {
      title: "Insolvency",
      content: [
        "Your investment manager’s insolvency or default, or that of any other brokers involved with your transaction, may lead to positions being liquidated or closed out without your consent. In certain circumstances, you may not get back the actual assets which you lodged as collateral and you may have to accept any available payments in cash. On request, your investment manager must provide an explanation of the extent to which it will accept liability for any insolvency of, or default by, other firms involved with your transactions.",
      ],
    },
    {
      title: "Self-directed investment (execution-only services)",
      content: [
        "Self-directed investment, where investors make their own investment decisions and transactions are made on an execution-only basis, is not for everybody. Investors who choose to invest in this manner should regularly review their portfolio, or seek professional advice, to ensure that the underlying assets remain in line with their investment objectives. This can be particularly important for those investing towards a defined time horizon – for example, those investing for retirement via a pension.",
      ],
    },
    {
      title: "Internet Trading Risks",
      content: [
        "There are risks associated with utilizing an Internet-based deal execution trading system including, but not limited to, the failure of hardware, software, and Internet connection. Since ARK Capital does not control signal power, its reception or routing via Internet, configuration of your equipment or reliability of its connection, we cannot be responsible for communication failures, distortions or delays when trading via the Internet. ARK Capital employs backup systems and contingency plans to minimize the possibility of system failure, and trading via telephone is available.",
        "This list is not intended to be fully inclusive of all relevant risks; we would strongly encourage you to ensure that you have read all relevant literature, and that you are comfortable that you understand all of the associated risks relating to an investment, before you decide whether or not to purchase it.  Should you be in any doubt as to the risks involved, or to the suitability of a particular investment, you should seek professional financial advice.",
      ],
    },
  ],
  ru: [
    {
      title: "Валютный риск",
      content: [
        "Инвестиции, выраженные в валюте, отличной от доллара, или совершающие сделки на иностранных рынках, включая финансовые рынки развивающихся стран (развивающиеся рынки), могут подвергнуть вас большему риску из-за колебаний валютных курсов. Это может негативно сказаться на стоимости вашей прибыли и стоимости ваших инвестиций. Инвестиции в развивающиеся рынки подвержены дополнительным рискам, включая ускоренную инфляцию, колебания валютных курсов, неблагоприятное законодательство о репатриации и фискальные меры, а также макроэкономические и политические факторы.",
      ],
    },
    {
      title: "Риски инвестиционного менеджера",
      content: [
        "Это риск убытков из-за плохой производительности управляющих фондом в вашем портфеле, а также у нас в управлении вашим управляемым или консультированным портфелем.",
      ],
    },
    {
      title: "Риск ликвидности",
      content: [
        "Могут возникнуть трудности с продажей инвестиций из-за ряда факторов, включая, но не ограничиваясь, несостоятельностью инвестиций, неблагоприятными условиями на фондовом рынке, ограничениями на продажу, налагаемыми на фонды их менеджерами (иногда называемыми блокировкой, ограничением на продажу, уведомительными периодами или приостановкой выкупа). В таких обстоятельствах вы можете не иметь возможности продать такие инвестиции своевременно, и стоимость этих инвестиций может значительно упасть.",
      ],
    },
    {
      title: "Стабилизация/Первичные публичные предложения (IPO)/Новые выпуски",
      content: [
        "Когда ценные бумаги только выпускаются, цена на рынке иногда искусственно поддерживается эмитентом в период, когда новое предложение должно быть продано общественности. Это называется стабилизацией и она может повлиять не только на цену нового предложения, но также на цену других ценных бумаг, связанных с ним. Стабилизация допускается, если соблюдаются строгие правила, чтобы предотвратить падение цен до тех пор, пока не найдутся покупатели. Общий эффект этого процесса может заключаться в том, что цена будет на более высоком уровне, чем это было бы в противном случае во время стабилизации.",
      ],
    },
    {
      title: "Приостановка торговли",
      content: [
        "При определенных условиях торговлю можно приостановить или сделать практически невозможной. Это может произойти, например, в периоды быстрого движения цен, если цена поднимается или опускается за одну торговую сессию настолько, что по правилам соответствующей биржи торговлю приостанавливают или ограничивают.",
      ],
    },
    {
      title: "Защита клиринговой палаты",
      content: [
        "На многих биржах расчет сделки 'гарантирован' биржей или клиринговой палатой. Тем не менее, эта гарантия может не защитить вас, если ваш инвестиционный менеджер или другая сторона не выполняет свои обязательства перед биржей.",
      ],
    },
    {
      title: "Несостоятельность",
      content: [
        "Банкротство или дефолт вашего инвестиционного менеджера или других брокеров, участвующих в вашей сделке, может привести к ликвидации или закрытию позиций без вашего согласия. В определенных обстоятельствах вы можете не вернуть фактические активы, которые вы внесли в качестве залога, и вам, возможно, придется принять любые доступные выплаты наличными. По запросу ваш инвестиционный менеджер должен предоставить объяснение того, в какой степени он будет нести ответственность за банкротство или дефолт других фирм, участвующих в ваших сделках.",
      ],
    },
    {
      title: "Инвестиции по собственному усмотрению (услуги по исполнению)",
      content: [
        "Инвестиции по собственному усмотрению, где инвесторы принимают собственные решения об инвестициях, и сделки проводятся исключительно на условиях исполнения, не подходят для всех. Инвесторы, выбравшие такой способ инвестирования, должны регулярно проверять свой портфель или обращаться за профессиональной консультацией, чтобы убедиться, что базовые активы соответствуют их инвестиционным целям. Это может быть особенно важно для тех, кто инвестирует на определенный срок, например, тех, кто инвестирует на пенсию через пенсионный фонд.",
      ],
    },
    {
      title: "Риски интернет-торговли",
      content: [
        "Существуют риски, связанные с использованием системы сделок через интернет, включая, но не ограничиваясь, сбои оборудования, программного обеспечения и интернет-соединения. Поскольку ARK Capital не контролирует мощность сигнала, его прием или маршрутизацию через Интернет, настройку вашего оборудования или надежность его соединения, мы не несем ответственности за сбои в связи, искажения или задержки при торговле через Интернет. ARK Capital использует резервные системы и планы аварийных ситуаций для минимизации возможности сбоя системы, и доступна торговля по телефону.",
        "Этот список не претендует на полноту всех соответствующих рисков; мы настоятельно рекомендуем вам убедиться, что вы прочитали всю соответствующую литературу и удостоверились, что вы понимаете все связанные с инвестициями риски, прежде чем принимать решение о покупке или отказе от покупки. Если у вас возникают сомнения относительно рисков или соответствия конкретной инвестиции, вы должны обратиться за профессиональной финансовой консультацией.",
      ],
    },
  ],
};
const getRiskWarningContentList = (language) => {
  return RiskWarningContentList[language];
};
export { getRiskWarningContentList };
