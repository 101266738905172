import Hero from "./Hero/Hero";
import WhyArk from "./Why-Ark/WhyArk";
// image
import ArkImage from "./Ark Image/ArkImage";
// about
import About from "./About/About";

import News from "./News/News";
import FaQ from "./FAQ/FaQ";

const Home = () => {
  return (
    <main>
      <Hero />
      <WhyArk />

      <ArkImage />
      <About />
      <News />
      <FaQ />
    </main>
  );
};

export default Home;
