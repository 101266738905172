import image from "../../../assests/images/FAQ.jpg";
import Container from "../../../components/Container/Container";

const FaQImage = () => {
  return (
    <Container>
      <div className="w-full">
        <img src={image} alt="" className="w-full h-full object-cover" />
      </div>
    </Container>
  );
};

export default FaQImage;
