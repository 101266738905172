import { useEffect, useState } from "react";
import Container from "../../../components/Container/Container";
import Spinner from "../../../components/UI/Spinner";
import Owner from "../../About us/Team/components/Owner";
import GetAllVideos from "../../../utils/Insigths/News/GetAllVideos";
import YouTube, { YouTubeProps } from "react-youtube";

const formatDate = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const Media = () => {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 3;
  const [isLoading, setIsLoading] = useState(false);

  console.log(videos);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await GetAllVideos();

        const sortedVideos = data?.data
          .map((video) => ({
            ...video,
            date: new Date(video.date),
          }))
          .sort((a, b) => b.date - a.date);

        setVideos(sortedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getVideoIdFromURL = (url) => {
    const videoIdRegex =
      /(?:https?:\/\/(?:www\.)?)?youtube(?:\.googleapis)?\.(?:com|be)\/(?:watch\?v=|embed\/|v\/|user\/(?:\S+\/)?(?:[\\&\\?\\#]t=)?)([\w-]{11})/;
    const match = url.match(videoIdRegex);
    return match ? match[1] : null;
  };

  const opts = {
    height: "450px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const totalPages = Math.ceil(videos.length / videosPerPage);
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const nextPage = () => {
    scrollToTop();
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    scrollToTop();
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <section className="my-primary">
      <Container
        className={"px-smallContainer lg:px-mainContainer overflow-hidden"}
      >
        {videos?.length === 0 && (
          <>
            <div className="flex flex-col items-center justify-center gap-2 mb-secondary lg:w-1/2 lg:mx-auto">
              {isLoading ? (
                <>
                  <Spinner />
                  <p className="text-center text-lg">
                    Thank you for your patience as we retrieve the videos. In
                    the meantime, take this opportunity to acquaint yourself
                    with the distinguished owner of Ark Capital.
                  </p>
                </>
              ) : (
                <p className="text-center text-lg">
                  No video found. <br />
                  Take this opportunity to acquaint yourself with the
                  distinguished owner of Ark Capital.
                </p>
              )}
            </div>

            <Owner />
          </>
        )}

        {videos?.length !== 0 && (
          <>
            <div>
              <div className="lg:w-3/4 lg:mx-auto">
                <h1 className="text-font text-2xl sm:text-3xl lg:text-4xl text-center">
                  Inside the Mind of a Visionary: Captivating Interviews with
                  Our Company's Owner on Bloomberg
                </h1>
              </div>
            </div>

            <div className="mt-primary grid grid-cols-1  justify-between gap-secondary lg:w-3/4">
              {currentVideos.map((content, index) => (
                <div key={index}>
                  <div className="text-font flex flex-col">
                    <p className="italic">{formatDate(content.date)}</p>
                    <h2 className="text-xl font-semibold mt-2 mb-4">
                      {content.title}
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{ __html: content.description }}
                    ></p>

                    <div className="mt-6">
                      <YouTube
                        opts={opts}
                        videoId={getVideoIdFromURL(`${content.url}`)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-primary">
              <button
                className="mx-2 py-2 px-4 rounded bg-blue text-white"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {generatePageNumbers().map((pageNumber, index) => (
                <button
                  key={index}
                  className={`mx-2 py-2 px-4 rounded ${
                    currentPage === pageNumber
                      ? "bg-blue text-white"
                      : "bg-gray-300"
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}

              <button
                className="mx-2 py-2 px-4 rounded bg-blue text-white"
                onClick={nextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </>
        )}
      </Container>
    </section>
  );
};

export default Media;
