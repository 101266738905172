import {
  PuzzlePiece,
  Handshake,
  Swap,
  ShieldCheck,
} from "@phosphor-icons/react";

const custodySecurityContent = {
  en: {
    header: {
      title: "Extended Security",
      text: "At Ark Capital Management, we go the extra mile to offer our clients extended security that goes beyond the norm. By thinking outside the box we present exciting new ventures:",
    },
    list: [
      {
        title: "SafeGuard",
        text: "Segregating client cash and assets in a Trust account held with our custodian, providing an added layer of protection",
        icon: <PuzzlePiece size={54} weight="light" />,
      },
      {
        title: "Diversify",
        text: "Allowing asset and currency conversion into alternative currencies and assets",
        icon: <Swap size={54} weight="light" />,
      },
      {
        title: "CustodyPro",
        text: "Offering additional custody services, including multi-asset and currency custody in a neutral jurisdiction and counterparties.",
        icon: <Handshake size={54} weight="light" />,
      },
      {
        title: "Confidentiality",
        text: "Ensuring strict confidentiality to safeguard your sensitive information",
        icon: <ShieldCheck size={54} weight="light" />,
      },
    ],
  },
  ru: {
    header: {
      title: "Расширенная система безопасности",
      text: "В Ark Capital Management мы делаем все возможное, чтобы предложить нашим клиентам расширенный уровень безопасности, выходящий за рамки нормы. Мысля нестандартно, мы представляем новые захватывающие проекты:",
    },
    list: [
      {
        title: "защита",
        text: "Разделение денежных средств и активов клиента на доверительном счете, хранящемся у нашего кастодиана, обеспечивает дополнительный уровень защиты",
        icon: <PuzzlePiece size={54} weight="light" />,
      },
      {
        title: "Дизерфикация активов",
        text: "Возможность конвертации активов и валют в альтернативные валюты и активы",
        icon: <Swap size={54} weight="light" />,
      },
      {
        title: "CustodyPro",
        text: "Предлагая дополнительные услуги по хранению, включая хранение нескольких активов и валюты в нейтральной юрисдикции и у контрагентов.",
        icon: <Handshake size={54} weight="light" />,
      },
      {
        title: "Конфиденциальность",
        text: "Обеспечение строгой конфиденциальности для защиты вашей конфиденциальной информации",
        icon: <ShieldCheck size={54} weight="light" />,
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getCustodySecurityContent = (language) => {
  return custodySecurityContent[language];
};

export default getCustodySecurityContent;
