// supervison component
import Supervisionheader from "./Component/Supervisionheader";
import ImageGrid from "./Component/ImageGrid";
import SuperviosonContent from "./Component/SuperviosonContent";

const Supervision = () => {
  return (
    <main className="py-primary">
      <Supervisionheader />
      <ImageGrid />
      <SuperviosonContent />
    </main>
  );
};

export default Supervision;
