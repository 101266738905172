import { useState, useContext, useEffect } from "react";
import Spinner from "../../../../components/UI/Spinner.jsx";
import Content from "./Components/Content.jsx";
import inputStyle from "./InputsStyle.js";
// Utiles
import performSaveDocumentsData from "../../../../utils/salesforce/SaveDocumentData.js";
import performAuth from "../../../../utils/salesforce/Authantication.js";
import performSetFormStatus from "../../../../utils/account and forms/SetFormStatus.js";
// context
import { UserInFoContext } from "../../../../context/UserContext.js";
import { SalesForceContext } from "../../../../context/SalesForceContext.js";
import { Star } from "@phosphor-icons/react";

const CustomUploadFrom = ({ formInputs, onSubmit }) => {
  const [formState, setFormState] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [saveDataResponse, setSaveDataResponse] = useState("");
  const [formSubmitIsLoding, setFormSubmitIsLoading] = useState(false);
  const [authIsDone, setAuthIsDone] = useState(false);
  // context
  const { accessToken, setAccessToken } = useContext(SalesForceContext);
  const { userData } = useContext(UserInFoContext);
  const token = userData?.token;
  const CIFId = userData?.cifid;
  const pageTitle = formInputs[currentPage]?.inputs[0]?.pageTitle;
  const pageContent = formInputs[currentPage]?.inputs?.find(
    (input) => input.content
  );
  const currentPageInputs = pageContent
    ? formInputs[currentPage]?.inputs.slice(2)
    : formInputs[currentPage]?.inputs.slice(1);

  const changeHandler = async (event) => {
    setIsError(false);

    const selectedFiles = event.target.files;

    let totalSize = 0; // To track the total size of selected files

    for (let i = 0; i < selectedFiles.length; i++) {
      totalSize += selectedFiles[i].size;
      if (selectedFiles[i].size > 5 * 1024 * 1024) {
        alert("File size exceeds 5 MB limit. Please choose a smaller file.");
        event.target.value = ""; // Clear the input
        return;
      }
    }

    if (totalSize > 5 * 1024 * 1024) {
      alert("Total file size exceeds 5 MB limit. Please choose smaller files.");
      event.target.value = ""; // Clear the input
      return;
    }

    const originalName = event.target.name; // Original input name

    // Create new form entries for the selected files
    const newFormEntries = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      const name =
        selectedFiles.length > 1 ? `${originalName}_${i + 1}` : originalName;

      // Read file content and convert to base64
      const reader = new FileReader();
      reader.onload = async () => {
        const base64Data = reader.result.split(",")[1];

        // Create the new form entry
        const newFormEntry = {
          attributes: {
            type: "Attachment",
            referenceId: name,
          },
          contentType: file.type,
          name: name,
          body: base64Data,
          parentId: CIFId,
        };

        newFormEntries.push(newFormEntry);

        // Update formState by replacing entries with the same name
        setFormState((prevState) => {
          const updatedFormState = prevState.filter(
            (entry) => !entry.name.startsWith(originalName)
          );
          return [...updatedFormState, ...newFormEntries];
        });
      };

      reader.readAsDataURL(file);
    }
  };

  // function to make validation if the all require filed have a value before we submit
  const validateInputs = () => {
    let isValid = true;

    currentPageInputs.forEach((input) => {
      if (input.require) {
        const entryExists = formState.some((entry) => {
          return entry.name.startsWith(input.name);
        });

        if (!entryExists) {
          input.error = true;
          setIsError(true);
          isValid = false;
        } else {
          input.error = false;
        }
      }
    });

    return isValid;
  };

  const performAuthHandler = async () => {
    try {
      const authData = await performAuth();

      // if performAuth succefully return, we set access token, and we set formIS to specific form, and we generate the saveFormData to send it via savedata api
      if (!authData.error) {
        setAccessToken(authData.data.access_token);
        setAuthIsDone(true);
      } else {
        setSaveDataResponse("authentication-error");
      }
    } catch (error) {
      console.error(
        "An error occurred while performing autheentication:",
        error
      );

      setSaveDataResponse("authentication-faield");
    }
  };

  useEffect(() => {
    if (accessToken && authIsDone) {
      const finalObjecy = {
        records: formState,
      };

      const jsonString = JSON.stringify(finalObjecy);
      perfromSaveHandler(accessToken, jsonString);
    }
  }, [accessToken, authIsDone, formState]);

  const perfromSaveHandler = async (token, json) => {
    try {
      setFormSubmitIsLoading(true);

      const saveData = await performSaveDocumentsData(token, json);
      if (saveData.hasErrors === false) {
        performMovingFormsHandler();
        return { success: true }; // Indicate success
      } else {
        return { success: false, response: "save-data-error" }; // Indicate failure
      }
    } catch (error) {
      return { success: false, response: "save-data-failed" }; // Indicate failure
    } finally {
      setAuthIsDone(false);
      setFormSubmitIsLoading(false);
    }
  };

  const performMovingFormsHandler = async () => {
    const formStatus = {
      cif: 1,
      fatca: 1,
      document: 1,
    };

    try {
      const data = await performSetFormStatus(token, formStatus);

      if (data.status_code === 200) {
        onSubmit("Upload");
        console.log("set form sucess");
      } else {
        console.log("failed to perform set form");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = async () => {
    const isValid = validateInputs();

    if (!isValid) return;

    performAuthHandler();
  };

  return (
    <div>
      <div className="p-2 bg-blue text-white text-lg ">
        <h1 className="">{pageTitle}</h1>
      </div>
      <form>
        {/* inputs */}
        <div className="border border-gray-400">
          {pageContent && <Content content={pageContent.content} />}

          {currentPageInputs.map((input, index) => {
            return (
              <div
                key={index}
                className={`${inputStyle.boxStyle} flex-col gap-4 lg:flex-row py-6`}
              >
                <label className={inputStyle.labelStyle}>
                  {input.label}{" "}
                  {input.require && (
                    <span className="text-blue flex items-center gap-x-1">
                      <Star size={14} weight="fill" />
                    </span>
                  )}
                </label>

                <span
                  className={`${inputStyle.inputStyle} flex-1 overflow-hidden`}
                >
                  <input
                    type={input.type}
                    name={input.name}
                    id={input.name}
                    onChange={changeHandler}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                    multiple
                    error={""}
                  />

                  {isError && input.error && (
                    <p className="text-sm text-red-500 mt-1">
                      This field is required
                    </p>
                  )}
                </span>
              </div>
            );
          })}
        </div>
        {/* buttons */}

        <div className="mt-6 flex items-center gap-x-3 justify-end">
          {saveDataResponse === "save-data-failed" && (
            <p className="text-sm text-red-500">
              An error occurred during saving data either due to your internet
              connection or from our engine. Please try again. If the error
              persists, please contact us at{" "}
              <a href="mailto:cs@ark-cap.com" className="underline">
                cs@ark-cap.com
              </a>
            </p>
          )}
          {saveDataResponse === "save-data-error" && (
            <p className="text-sm text-red-500">
              Please ensure you have a stable internet connection and try again.
            </p>
          )}
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-blue text-white px-6 py-1.5 rounded-md flex items-center gap-x-2"
          >
            {formSubmitIsLoding && <Spinner />}
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomUploadFrom;
