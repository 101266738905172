import { useContext } from "react";
import { Link } from "react-router-dom";
// content
import getServicesLinks from "constant/Footer/FooterServices";
// context
import { LanguageContext } from "context/LanguageContext.js";

const FooterServices = () => {
  const { language } = useContext(LanguageContext);
  const servicesContent = getServicesLinks();
  const servicesLinks = servicesContent[language];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="lg:mx-auto ">
      <h4 className="text-white text-[15px] font-medium uppercase mb-8">
        {language === "en" ? "ARK Services" : "Услуги ARK"}
      </h4>
      <ul className="flex flex-col gap-3">
        {servicesLinks.map((item, index) => (
          <Link onClick={scrollToTop} key={index} to={item.to}>
            <li className=" text-lightGreyFont text-[14px] hover:text-lightWhiteFont transition ease-in duration-100">
              {item.link}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default FooterServices;
