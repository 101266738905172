import { useState } from "react";

import { CaretDown, CaretUp } from "@phosphor-icons/react";

const BlueBox = ({ title, text, textTwo }) => {
  const [isContentVisble, setIsContentVisible] = useState(false);

  const contentVisibleHanlder = () => {
    setIsContentVisible((cur) => !cur);
  };
  return (
    <div className="flex-1 bg-newBlue p-8 lg:p-14 text-white rounded-md">
      <div
        className={`flex items-center justify-between ${
          isContentVisble ? "mb-small" : ""
        }`}
        onClick={contentVisibleHanlder}
      >
        <h1 className="text-3xl lg:mb-small">{title}</h1>

        <span className="lg:hidden">
          {isContentVisble && <CaretUp size={32} weight="light" />}
          {!isContentVisble && <CaretDown size={32} weight="light" />}
        </span>
      </div>
      <div className={`${isContentVisble ? "block" : "hidden"} lg:block`}>
        <p className="">{text}</p>
        <p className="mt-small">{textTwo}</p>
      </div>
    </div>
  );
};

export default BlueBox;
