import BASE_API_URL from "../BaseUrl";

const perfomSubmitContactUs = async (state) => {
  console.log(state);
  try {
    const response = await fetch(`${BASE_API_URL}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to submit contact form");
      return responseData;
    } else {
      console.log("submit contact form successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform submit contact form");
  }
};

export default perfomSubmitContactUs;
