import { GlobeHemisphereEast, Briefcase, Users } from "@phosphor-icons/react";
const aboutHeroContent = {
  en: {
    text: "Ark Capital Management Limited is a premier asset management and prime brokerage firm based in Dubai, United Arab Emirates. Established in 2013, the company is authorized by the Dubai Financial Services Authority (DFSA) and specializes in crafting tailor-made solutions for high-net-worth",
    textTwo:
      "individuals, investors, traders, asset managers, family offices, and financial professionals across the globe.With our headquarters strategically located in Dubai, we offer unparalleled liquidity and connectivity to global markets. Our main focus is on providing unbiased and independent solutions.",
    icons: [
      <Briefcase size={58} weight="thin" />,
      <GlobeHemisphereEast size={58} weight="thin" />,
      <Users size={58} weight="thin" />,
    ],
  },
  ru: {
    text: "Ark Capital Management Limited - ведущая компания по управлению активами и первоклассная брокерская фирма, базирующаяся в Дубае, Объединенные Арабские Эмираты. Основанная в 2013 году, компания авторизована Управлением финансовых услуг Дубая (DFSA) и специализируется на разработке индивидуальных решений для состоятельных людей.",
    textTwo:
      "частные лица, инвесторы, трейдеры, управляющие активами, семейные офисы и финансовые специалисты по всему миру.Благодаря стратегическому расположению нашей штаб-квартиры в Дубае, мы предлагаем беспрецедентную ликвидность и возможность подключения к мировым рынкам. Наше основное внимание сосредоточено на предоставлении непредвзятых и независимых решений.",
    icons: [
      <Briefcase size={58} weight="thin" />,
      <GlobeHemisphereEast size={58} weight="thin" />,
      <Users size={58} weight="thin" />,
    ],
  },
};

// Function to retrieve content based on the selected language
const getAboutHeroContent = (language) => {
  return aboutHeroContent[language];
};

export default getAboutHeroContent;
