import custodyHeroOne from "../../../assests/images/services/custody/custody-1.jpg";
import custodyHeroTwo from "../../../assests/images/services/custody/custody-2.jpg";

const custodyHeroContent = {
  en: {
    title:
      "Unleash the Full Potential of Your Assets with Ark Capital's Premium Custody Services – Safeguard, Grow, and Thrive with Confidence",
    custodyHero: [
      {
        text: [
          "Ark Capital Management is a fully licensed custodian, offering you complete peace of mind that your assets are secure and well-protected. Our robust regulatory framework guarantees that your investments remain safeguarded and fully segregated at all times.",
          "Our primary custodian is First Abu Dhabi Bank (FAB) - one of the world's largest and safest banks, with an impressive AA- rating from both Standards & Poor's and Fitch.",
        ],
        image: custodyHeroOne,
      },
      {
        text: [
          "FAB is strictly regulated by both the Central Bank of the UAE and the UAE government, ensuring that your assets are held to the highest possible standard of care and protection.",
          "Our coverage includes direct custody services in up to 90+ global and regional markets. When choosing Ark Capital, you're electing to have a strategic partner that supports the markets you wish to access today and in the future.",
        ],
        image: custodyHeroTwo,
      },
    ],
  },
  ru: {
    title:
      "Раскройте весь потенциал ваших активов с помощью услуг по хранению премиум–класса от Ark Capital - защищайте, растите и процветайте с уверенностью",
    custodyHero: [
      {
        text: [
          "Ark Capital Management является полностью лицензированным кастодианом, предлагающим вам полную уверенность в том, что ваши активы находятся в безопасности и хорошо защищены. Наша надежная нормативно-правовая база гарантирует, что ваши инвестиции всегда будут защищены и полностью разделены",
          "Нашим основным депозитарием является First Abu Dhabi Bank (FAB) - один из крупнейших и безопасных банков в мире, имеющий впечатляющий рейтинг AA- как от Standard & Poor's, так и от Fitch.",
        ],
        image: custodyHeroOne,
      },
      {
        text: [
          "Деятельность FAB строго регулируется как центральным банком ОАЭ, так и правительством ОАЭ, гарантируя, что ваши активы находятся в соответствии с максимально возможными стандартами заботы и защиты.",
          "Наш охват включает в себя прямые услуги по хранению на более чем 90 глобальных и региональных рынках. Выбирая Ark Capital, вы выбираете стратегического партнера, который поддерживает рынки, к которым вы хотите получить доступ сегодня и в будущем.",
        ],
        image: custodyHeroTwo,
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getCustodyHeroContent = (language) => {
  return custodyHeroContent[language];
};

export default getCustodyHeroContent;
