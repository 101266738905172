const IressHeader = {
  en: {
    title:
      "Iress is a cutting-edge trading platform that offers a wide range of features and functionalities to empower traders and investors. Here is an overview of the key aspects and benefits of using Iress:",
  },
  ru: {
    title:
      "Iress - это современная торговая платформа, предлагающая широкий спектр функций и возможностей для улучшения возможностей трейдеров и инвесторов. Вот обзор ключевых аспектов и преимуществ использования Iress:",
  },
};

// Function to retrieve content based on the selected language
const getIressHeaderContent = (language) => {
  return IressHeader[language];
};

export default getIressHeaderContent;
