import { useContext } from "react";
// UI
import Container from "components/Container/Container";

import BigList from "../UI/BigList";
import SmallList from "../UI/SmallList";
// Content
import { getDisclaimerFirstChunkContent } from "../../../constant/Disclaimer/Disclaimer/Disclaimer";
import { getNoWarrantyContent } from "constant/Disclaimer/Disclaimer/Disclaimer";
import { getNoRecommendationsContent } from "constant/Disclaimer/Disclaimer/Disclaimer";
import { getContentList } from "constant/Disclaimer/Disclaimer/Disclaimer.js";
import { getlimitationContent } from "constant/Disclaimer/Disclaimer/Disclaimer";
import { getcontentListTwo } from "constant/Disclaimer/Disclaimer/Disclaimer.js";

// context
import { LanguageContext } from "context/LanguageContext";

const Diclaimer = () => {
  const { language } = useContext(LanguageContext);
  const disclaimerFirstChunck = getDisclaimerFirstChunkContent(language);
  const noWarranty = getNoWarrantyContent(language);
  const noRecommendations = getNoRecommendationsContent(language);
  const contentList = getContentList(language);
  const limitation = getlimitationContent(language);
  const contentListTwo = getcontentListTwo(language);

  return (
    <main>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-center text-3xl lg:text-4xl mt-secondary lg:mt-primary ">
          <h1>
            {language === "en"
              ? "Current ARK Capital Disclaimer"
              : "Текущий дисклеймер ARK Capital"}
          </h1>
        </div>

        <div className="mb-primary">
          <section className={"mt-secondary "}>
            <BigList contentList={disclaimerFirstChunck} />
          </section>
          <section className={"mt-secondary "}>
            <BigList contentList={noWarranty} />
          </section>

          <section className={"mt-secondary "}>
            <BigList contentList={noRecommendations} />
          </section>

          <section className={"mt-secondary "}>
            <SmallList contentList={contentList} />
          </section>

          <section className={"mt-secondary "}>
            <BigList contentList={limitation} />
          </section>

          <section className={"mt-secondary "}>
            <SmallList contentList={contentListTwo} />
          </section>
        </div>
      </Container>
    </main>
  );
};

export default Diclaimer;
