import BASE_API_URL from "../BaseUrl";

const performAccountType = async (state, token) => {
  try {
    const response = await fetch(`${BASE_API_URL}/set-type`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(state),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to change Type");
      return responseData;
    } else {
      console.log("change type successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perfom change Type.");
  }
};

export default performAccountType;
