import { useState } from "react";
//ui
import Container from "../../../components/Container/Container";
// registartion forms
import LoginForm from "./Login/LoginForm";
import SignupForm from "./Signup/SignupForm";
import RestPasswordForm from "./Rest Password/RestPasswordForm";

const Register = () => {
  // state and handler to toggle between login and sigunup form
  const [toggleForms, setToggleForms] = useState("sign-in");

  const toggleBetweenFormsHandler = (form) => {
    setToggleForms(form);
  };

  return (
    <main>
      <section className="">
        <Container className={"px-smallContainer lg:px-mainContainer"}>
          {toggleForms === "sign-up" && (
            <SignupForm onTogleForms={toggleBetweenFormsHandler} />
          )}
          {toggleForms === "sign-in" && (
            <LoginForm onTogleForms={toggleBetweenFormsHandler} />
          )}
          {toggleForms === "reset-password" && (
            <RestPasswordForm onTogleForms={toggleBetweenFormsHandler} />
          )}
        </Container>
      </section>
    </main>
  );
};

export default Register;
