import { useEffect, useRef, useState } from "react";
import useConfirmPassword from "../../../../form/Components/Hooks/use-confirm-password";
import useInput from "../../../../form/Components/Hooks/user-input";
import PasswordInput from "../../../../form/Components/Inputs/PasswordInput";
import Spinner from "../../../../components/UI/Spinner";
import inputFromMessage from "../../../../form/Components/Messages/inputErrorMessage";

import performConfirmRestPassword from "../../../../utils/Registartion/ConfirmRestPassword";

const ConfirmRestPassword = ({ onToggle }) => {
  const [formState, setFormState] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const inputStyle =
    "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue ";

  const clearErrorMessage = () => {
    setFormState("");
  };
  const {
    value: passwordInput,
    isValid: passwordIsValid,
    HasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    inputFocusHandler: passwordFocusHandler,
    isFocus: passwordIsFocus,
    reset: passwordReset,
  } = useInput((value) =>
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value.trim(""))
  );

  const {
    value: confirmPasswordInput,
    isValid: confirmPasswordIsValid,
    HasError: confirmPasswordHasError,
    inputChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    inputFocusHandler: confirmPasswordFocusHanlder,
    isFocus: confirmPasswordIsFocus,
    reset: confirmPasswordReset,
  } = useConfirmPassword(passwordInput);

  // input ref
  const codeInputs = useRef([]);
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);

  // handler for tracking inputs in the form
  const handleVerificationCodeChange = (e, index) => {
    const value = e.target.value;

    setVerificationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;

      return newCode;
    });

    if (value !== "" && index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const formIsValid =
    verificationCode.join("").length === 6 &&
    passwordIsValid &&
    confirmPasswordIsValid;

  const submitFormHandler = async (event) => {
    event.preventDefault();
    clearErrorMessage();

    if (!formIsValid) {
      setFormState("empty");
      return;
    }

    const formState = {
      code: verificationCode.join(""),
      password: passwordInput,
      password_confirmation: confirmPasswordInput,
    };

    try {
      setIsloading(true);
      const data = await performConfirmRestPassword(formState);

      if (data.status_code === 422) {
        setFormState("invalid-data");
      } else {
        onToggle("sign-in");
      }
    } catch (error) {
      setFormState("failed");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center lg:w-3/4 mx-auto">
      <p>
        The verification code has been successfully sent to your email. Please
        check your inbox, enter the code, and then proceed to choose your new
        password. Thank you.
      </p>
      <form className="my-secondary flex flex-col gap-small">
        {formState === "empty" && (
          <p className="mb-2 text-red-400 text-sm">
            Please ensure that you have entered a valid data.
          </p>
        )}
        {formState === "invalid-data" && (
          <p className="mb-2 text-red-400 text-sm">
            The selected code is invalid.
          </p>
        )}

        {formState === "failed" && (
          <p className="mb-2 text-red-400 text-sm">
            An error occurred either due to your internet connection or from our
            engine. Please try again. If the error persists, please contact us
            at{" "}
            <a
              href="mailto:cs@ark-cap.com"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              cs@ark-cap.com
            </a>
          </p>
        )}
        <div className="flex items-center justify-center  space-x-4">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="w-16 h-16 bg-white border border-gray-200 rounded-lg flex items-center justify-center shadow-md"
            >
              <input
                ref={(el) => (codeInputs.current[index] = el)}
                type="text"
                id={`code-${index}`}
                name={`code-${index}`}
                value={verificationCode[index] || ""}
                onChange={(e) => {
                  handleVerificationCodeChange(e, index);
                  // clearHandler();
                }}
                maxLength={1}
                className="w-full h-full text-center text-xl bg-transparent outline-none"
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-ssmall">
          <PasswordInput
            inputStyle={inputStyle}
            type={"password"}
            name={"password"}
            id={"password"}
            placeholder={"New password"}
            value={passwordInput}
            onChange={(event) => {
              passwordChangeHandler(event);
              clearErrorMessage();
            }}
            onBlur={passwordBlurHandler}
            hasError={passwordHasError}
            onFocus={passwordFocusHandler}
            isFocus={passwordIsFocus}
            errorMessage={inputFromMessage.passwordSignUp}
          />
          <PasswordInput
            inputStyle={inputStyle}
            name={"confirmPassword"}
            id={"confirmPassword"}
            placeholder={"Confirm password"}
            value={confirmPasswordInput}
            onChange={(event) => {
              confirmPasswordChangeHandler(event);
              clearErrorMessage();
            }}
            onBlur={confirmPasswordBlurHandler}
            onFocus={confirmPasswordFocusHanlder}
            isFocus={confirmPasswordIsFocus}
            hasError={confirmPasswordHasError}
            errorMessage={inputFromMessage.passwordConfirm}
          />
        </div>

        <button
          onClick={submitFormHandler}
          className="w-full bg-blue text-white p-2 rounded-md flex items-center justify-center gap-x-2"
        >
          {isLoading && <Spinner />} Confirm
        </button>
      </form>
    </div>
  );
};

export default ConfirmRestPassword;
