const nationalityList = [
  { label: "Åland Islander", value: "Åland Islander" },
  { label: "Albanian", value: "Albanian" },
  { label: "Algerian", value: "Algerian" },
  { label: "American Samoan", value: "American Samoan" },
  { label: "Andorran", value: "Andorran" },
  { label: "Angolan", value: "Angolan" },
  { label: "Anguillan", value: "Anguillan" },
  { label: "Antarctic", value: "Antarctic" },
  { label: "Antiguan or Barbudan", value: "Antiguan or Barbudan" },
  { label: "Argentine", value: "Argentine" },
  { label: "Armenian", value: "Armenian" },
  { label: "Aruban", value: "Aruban" },
  { label: "Australian", value: "Australian" },
  { label: "Austrian", value: "Austrian" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bahamian", value: "Bahamian" },
  { label: "Bahraini", value: "Bahraini" },
  { label: "Bangladeshi", value: "Bangladeshi" },
  { label: "Barbadian", value: "Barbadian" },
  { label: "Belarusian", value: "Belarusian" },
  { label: "Belgian", value: "Belgian" },
  { label: "Belizean", value: "Belizean" },
  { label: "Beninese", value: "Beninese" },
  { label: "Bermudian", value: "Bermudian" },
  { label: "Bhutanese", value: "Bhutanese" },
  { label: "Bolivian", value: "Bolivian" },
  {
    label: "Bonaire, Sint Eustatius, and Saba",
    value: "Bonaire, Sint Eustatius, and Saba",
  },
  { label: "Bosnian or Herzegovinian", value: "Bosnian or Herzegovinian" },
  { label: "Motswana", value: "Motswana" },
  { label: "Bouvet Islander", value: "Bouvet Islander" },
  { label: "Brazilian", value: "Brazilian" },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { label: "Bruneian", value: "Bruneian" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burkinabé", value: "Burkinabé" },
  { label: "Burundian", value: "Burundian" },
  { label: "Cambodian", value: "Cambodian" },
  { label: "Cameroonian", value: "Cameroonian" },
  { label: "Canadian", value: "Canadian" },
  { label: "Cape Verdean", value: "Cape Verdean" },
  { label: "Caymanian", value: "Caymanian" },
  { label: "Central African", value: "Central African" },
  { label: "Chadian", value: "Chadian" },
  { label: "Chilean", value: "Chilean" },
  { label: "Chinese", value: "Chinese" },
  { label: "Christmas Islander", value: "Christmas Islander" },
  { label: "Cocos Islander", value: "Cocos Islander" },
  { label: "Colombian", value: "Colombian" },
  { label: "Comoran", value: "Comoran" },
  { label: "Congolese", value: "Congolese" },
  { label: "Congolese", value: "Congolese" },
  { label: "Cook Islander", value: "Cook Islander" },
  { label: "Costa Rican", value: "Costa Rican" },
  { label: "Ivorian", value: "Ivorian" },
  { label: "Croatian", value: "Croatian" },
  { label: "Cuban", value: "Cuban" },
  { label: "Curaçaoan", value: "Curaçaoan" },
  { label: "Cypriot", value: "Cypriot" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Djiboutian", value: "Djiboutian" },
  { label: "Dominican", value: "Dominican" },
  { label: "Dominican", value: "Dominican" },
  { label: "Ecuadorean", value: "Ecuadorean" },
  { label: "Egyptian", value: "Egyptian" },
  { label: "Salvadoran", value: "Salvadoran" },
  { label: "Equatorial Guinean", value: "Equatorial Guinean" },
  { label: "Eritrean", value: "Eritrean" },
  { label: "Estonian", value: "Estonian" },
  { label: "Ethiopian", value: "Ethiopian" },
  { label: "Falkland Islander", value: "Falkland Islander" },
  { label: "Faroese", value: "Faroese" },
  { label: "Fijian", value: "Fijian" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "French Guianese", value: "French Guianese" },
  { label: "French Polynesian", value: "French Polynesian" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
  },
  { label: "Gabonese", value: "Gabonese" },
  { label: "Gambian", value: "Gambian" },
  { label: "Georgian", value: "Georgian" },
  { label: "German", value: "German" },
  { label: "Ghanaian", value: "Ghanaian" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greek", value: "Greek" },
  { label: "Greenlandic", value: "Greenlandic" },
  { label: "Grenadian", value: "Grenadian" },
  { label: "Guadeloupean", value: "Guadeloupean" },
  { label: "Guamanian", value: "Guamanian" },
  { label: "Guatemalan", value: "Guatemalan" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinean", value: "Guinean" },
  { label: "Guinea-Bissauan", value: "Guinea-Bissauan" },
  { label: "Guyanese", value: "Guyanese" },
  { label: "Haitian", value: "Haitian" },
  {
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
  },
  { label: "Vatican", value: "Vatican" },
  { label: "Honduran", value: "Honduran" },
  { label: "Hong Kong, Chinese", value: "Hong Kong, Chinese" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Indian", value: "Indian" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Iranian", value: "Iranian" },
  { label: "Iraqi", value: "Iraqi" },
  { label: "Irish", value: "Irish" },
  { label: "Manx", value: "Manx" },
  { label: "Israeli", value: "Israeli" },
  { label: "Italian", value: "Italian" },
  { label: "Jamaican", value: "Jamaican" },
  { label: "Japanese", value: "Japanese" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordanian", value: "Jordanian" },
  { label: "Kazakhstani", value: "Kazakhstani" },
  { label: "Kenyan", value: "Kenyan" },
  { label: "I-Kiribati", value: "I-Kiribati" },
  { label: "North Korean", value: "North Korean" },
  { label: "South Korean", value: "South Korean" },
  { label: "Kuwaiti", value: "Kuwaiti" },
  { label: "Kyrgyzstani", value: "Kyrgyzstani" },
  { label: "Laotian", value: "Laotian" },
  { label: "Latvian", value: "Latvian" },
  { label: "Lebanese", value: "Lebanese" },
  { label: "Mosotho", value: "Mosotho" },
  { label: "Liberian", value: "Liberian" },
  { label: "Libyan", value: "Libyan" },
  { label: "Liechtensteiner", value: "Liechtensteiner" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "Luxembourger", value: "Luxembourger" },
  { label: "Macanese", value: "Macanese" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Malawian", value: "Malawian" },
  { label: "Malaysian", value: "Malaysian" },
  { label: "Maldivian", value: "Maldivian" },
  { label: "Malian", value: "Malian" },
  { label: "Maltese", value: "Maltese" },
  { label: "Marshallese", value: "Marshallese" },
  { label: "Martiniquais", value: "Martiniquais" },
  { label: "Mauritanian", value: "Mauritanian" },
  { label: "Mauritian", value: "Mauritian" },
  { label: "Mahoran", value: "Mahoran" },
  { label: "Mexican", value: "Mexican" },
  { label: "Micronesian", value: "Micronesian" },
  { label: "Moldovan", value: "Moldovan" },
  { label: "Monégasque", value: "Monégasque" },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Montenegrin", value: "Montenegrin" },
  { label: "Montserratian", value: "Montserratian" },
  { label: "Moroccan", value: "Moroccan" },
  { label: "Mozambican", value: "Mozambican" },
  { label: "Burmese", value: "Burmese" },
  { label: "Namibian", value: "Namibian" },
  { label: "Nauruan", value: "Nauruan" },
  { label: "Nepali", value: "Nepali" },
  { label: "Dutch", value: "Dutch" },
  { label: "New Caledonian", value: "New Caledonian" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaraguan", value: "Nicaraguan" },
  { label: "Nigerien", value: "Nigerien" },
  { label: "Nigerian", value: "Nigerian" },
  { label: "Niuean", value: "Niuean" },
  { label: "Norfolk Islander", value: "Norfolk Islander" },
  { label: "Northern Marianan", value: "Northern Marianan" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Omani", value: "Omani" },
  { label: "Pakistani", value: "Pakistani" },
  { label: "Palauan", value: "Palauan" },
  { label: "Palestinian", value: "Palestinian" },
  { label: "Panamanian", value: "Panamanian" },
  { label: "Papua New Guinean", value: "Papua New Guinean" },
  { label: "Paraguayan", value: "Paraguayan" },
  { label: "Peruvian", value: "Peruvian" },
  { label: "Filipino", value: "Filipino" },
  { label: "Pitcairn Islander", value: "Pitcairn Islander" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Puerto Rican", value: "Puerto Rican" },
  { label: "Qatari", value: "Qatari" },
  { label: "Réunionese", value: "Réunionese" },
  { label: "Romanian", value: "Romanian" },
  { label: "Russian", value: "Russian" },
  { label: "Rwandan", value: "Rwandan" },
  { label: "Barthélemois", value: "Barthélemois" },
  { label: "Saint Helenian", value: "Saint Helenian" },
  { label: "Kittitian or Nevisian", value: "Kittitian or Nevisian" },
  { label: "Saint Lucian", value: "Saint Lucian" },
  { label: "Saint-Martinoise", value: "Saint-Martinoise" },
  {
    label: "Saint-Pierrais or Miquelonnais",
    value: "Saint-Pierrais or Miquelonnais",
  },
  {
    label: "Saint Vincentian or Grenadian",
    value: "Saint Vincentian or Grenadian",
  },
  { label: "Samoan", value: "Samoan" },
  { label: "Sammarinese", value: "Sammarinese" },
  { label: "São Toméan", value: "São Toméan" },
  { label: "Saudi", value: "Saudi" },
  { label: "Senegalese", value: "Senegalese" },
  { label: "Serbian", value: "Serbian" },
  { label: "Seychellois", value: "Seychellois" },
  { label: "Sierra Leonean", value: "Sierra Leonean" },
  { label: "Singaporean", value: "Singaporean" },
  { label: "Sint Maarten (Dutch part)", value: "Sint Maarten (Dutch part)" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovenian", value: "Slovenian" },
  { label: "Solomon Islander", value: "Solomon Islander" },
  { label: "Somali", value: "Somali" },
  { label: "South African", value: "South African" },
  {
    label: "South Georgia or South Sandwich Islands",
    value: "South Georgia or South Sandwich Islands",
  },
  { label: "South Sudanese", value: "South Sudanese" },
  { label: "Spanish", value: "Spanish" },
  { label: "Sri Lankan", value: "Sri Lankan" },
  { label: "Sudanese", value: "Sudanese" },
  { label: "Surinamese", value: "Surinamese" },
  { label: "Svalbard", value: "Svalbard" },
  { label: "Swazi", value: "Swazi" },
  { label: "Swedish", value: "Swedish" },
  { label: "Swiss", value: "Swiss" },
  { label: "Syrian", value: "Syrian" },
  { label: "Taiwanese", value: "Taiwanese" },
  { label: "Tajikistani", value: "Tajikistani" },
  { label: "Tanzanian", value: "Tanzanian" },
  { label: "Thai", value: "Thai" },
  { label: "Timorese", value: "Timorese" },
  { label: "Togolese", value: "Togolese" },
  { label: "Tokelauan", value: "Tokelauan" },
  { label: "Tongan", value: "Tongan" },
  { label: "Trinidadian or Tobagonian", value: "Trinidadian or Tobagonian" },
  { label: "Tunisian", value: "Tunisian" },
  { label: "Turkish", value: "Turkish" },
  { label: "Turkmen", value: "Turkmen" },
  { label: "Turks and Caicos Islander", value: "Turks and Caicos Islander" },
  { label: "Tuvaluan", value: "Tuvaluan" },
  { label: "Ugandan", value: "Ugandan" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Emirian", value: "Emirian" },
  { label: "British", value: "British" },
  { label: "American", value: "American" },
  {
    label: "United States Minor Outlying Islander",
    value: "United States Minor Outlying Islander",
  },
  { label: "Uruguayan", value: "Uruguayan" },
  { label: "Uzbekistani", value: "Uzbekistani" },
  { label: "Ni-Vanuatu", value: "Ni-Vanuatu" },
  { label: "Venezuelan", value: "Venezuelan" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Virgin Islander, British", value: "Virgin Islander, British" },
  { label: "Virgin Islander, U.S.", value: "Virgin Islander, U.S." },
  { label: "Wallis and Futuna Islander", value: "Wallis and Futuna Islander" },
  { label: "Sahrawi", value: "Sahrawi" },
  { label: "Yemeni", value: "Yemeni" },
  { label: "Zambian", value: "Zambian" },
  { label: "Zimbabwean", value: "Zimbabwean" },
];

export default nationalityList;
