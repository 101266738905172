import React, { useState, useRef, useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
import MT5SmartFeaturesBox from "./SmartFeaturesBox";
import Button from "components/UI/Button";

// content
import {
  getFlexibleTradingSystem,
  getProfessionalTechnicalAnalysis,
  getFundamentalAnalysis,
  getTradingSignalsAndCopyTrading,
  getMobileTrading,
  getWebTrading,
  getAlgorithmicTrading,
} from "constant/services/MT5/smartFeatureContent";

const MT5SmartFeatures = () => {
  const { language } = useContext(LanguageContext);

  const FlexibleContent = getFlexibleTradingSystem(language);
  const ProfessionalTechnicalContent =
    getProfessionalTechnicalAnalysis(language);
  const FundamentalAnalysisContent = getFundamentalAnalysis(language);
  const FundamentalAnalysisAndCopyTrading =
    getTradingSignalsAndCopyTrading(language);

  const MobileTradingContent = getMobileTrading(language);
  const WebTradingContent = getWebTrading(language);
  const AlgorithmicTradingContent = getAlgorithmicTrading(language);

  // others
  const [visibleComponents, setVisibleComponents] = useState(1);
  const sectionRef = useRef(null);

  const handleSeeMoreFeatures = () => {
    setVisibleComponents(visibleComponents + 1);
  };

  const handleHideFeatures = () => {
    setVisibleComponents(1);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="my-secondary lg:mt-primary" ref={sectionRef}>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="lg:w-1/2 mx-auto">
          <h2 className="text-4xl lg:text-5xl text-font text-center">
            {`${language === "en" ? "Smart Features" : "Умные функции"}`}
          </h2>
        </div>

        <div className="flex flex-col lg:flex-row mt-secondary gap-secondary lg:mt-primary lg:gap-primary ">
          <MT5SmartFeaturesBox content={FlexibleContent} />
          <MT5SmartFeaturesBox content={ProfessionalTechnicalContent} />
        </div>
        {visibleComponents >= 2 && (
          <div className="flex flex-col lg:flex-row mt-secondary gap-secondary transition-all duration-500">
            <MT5SmartFeaturesBox content={FundamentalAnalysisContent} />
            <MT5SmartFeaturesBox content={FundamentalAnalysisAndCopyTrading} />
          </div>
        )}
        {visibleComponents >= 3 && (
          <div className="flex flex-col lg:flex-row mt-secondary gap-secondary transition-all duration-500">
            <MT5SmartFeaturesBox content={MobileTradingContent} />
            <MT5SmartFeaturesBox content={WebTradingContent} />
          </div>
        )}
        {visibleComponents >= 4 && (
          <div className="flex flex-col lg:flex-row mt-secondary gap-secondary lg:mt-primary lg:gap-primary  lg:w-1/2 transition-all duration-500">
            <MT5SmartFeaturesBox content={AlgorithmicTradingContent} />
          </div>
        )}

        {visibleComponents < 4 && (
          <div className="flex justify-center mt-secondary">
            <Button onClick={handleSeeMoreFeatures}>{`${
              language === "en"
                ? "See more Features"
                : "Просмотреть больше функций"
            }`}</Button>
          </div>
        )}
        {visibleComponents === 4 && (
          <div className="flex justify-center mt-secondary">
            <Button onClick={handleHideFeatures}>{`${
              language === "en" ? "Hide" : "Скрыть"
            }`}</Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default MT5SmartFeatures;
