import Spinner from "../Spinner";

const IsLoading = ({ isWhite, language }) => {
  const data = `${
    language === "en" ? "Loading data..." : "Загрузка данных..."
  }`;
  return (
    <div>
      <Spinner />
      <p>{data}</p>
    </div>
  );
};

export default IsLoading;
