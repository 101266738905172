const IressContent = {
  en: {
    features: [
      {
        title: "Flexible Trading System",
        text: "Iress provides a flexible trading system that caters to the diverse needs of traders. Whether you're a day trader executing quick trades or a long-term investor managing your portfolio, the platform offers the necessary tools and order types to accommodate different trading strategies.",
      },
      {
        title: "Professional Technical Analysis: ",
        text: "With advanced charting capabilities and a comprehensive set of technical indicators, Iress supports professional technical analysis. Traders can perform in-depth analysis, identify trends, and make informed decisions based on the extensive range of tools and indicators available within the platform.",
      },
      {
        title: "Fundamental Analysis: ",
        text: "Iress Recognizes the importance of fundamental analysis in assessing the value and prospects of financial instruments. The platform provides access to a wealth of fundamental data, economic indicators, and news updates to help traders analyze market trends and make well-informed investment decisions.",
      },
      {
        title: "Algorithmic Trading:",
        text: " Iress supports algorithmic trading, enabling traders to automate their trading strategies. The platform provides a robust development environment and programming capabilities to create and optimize custom trading algorithms. Traders can leverage the power of automation to execute trades based on predefined rules and parameters.",
      },
      {
        title: "Mobile Trading: ",
        text: "Iress provides a mobile trading application, enabling users to trade on the go. The mobile app offers real-time market data, order placement capabilities, account monitoring, and more. Traders can access the markets and manage their portfolios from their smartphones or tablets, giving them flexibility and convenience.",
      },
      {
        title: "Web Trading: ",
        text: "With Iress traders can access the platform directly from their web browser. The web trading feature allows users to trade and manage their accounts without the need for any software installation. It provides a user-friendly interface, real-time market data, and a range of trading tools accessible from any internet-connected device.",
      },
    ],
  },
  ru: {
    features: [
      {
        title: "Гибкая торговая система",
        text: "Iress предоставляет гибкую торговую систему, учитывающую разнообразные потребности трейдеров. Независимо от того, являетесь ли вы дневным трейдером, выполняющим быстрые сделки, или долгосрочным инвестором, управляющим своим портфелем, платформа предлагает необходимые инструменты и типы ордеров для различных стратегий торговли.",
      },
      {
        title: "Профессиональный технический анализ: ",
        text: "С продвинутыми возможностями построения графиков и обширным набором технических индикаторов Iress поддерживает профессиональный технический анализ. Трейдеры могут проводить глубокий анализ, выявлять тенденции и принимать информированные решения на основе обширного набора инструментов и индикаторов, доступных внутри платформы.",
      },
      {
        title: "Фундаментальный анализ: ",
        text: "Iress придает большое значение фундаментальному анализу при оценке стоимости и перспектив финансовых инструментов. Платформа предоставляет доступ к обширным фундаментальным данным, экономическим показателям и новостным обновлениям, чтобы помочь трейдерам анализировать рыночные тенденции и принимать обоснованные инвестиционные решения.",
      },
      {
        title: "Алгоритмическая торговля:",
        text: "Iress поддерживает алгоритмическую торговлю, что позволяет трейдерам автоматизировать свои стратегии торговли. Платформа предоставляет мощное средство разработки и программирования для создания и оптимизации пользовательских алгоритмов торговли. Трейдеры могут использовать силу автоматизации для выполнения сделок на основе заранее заданных правил и параметров.",
      },
      {
        title: "Торговля с мобильного устройства: ",
        text: "Iress предоставляет мобильное приложение для торговли, позволяя пользователям торговать в п пути. Мобильное приложение предлагает данные реального времени, возможности размещения ордеров, мониторинг счета и многое другое. Трейдеры могут получать доступ к рынкам и управлять своими портфелями с смартфонов или планшетов, обеспечивая гибкость и удобство.",
      },
      {
        title: "Торговля через веб: ",
        text: "С Iress трейдеры могут получить доступ к платформе непосредственно через веб-браузер. Функция торговли через веб позволяет пользователям торговать и управлять своими счетами без необходимости установки какого-либо программного обеспечения. Платформа предоставляет дружественный интерфейс, данные реального времени и набор инструментов для торговли, доступных с любого устройства, подключенного к Интернету.",
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getIressContent = (language) => {
  return IressContent[language];
};

export default getIressContent;
