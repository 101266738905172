const disclaimerFirstChunk = {
  en: {
    contentTwo: [
      "This Website and/or App can be accessed worldwide. However, the information on the Website and/or App is related to ARK Capital Management (Dubai) Limited ('ARK Capital').",
      "Any fact, assessment, analysis, forecasts, opinion and other information (collectively 'Information') released by ARK Capital,",
      "whether provided on ARK Capital’s Website, on third party websites, in marketing materials, newsletters, in individual e-mails and letters, in lectures, individual conversations or in any other form of written or verbal communication (collectively 'Publications') are provided for informational and/or marketing purposes only.",
    ],
    list: {
      title:
        "Unless specifically stated, no Publication of ARK Capital should be construed as an offer (or solicitation of an offer) to:",
      list: [
        "buy or sell any currency, product or financial instrument,",
        "make any investment, or (collectively 'Offers').",
        "participate in any particular trading strategy",
      ],
    },
    content: [
      "Notwithstanding anything to the contrary (not even if specifically stated), no Publication of ARK Capital should be construed as an Offer in any jurisdiction in which such Offer would be illegal. Any such perceived Offer will not be honored by ARK Capital.",
    ],
  },
  ru: {
    contentTwo: [
      "Этот Веб-сайт и/или Приложение доступны во всем мире. Однако информация на Веб-сайте и/или Приложении связана с ARK Capital Management (Dubai) Limited ('ARK Capital').",
      "Любая фактическая информация, оценка, анализ, прогнозы, мнение и другая информация (коллективно 'Информация'), предоставляемая ARK Capital,",
      "будь то на Веб-сайте ARK Capital, на Веб-сайтах третьих лиц, в маркетинговых материалах, рассылках, в индивидуальных электронных письмах и письмах, на лекциях, в индивидуальных беседах или в любой другой форме письменной или устной коммуникации (коллективно 'Публикации'), предоставляются исключительно в информационных и/или маркетинговых целях.",
    ],
    list: {
      title:
        "За исключением явного указания, ни одна Публикация ARK Capital не должна рассматриваться как предложение (или призыв к предложению) на:",
      list: [
        "покупку или продажу какой-либо валюты, продукта или финансового инструмента,",
        "совершение каких-либо инвестиций, или (коллективно 'Предложения').",
        "участие в какой-либо конкретной торговой стратегии",
      ],
    },
    content: [
      "Независимо от чего-либо, несмотря даже на явное заявление, ни одна Публикация ARK Capital не должна рассматриваться как Предложение в любой юрисдикции, в которой такое Предложение было бы незаконным. Никакое такое воспринимаемое Предложение не будет принято ARK Capital.",
    ],
  },
};
const getDisclaimerFirstChunkContent = (language) => {
  return disclaimerFirstChunk[language];
};
export { getDisclaimerFirstChunkContent };

const noWarranty = {
  en: {
    header: "No Representation, No Warranty",
    list: {
      title:
        "ARK Capital uses reasonable efforts to obtain information from reliable sources, but all Publications are provided on an 'as is' basis without representation or warranty of any kind (neither express nor implied) and ARK Capital disclaims liability for any Publication not being complete, accurate, suitable and relevant for the recipient. Specifically, ARK Capital disclaims liability towards any subscriber, client, partner, supplier, counterparty and other recipients for:",
      list: [
        "the completeness, accuracy, reliability, contemporaneity, suitability, or availability with respect to the contents of this Website;",
        "the non-infringement, title, merchantability or fitness for any particular purpose of the contents of this Website;",
        "that the contents available through this Website or any functions associated with this Website will be uninterrupted or error-free or that defects will be corrected or that this Website and its server is and will be free of all viruses or other harmful elements;",
        "the accuracy of any market quotations;",
        "any delay, inaccuracy, error, interruption or omission in providing market quotations; or - and any discontinuance of market quotations.",
      ],
    },
    content: [
      "The Publications of ARK Capital are not updated after their release and may due to changing circumstances become inaccurate and possibly misleading after a period of time which may vary from seconds and minutes to days, weeks and months depending on the Information. ARK Capital gives no guarantee against, and assumes no liability towards any recipient for a Publication being outdated and shall be under no obligation to update the Publication.",
      "Any Publication may be personal to the author and may not reflect the opinion of ARK Capital. ARK Capital reserves the right at its sole discretion to withdraw or amend any Publication or Information provided at any time without notice (prior or subsequent).",
    ],
  },
  ru: {
    header: "Отсутствие гарантий и представлений",
    list: {
      title:
        "ARK Capital прилагает разумные усилия для получения информации из надежных источников, но все Публикации предоставляются 'как есть' без каких-либо представлений или гарантий любого рода (как выраженных, так и подразумеваемых), и ARK Capital не несет ответственности за неполноту, неточность, соответствие и актуальность Публикации для получателя. В частности, ARK Capital отказывается от ответственности перед любым абонентом, клиентом, партнером, поставщиком, контрагентом и другими получателями за:",
      list: [
        "полноту, точность, надежность, актуальность, соответствие или доступность в отношении содержания этого Веб-сайта;",
        "отсутствие нарушения, права собственности, товарной пригодности или пригодности для конкретной цели содержания этого Веб-сайта;",
        "то, что содержание, доступное через этот Веб-сайт или любые функции, связанные с этим Веб-сайтом, будет бесперебойным или безошибочным, что дефекты будут устранены или что этот Веб-сайт и его сервер свободны от всех вирусов или других вредных элементов;",
        "точность любых рыночных котировок;",
        "любую задержку, неточность, ошибку, прерывание или пропуск в предоставлении рыночных котировок; или - и прекращение рыночных котировок.",
      ],
    },
    content: [
      "Публикации ARK Capital не обновляются после их выпуска и могут из-за изменяющихся обстоятельств становиться устаревшими и, возможно, вводящими в заблуждение через определенное время, которое может варьироваться от секунд и минут до дней, недель и месяцев в зависимости от информации. ARK Capital не гарантирует отсутствие и не несет ответственности перед получателями за устаревшую Публикацию и не обязуется обновлять Публикацию.",
      "Любая Публикация может быть личной для автора и может не отражать мнение ARK Capital. ARK Capital оставляет за собой право по собственному усмотрению отозвать или изменить любую Публикацию или Информацию в любое время без уведомления (предварительного или последующего).",
    ],
  },
};
const getNoWarrantyContent = (language) => {
  return noWarranty[language];
};
export { getNoWarrantyContent };

const noRecommendations = {
  en: {
    header: "No Recommendations",
    list: {
      title:
        "ARK Capital does not in any of its Publications take into account any particular recipient’s investment objectives, special investment goals, financial situation, and specific needs and demands. Therefore, all Publications of ARK Capital are, unless otherwise specifically stated, intended for informational and/or marketing purposes only and should not be construed as:",
      list: [
        "business, financial, investment, hedging, legal, regulatory, tax or accounting advice,",
        "a recommendation or trading idea, or any other type of encouragement to act, invest or divest in a particular manner (collectively 'Recommendations')",
      ],
    },
    content: [
      "ARK Capital shall not be responsible for any loss arising from any investment based on a perceived Recommendation.",
      "Notwithstanding anything to the contrary (not even if specifically stated), no Publication (including possible Recommendations) shall be construed as a representation or warranty (neither express nor implied) that the recipient will profit from trading in accordance with a trading strategy set forth in a Publication or that the recipient will not sustain losses from trading in accordance with a trading strategy set forth in a Publication.",
    ],
  },
  ru: {
    header: "Отсутствие рекомендаций",
    list: {
      title:
        "ARK Capital не принимает во внимание цели инвестиций, специальные инвестиционные цели, финансовое положение и конкретные потребности и требования конкретного получателя в любых своих Публикациях. Поэтому все Публикации ARK Capital, если не оговорено иное, предназначены исключительно для информационных и/или маркетинговых целей и не должны рассматриваться как:",
      list: [
        "бизнес-, финансовый, инвестиционный, хеджирования, юридический, регуляторный, налоговый или бухгалтерский совет,",
        "рекомендация или торговая идея или любой другой вид поощрения к действию, инвестированию или отчуждению в определенном порядке (в совокупности 'Рекомендации')",
      ],
    },
    content: [
      "ARK Capital не несет ответственности за любые убытки, возникшие в результате инвестиций, основанных на воспринимаемой Рекомендации.",
      "Независимо от противоположного (даже если это явно не указано), ни одна Публикация (включая возможные Рекомендации) не должна рассматриваться как представление или гарантия (как выраженная, так и подразумеваемая) того, что получатель извлечет прибыль от торговли в соответствии с торговой стратегией, изложенной в Публикации, или что получатель не понесет убытков от торговли в соответствии с торговой стратегией, изложенной в Публикации.",
    ],
  },
};
const getNoRecommendationsContent = (language) => {
  return noRecommendations[language];
};
export { getNoRecommendationsContent };

const contentList = {
  en: [
    {
      title: "Risk Warning",
      content: [
        "Trading in the products and services of ARK Capital may, even if made in accordance with a Recommendation, result in losses as well as profits. In particular trading in leveraged products, such as but not limited to, foreign exchange, derivatives and commodities can be very speculative, and losses and profits may fluctuate both violently and rapidly.",
      ],
    },
    {
      title: "Speculative trading is not suitable for all investors.",
      content: [
        "Any mentioning, if any, in a Publication of the risks pertaining to a particular product or service may not and should neither be construed as a comprehensive disclosure nor full description of all risks pertaining to such product or service and ARK Capital strongly encourages any recipient considering trading in its products and services to employ and continuously consult suitable financial advisors prior to the conclusion of any investment or transaction.",
      ],
    },
    {
      title: "Local regulatory restrictions",
      content: [
        "ARK Capital’s Publications may be accessed worldwide. The Information provided in such Publications is however only intended for use by recipients located in countries where such use does not constitute a violation of Applicable Laws or regulations. None of the products and services referred to in the Publications of ARK Capital are available to recipients residing in countries where the provision of such products and services would constitute a violation of mandatory Applicable Laws or regulations.",
        "It is the sole responsibility of any recipient employing or requesting a product or service of ARK Capital to comply with all applicable legislation or regulation.",
      ],
    },
    {
      title: "Use of the Website",
      content: [
        "Any use of ARK Capital’s Website is subject to ARK Capital’s 'Terms of Use,' and 'Online Data Protection Policy' as amended from time to time, both of which shall be construed as constituting an integrated part of this Disclaimer.",
        "ARK Capital shall not be liable for any damage or injury arising out of any person’s or entity’s access to, or inability to access, any website of ARK Capital. This limitation includes, but is not limited to, any damage to computer equipment and computer systems caused by virus, malware and any other harmful computer coding.",
        "Consulting a website of ARK Capital does not constitute a customer relationship, and ARK Capital shall not have any duty or incur any liability or responsibility towards any person or entity as a result of such person or entity consulting the Website of ARK Capital.",
        "Any market data displayed on this Website or App may only be used for your own internal business purposes and only in connection with this specific service. Distribution of any market data to any third party is prohibited.",
      ],
    },
    {
      title: "Translation discrepancies",
      content: [
        "ARK Capital offers its Publications in a wide range of languages for the recipient’s comfort of use. In case of discrepancy between the original English text and any translation, the original English text shall prevail.",
        "All recipients are advised to carefully examine the original English text before acting upon a translation thereof. Any user who does not fully understand the original English text is strongly encouraged to contact ARK Capital by e-mail at cs@ark-cap.com or by telephone at XXXXXX.",
        "ARK Capital shall not be bound by, or liable to any recipient for, an incomplete or inaccurate translation of an original English text to another language.",
      ],
    },
    {
      title: "Client relationship",
      content: [
        "When applying for an account with any entity within ARK Capital, each applicant will be subject to the relevant set of business terms. All applicants and clients are deemed to have read and understood the business terms and related policies applicable to them and their relationship with the relevant entity of ARK Capital.",
        "Consulting a website or receiving a Publication of ARK Capital does not constitute a customer relationship, and ARK Capital shall not have any duty or incur any liability or responsibility towards any person or entity as a result thereof.",
      ],
    },
    {
      title: "Third party services",
      content: [
        "Persons or entities not belonging to ARK Capital may advertise on ARK Capital’s websites, through links, banners or otherwise. ARK Capital shall not be held responsible or liable for the offerings, information or acts of any such third party. If a user of ARK Capital’s Website decides to act upon any such advertising, such user does so entirely at its own risk.",
        "Persons or entities not belonging to ARK Capital may advertise on ARK Capital’s websites, through links, banners or otherwise. ARK Capital shall not be held responsible or liable for the offerings, information or acts of any such third party. If a user of ARK Capital’s Website decides to act upon any such advertising, such user does so entirely at its own risk.",
      ],
    },
  ],
  ru: [
    {
      title: "Предупреждение о рисках",
      content: [
        "Торговля продуктами и услугами ARK Capital может, даже если она сделана в соответствии с Рекомендацией, привести к убыткам, а также к прибыли. Особенно торговля кредитными продуктами, такими как, но не ограничиваясь, валютой, производными и товарами, может быть очень спекулятивной, и убытки и прибыль могут колебаться как насильственно, так и быстро.",
      ],
    },
    {
      title: "Спекулятивная торговля не подходит для всех инвесторов.",
      content: [
        "Любое упоминание, если оно есть, в Публикации о рисках, относящихся к конкретному продукту или услуге, не может и не должно рассматриваться как полное раскрытие или полное описание всех рисков, относящихся к такому продукту или услуге, и ARK Capital настоятельно советует любому получателю, рассматривающему торговлю его продуктами и услугами, привлекать и непрерывно консультироваться с соответствующими финансовыми консультантами перед заключением любой инвестиции или сделки.",
      ],
    },
    {
      title: "Местные регуляторные ограничения",
      content: [
        "Публикации ARK Capital могут быть доступны во всем мире. Однако предоставленная в таких Публикациях информация предназначена только для использования получателями, находящимися в странах, где такое использование не является нарушением действующих законов или нормативных актов. Ни один из продуктов и услуг, указанных в Публикациях ARK Capital, не предоставляется получателям, проживающим в странах, где предоставление таких продуктов и услуг является нарушением обязательных законов или нормативных актов.",
        "Исключительная ответственность за соблюдение всех применимых законов и нормативных актов несет любой получатель, привлекающий или запрашивающий продукт или услугу ARK Capital.",
      ],
    },
    {
      title: "Использование веб-сайта",
      content: [
        "Любое использование веб-сайта ARK Capital подпадает под 'Условия использования' и 'Политику защиты данных в Интернете' ARK Capital, которые рассматриваются как неотъемлемая часть настоящего Уведомления и подлежат толкованию.",
        "ARK Capital не несет ответственности за любой ущерб или повреждение, возникшее вследствие доступа любого лица или субъекта к веб-сайту ARK Capital или невозможности такого доступа. К таким ограничениям относится, включая, но не ограничиваясь, ущерб компьютерному оборудованию и компьютерным системам, вызванный вирусами, вредоносным программным обеспечением и любым другим вредоносным программным кодированием.",
        "Консультирование веб-сайта ARK Capital не означает установление отношений с клиентами, и ARK Capital не несет никаких обязанностей и не несет ответственности перед любым лицом или субъектом в результате такого консультирования веб-сайта ARK Capital.",
        "Любые рыночные данные, отображаемые на этом веб-сайте или в приложении, могут использоваться только в целях внутреннего бизнеса и только в связи с этой конкретной услугой. Распространение рыночных данных третьим лицам запрещено.",
      ],
    },
    {
      title: "Разногласия в переводе",
      content: [
        "ARK Capital предлагает свои Публикации на широком диапазоне языков для комфорта использования получателя. В случае расхождения между оригинальным текстом на английском языке и любым переводом, оригинальный английский текст должен иметь преимущество.",
        "Все получатели рекомендуется внимательно изучать оригинальный английский текст перед осуществлением действий по переводу. Любому пользователю, который не полностью понимает оригинальный английский текст, настоятельно рекомендуется связаться с ARK Capital по адресу cs@ark-cap.com или по телефону XXXXXX.",
        "ARK Capital не связан и не несет ответственности перед любым получателем за неполный или неточный перевод оригинального английского текста на другой язык.",
      ],
    },
    {
      title: "Клиентские отношения",
      content: [
        "Подавая заявку на открытие счета в любой из компаний ARK Capital, каждый заявитель будет подвергаться соответствующему набору деловых условий. Все заявители и клиенты считаются прочитавшими и понявшими деловые условия и связанные с ними политики, применимые к ним и их отношениям с соответствующей компанией ARK Capital.",
        "Консультирование веб-сайта или получение Публикации ARK Capital не означает установление клиентских отношений, и ARK Capital не несет никаких обязанностей и не несет ответственности перед любым лицом или субъектом в результате такого консультирования.",
      ],
    },
    {
      title: "Услуги третьих сторон",
      content: [
        "Лица или субъекты, не входящие в состав ARK Capital, могут рекламироваться на веб-сайтах ARK Capital, через ссылки, баннеры или иным образом. ARK Capital не несет ответственности и не несет ответственности за предложения, информацию или действия любой такой третьей стороны. Если пользователь веб-сайта ARK Capital решит действовать в ответ на любую такую ​​рекламу, такой пользователь делает это исключительно на свой страх и риск.",
        "Лица или субъекты, не входящие в состав ARK Capital, могут рекламироваться на веб-сайтах ARK Capital, через ссылки, баннеры или иным образом. ARK Capital не несет ответственности и не несет ответственности за предложения, информацию или действия любой такой третьей стороны. Если пользователь веб-сайта ARK Capital решит действовать в ответ на любую такую ​​рекламу, такой пользователь делает это исключительно на свой страх и риск.",
      ],
    },
  ],
};

const getContentList = (language) => {
  return contentList[language];
};
export { getContentList };

const limitation = {
  en: {
    header: "Limitation of Liability",
    list: {
      title:
        "ARK Capital does not accept any liability for any damage, loss or claims of any kind, whether under contract or tort or otherwise, including without limitation, any indirect or consequential damages of any kind or loss of business or profits howsoever caused and whether as a result of, or in connection with (directly or indirectly):",

      list: [
        "the use of this Website;",
        "reliance on the contents of this Website;",
        "any error or omission or defect in the contents of this Website;",
        "any action, inaction or decision taken as a result of or in connection with the use of this Website or any information on this Website; or",
        "any functions associated with this Website or any viruses or other harmful elements.",
        "Part of this website contains information provided by third parties. These third parties are solely responsible for ensuring that the information they submit to ARK Capital is accurate, complete and current and complies with all Applicable Laws and regulations. ARK Capital is not responsible on any basis whatsoever for any information provided by third parties and will not verify such information. ARK Capital reserves the right to omit, suspend, remove or edit any third party information on this Website.",
        "This Website provides general information only and is not intended, nor to be relied upon on any basis whatsoever, as any form of  legal, financial, tax or other professional advice. You should consult appropriate professional advisers if you require financial or other advice.",
        "Access to this Website may be suspended temporarily or permanently and without notice.",
      ],
    },
    content: [
      "If ARK Capital at any time and for any reason, should become liable for the loss of any person and/or entity, including without limitation, if any provision of this disclaimer is, or at any time becomes to any extent or in any circumstances invalid, illegal or unenforceable for any reason, the liability of ARK Capital shall be limited to such person’s and/or entity’s duly documented direct loss, which for the avoidance of doubt, and without limitation, shall not include damages for any incidental and consequential losses, damages for lost opportunity, damages for lost profit, statutory damages, nominal damages, punitive damages, restitutionary or disgorgement damages, damages for costs, including legal costs, and damages for any other indirect loss.",
    ],
  },
  ru: {
    header: "Ограничение ответственности",
    list: {
      title:
        "ARK Capital не несет ответственности за любой ущерб, убытки или претензии любого характера, будь то по договору, по вине или иным образом, включая, но не ограничиваясь, любые косвенные или последующие убытки любого характера или потерю бизнеса или прибыли, каким бы образом они ни были вызваны и независимо от того, было ли это результатом, или в связи с (непосредственно или косвенно):",

      items: [
        "использование этого веб-сайта;",
        "полагаясь на содержание этого веб-сайта;",
        "любую ошибку или упущение или дефект в содержании этого веб-сайта;",
        "любое действие, бездействие или решение, принятое в результате или в связи с использованием этого веб-сайта или любой информации на этом веб-сайте; или",
        "любые функции, связанные с этим веб-сайтом, или любые вирусы или другие вредоносные элементы.",
        "Часть этого веб-сайта содержит информацию, предоставленную третьими сторонами. Эти третьи стороны несут исключительную ответственность за обеспечение точности, полноты и актуальности информации, предоставленной ими ARK Capital и за соблюдение всех действующих законов и нормативных актов. ARK Capital не несет никакой ответственности за предоставленную третьими сторонами информацию и не будет проверять такую информацию. ARK Capital оставляет за собой право пропускать, приостанавливать, удалять или редактировать информацию третьих сторон на этом веб-сайте.",
        "Этот веб-сайт предоставляет только общую информацию и не предназначен, ни на что полагаться, как на любой вид юридической, финансовой, налоговой или иной профессиональной консультации. Если вам требуется финансовая или иная консультация, вы должны проконсультироваться с соответствующими профессиональными советниками.",
        "Доступ к этому веб-сайту может быть временно или постоянно приостановлен без уведомления.",
      ],
    },
    content: [
      "Если ARK Capital в любое время и по любой причине станет нести ответственность за ущерб какого-либо лица и/или субъекта, включая, но не ограничиваясь, если какая-либо положение настоящего отказа от ответственности становится, или в любое время становится, в той или иной степени или в тех или иных обстоятельствах недействительным, незаконным или невыполнимым по какой-либо причине, ответственность ARK Capital ограничится документально подтвержденными прямыми убытками такого лица и/или субъекта, которые, без ограничения, не включают в себя убытки от любых косвенных и последующих убытков, убытки от упущенной выгоды, убытки от потери прибыли, установленные законом убытки, номинальные убытки, карательные убытки, убытки восстановительного или возврата, убытки в связи с расходами, включая юридические расходы, и убытки из-за любых других косвенных убытков.",
    ],
  },
};

const getlimitationContent = (language) => {
  return limitation[language];
};
export { getlimitationContent };

export const contentListTwo = {
  en: [
    {
      title: "Conflict of interest",
      content: [
        "In order to prevent conflicts of interest, ARK Capital has established appropriate business procedures, including procedures applicable to research and analysis to ensure objective research reports. ARK Capital's research reports are never disclosed to nor discussed with any of the subject of the analysis, e.g. issuers of securities or others.",
        "ARK Capital may hold, take, transfer or alienate positions in securities, foreign exchange or other financial instruments covered by a research report.",
        "ARK Capital may act as market maker in all products and services not traded on an exchange (i.e. OTC products such as foreign exchange and CFDs) and may therefore be the counterparty in any trade conducted by customers in these products.",
        "ARK Capital does not engage in corporate finance activities. Accordingly, none of ARK Capital's directors, officers, employees or representatives, namely people in charge of analysis reports, receives remuneration in connection with the release of any analysis.",
      ],
    },
    {
      title: "Privacy and Use of Personal Data",
      content: [
        "When signing up for a demo or live account with any entity of ARK Capital, this entity will, for administrative, commercial and/or legal purposes, require and store information about the user, including, but not limited to, name and address. Furthermore, ARK Capital may require some financial information for credit assessment purposes, as well as users’ e-mail address to be able to mail the login information. Users’ e-mail addresses will not be passed on to third parties, but may be used by any entity of ARK Capital for the promotion of its products and services.",
        "ARK Capital is obliged by law to treat personal data as confidential and may not pass on or use any personal data without valid legal grounds.",
        "All personal data is subject to the Online Data Privacy Policy.",
      ],
    },
    {
      title: "Supervision",
      content: [
        "ARK Capital is under the supervision of the Dubai Financial Services Authority ('DFSA').",
      ],
    },
    {
      title: "Disputes",
      content: [
        "This Disclaimer and any dispute arising from or in relation thereto, including disputes regarding its legality, validity, binding effect (including adoption) and enforceability, shall be settled by DIFC courts in accordance with and subject exclusively to DIFC laws.",
      ],
    },
    {
      title: "Non-Independent Investment Research",
      content: [
        "Any information which could be construed as investment research has not been prepared in accordance with legal requirements designed to promote the independence of investment research. Further it is not subject to any prohibition on dealing ahead of the dissemination of investment research. ARK Capital, its owners, subsidiaries, affiliates or employees of the same may perform services for, solicit business from, hold long or short positions in, or otherwise be interested in the investments (including derivatives), of any issuer mentioned herein.",
      ],
    },
    {
      title: "Forward-Looking Statements",
      content: [
        "Where there are statements contained in this website which are not historical facts, such statements could be data obtained as a result of simulated past performance or future performance based on current expectations, estimates, projections, opinions and beliefs of members of ARK Capital. Such statements involve known and unknown risks, uncertainties and other factors, and undue reliance should not be placed thereon. Additionally, this website may contain 'forward-looking statements'. Actual events or results or actual performance may differ materially from those reflected or contemplated in such forward-looking statements. None of any opinions that may be inferred from the contents of this Website is intended to be relied upon as advice or recommendation of any action. They are intended merely as articulations of personal musings and speculations factually.",
        "This material is confidential and should not be copied, distributed, published or reproduced in whole or in part or disclosed by recipients to any other person.",
      ],
    },
  ],
  ru: [
    {
      title: "Конфликт интересов",
      content: [
        "Для предотвращения конфликтов интересов ARK Capital установил соответствующие деловые процедуры, включая процедуры, применимые к исследованиям и анализу, чтобы обеспечить объективные исследовательские отчеты. Исследовательские отчеты ARK Capital никогда не раскрываются и не обсуждаются ни с одним из субъектов анализа, например, эмитентами ценных бумаг или другими.",
        "ARK Capital может держать, принимать, передавать или отчуждать позиции в ценных бумагах, иностранной валюте или других финансовых инструментах, охваченных исследовательским отчетом.",
        "ARK Capital может действовать в качестве создателя рынка во всех продуктах и услугах, не торгуемых на бирже (т. е. внебиржевые продукты, такие как иностранная валюта и CFD), и, следовательно, может быть контрагентом в любой сделке, проводимой клиентами в этих продуктах.",
        "ARK Capital не занимается корпоративными финансовыми операциями. Следовательно, ни один из директоров, сотрудников или представителей ARK Capital, а именно лица, ответственные за аналитические отчеты, не получает вознаграждение в связи с выпуском какого-либо анализа.",
      ],
    },
    {
      title: "Конфиденциальность и использование персональных данных",
      content: [
        "При регистрации демонстрационного или реального счета у любой компании ARK Capital для административных, коммерческих и/или юридических целей потребуется и будет храниться информация о пользователе, включая, но не ограничиваясь, имя и адрес. Кроме того, ARK Capital может потребовать некоторую финансовую информацию для оценки кредитоспособности, а также адрес электронной почты пользователей, чтобы иметь возможность отправлять информацию о входе. Адреса электронной почты пользователей не будут передаваться третьим лицам, но могут использоваться любой компанией ARK Capital для продвижения своих продуктов и услуг.",
        "ARK Capital обязан согласно закону считать персональные данные конфиденциальными и не может передавать или использовать их без действительных юридических оснований.",
        "Все персональные данные подпадают под политику конфиденциальности данных, доступную онлайн.",
      ],
    },
    {
      title: "Надзор",
      content: [
        "ARK Capital находится под надзором Дубайского управления финансовых услуг ('DFSA').",
      ],
    },
    {
      title: "Споры",
      content: [
        "Этот Отказ от ответственности и любой спор, возникший или связанный с ним, включая споры относительно его законности, действительности, обязательной силы (включая принятие) и применимости, будут рассматриваться судами ДИФК в соответствии и исключительно с учетом законов ДИФК.",
      ],
    },
    {
      title: "Не независимые инвестиционные исследования",
      content: [
        "Любая информация, которая может быть расценена как инвестиционное исследование, не подготовлена в соответствии с правовыми требованиями, предназначенными для содействия независимости инвестиционного исследования. Кроме того, она не подпадает под какие-либо ограничения в отношении проведения сделок до распространения инвестиционного исследования. ARK Capital, его владельцы, дочерние компании, аффилированные лица или сотрудники могут оказывать услуги, привлекать клиентов, иметь длинные или короткие позиции или проявлять интерес к инвестициям (включая деривативы) любого упомянутого здесь эмитента.",
      ],
    },
    {
      title: "Заявления о будущем",
      content: [
        "Если в этом веб-сайте содержатся заявления, которые не являются историческими фактами, такие заявления могли быть получены в результате моделирования прошлой производительности или будущей производительности на основе текущих ожиданий, оценок, прогнозов, мнений и убеждений сотрудников ARK Capital. Такие заявления включают в себя известные и неизвестные риски, неопределенности и другие факторы, и на них не следует полагаться без основания. Кроме того, этот веб-сайт может содержать 'заявления о будущем'. Фактические события или результаты или фактическая производительность могут существенно отличаться от тех, которые отражены или рассматриваются в таких заявлениях о будущем. Ни одно из мнений, которые могут быть выведены из содержания этого веб-сайта, не предназначено для того, чтобы на них полагаться в качестве совета или рекомендации какого-либо действия. Они предназначены исключительно как высказывания личных размышлений и догадок фактически.",
        "Этот материал является конфиденциальным и не должен быть копирован, распространен, опубликован или воспроизведен полностью или частично или разглашен получателям другим лицам.",
      ],
    },
  ],
};
const getcontentListTwo = (language) => {
  return contentListTwo[language];
};
export { getcontentListTwo };
