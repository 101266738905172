import MT5Hero from "./Components/MT5Hero";
import MT5Plaftrom from "./Components/MT5Plaftrom";
import MT5FullImage from "./Components/MT5FullImage";
import MT5SmartFeatures from "./Components/MT5SmartFeatures";

import OpenAccount from "../UI/OpenAccount";
const MT5 = () => {
  return (
    <main>
      <MT5Hero />
      <MT5Plaftrom />
      <MT5FullImage />
      <MT5SmartFeatures />
      <OpenAccount />
    </main>
  );
};

export default MT5;
