import BASE_API_URL from "../BaseUrl";

const performConfirmRestPassword = async (formData) => {
  try {
    const response = await fetch(`${BASE_API_URL}/password/code/check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to confirm Rest Email");
      return responseData;
    } else {
      console.log("confirm Rest Email successful");
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform confirm Rest Email.");
  }
};

export default performConfirmRestPassword;
