const inputErrorMessage = {
  text: "Please enter valid input for this field",
  fullName: "Please enter your full name (e.g. John Doe).",
  number: "Please enter a valid phone ",
  email: "Please enter a valid email address (e.g. john@example.com)",
  password: "Please enter a valid password",
  passwordSignUp:
    "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit",
  passwordConfirm: "Passwords do not match",
};

export default inputErrorMessage;
