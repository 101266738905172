const CIF_Corporate_Form_conten = [
  // company Details
  {
    inputs: [
      {
        pageTitle: "Identification details of your Company",
      },

      {
        label: "Registered name",
        type: "text",
        name: "Registered_name",
        placeholder: "",
        require: true,
        error: false,
      },

      {
        label: "Legal form ",
        type: "text",
        name: "Legal_form",
        placeholder: "",
        require: true,
        error: false,
      },

      {
        label: "Other used trading name(s)",
        type: "text",
        name: "Other_used_trading_name",
        placeholder: "",
        require: false,
        error: false,
      },

      {
        label: "Previous Trading/Registered name(s)",
        type: "text",
        name: "Previous_Trading_Registered_name",
        placeholder: "",
        require: false,
        error: false,
      },

      {
        label: "Date of incorporation",
        type: "date",
        name: "Date_of_incorporation",
        placeholder: "",
        require: true,
        error: false,
      },

      {
        label: "Place of incorporation",
        type: "text",
        name: "Place_of_incorporation",
        placeholder: "",
        require: true,
        error: false,
      },

      {
        label: "Registration number",
        type: "number",
        name: "Registration_number",
        placeholder: "",
        require: true,
        error: false,
      },

      {
        label: "TIN Number (if applicable)",
        type: "text",
        name: "TIN_Number",
        placeholder: "",
        require: false,
        error: false,
      },

      // Registered address
      {
        mega: true,
        magaTitle: "Registered address",
        megaName: "Registered_address",
        megaInputs: [
          {
            label: "Street",
            name: "Street",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Building Name/No.",
            name: "Building_Name_No",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Premises No:",
            name: "Premises_No",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "City",
            name: "City",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            placeholder: "Select a Country",
            country: true,
            require: true,
            error: false,
          },
        ],
      },
      // trading address
      {
        mega: true,
        magaTitle: "Trading address (only if different from above)",
        megaName: "Trading_address",
        megaInputs: [
          {
            label: "Street",
            name: "Street",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Building Name/No. ",
            name: "Building_Name_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Premises No:",
            name: "Premises_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "City",
            name: "City",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            placeholder: "Select a Country",
            country: true,
            require: false,
            error: false,
          },
        ],
      },
      // Mailing address
      {
        mega: true,
        magaTitle: "Mailing address (only if different from above)",
        megaName: "Mailing_address",
        megaInputs: [
          {
            label: "Street",
            name: "Street",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "PO Box/Zip Code:",
            name: "PO_Box_Zip_Code",
            type: "number",
            require: false,
            error: false,
          },
          {
            label: "Building Name/No. ",
            name: "Building_Name_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Premises No: ",
            name: "Premises_No",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "City",
            name: "City",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            placeholder: "Select a Country",
            country: true,
            require: false,
            error: false,
          },
        ],
      },

      {
        label: "Other countries of operations",
        name: "Other_countries_of_operations",
        type: "select",
        placeholder: "Select place country",
        country: true,
        require: true,
        error: false,
      },

      //   contact details
      {
        mega: true,
        magaTitle: "Contact details",
        megaName: "Contact_details",
        megaInputs: [
          {
            label: "Telephone",
            name: "Telephone",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "Fax",
            name: "Fax",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "Registered Email",
            name: "Registered_Email",
            type: "email",
            require: true,
            error: false,
          },
          {
            label: "Website",
            name: "Website",
            type: "url",
            require: true,
            error: false,
          },
        ],
      },

      {
        label: "Country of fiscal residence",
        name: "Country_of_fiscal_residence",
        type: "select",
        placeholder: "Select country",
        country: true,
        require: true,
        error: false,
      },

      //   Business activity
      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "Business activity(ies)",
        megaName: "Business_activity",
        megaInputs: [
          {
            lable: "services or products you are interested in",
            name: "selectedValue",
            type: "select",
            placeholder: "Select",
            options: [
              {
                label: "Commercial activities",
                value: "Commercial activities",
              },
              {
                label: "Investment activities",
                value: "Investment activities",
              },
              {
                label: "Personal investment vehicle",
                value: "Personal investment vehicle",
              },
              {
                label: "Real Estate activities",
                value: "Real Estate activities",
              },
            ],
            classNames: "w-3/4",
          },
          {
            label:
              "If commercial activities, please provide details of the nature of the activities",
            name: "If_commercial_activities_please_provide_details_of_the_nature_of_the_activities",
            type: "text",
          },
        ],
      },

      //   entity pary of groupd
      {
        mega: true,
        magaTitle: "Is the entity part of a Group?",
        megaName: "Is_the_entity_part_of_a_Group",
        megaMessage: true,
        megaInputs: [
          {
            label: "Select",
            type: "select",
            name: "SelectedValue",
            placeholder: "Select",
            options: [
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ],
            require: true,
            error: false,
          },
          {
            label: "Name",
            name: "Name",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Country",
            name: "Country",
            type: "select",
            country: true,
            require: false,
            error: false,
          },
          {
            label: "website",
            name: "website",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      // entity supervised by a Financial Services
      {
        mega: true,
        megaMessage: true,
        magaTitle:
          "Is the entity supervised by a Financial Services Regulator?",
        megaName: "Is_the_entity_supervised_by_a_Financial_Services_Regulator",
        megaInputs: [
          {
            label: "Select",
            type: "select",
            name: "SelectedValue",
            placeholder: "Select",
            options: [
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ],
            require: true,
            error: false,
          },
          {
            label: "Name of external Auditor",
            name: "Name_of_external_Auditor",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      //Politically member
      {
        label:
          "Are you /is a member of your family / a close associate a Politically Exposed Person?",
        type: "select",
        name: "Are_you_is_a_member_of_your_family_a_close_associate_a_Politically_Exposed_Person",
        placeholder: "Select",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "Politically Exposed Person",
        megaMessage: true,
        specialDesign: true,
        megaName: "Politically_Exposed_Person",
        megaInputs: [
          {
            label: "Name ",
            name: "Name_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Function ",
            name: "Function_1",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Name ",
            name: "Name_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Function ",
            name: "Function_2",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Name ",
            name: "Name_3",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "Function ",
            name: "Function_3",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  //   List of Directors
  {
    inputs: [
      {
        pageTitle: "List of Directors / Partners of the Company",
      },
      {
        mega: true,
        magaTitle: "Name Director / Partner",
        fourColumns: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        megaName: "Name_Director_Partner",
        megaInputs: [
          {
            label: "name",
            type: "text",
            name: "name_1",
          },
          {
            label: "position",
            type: "text",
            name: "position_1",
          },

          {
            label: "name",
            type: "text",
            name: "name_2",
          },
          {
            label: "position",
            type: "text",
            name: "position_2",
          },

          {
            label: "name",
            type: "text",
            name: "name_3",
          },
          {
            label: "position",
            type: "text",
            name: "position_3",
          },

          {
            label: "name",
            type: "text",
            name: "name_4",
          },
          {
            label: "position",
            type: "text",
            name: "position_4",
          },

          {
            label: "name",
            type: "text",
            name: "name_5",
          },
          {
            label: "position",
            type: "text",
            name: "position_5",
          },

          {
            label: "name",
            type: "text",
            name: "name_6",
          },
          {
            label: "position",
            type: "text",
            name: "position_6",
          },
          {
            label: "name",
            type: "text",
            name: "name_7",
          },
          {
            label: "position",
            type: "text",
            name: "position_7",
          },
          {
            label: "name",
            type: "text",
            name: "name_8",
          },
          {
            label: "position",
            type: "text",
            name: "position_8",
          },
        ],
      },
    ],
  },

  // List of the Shareholders of the Company
  {
    inputs: [
      {
        pageTitle: "List of the Shareholders of the Company",
      },
      {
        mega: true,
        megaMessage: true,
        magaTitle: "Is the Company publicly listed?",
        megaName: "Is_the_Company_publicly_listed",
        megaInputs: [
          {
            label: "Select",
            type: "select",
            name: "SelectedValue",
            placeholder: "Select",
            options: [
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ],
            require: true,
            error: false,
          },
          {
            label: "Name Exchange",
            type: "text",
            name: "Name_Exchange",
            require: false,
            error: false,
          },
          {
            label: "Ticker/Code",
            type: "text",
            name: "Ticker_Code",
            require: false,
            error: false,
          },
        ],
      },
      {
        mega: true,
        magaTitle: "List of Shareholders",
        megaName: "List_of_Shareholders",
        multiple: true,
        megaError: false,
        megaRequire: true,
        megaInputs: [
          {
            label: "Name",
            type: "text",
            name: "Name_1",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_1",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_1",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_2",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_2",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_2",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_3",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_3",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_3",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_4",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_4",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_4",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_5",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_5",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_5",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_6",
          },
          {
            label: "%Shares",
            type: "number",
            name: "Shares_6",
          },
          {
            label: "Name of individual(s) ",
            placeholder: "controlling the Shareholder",
            type: "text",
            name: "Name_of_individual_6",
          },
        ],
      },
    ],
  },

  //List of authorised signatories:
  {
    inputs: [
      {
        pageTitle:
          "List of authorised signatories: (Please list the names of the persons who will interact with us)",
      },
      {
        mega: true,
        magaTitle: "List of authorised signatories",
        megaName: "List_of_authorised_signatories",
        fourColumns: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        megaInputs: [
          {
            label: "Name",
            type: "text",
            name: "Name_1",
          },
          {
            label: "position",
            type: "text",
            name: "position_1",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_2",
          },
          {
            label: "position",
            type: "text",
            name: "position_2",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_3",
          },
          {
            label: "position",
            type: "text",
            name: "position_3",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_4",
          },
          {
            label: "position",
            type: "text",
            name: "position_4",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_5",
          },
          {
            label: "position",
            type: "text",
            name: "position_5",
          },
          {
            label: "Name",
            type: "text",
            name: "Name_6",
          },
          {
            label: "position",
            type: "text",
            name: "position_6",
          },
        ],
      },
    ],
  },

  //  your busniess
  {
    inputs: [
      { pageTitle: "About your business intentions with us" },
      {
        label: "How did you learn about us and our services? ",
        type: "select",
        name: "How_did_you_learn_about_us_and_our_services",
        placeholder: "Select",
        options: [
          {
            label: "By one of your employees",
            value: "By one of your employees",
          },
          {
            label: "On my own initiative (marketing, website…)",
            value: "On my own initiative (marketing, website…)",
          },
          {
            label: "Referral by one of your other customers",
            value: "Referral by one of your other customers",
          },
          {
            label:
              "Referral by one of your business introducers/intermediaries",
            value:
              "Referral by one of your business introducers/intermediaries",
          },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "What are the services or products you are interested in?",
        megaName: "What_are_the_services_or_products_you_are_interested_in",
        multiple: true,
        megaError: false,
        megaRequire: true,
        megaInputs: [
          {
            lable: "services or products you are interested in",
            name: "selectedValue",
            type: "select",
            placeholder: "Select",
            options: [
              { label: "Investment advice", value: "Investment advice" },
              {
                label: "Arranging credit facilities",
                value: "Arranging credit facilities",
              },
              {
                label:
                  "Receipt and transmission of orders in financial products",
                value:
                  "Receipt and transmission of orders in financial products",
              },
              {
                label: "Execution of orders in financial products",
                value: "Execution of orders in financial products",
              },
              {
                label: "Arranging investments",
                value: "Arranging investments",
              },
              {
                label: "Discretionary Portfolio Management",
                value: "Discretionary Portfolio Management",
              },
              {
                label: "Non-discretionary Portfolio Management",
                value: "Non-discretionary Portfolio Management",
              },
            ],
          },
          {
            label: "Other",
            name: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Which amount of assets do you want to be serviced by us?",
        megaName: "Which_amount_of_assets_do_you_want_to_be_serviced_by_us",
        megaInputs: [
          {
            label: "USD",
            name: "USD",
            type: "number",
            require: true,
            error: false,
          },
          {
            label: "% of total net wealth",
            name: "total_net_wealth",
            type: "number",
            require: true,
            error: false,
          },
        ],
      },

      {
        label: "What kind of assets will be transferred to us?  ",
        type: "select",
        name: "What_kind_of_assets_will_be_transferred_to_us",
        placeholder: "Select",
        options: [
          { label: "Securities", value: "securities" },
          {
            label: "Cash",
            value: "cash",
          },
          {
            label: "Cash & Securities",
            value: "Cash & Securities",
          },
        ],
        require: true,
        error: false,
      },

      {
        label: "What is the expected annual deposit of assets?  ",
        type: "select",
        name: "What_is_the_expected_annual_deposit_of_assets",
        placeholder: "Select",
        options: [
          { label: "Less than 500.000 USD", value: "Less than 500.000 USD" },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD",
          },
          {
            label: "More than 1.000.000 USD",
            value: "More than 1.000.000 USD",
          },
        ],
        require: true,
        error: false,
      },

      {
        label: "What is the expected annual withdrawal of assets?",
        type: "select",
        name: "What_is_the_expected_annual_withdrawal_of_assets",
        placeholder: "Select",
        options: [
          { label: "Less than 500.000 USD", value: "Less than 500.000 USD" },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD",
          },
          {
            label: "More than 1.000.000 USD",
            value: "More than 1.000.000 USD",
          },
        ],
        require: true,
        error: false,
      },

      {
        label: "What is the expected number of trades per month?",
        type: "select",
        name: "What_is_the_expected_number_of_trades_per_month",
        placeholder: "Select",
        options: [
          { label: "0 - 20", value: "0-20" },
          {
            label: "21 - 50",
            value: "21-50",
          },
          {
            label: "50+",
            value: "50+",
          },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        bigDesign: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "Which Trading Platform would you like access to?",
        megaName: "Which_Trading_Platform_would_you_like_access_to",
        megaInputs: [
          {
            label: "Iress (Equities)",
            name: "Iress_Equities",
            type: "select",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "Leverage",
                value: "Leverage",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],
          },

          {
            label: "MT5 (FX - Commodities - Indices)",
            type: "select",
            name: "MT5_FX_Commodities_Indices",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "1:100",
                value: "1:100",
              },
              {
                label: "1:30",
                value: "1:30",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],
          },

          {
            label:
              "ARK Pro (FX - Commodities - Indices - Equities - Options - Futures)",
            type: "select",
            name: "ARK_Pro_FX_Commodities_Indices_Equities_Options_Futures",
            placeholder: "In terms of leverage required, please choose one",
            options: [
              {
                label: "Leverage",
                value: "Leverage",
              },
              {
                label: "No Leverage",
                value: "No Leverage",
              },
            ],
          },

          {
            label: "Dealing Desk/Emails/Voice/BBG Chat",
            type: "select",
            name: "Dealing_Desk_Emails_Voice_BBG_Chat",
            placeholder: "Dealing Desk/Emails/Voice/BBG Chat",
            options: [
              {
                label: "Dealing Desk/Emails/Voice/BBG Chat",
                value: "Dealing Desk/Emails/Voice/BBG Chat",
              },
            ],
          },
        ],
      },

      {
        mega: true,
        magaTitle: "How will the assets be transferred to/from us?",
        megaName: "How_will_the_assets_be_transferred_to_from_us",
        megaInputs: [
          {
            label: "Name financial institution",
            name: "Name_financial_institution",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Country financial institution",
            name: "Country_financial_institution",
            type: "text",
            require: true,
            error: false,
          },
          {
            label: "Name accountholder ",
            name: "Name_accountholder",
            type: "text",
            require: true,
            error: false,
          },
        ],
      },
    ],
  },

  // compmay wealth
  {
    inputs: [
      {
        pageTitle: "About the company’s wealth",
      },

      {
        mega: true,
        magaTitle:
          "If the company is not regulated by a Financial Authority, please provide the information below for point 1 and 2:",
        megaName:
          "If_the_company_is_not_regulated_by_a_Financial_Authority_please_provide_the_information_below_for_point_1_and_2",
        megaInputs: [
          {
            label:
              "1)	What is the main source of initial capital for the company?",
            name: "What_is_the_main_source_of_initial_capital_for_the_company",
            type: "text",
            require: false,
            error: false,
          },
          {
            label: "2)	How was the initial capital sourced/generated?",
            name: "How_was_the_initial_capital_sourced_generated",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },

      {
        label: "What is the estimated global net wealth of your company? ",
        type: "select",
        name: "What_is_the_estimated_global_net_wealth_of_your_company",
        placeholder: "Select",
        options: [
          { label: "Less than 500.000 USD", value: "Less than 500.000 USD" },
          {
            label: "500.000 to 1.000.000 USD",
            value: "500.000 to 1.000.000 USD",
          },
          {
            label: "1.000.000 to 5.000.000 USD",
            value: "1.000.000 to 5.000.000 USD",
          },
          {
            label: "5.000.000 to 10.000.000 USD",
            value: "5.000.000 to 10.000.000 USD",
          },
          {
            label: "More than 10.000.000 USD",
            value: "More than 10.000.000 USD",
          },
        ],
        require: true,
        error: false,
      },

      {
        mega: true,
        magaTitle: "Company a Personal Investment ",
        megaName: "Company_a_Personal_Investment",
        megaInputs: [
          {
            label: "Is your Company a Personal Investment Vehicle? ",
            name: "Is_your_Company_a_Personal_Investment_Vehicle",
            type: "select",
            placeholder: "Select",
            options: [
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ],

            require: true,
            error: false,
          },
          {
            label: "If Yes what is the source of the",
            name: "If_Yes_what_is_the_source_of_the",
            type: "select",
            placeholder: "Select",
            options: [
              { label: "Professional Income", value: "Professional Income" },
              { label: "Sale of Investments", value: "Sale of Investments" },
              {
                label: "Real estate transactions",
                value: "Real estate transactions",
              },
              { label: "Gift / Inheritance", value: "Gift / Inheritance" },
            ],
            require: false,
            error: false,
          },
        ],
      },

      {
        mega: true,
        magaTitle: "Vehicle’s capital",
        megaName: "Vehicles_capital",
        megaInputs: [
          {
            label: "Vehicle’s capital",
            name: "Vehicles_capital",
            type: "select",
            placeholder: "Select",
            options: [
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ],
            require: true,
            error: false,
          },
          {
            label: "Other",
            name: "Other",
            type: "text",
            require: false,
            error: false,
          },
        ],
      },
    ],
  },

  // company expiernce
  {
    inputs: [
      {
        pageTitle:
          "About the company’s experience and understanding of financial markets and instruments",
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "How is the company’s knowledge and understanding of financial markets and products?",
        megaName:
          "How_is_the_company_knowledge_and_understanding_of_financial_markets_and_products",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Poor",
                value: "Poor",
              },
              {
                label: "Moderate ",
                value: "Moderate ",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Excellent",
                value: "Excellent",
              },
            ],
          },
          {
            name: "Other_How_is_your_knowledge_and_understanding_of_financial_markets_and_products",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "For how long has the company been active on the financial markets / investing in financial products?",

        megaName:
          "For_how_long_has_compnay_been_active_on_the_financial_markets_investing_in_financial_products",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "Less than 1 year",
                value: "Less than 1 year",
              },
              {
                label: "1 to 2 years ",
                value: "1 to 2 years ",
              },
              {
                label: "3 to 5 years",
                value: "3 to 5 years",
              },
              {
                label: "more than 5 years",
                value: "more than 5 years",
              },
            ],
          },
          {
            name: "Other_For_how_long_have_you_been_active_on_the_financial_markets_investing_in_financial_products",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle: "How have the company’s assets been managed before?",
        megaName: "How_have_company_assets_been_managed_before",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "by myself",
                value: "by myself",
              },
              {
                label: "with advice of a financial advisor",
                value: "with advice of a financial advisor",
              },
              {
                label: "non-discretionary management",
                value: "non-discretionary management",
              },
              {
                label: "discretionary management",
                value: "discretionary management",
              },
            ],
          },
          {
            name: "Other_How_have_your_assets_been_managed_before",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "In which financial products has the company already invested on a regular basis?",
        megaName:
          "In_which_financial_products_has_the_compnay_already_invested_on_a_regular_basis",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "shares (equity)",
                value: "shares (equity)",
              },
              {
                label: "bonds (debentures)",
                value: "bonds (debentures)",
              },
              {
                label: "collective investment funds",
                value: "collective investment funds",
              },
              {
                label: "sukuk",
                value: "sukuk",
              },
              {
                label: "structured products",
                value: "structured products",
              },
              {
                label: "derivatives (options, futures)",
                value: "derivatives (options, futures)",
              },
              {
                label: "insurance investments",
                value: "insurance investments",
              },
              {
                label: "commodities",
                value: "commodities",
              },
              {
                label: "currencies",
                value: "currencies",
              },
              {
                label: "hedge funds",
                value: "hedge funds",
              },
              {
                label: "private equity",
                value: "private equity",
              },
            ],
          },
          {
            name: "Other_In_which_financial_products_have_you_already_invested_on_a_regular_basis",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "Which financial products are you interested in investing with Ark Capital?",
        megaName:
          "which_financial_products_are_you_interested_in_investing_with_ark_capital",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "shares (equity)",
                value: "shares (equity)",
              },
              {
                label: "bonds (debentures)",
                value: "bonds (debentures)",
              },
              {
                label: "collective investment funds",
                value: "collective investment funds",
              },
              {
                label: "sukuk",
                value: "sukuk",
              },
              {
                label: "structured products",
                value: "structured products",
              },
              {
                label: "derivatives (options, futures)",
                value: "derivatives (options, futures)",
              },
              {
                label: "insurance investments",
                value: "insurance investments",
              },
              {
                label: "commodities",
                value: "commodities",
              },
              {
                label: "currencies",
                value: "currencies",
              },
              {
                label: "hedge funds",
                value: "hedge funds",
              },
              {
                label: "CFDs",
                value: "CFDs",
              },
            ],
          },
          {
            name: "Other_which_financial_products_are_you_interested_in_investing_with_ark_capital",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        mega: true,
        multiple: true,
        megaError: false,
        megaRequire: true,
        magaTitle:
          "How often does the company take investments decisions (buy/sell)?",
        megaName:
          "How_often_does_the_company_take_investments_decisions_buy_sell",
        megaInputs: [
          {
            type: "select",
            name: "selectedValue",
            placeholder: "select",
            options: [
              {
                label: "seldom",
                value: "seldom",
              },
              {
                label: "monthly",
                value: "monthly",
              },
              {
                label: "weekly",
                value: "weekly",
              },
              {
                label: "daily",
                value: "daily",
              },
            ],
          },
          {
            name: "Other_How_often_do_you_take_investments_decisions_buy_sell",
            label: "Other",
            type: "text",
          },
        ],
      },

      {
        label:
          "About which financial services and/or financial products would the company like to receive more information?",
        type: "text",
        name: "About_which_financial_services_and_or_financial_products_would_the_company_like_to_receive_more_information",

        require: true,
        error: false,
      },
    ],
  },

  // final page
  {
    inputs: [
      {
        pageTitle: "last Page",
      },
      {
        content: [
          {
            title: "Data protection statement",
            text: [
              "In order to provide you with products and services, we need to collect, use, share and store personal and financial information about you. This includes the information requested on this form and information obtained from third parties. The information requested may be used to assist us in providing the service you are applying for, to advise you of other products and services, to confirm, update and enhance records, and to establish your identity. The data that we collect may be shared/transferred to / or stored / or processed at, our offices in other countries outside the DIFC.",
            ],
          },
          {
            title: "Declaration",
            text: [
              "We confirm that all the assets/funds to be invested upon advice/arrangements by you have been lawfully acquired and are not derived from, either directly or indirectly, or otherwise connected with any criminal or unlawful activity. ",
              "We declare that the above-mentioned information is true and correct to the best of our knowledge and it has been made in good faith. We accept that you will rely on this information to fulfil all of its regulatory requirements.",

              "We confirm that the company has net assets of more than 500.000 USD and sufficient experience and understanding of relevant financial markets, products or transactions and any associated risks. Therefore, we accept classification as Professional Client.",
            ],
          },
        ],
      },

      {
        label: "Name",
        type: "text",
        name: "Capacity",
        require: true,
        error: false,
      },
      {
        label: "Date",
        type: "date",
        name: "Date_of",
        require: true,
        error: false,
      },
      {
        label: "Signature",
        type: "upload",
        name: "Signature",
        require: true,
        error: false,
      },
    ],
  },
];

export default CIF_Corporate_Form_conten;
