import disvoverMoreImage from "../../../assests/images/services/Advisory/discover-more.jpg";

const advisoryContent = {
  en: {
    text: [
      "Ark Capital Management offers a regulated ecosystem for managers and clients, allowing them to delegate investment decisions and client assets to an advisor or fund under our team’s supervision. ",

      "This structure ensures that managers comply with client requirements and regulatory standards, which we monitor through trade and reporting oversight. ",
    ],
    image: disvoverMoreImage,
  },
  ru: {
    text: [
      "Платформа для консультирования и управления фондами – Ark Capital Management предлагает регулируемую экосистему для менеджеров и клиентов, позволяющую им делегировать инвестиционные решения и активы клиентов консультанту или фонду под руководством нашей команды.",

      "Такая структура гарантирует, что менеджеры соблюдают требования клиентов и нормативные стандарты, которые мы отслеживаем с помощью надзора за торговлей и отчетностью.",
    ],
    image: disvoverMoreImage,
  },
};

// Function to retrieve content based on the selected language
const getAdvisoryContent = (language) => {
  return advisoryContent[language];
};

export default getAdvisoryContent;
