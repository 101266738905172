import { useContext } from "react";
// UI
import Container from "components/Container/Container";

import SmallList from "../UI/SmallList";
import BigList from "../UI/BigList";

// content
import { getriskWarningFirstChunk } from "constant/Disclaimer/RiskWarning/RiskWarning";
import { getRiskWarningNoRecommendation } from "constant/Disclaimer/RiskWarning/RiskWarning";
import { getRiskWarningContentList } from "constant/Disclaimer/RiskWarning/RiskWarning";

// context
import { LanguageContext } from "context/LanguageContext";

const RiskWarning = () => {
  const { language } = useContext(LanguageContext);

  const riskWarningFirstChunk = getriskWarningFirstChunk(language);
  const RiskWarningNoRecomandation = getRiskWarningNoRecommendation(language);
  const RiskWarningContentList = getRiskWarningContentList(language);

  return (
    <main>
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-center text-3xl lg:text-4xl mt-secondary lg:mt-primary ">
          <h1>
            {language === "en" ? "Risk Warning " : "Предупреждение о рисках"}
          </h1>
        </div>

        <div className="mb-primary">
          <section className="mt-secondary">
            <SmallList contentList={riskWarningFirstChunk} />
          </section>

          <section className="mt-secondary">
            <BigList contentList={RiskWarningNoRecomandation} />
          </section>

          <section className="mt-secondary">
            <SmallList contentList={RiskWarningContentList} />
          </section>
        </div>
      </Container>
    </main>
  );
};

export default RiskWarning;
