import { IsVerfiedProvider } from "./IsVerfiedContext";
import { UserInfoProvicer } from "./UserContext";
import { FormStateProvider } from "./FormStateContext";
import { CookieContextProvider } from "./CookiesContext";
import { SalesForceProvider } from "./SalesForceContext";
import { LanguageProvider } from "./LanguageContext";
import { FAQProvider } from "./FAQContext";
import { NewsProvider } from "./NewsContext";

const ContextProvider = ({ children }) => {
  return (
    <IsVerfiedProvider>
      <UserInfoProvicer>
        <FormStateProvider>
          <CookieContextProvider>
            <SalesForceProvider>
              <LanguageProvider>
                <FAQProvider>
                  <NewsProvider>{children}</NewsProvider>
                </FAQProvider>
              </LanguageProvider>
            </SalesForceProvider>
          </CookieContextProvider>
        </FormStateProvider>
      </UserInfoProvicer>
    </IsVerfiedProvider>
  );
};

export default ContextProvider;
