import humalityImage from "../../../assests/images/About/ethos/humility-image.jpg";
import alignmentImage from "../../../assests/images/About/ethos/alignmentimage.jpg";
import integrityImage from "../../../assests/images/About/ethos/integrity-image.jpg";

const ethosConent = {
  en: {
    title:
      "Our corporate culture at ARK Capital is built on a set of guiding principles that shape every aspect of our business. From working with employees and partners to engaging with investors and shareholders, we are committed to upholding these values because we believe that by living up to these standards, we're better equipped to manage assets and deliver optimal results for our clients.",
    list: [
      {
        header: "Humility",
        text: "We approach our work with a humble attitude, recognizing both our strengths and constraints. This perspective enables us to identify areas where we can improve our decision-making and investment capabilities, ultimately leading to better outcomes for our clients",
        image: humalityImage,
      },
      {
        header: "Alignment",
        text: "Our clients' interests come first. To ensure that our purpose and motivation as investors are completely aligned with those of our clients, we invest alongside them and structure our investment professionals' compensation to incentivize long-term thinking",
        image: alignmentImage,
      },
      {
        header: "Integrity",
        text: "As custodians of capital, we hold ourselves to the highest ethical standards, always striving to do what is right. By upholding our principles and commitments, we cultivate long-lasting connections built on a foundation of trust and integrity",
        image: integrityImage,
      },
    ],
  },
  ru: {
    title:
      "Ark Capital Management Limited - ведущая компания по управлению активами и первоклассная брокерская фирма, базирующаяся в Дубае, Объединенные Арабские Эмираты. Основанная в 2013 году, компания авторизована Управлением финансовых услуг Дубая (DFSA) и специализируется на разработке индивидуальных решений для состоятельных людей.",
    list: [
      {
        header: "Смирение",
        text: "Мы подходим к нашей работе со скромным отношением, признавая как наши сильные стороны, так и ограничения. Такая перспектива позволяет нам определить области, в которых мы можем улучшить наши возможности в области принятия решений и инвестирования, что в конечном итоге приведет к лучшим результатам для наших клиентов",
        image: humalityImage,
      },
      {
        header: "Выравнивание",
        text: "Интересы наших клиентов превыше всего. Чтобы гарантировать, что наши цели и мотивация как инвесторов полностью совпадают с целями и мотивацией наших клиентов, мы инвестируем вместе с ними и структурируем вознаграждение наших специалистов по инвестициям таким образом, чтобы стимулировать долгосрочное мышление",
        image: alignmentImage,
      },
      {
        header: "Целостность ",
        text: "Как хранители капитала, мы придерживаемся самых высоких этических стандартов, всегда стремясь поступать правильно. Придерживаясь наших принципов и обязательств, мы развиваем долгосрочные связи, построенные на фундаменте доверия и добросовестности",
        image: integrityImage,
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getEthosContent = (language) => {
  return ethosConent[language];
};

export default getEthosContent;
