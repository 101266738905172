const GetfooterDisclaimerLinks = () => {
  const links = {
    en: [
      {
        link: "Data Protection Policy",
        to: "/protection-policy",
      },
      {
        link: "Cookie Policy",
        to: "/cookie-policy",
      },
      {
        link: "Diclaimer",
        to: "/disclaimer",
      },
      {
        link: "Risk Warning",
        to: "/risk-warning",
      },
      {
        link: "Terms of use",
        to: "/terms-of-use",
      },
    ],

    ru: [
      {
        link: "Политика защиты данных.",
        to: "/protection-policy",
      },
      {
        link: "Политика файлов cookie",
        to: "/cookie-policy",
      },
      {
        link: "Отказ от ответственности.",
        to: "/disclaimer",
      },
      {
        link: "Предупреждение о рисках",
        to: "/risk-warning",
      },
      {
        link: "Условия использования",
        to: "/terms-of-use",
      },
    ],
  };

  return links;
};

export default GetfooterDisclaimerLinks;
