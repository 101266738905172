import { createContext, useState } from "react";

export const SalesForceContext = createContext();

export const SalesForceProvider = ({ children }) => {
  // state to save response form API request
  const [accessToken, setAccessToken] = useState(null);

  // Variables to save salesforce endpoints
  const salesfoceEndPoint = {
    CIF_individual: "saveIndividualCIF",
    CIF_corporate: "saveCorporateCIF",
    FATCA_individual: "saveIndividualFatca",
    FATCA_corporate: "saveCorporateFatca",
    Document_individual: "saveIndividualDocuments",
    Document_corporate: "saveCorperateDocument",
  };

  return (
    <SalesForceContext.Provider
      value={{
        accessToken,
        setAccessToken,
        salesfoceEndPoint,
      }}
    >
      {children}
    </SalesForceContext.Provider>
  );
};
