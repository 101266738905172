import teamImage from "../../assests/images/About/Team/team.jpg";
import ownerImage from "../../assests/images/About/Team/SAed logo.jpg";

import imageOne from "../../assests/images/About/supervision/1.jpg";
import imageTwo from "../../assests/images/About/supervision/2.jpg";
import imageThree from "../../assests/images/About/supervision/3.jpg";

const contactImages = [imageOne, imageTwo, imageThree];

export { teamImage, ownerImage, contactImages };
