const teamMemberContent = {
  en: [
    {
      title: "COO",
      text: "With extensive expertise in the financial industry, particularly in operational management, she has led Mashreq Securities, the brokerage arm of Mashreq Bank, with great success. Her track record speaks for itself, showcasing her ability to develop and implement efficient operational processes that result in a seamless client journey.",
    },
    {
      title: "CRO",
      text: "Boasting an extensive career in developing and implementing effective risk management frameworks, he brings invaluable expertise to ARK Capital. His previous roles include serving as the Head of CFD Desk and Risk Management at Artion Securities, as well as the Head of International Equities and CFD Desk at Egnatia Bank in Athens, Greece.",
    },
    {
      title: "Sales and execution",
      text: "Our team of sales and execution traders brings together a diverse range of skill sets and backgrounds, perfectly aligned with our tailored global client and product coverage. This dynamic mix includes investment bankers with extensive expertise in capital markets, private equity, and M&A, analysts with strong backgrounds in equity research and economic forecasting, as well as traders who specialize in market making, execution, and risk management.",
    },
    {
      title: "Compliance officer",
      text: "Our dedicated Compliance Officer is a seasoned professional with a strong track record in ensuring regulatory compliance and fostering a culture of compliance within our organization. As a natural leader and collaborator, he works closely with all teams to proactively address compliance issues and drive our continued success.",
    },
  ],
  ru: [
    {
      title: "(COO)",
      text: "Обладая обширным опытом в финансовой индустрии, особенно в области операционного управления, она с большим успехом руководила Mashreq Securities, брокерским подразделением Mashreq Bank. Ее послужной список говорит сам за себя, демонстрируя ее способность разрабатывать и внедрять эффективные операционные процессы, которые обеспечивают бесперебойную работу с клиентами.",
    },
    {
      title: "(CRO)",
      text: "Обладая обширной карьерой в области разработки и внедрения эффективных систем управления рисками, он привносит бесценный опыт в ARK Capital. Его предыдущие должности включают должность руководителя отдела CFD и управления рисками в Artion Securities, а также руководителя отдела международных операций с акциями и CFD в Egnatia Bank в Афинах, Греция.",
    },
    {
      title: "Продажи и исполнение",
      text: "Наша команда трейдеров по продажам и исполнению сделок объединяет широкий спектр навыков и опыта работы, идеально соответствующих нашему индивидуальному глобальному охвату клиентов и продуктов. В этот динамичный состав входят инвестиционные банкиры с обширным опытом работы на рынках капитала, прямых инвестиций и слияний и поглощений, аналитики с большим опытом в области исследований акционерного капитала и экономического прогнозирования, а также трейдеры, специализирующиеся на формировании рынка, исполнении сделок и управлении рисками.",
    },
    {
      title: "Специалист по соблюдению требований",
      text: "Наш преданный своему делу специалист по комплаенсу - опытный профессионал с большим опытом в обеспечении соблюдения нормативных требований и формировании культуры комплаенса в нашей организации. Будучи прирожденным лидером и сотрудником, он тесно сотрудничает со всеми командами, чтобы активно решать вопросы соблюдения нормативных требований и способствовать нашему дальнейшему успеху.",
    },
  ],
};

// Function to retrieve content based on the selected language
const getTeamMemberContent = (language) => {
  return teamMemberContent[language];
};

export default getTeamMemberContent;
