const HeroContent = {
  en: {
    title: "ARK CAPITAL MANAGEMENT",
    subTitle: "IN THE HEART OF DUBAI INTERNATIONAL FINANCIAL CENTRE",
  },
  ru: {
    title: "УПРАВЛЕНИЕ КАПИТАЛОМ ARK",
    subTitle: "В самом центре Дубайского международного финансового центра",
  },
};

const getContent = (language) => {
  return HeroContent[language];
};

export default getContent;
