import assestImageOne from "../../../assests/images/services/AssetManagement/assest-2.jpg";
import assestImageTwo from "../../../assests/images/services/AssetManagement/assest-4.jpg";

const heroContent = {
  en: {
    title:
      " Transform Your Financial Landscape. Harness the Power of ARK Capital's Premier Asset Management Solutions - Safeguard, Amplify, and Flourish with Confidence",
    heroContent: [
      {
        text: [
          "Intrinsic to our mission is our commitment to safeguarding and expanding our clients' assets. Our philosophy is built upon client-centered principles, and we work closely with our clients to fully understand their unique requirements and circumstances.",
          "Our investment managers have superior expertise across multiple asset classes including equity, fixed-income, and private investments. They deploy a wide range of strategies to generate excess returns while mitigating risk. With consistent and robust monitoring, we draw upon market research to ensure accountability and innovation of the investment process.",
        ],
        image: assestImageOne,
      },
      {
        text: [
          "Our team is dedicated to providing tailored solutions that align with each client's expectations and limitations. We take pride in developing a roadmap that is both appropriate and effective, keeping our clients' best interests at the forefront of every decision.",
          "Our services offer global market access and flexible investment strategies across multiple asset classes and currencies, allowing for the diversification of your portfolio",
        ],
        image: assestImageTwo,
      },
    ],
  },
  ru: {
    title:
      "Преобразите свой финансовый ландшафт. Используйте мощь лучших решений ARK Capital по управлению активами - защищайте, расширяйте и процветайте с уверенностью",
    heroContent: [
      {
        text: [
          "Неотъемлемой частью нашей миссии является наше стремление к сохранению и расширению активов наших клиентов. Наша философия основана на принципах клиентоориентированности, и мы тесно сотрудничаем с нашими клиентами, чтобы полностью понять их уникальные требования и обстоятельства.",
          "Наши инвестиционные менеджеры обладают превосходным опытом работы с различными классами активов, включая акционерный капитал, инвестиции с фиксированным доходом и частные инвестиции. Они используют широкий спектр стратегий для получения избыточной прибыли при одновременном снижении рисков. Благодаря постоянному и надежному мониторингу мы опираемся на исследования рынка, чтобы обеспечить подотчетность и инновационность инвестиционного процесса.",
        ],
        image: assestImageOne,
      },
      {
        text: [
          "Наша команда стремится предоставлять индивидуальные решения, соответствующие ожиданиям и ограничениям каждого клиента. Мы гордимся тем, что разрабатываем дорожную карту, которая является одновременно подходящей и эффективной, ставя интересы наших клиентов во главу угла при принятии каждого решения.",
          "Наши услуги предлагают доступ к глобальным рынкам и гибкие инвестиционные стратегии для различных классов активов и валют, что позволяет диверсифицировать ваш портфель",
        ],
        image: assestImageTwo,
      },
    ],
  },
};

// Function to retrieve content based on the selected language
const getHeroContent = (language) => {
  return heroContent[language];
};

export default getHeroContent;
