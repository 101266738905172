import { Link } from "react-router-dom";

const Button = ({ children, to, onClick }) => {
  return (
    <Link to={to}>
      <button
        onClick={onClick}
        className="bg-newBlue px-small py-2.5 text-white hover:bg-hoverBlue rounded-md tracking-wide"
      >
        {children}
      </button>
    </Link>
  );
};

export default Button;
