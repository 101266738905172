import Container from "../../../components/Container/Container";
import quickLinksContent from "../../../constant/Footer/FooterQuickLinks";
import { Link } from "react-router-dom";
const FooterBanner = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="py-7 bg-[#060B18]">
      <Container className={"px-smallContainer md:px-mainContainer"}>
        <div className="flex flex-col lg:flex-row gap-y-6 items-center justify-between">
          <ul className="flex flex-wrap gap-y-4 justify-center lg:justify-start items-center  text-[#b3b3b3] text-[13px]">
            {quickLinksContent.map((item, index) => (
              <Link
                key={index}
                onClick={scrollToTop}
                to={item.to}
                className="border-r border-lightGreyFont last:border-r-0 px-4 first:px-0 first:pr-4 last:px-0 last:pl-4 hover:text-lightWhiteFont transition ease-in duration-100"
              >
                {item.link}
              </Link>
            ))}
          </ul>

          <p className="text-[13px] text-[#b3b3b3]">
            Powered By ARK Capital Management, Copyright 2023 All Right Reserved
          </p>
        </div>
      </Container>
    </div>
  );
};

export default FooterBanner;
