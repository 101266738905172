// const servicesLinks = [
//   {
//     link: "Custody Services",
//     to: "/custody   ",
//   },
//   {
//     link: "Prime Brokerage",
//     to: "/prime-brokerage",
//   },
//   {
//     link: "Asset Management",
//     to: "/asset-management",
//   },
//   {
//     link: "Advisory",
//     to: "/advisory",
//   },
// ];

// export default servicesLinks;

const getServicesLinks = () => {
  const links = {
    en: [
      {
        link: "Custody Services",
        to: "/custody   ",
      },
      {
        link: "Prime Brokerage",
        to: "/prime-brokerage",
      },
      {
        link: "Asset Management",
        to: "/asset-management",
      },
      {
        link: "Advisory",
        to: "/advisory",
      },
    ],

    ru: [
      {
        link: "Услуги по хранению",
        to: "/custody   ",
      },
      {
        link: "Прайм-брокеридж",
        to: "/prime-brokerage",
      },
      {
        link: "Управление активами",
        to: "/asset-management",
      },
      {
        link: "Консультации",
        to: "/advisory",
      },
    ],
  };

  return links;
};

export default getServicesLinks;
