import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

// UI
import Container from "components/Container/Container";
import TwoColumnsGrid from "components/UI/TwoColumnsGrid";

// content
import getDiscoverMoreContent from "constant/services/Advisory/AdvisoryMore";

const DiscoverMore = () => {
  const { language } = useContext(LanguageContext);
  const content = getDiscoverMoreContent(language);
  return (
    <section className="my-secondary lg:my-primary">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <TwoColumnsGrid content={content} />
      </Container>
    </section>
  );
};

export default DiscoverMore;
