import { useState, useContext } from "react";
// UI
import Container from "components/Container/Container";
import Button from "components/UI/Button";
// component
import SmallList from "../UI/SmallList";
import ManageCookie from "./ManageCookie";

// content
import { getwhatAreCookies } from "constant/Disclaimer/Cookie/Cookies";
import { getuseCookieFor } from "constant/Disclaimer/Cookie/Cookies";
// context
import { LanguageContext } from "context/LanguageContext";

const CookiePolicy = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  const { language } = useContext(LanguageContext);

  const whatAreCookies = getwhatAreCookies(language);
  const useCookieFor = getuseCookieFor(language);

  const handleManageCookieModal = () => {
    setIsModalActive((cur) => !cur);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <main className="py-secondary lg:py-primary relative ">
      <Container className={"px-smallContainer lg:px-mainContainer"}>
        <div className="text-center text-3xl lg:text-4xl  ">
          <h1>
            {language === "en"
              ? "Cookie Policy"
              : "Политика в отношении файлов cookie"}
          </h1>
        </div>

        <div>
          <section className="mt-secondary">
            <SmallList contentList={whatAreCookies} />
          </section>
          <section className="mt-secondary">
            <SmallList contentList={useCookieFor} />
          </section>
        </div>

        <div>
          <Button onClick={handleManageCookieModal}>
            {language === "en" ? "Manage consent" : "Управление согласием"}
          </Button>
        </div>

        <ManageCookie
          onClick={handleManageCookieModal}
          active={isModalActive}
        />
      </Container>
    </main>
  );
};

export default CookiePolicy;
