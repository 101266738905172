const primeBrokerageSolutionContent = {
  en: {
    header: {
      title: "Solutions and funding services",
      subTitle:
        "We offer customized reporting solutions and funding services in accordance with appropriate risk allocation.",
    },
    list: [
      "Sub custody and leverage accounts",
      "OTC FX spot and derivatives",
      "OTC direct dealing desk coverage.",
      "Structured Notes",
      "OTC Fixed income trading",
      "Direct dealing desk contact",
    ],
  },
  ru: {
    header: {
      title: "Решения и услуги финансирования",
      subTitle:
        "Мы предлагаем индивидуальные решения в области отчетности и финансирования в соответствии с соответствующим распределением рисков.",
    },
    list: [
      "Счета субподряда и кредитного плеча",
      "Внебиржевой валютный спот и производные финансовые инструменты",
      "Покрытие внебиржевого отдела прямых продаж ",
      "Структурированные заметки",
      "Внебиржевая торговля с фиксированным доходом ",
      "Прямой контакт со службой поддержки",
    ],
  },
};

// Function to retrieve content based on the selected language
const getPrimeBrokerageSolutionContent = (language) => {
  return primeBrokerageSolutionContent[language];
};

export default getPrimeBrokerageSolutionContent;
