import { useState } from "react";
import { Plus, Minus } from "@phosphor-icons/react";

const SmartFeaturesBox = ({ content }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="flex-1">
      <h2 className="text-3xl text-blue mb-secondary">{content.header}</h2>
      <div>
        {content.list.map((item, index) => (
          <div key={index}>
            <div
              className="flex items-center cursor-pointer gap-x-4 border-b border-gray-200 pb-2"
              onClick={() => handleClick(index)}
            >
              <span
                className={`transition ease-in duration-500 text-newBlue p-1 `}
              >
                {activeIndex === index ? (
                  <Minus size={20} />
                ) : (
                  <Plus size={20} />
                )}
              </span>
              <h3 className="text-lg">{item.title}</h3>
            </div>
            <div
              className={` ${
                activeIndex === index
                  ? "max-h-[500px] opacity-100 visible select-auto my-8  "
                  : "max-h-0 opacity-0 select-none invisible my-2"
              } transition-all ease-in duration-500 `}
            >
              <span className=" text-lightFont flex flex-col gap-2 text-[14px]">
                {item.text}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmartFeaturesBox;
