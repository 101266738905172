import React from "react";

const BigList = ({ contentList }) => {
  return (
    <div className="lg:w-[90%] text-font">
      {contentList.contentTwo && (
        <div className="flex flex-col gap-3 mb-10">
          {contentList?.contentTwo?.map((text, index) => (
            <p className="text-[17px]" key={index}>
              {text}
            </p>
          ))}
        </div>
      )}
      <h6 className="font-semibold underline text-xl mb-3 tracking-wider">
        {contentList?.header}
      </h6>
      <h3>{contentList?.list?.title}</h3>
      <ul className="flex flex-col gap-3 list-disc list-inside mt-4 mb-8">
        {contentList.list?.list?.map((text, index) => (
          <li className="text-[17px]" key={index}>
            {text}
          </li>
        ))}
      </ul>

      <div className="flex flex-col gap-3">
        {contentList?.content?.map((text, index) => (
          <p className="text-[17px]" key={index}>
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default BigList;
